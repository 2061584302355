import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';

const ChannelMenu = ({ selectedMoreId, setSelectedMoreId, channelSettingsAnchor, setOpenChannelSettingsModal, closeOptionsMenu, handleOpenDeleteModal }) => {
  return (
    <ClickAwayListener onClickAway={() => {}}>
      <Popper open={selectedMoreId} anchorEl={channelSettingsAnchor} placement={'bottom'} transition onMouseOver={() => setSelectedMoreId(true)} onMouseLeave={() => setSelectedMoreId(false)}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box className="movie-options-cloud" sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
              <MenuList>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenChannelSettingsModal(true);
                    closeOptionsMenu();
                  }}
                >
                  Settings
                </MenuItem>

                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenDeleteModal(true);
                    closeOptionsMenu();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default ChannelMenu;
ChannelMenu.defaultProps = {
  selectedMoreId: '',
  setSelectedMoreId: '',
  channelSettingsAnchor: '',
  setOpenChannelSettingsModal: () => {},
  closeOptionsMenu: false
};
