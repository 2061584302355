import { makeStyles } from '@material-ui/core';

const useStyleCardAsset = () => {
  const styleMovieThumbnail = makeStyles({
    newMovieWrapp: { position: 'relative', marginBottom: '20px', width: '100%' },
    thubmnailSize: {
      width: '350px',
      minHeight: '100px',
      ['@media (max-width:600px)']: {
        width: '98%'
      }
      // ['@media (min-width:720px)']: {
      //   width: '260px'
      // },
      // ['@media (min-width:780px)']: {
      //   width: '270px'
      // },
      // ['@media (min-width:840px)']: {
      //   width: '290px'
      // },
      // ['@media (min-width:900px)']: {
      //   width: '320px'
      // },
      // ['@media (min-width:960px)']: {
      //   width: '350px'
      // },
      // ['@media (min-width:1000px)']: {
      //   width: '400px'
      // },
      // ['@media (min-width:1280px)']: {
      //   width: '350px'
      // },
      // ['@media (min-width:1500px)']: {
      //   width: '400px'
      // },
      // ['@media (min-width:1750px)']: {
      //   width: '440px'
      // },
      // ['@media (min-width:1920px)']: {
      //   width: '380px'
      // },
      // ['@media (min-width:2220px)']: {
      //   width: '440px'
      // },
      // ['@media (min-width:2450px)']: {
      //   width: '460px'
      // }
    },
    newMovie: {
      position: 'relative'
    },
    isTestimonialFinishedFalse: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      width: '40px',
      height: '40px',
      borderRadius: '50%'
    },
    isTestimonialFinishedTrue: {
      position: 'absolute',
      bottom: '85px',
      right: '20px',
      width: '40px',
      height: '40px',
      borderRadius: '50%'
    },
    logoMovie: {
      borderRadius: '15px',
      border: '1px solid #4E4B66',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      objectFit: 'cover',
      aspectRatio: '16/9'
    },
    loaderWrapp: {
      backgroundColor: 'black'
    },
    checkBox: {
      position: 'absolute',
      width: '25px',
      height: '25px',
      top: '15px',
      left: '13px',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'grey',
      '&:hover': {
        opacity: '1'
      }
    },
    infoDiv: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: '20px'
    },
    infoDivMobile: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '15px'
    },
    movieName: {
      color: 'white',
      fontFamily: "'Poppins', sans-serif",
      fontStyle: 'normal',
      fontWeight: '700',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      fontSize: '13px'
    },
    nameSpan: {
      textAlign: 'left',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      width: '300px',
      whiteSpace: 'nowrap'
    },
    interaction: {
      position: 'absolute',
      bottom: '10px',
      left: '10px'
    },
    movieOptions: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      zIndex: '250'
    },
    date: {
      color: 'gray'
    },
    textDesc: {
      color: 'white',
      fontSize: '11px',
      fontWeight: 'bold',
      marginTop: '3px'
    },
    bottBtnsWrapp: {
      width: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '0.4em',
        height: '7px',
        borderRadius: '10px'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.1)',
        outline: '1px solid slategrey'
      }
    },
    bottBtns: {
      padding: '5px 0',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& :not(:first-child)': {
        marginLeft: '14px'
      },
      '& button': {
        whiteSpace: 'nowrap',
        backgroundColor: '#A996FF',
        border: '1px solid #A996FF',
        color: '#5E5595',
        padding: '2px 8px',
        borderRadius: '10px',
        fontWeight: 'bold'
      }
    },
    uploadImg: {
      position: 'absolute',
      width: '25px',
      height: '25px',
      bottom: '10px',
      right: '10px',
      borderRadius: '50%',
      backgroundColor: 'grey',
      '&:hover': {
        opacity: '1'
      }
    },
    playBtn: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      cursor: 'pointer'
    }
  });
  return { styleMovieThumbnail };
};

export default useStyleCardAsset;
