import { makeStyles } from '@material-ui/core';

const stylesStepStart = makeStyles({
  generalStyle: { color: 'white', width: '100%' },
  header: { fontWeight: 700 },
  inputTitle: { fontWeight: '600' },
  inputClientReview: {
    paddingLeft: '10px',
    outline: 'none',
    color: 'white',
    backgroundColor: '#262338',
    border: '1px solid #262338',
    width: '100%',
    height: '40px',
    borderRadius: '8px',
    '&::placeholder': {
      color: 'white',
      opacity: 0.5
    }
  },
  mainBodyStyle: { marginTop: '35px' },
  channelTypeStyle: { marginTop: '25px' },
  inputWidgetStyle: { marginLeft: '15px' },
  unchecked: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: '#262338', cursor: 'pointer' },
  typesCheckbox: { display: 'flex', alignItems: 'center' },
  labels: { marginLeft: '10px' },
  uncheckedWidget: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: '#262338', marginLeft: '20px', cursor: 'pointer' }
});

const StepStart = ({ channelDescription, channelTitle, dispatch }) => {
  const classes = stylesStepStart();

  return (
    <article className={classes.generalStyle}>
      <div>
        <h1 className={classes.header}>New channel</h1>
      </div>

      <div className={classes.mainBodyStyle}>
        <div>
          <p className={classes.inputTitle}>Channel title:</p>
          <input type="text" placeholder="Channel name" onChange={(e) => dispatch({ type: 'UPDATE_TITLE', payload: e.target.value })} value={channelTitle} className={classes.inputClientReview} />
        </div>

        <div className={classes.channelTypeStyle}>
          <p className={classes.inputTitle}>Description:</p>
          <textarea
            placeholder="Channel description"
            style={{ height: 100, paddingTop: 10 }}
            className={classes.inputClientReview}
            value={channelDescription}
            onChange={(e) => dispatch({ type: 'UPDATE_DESC', payload: e.target.value })}
          ></textarea>
        </div>
      </div>
    </article>
  );
};

export default StepStart;
StepStart.defaultProps = {
  channelType: '',
  channelTitle: '',
  dispatch: () => {}
};
