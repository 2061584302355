const CropLabel = ({ isCrop, handleSetIsCrop }) => {
  return (
    <span
      id="crop-label"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleSetIsCrop((p) => !p);
      }}
      style={{
        position: 'absolute',
        cursor: 'pointer',
        fontWeight: 700,
        backgroundColor: isCrop ? 'green' : 'gray',
        color: 'white',
        fontSize: '9px',
        top: '3px',
        right: '0',
        padding: '4px 7px',
        borderRadius: '10px',
        opacity: '0.6'
      }}
    >
      Crop
    </span>
  );
};

export default CropLabel;
CropLabel.defaultProps = { 
  isCrop: false, 
  handleSetIsCrop: () => {} 
}
