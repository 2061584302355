import addPlus from '../../media/addPlus.png';

const itemsDrodpdown = [
  {
    id: Math.random().toString(),
    value: 'New Question template 1'
  },
  {
    id: Math.random().toString(),
    value: 'New Question template 2'
  },
  {
    id: Math.random().toString(),
    value: 'New Question template 3'
  }
];

const QuestionsList = ({ styles, dispatch, typeId }) => {
  const addQuestion = (item) => {
    dispatch({ type: 'ADD_QUESTION', payload: { ...item, id: Math.random().toString() }, typeId: typeId });
  };

  return (
    <ul className={styles.dropList}>
      {itemsDrodpdown.map((item) => {
        return (
          <li key={item.id} className={styles.dropdownItem} onClick={() => addQuestion(item)}>
            <img src={addPlus} alt="add" />
            <span className={styles.dropdownItemTxt}>{item?.value || 'Name'}</span>
          </li>
        );
      })}
    </ul>
  );
};

export default QuestionsList;
