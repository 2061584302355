import alignCenter from '../buttons/alignCenter.png';
import alignLeft from '../buttons/alignLeft.png';
import alignRight from '../buttons/alignRight.png';
import animation from '../buttons/animation.png';
import bold from '../buttons/bold.png';
import color from '../buttons/color.png';
import italic from '../buttons/italic.png';
import opacity from '../buttons/opacity.png';
import rectangle1 from '../buttons/rectangle1.png';
import rectangle2 from '../buttons/rectangle2.png';
import square from '../buttons/square.png';
import tt from '../buttons/tt.png';
import underline from '../buttons/underline.png';
import trash from '../buttons/trash.png';
import lineV from '../buttons/lineV.png';
import line from '../buttons/line.png';
import shade from '../buttons/shade.png';
import star from '../buttons/star.png';
import polygon from '../buttons/polygon.png';
import rectangle3 from '../buttons/rectangle3.png';
import rectangle4 from '../buttons/rectangle4.png';
import sound from '../buttons/sound.png';
import color2 from '../buttons/color2.png';
import clock from '../buttons/clock.png';
import info from '../buttons/info.png';
import settings from '../buttons/settings.png';
import scale from '../buttons/scale.png';
import downarrow from '../buttons/downarrow.png';
import uparrow from '../buttons/uparrow.png';
import hyphen from '../buttons/hyphen.png';
import leftarrow from '../buttons/leftarrow.png';
import rightarrow from '../buttons/rightarrow.png';
import vline from '../buttons/vline.png';
import movies from '../buttons/movies.png';
import left_top from '../buttons/left-top.svg';
import left_center from '../buttons/left-center.svg';
import left_bottom from '../buttons/left-bottom.svg';

import mid_top from '../buttons/mid-top.svg';
import mid_center from '../buttons/mid-center.svg';
import mid_bottom from '../buttons/mid-bottom.svg';

import right_top from '../buttons/right-top.svg';
import right_center from '../buttons/right-center.svg';
import right_bottom from '../buttons/right-bottom.svg';

const useIcons = () => {
    return {alignCenter, alignLeft, alignRight, animation, bold, color, italic, 
            opacity, rectangle1, rectangle2, square, tt, underline, trash, lineV,
            line, shade, star, polygon, rectangle3, rectangle4, sound, color2, clock, info,
            settings, scale, downarrow, uparrow, hyphen, leftarrow, rightarrow, vline, movies, left_top, left_center,
            left_bottom, mid_top, mid_center, mid_bottom, right_top, right_center, right_bottom }
};

export default useIcons;