import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    sidebarWrapp: {
      paddingTop:'2%',
      minWidth: '6%',
      backgroundColor: '#262338',
      height:'100%',
      display: 'flex',
      justifyContent: 'center',
      position:'fixed',
      zIndex:'1'
    },
    unameWrapp:{ 
      minWidth: '69px',
      maxWidth:'97px',
      maxHeight:'67px',
      minHeight: '45px', 
      clipPath:'circle()'
  },

    sidebar:{
      width:'100%',
      position:'relative',
      display:'flex',
      alignItems:'center',
      flexDirection:'column'
    },
    floatBtnWrapp:{
      width:'100%',
      padding:'10px',
      // position:'absolute',
      // marginTop:'350px',
      // left:'0',
      display:'flex',
      justifyContent:'center',
      
    },
    floatBtnWrappInner:{
      display:'flex',
     // position:'absolute',
      cursor: 'pointer'
    },
    innerSidebar: {
        marginTop: '40px',
        width: '69%',
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',    
    },
    icon: {
        cursor:'pointer',
        margin:'20% 0',
        textAlign: 'center'
    },
    iconText: {
      fontFamily:"'Poppins', sans-serif", 
      fontWeight: "600",
      color: '#A996FF', 
      fontSize: '10px'
    },
    active:{
      width: '100%',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      borderRadius:'8px', 
    }
  });

const useStyle = () => {
    const classes = useStyles();
  return {classes};
};

export default useStyle;
