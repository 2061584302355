import React from 'react';
import SingleQuestionBox from '../../single/SingleQuestionBox';
import ButtonsStepTwo from './buttons/ButtonsStepTwo';
import PreviewQuestBox from './questionPreviewBox/PreviewQuestBox';

const StepTwo = ({ questionStep, setQuestionSteps, state, dispatch, typeId, setOpenQuestion }) => {
  const stepTwoInputs = ['Welcome message:', 'Instructions'];

  return (
    <>
      <PreviewQuestBox state={state} typeId={typeId} />

      {stepTwoInputs.map((item, index) => (
        <SingleQuestionBox key={index} index={index} questionTitle={item} questionStep={questionStep} state={state} dispatch={dispatch} />
      ))}

      {questionStep === 2 && <ButtonsStepTwo setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} questionStep={questionStep} dispatch={dispatch} setOpenQuestion={setOpenQuestion} />}
    </>
  );
};

export default StepTwo;
StepTwo.defaultProps = {
  questionStep: 1,
  setQuestionSteps: () => {},
  state: [],
  dispatch: () => {},
  typeId: 1
};
