import React from 'react';
import { FaViber, FaWhatsapp } from 'react-icons/fa';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, ViberShareButton, WhatsappShareButton } from 'react-share';
import fbImg from '../media_movie/labs/fb.png';
import linkedinImg from '../media_movie/labs/linkedin.png';
import twitterImg from '../media_movie/labs/twiter.png';

const TabSocialMobile = ({ classes, shareUrl }) => {
  return (
    <div className={classes.innerTabMobile}>
      <div className={classes.shareRowMobile}>
        <div className={classes.rowLeftMobile}>
          <img src={fbImg} alt="fb" />
          <span style={{ color: '#888893', marginLeft: '8px', fontFamily: 'Poppins' }}>Facebook</span>
        </div>

        <div>
          <FacebookShareButton url={shareUrl} className="Demo__some-network__share-button">
            <button className={classes.shareBtnMobile} type="button">
              Share
            </button>
          </FacebookShareButton>
        </div>
      </div>

      {/* TWITTER */}
      <div className={classes.shareRowMobile}>
        <div className={classes.rowLeftMobile}>
          <img src={twitterImg} alt="twitter" />
          <span style={{ color: '#888893', marginLeft: '8px', fontFamily: 'Poppins' }}>Twitter</span>
        </div>

        <div>
          <TwitterShareButton url={shareUrl}>
            <button className={classes.shareBtnMobile} type="button">
              Share
            </button>
          </TwitterShareButton>
        </div>
      </div>

      {/* LINKEDIN */}
      <div className={classes.shareRowMobile}>
        <div className={classes.rowLeftMobile}>
          <img src={linkedinImg} alt="linkedin" />
          <span style={{ color: '#888893', marginLeft: '8px', fontFamily: 'Poppins' }}>Linkedin</span>
        </div>

        <div>
          <LinkedinShareButton url={shareUrl}>
            <button className={classes.shareBtnMobile} type="button">
              Share
            </button>
          </LinkedinShareButton>
        </div>
      </div>

      {/* Whatsup */}
      <div className={classes.shareRowMobile}>
        <div className={classes.rowLeftMobile}>
          {/* <img src={linkedinImg} alt='linkedin' />  */}
          <FaWhatsapp size={26} style={{ color: '#888893' }} />
          <span style={{ color: '#888893', marginLeft: '8px', fontFamily: 'Poppins' }}>WhatsApp</span>
        </div>

        <div>
          <WhatsappShareButton url={shareUrl}>
            <button className={classes.shareBtnMobile} type="button">
              Share
            </button>
          </WhatsappShareButton>
        </div>
      </div>

      {/* Viber */}
      <div className={classes.shareRowMobile}>
        <div className={classes.rowLeftMobile}>
          <FaViber size={24} style={{ color: '#888893' }} />
          <span style={{ color: '#888893', marginLeft: '8px', fontFamily: 'Poppins' }}>Viber</span>
        </div>

        <div>
          <ViberShareButton url={shareUrl}>
            <button className={classes.shareBtnMobile} type="button">
              Share
            </button>
          </ViberShareButton>
        </div>
      </div>
    </div>
  );
};

export default TabSocialMobile;
