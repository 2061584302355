import progressStyle from './stepProgress.module.css';

const StepProgress = ({ questionStep }) => {
  return (
    <div className={progressStyle.progressWrapp}>
      <div className={progressStyle.progressBody}>
        <div className={progressStyle.itemBig}></div>

        <div className={progressStyle.smallItemBox} style={{ opacity: questionStep < 1 && 0.5 }}>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
        </div>

        <div className={progressStyle.itemBig} style={{ opacity: questionStep < 1 && 0.5 }}></div>

        <div className={progressStyle.smallItemBox} style={{ opacity: questionStep < 2 && 0.5 }}>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
        </div>

        <div className={progressStyle.itemBig} style={{ opacity: questionStep < 2 && 0.5 }}></div>

        <div className={progressStyle.smallItemBox} style={{ opacity: questionStep < 3 && 0.5 }}>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
          <div className={progressStyle.itemSmall}></div>
        </div>

        <div className={progressStyle.itemBig} style={{ opacity: questionStep < 3 && 0.5 }}></div>
      </div>
    </div>
  );
};

export default StepProgress;
StepProgress.defaultProps = {
  questionStep: 1
};
