import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../services/apiServices/authService';
import ButtonFab from '../../styled/buttons/ButtonFab';
import MobileLoggedIn from '../appArchitect/mobile/MobileLoggedIn';
import { default as loginCompClass, default as signupClass } from './css/LoginComponent.module.css';
import msg from './medias/msg.png';
import ForgotPassModal from './modals/ForgotPassModal';

const LoginComponent = ({ setIsLogged, setLoginVisible, isMobile }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [openForgotPassModal, setOpenForgotPassModal] = useState(false);
  const navigate = useHistory();

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleChangeEmail = (event) => {
    setEmailError(null);
    setEmail(event.target.value);
  };

  const handleChangePassword = (event) => {
    setPasswordError(null);
    setPassword(event.target.value);
  };

  const handleLoginClick = () => {
    let error = false;
    if (!isValidEmail(email)) {
      setEmailError('Email is invalid');
      error = true;
    }

    if (password.length < 1) {
      setPasswordError('Password cannot be empty');
      error = true;
    }

    if (error) {
      return;
    }

    const { req, cancelTokenSource } = authService.doLogin(email, password);

    req
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem('username', email);
          localStorage.setItem('token', res.data.token);
          localStorage.setItem('counterPricing', 4);
          setIsLogged(true);
        } else {
          errorLogin();
          setIsLogged(false);
        }
      })
      .catch((err) => {
        errorLogin();
        setIsLogged(false);
      });
  };

  const errorLogin = () => {
    toast.error('Wrong credentials!', {
      position: 'top-right',
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true
    });
  };

  useEffect(() => {
    let rootEl = document.getElementById('root');
    if (rootEl) {
      rootEl.click();
    }
  }, []);

  if (isMobile) {
    return (
      <MobileLoggedIn
        handleChangeEmail={handleChangeEmail}
        handleChangePassword={handleChangePassword}
        setOpenForgotPassModal={setOpenForgotPassModal}
        handleLoginClick={handleLoginClick}
        emailError={emailError}
        passwordError={passwordError}
      />
    );
  }

  return (
    <>
      <Grid container className={loginCompClass.cont}>
        <div className={loginCompClass.label}>Email</div>
        <div className={signupClass.wrapInpImg}>
          <input className={loginCompClass.input} onChange={(e) => handleChangeEmail(e)} name="email" />
          <img src={msg} alt="message" className={signupClass.inputImg} />
        </div>
        <span className={loginCompClass.error}>{emailError}</span>
        <br />

        <div className={loginCompClass.label}>Password</div>
        <input className={loginCompClass.input} type="password" name="password" onChange={(e) => handleChangePassword(e)} />
        <span className={loginCompClass.error}>{passwordError}</span>

        <div className={loginCompClass.forgotPassWrapp}>
          <p className={loginCompClass.forgotPass} onClick={() => setOpenForgotPassModal(true)}>
            Forgot password?
          </p>
        </div>
      </Grid>
      <Grid container className={`${loginCompClass.buttoncontL} ${loginCompClass.buttoncontLogin}`}>
        <div style={{ visibility: 'hidden' }}>
          <ButtonFab tittle="Sign Up" onClickHandler={() => setLoginVisible(false)} isLoggedIn={false} />
        </div>
        <ButtonFab tittle="Login" onClickHandler={handleLoginClick} isLoggedIn={false} />

        <div style={{ paddingTop: '20px', color: 'white', fontSize: '14px', display: 'flex', justifyContent: 'flex-end', fontFamily: "'Poppins', sans-serif", alignItems: 'flex-end', width: '100%' }}>
          <p>
            {"Don't have an account? "}
            <span style={{ color: 'white', cursor: 'pointer' }} onClick={() => navigate.push('/signup')}>
              <u> Get started for free</u>
            </span>
          </p>
        </div>
      </Grid>

      {openForgotPassModal && <ForgotPassModal setOpenForgotPassModal={setOpenForgotPassModal} />}
    </>
  );
};

export default LoginComponent;
