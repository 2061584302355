import jwt_decode from 'jwt-decode';

export const useCustomCheck = () => {
  let isLog = false;
  const haveToken = localStorage.getItem('token');

  if (!!haveToken) {
    let decoded = jwt_decode(haveToken);

    let expDate = new Date(decoded.exp * 1000);
    let today = new Date();

    //is token expired
    if (expDate < today) {
      isLog = false;
    } else {
      isLog = true;
    }

    let userRole = decoded.role;
    if (!userRole) {
      isLog = false;
    } else {
      localStorage.setItem('role', userRole);
    }
  }
  return isLog;
};
