import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { useState } from 'react';
import { SketchPicker } from 'react-color';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({ open, handleClose, updateBrandingColor, color = '', useHexCode = false }) {
  const [currentColor, setCurrentColor] = useState(color);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Choose Color
        </DialogTitle>
        <DialogContent>
          <SketchPicker
            color={currentColor}
            onChangeComplete={(e) => {
              if (useHexCode) {
                setCurrentColor(e.hex);
              } else {
                setCurrentColor(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (!!currentColor) {
                updateBrandingColor(currentColor);
              }
              handleClose();
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
