import { useEffect, useState } from 'react';
import { rangeColors } from './AssetRangeColors';

const AssetRanges = ({ activeSceneID, activeAssets, sceneDuration, sceneStart, assetX, selectedAsset, triggerAssetRangers }) => {
  const [assetLinesWidth, setAssetLinesWidth] = useState([]);

  useEffect(() => {
    let arr = [];

    if (sceneDuration > 0) {
      for (let i = 0; i < activeAssets.length; i++) {
        if (activeAssets[i].deleted === true || activeAssets[i].isMaster === true || activeAssets[i].asset?.type === 'background') continue;

        let trimLen = 0;
        if (activeAssets[i].trim > 0) {
          trimLen = (730 * activeAssets[i].trim) / sceneDuration;
        }
        let x = (730 * (activeAssets[i].start - sceneStart)) / sceneDuration;
        let len = (730 * activeAssets[i].length) / sceneDuration;
        let item = {
          x: x,
          len: len,
          color: rangeColors[activeAssets[i].asset.typeID]
        };

        // add range at top
        if (activeAssets[i].isMaster) {
          arr.unshift(item);
        } else {
          arr.push(item);
        }
      }

      setAssetLinesWidth(arr);
    }
  }, [triggerAssetRangers, sceneStart, sceneDuration, activeSceneID, activeAssets]);

  return (
    <>
      <div style={{ marginTop: '8px' }}>
        {assetLinesWidth.map((x, i) => (
          <div key={i} style={{ width: x.len + 'px', marginLeft: x.x + 'px', marginBottom: '8px', borderBottom: '3px solid ' + x.color }}></div>
        ))}
      </div>
    </>
  );
};

export default AssetRanges;
