import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import forgotModCSS from './ForgotModal.module.css';
import authService from '../../../services/apiServices/authService';
import SuccessfullyReset from './SuccessfullyReset';
import ForgotPassMain from './ForgotPassMain';

const isValidEmail = (email) => {
  return /\S+@\S+\.\S+/.test(email);
};

const ForgotPassModal = ({ setOpenForgotPassModal }) => {
  const [mail, setMail] = useState('');
  const [succSent, setSuccSent] = useState(false);

  const handleForgotPass = () => {
    let mailTemp = mail.trim();

    let isValid = isValidEmail(mailTemp);

    if (isValid) {
      const { req } = authService.forgotPassword(mailTemp);

      req.then((res) => {
        setOpenForgotPassModal(false);
        setSuccSent(true);
      });
    } else {
      toast.error('Email is not valid!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  useEffect(() => {
    if (succSent) {
      setTimeout(() => {
        setOpenForgotPassModal(false);
      }, 2500);
    }
  }, [succSent]);
  return (
    <div className={forgotModCSS.modalPassForgotWrapp}>
      {!succSent && <ForgotPassMain mail={mail} setMail={setMail} handleForgotPass={handleForgotPass} />}

      <div onClick={() => setOpenForgotPassModal(false)} className={forgotModCSS.wrappHelperClear}></div>
      {succSent && <SuccessfullyReset mail={mail} />}
    </div>
  );
};

export default ForgotPassModal;
