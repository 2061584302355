import SaveDraftButton from '../../step2/buttons/saveDraft/SaveDraftButton';
import styles from './buttonStepThree.module.css';
import successImg from './success.png';

const ButtonsStepThree = ({ setQuestionSteps, dispatch }) => {
  const cleartAllInputs = () => {
    const emailInp = document.querySelector('.emailInput');
    emailInp.value = '';
    dispatch({ type: 'UPDATE_EMAIL', payload: '' });
    setQuestionSteps(3);
  };

  return (
    <div className={styles.bStepThreeWrapp}>
      <div className={styles.bStepThreeInner}>
        <span className={styles.sendAnotherTxt} onClick={cleartAllInputs}>
          Send another one?
        </span>
        <SaveDraftButton
          tittle={
            <>
              <img className={styles.buttonSuccessSign} src={successImg} alt="sent" />
              <span>Sent</span>
            </>
          }
          onClickHandler={() => {}}
        />
      </div>
    </div>
  );
};

export default ButtonsStepThree;
