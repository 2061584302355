import { Grid, makeStyles } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';
import { useContext, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { AiFillPauseCircle, AiOutlineSync } from 'react-icons/ai';
import { PAUSED, PLAYING } from '../../../constants/video_states';
import { CurrentTimeContext } from '../../../context/current_time_context';
import { PlayModeContext } from '../../../context/play_mode_context';
import { ReactComponent as PLAY_SMALL } from '../../../files/icons/PLAY_SMALL.svg';
import { ItemTypes } from '../../../utilities/items';
import SceneContent from '../../projectEditor/sceneList/scene/sceneContent/SceneContent';
import ActiveSceneMasterClip from '../../stage/ActiveSceneMasterClip';
import { AudioStage } from '../../stage/stage-objects/audioStage/AudioStage';
import { ImageStage } from '../../stage/stage-objects/imageStage/ImageStage';
import TextStageCanvas from '../../stage/stage-objects/textStage/TextStageCanvas';
import { VideoStage } from '../../stage/stage-objects/videoStage/VideoStage';
import AssetRanges from './AssetRanges';
import AssetTrimmer from './AssetTrimmer';

const useStyles = makeStyles((theme) => ({
  timeWrapper: {
    width: '786px', // 16:9 width don't change it if you don't know what are you doing
    height: '442px', // 16:9 height don't change it if you don't know what are you doing
    backgroundColor: 'lightgray',
    overflow: 'hidden',
    borderRadius: '5px'
  },
  root: {
    width: '100%',
    backgroundColor: 'lightblue'
  },
  margin: {
    height: theme.spacing(3)
  },
  draggableStyle: {
    backgroundColor: 'blue'
  },
  timeSlider: {
    minHeight: '70px',
    backgroundColor: '#262338',
    display: 'flex',
    position: 'relative',
    zIndex: '1000',
    width: '730px',
    borderRadius: '12px',
    outline: '1px solid #4E4B66'
  },
  assetTimeline: {
    backgroundColor: '#FFABE8',
    position: 'relative',
    width: '300px',
    left: '100px'
  },
  timeNeedleDiv: {
    width: '100%',
    position: 'absolute'
  },
  timeNeedle: {
    height: '80px',
    width: '100%',
    WebkitAppearance: 'none',
    background: 'transparent'
  },
  controlBtn: {
    position: 'absolute',
    fill: '#A996FF',
    cursor: 'pointer',
    zIndex: '999'
  },
  imgPrev: {
    width: '786px',
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgPrevInner: {
    width: '100%',
    height: '100px',
    display: 'flex'
  },
  itemSelc: {
    width: '135px',
    marginRight: '15px',
    borderRadius: '10px',
    border: '1px solid grey',
    backgroundColor: '#262338',
    cursor: 'pointer'
  },
  itemSelcInner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },

  resetLenBtn: {
    position: 'absolute',
    alignSelf: 'end',
    color: 'white',
    cursor: 'pointer',
    right: '10px',
    bottom: '10px'
  },

  itemRest: {
    width: '84%',
    height: '100%',
    borderRadius: '12px',
    border: '1px solid grey',
    backgroundColor: '#262338',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px 5px',
    overflow: 'hidden'
  }
}));

const ProjectTime = ({
  newRange,
  setNewRange,
  scenes,
  timeRangeClicked,
  setTimeRangeClicked,
  selectedAsset,
  setInteractObject,
  activeSceneContent,
  activeSceneID,
  triggerAssetRangers,
  setOpenAdvancedTextOptions,
  insertAsset,
  setSelectedAsset,
  setSceneAssetsReorder,
  setActiveSceneID,
  setCurrentAttribute,
  setLibraryUpdated
}) => {
  const classes = useStyles();

  const { currentTime, setCurrentTime } = useContext(CurrentTimeContext);
  const { playMode, setPlayMode } = useContext(PlayModeContext);
  const [intervalId, setIntervalId] = useState(0);

  const [sceneDuration, setSceneDuration] = useState(0);
  const [sceneStart, setSceneStart] = useState(0);
  const [clickStartedX, setClickStartedX] = useState(0);
  const [clickStartedY, setClickStartedY] = useState(0);

  useEffect(() => {
    if (activeSceneContent.length === 0) {
      setSceneStart(sceneDuration);
    }

    if (!!activeSceneContent.find((x) => x.isMaster === true)) {
      setSceneDuration(activeSceneContent.find((x) => x.isMaster === true).length);
      setSceneStart(activeSceneContent.find((x) => x.isMaster === true).start);
      if (Object.keys(selectedAsset).length === 0) {
        setSelectedAsset(activeSceneContent.find((x) => x.isMaster === true));
      }
    }
  }, [activeSceneContent]);

  useEffect(() => {
    let realCurrentTime = newRange / (100 / sceneDuration);
    setCurrentTime(realCurrentTime);
  }, [sceneDuration]);

  useEffect(() => {
    if (sceneDuration > 0) {
      let rangeValue = currentTime * (100 / sceneDuration); // calculate new range
      setNewRange(rangeValue);
    }

    if (currentTime >= sceneDuration) {
      handleStop();
    }
  }, [currentTime]);

  let changeRange = (e) => {
    let range = Number(e.target.value);
    let realCurrentTime = range / (100 / sceneDuration);

    setCurrentTime(realCurrentTime);
    setTimeRangeClicked(!timeRangeClicked);
  };

  const handlePlay = () => {
    setPlayMode(PLAYING);
    let t = new Date();
    t.setSeconds(t.getSeconds());
    let startUnix = t.getTime();

    let intervalId = window.setInterval(() => {
      let t2 = new Date();
      t2.setSeconds(t2.getSeconds());
      let currentUnixTime = t2.getTime();

      setCurrentTime(currentTime + (currentUnixTime - startUnix) / 1000);
    }, 150);
    setIntervalId(intervalId);
  };

  const handlePauseOrStop = () => {
    window.clearInterval(intervalId);
    setPlayMode(PAUSED);
  };

  const handleStop = () => {
    handlePauseOrStop();
    setPlayMode(PAUSED);
    setCurrentTime(0);
  };

  const resetSceneMasterClipLength = (sceneID) => {
    let item = {};
    item.type = 'resetmasterlen';
    item.sceneid = sceneID;
    setInteractObject(item);
  };

  const [{ canDrop3, isOver3 }, drop3] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        insertAsset(JSON.parse(JSON.stringify(positionedAsset)), true);
        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    ['any']
  );

  const [{ canDrop2, isOver2 }, drop2] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        insertAsset(JSON.parse(JSON.stringify(positionedAsset)));
        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    ['any']
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        insertAsset(JSON.parse(JSON.stringify(positionedAsset)));
        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    []
  );

  const screenClickHandler = (e, isDirtyClick) => {
    // select asset on screen workaround
    let screenX = e.clientX - e.currentTarget.offsetLeft; // clientX - position on visible screen, offsetLeft - position of #screen-screen
    let screenY = e.clientY - e.currentTarget.offsetTop + window.scrollY; // clientY - position on visible screen, offsetTop - position of #screen-screen, window scrollY - position if scrolled down

    let elementsAtClickedPoint = document.elementsFromPoint(e.clientX, e.clientY);
    let elementIds = elementsAtClickedPoint.map((x) => x.id).filter((x) => !!x);
    const hasCanvas = elementsAtClickedPoint.find((x) => x.tagName.toLowerCase() === 'canvas');

    if (elementIds.length > 0) {
      for (let i = 0; i < elementIds.length; i++) {
        if (elementIds[i] === 'crop-label' && hasCanvas) {
          const cropLabelEl = document.getElementById('crop-label');
          cropLabelEl.click();
          continue;
        }

        if (!elementIds[i].includes('screen_')) continue;

        let highestElement = document.getElementById(elementIds[i]);
        let { x, y, h, w } = highestElement.dataset;

        if (highestElement.tagName.toLowerCase() === 'canvas') {
          // because of z-index on multiple canvases this checks if user clicked textbox in canvas - see TextStageCanvas component, data attributes
          if (screenX > Number(x) && screenX < Number(x) + Number(w) && screenY > Number(y) && screenY < Number(y) + Number(h) && !isDirtyClick) {
            let selectedAsset = activeSceneContent.find((x) => x.assetStyleID === elementIds[i].replace('screen_', ''));
            if (!!selectedAsset && !isDirtyClick) {
              selectAssetFromSceneList(selectedAsset);
            }
            break;
          } else {
            // if user didn't click on textbox of this canvas, then turn off their pointer-events...
            let elem = document.querySelector('.canvas-container-full-id-' + elementIds[i].replace('screen_', ''));
            if (!!elem) {
              elem.style.pointerEvents = 'none';
            }
          }
        } else {
          if (screenX > Number(x) && screenX < Number(x) + Number(w) && screenY > Number(y) && screenY < Number(y) + Number(h)) {
            let selectedAsset = activeSceneContent.find((x) => x.assetStyleID === elementIds[i].replace('screen_', ''));
            if (!!selectedAsset && !isDirtyClick) {
              selectAssetFromSceneList(selectedAsset);
            }
            break;
          } else {
            let elem = document.getElementById(elementIds[i]);
            if (!!elem) {
              elem.style.pointerEvents = 'none';
            }
          }
        }
      }
    }
  };

  const selectAssetFromSceneList = (asset) => {
    document.querySelectorAll('.canvas-container-full').forEach((e) => (e.style.pointerEvents = 'none'));
    document.querySelectorAll('.asset-interact').forEach((e) => (e.style.pointerEvents = 'none'));
    let elem = document.querySelector('.canvas-container-full-id-' + asset.assetStyleID);
    if (!elem) {
      elem = document.getElementById('screen_' + asset.assetStyleID);
    }
    if (!!elem) {
      elem.style.pointerEvents = 'all';
    }

    setSelectedAsset(asset);
    if (!!asset.asset?.type) {
      setCurrentAttribute(asset.asset.type);
    }
  };

  return (
    <>
      <Grid
        container
        onMouseDown={(e) => {
          setClickStartedX(e.clientX);
          setClickStartedY(e.clientY);
          document.querySelectorAll('.canvas-container-full').forEach((e) => (e.style.pointerEvents = 'all'));
          document.querySelectorAll('.asset-interact').forEach((e) => (e.style.pointerEvents = 'all'));
        }}
        onClick={(e) => {
          let deviationX = Math.abs(clickStartedX - e.clientX);
          let deviationY = Math.abs(clickStartedY - e.clientY);

          // this prevents selecting asset if mouse is moved from the position of mouse down event and mouse release.
          let isDirtyClick = deviationX > 10 || deviationY > 10;

          if (isDirtyClick) {
            document.querySelectorAll('.canvas-container-full').forEach((e) => (e.style.pointerEvents = 'none'));
            document.querySelectorAll('.asset-interact').forEach((e) => (e.style.pointerEvents = 'none'));
            let elem = document.querySelector('.canvas-container-full-id-' + selectedAsset.assetStyleID);
            if (!elem) {
              elem = document.getElementById('screen_' + selectedAsset.assetStyleID);
            }
            if (!!elem) {
              elem.style.pointerEvents = 'all';
            }
            return;
          }

          if (e.detail === 1) {
            screenClickHandler(e, isDirtyClick);
          } else if (e.detail === 2 && Object.keys(selectedAsset).length > 0 && selectedAsset.asset.type === 'html') {
            setOpenAdvancedTextOptions(true);
          }
        }}
      >
        <div id="screen-screen" className={classes.timeWrapper} style={{ backgroundColor: activeSceneContent.find((x) => x.asset.type === 'background')?.htmlBackground || '#000000' }} ref={drop2}>
          {activeSceneContent.map((clip, index) => {
            if (clip.asset.type === 'image') {
              return <ImageStage image={clip} key={clip.assetStyleID} selectedAsset={selectedAsset} sceneDuration={sceneDuration} sceneStart={sceneStart} setInteractObject={setInteractObject} />;
            } else if (clip.asset.type === 'video') {
              return (
                <VideoStage
                  clip={clip}
                  key={clip.assetStyleID}
                  timeRangeClicked={timeRangeClicked}
                  selectedAsset={selectedAsset}
                  sceneDuration={sceneDuration}
                  sceneStart={sceneStart}
                  setInteractObject={setInteractObject}
                />
              );
            } else if (clip.asset.type === 'audio') {
              return <AudioStage index={index} audio={clip} key={clip.assetStyleID} timeRangeClicked={timeRangeClicked} selectedAsset={selectedAsset} sceneDuration={sceneDuration} sceneStart={sceneStart} />;
            } else if (clip.asset.type === 'html') {
              return <TextStageCanvas key={clip.assetStyleID} clip={clip} selectedAsset={selectedAsset} setInteractObject={setInteractObject} sceneDuration={sceneDuration} sceneStart={sceneStart} />;
            }
          })}
        </div>
      </Grid>
      <Grid container style={{ marginTop: '10px' }}>
        <Grid item>
          <Tooltip title="Play scene">
            {playMode !== PLAYING ? <PLAY_SMALL className={classes.controlBtn} onClick={handlePlay} /> : <AiFillPauseCircle className={classes.controlBtn} size={42} onClick={handlePauseOrStop} />}
          </Tooltip>
          <Grid style={{ marginLeft: '56px', width: '730px' }}>
            <div className={classes.timeSlider}>
              <AssetRanges activeSceneID={activeSceneID} activeAssets={activeSceneContent} sceneDuration={sceneDuration} sceneStart={sceneStart} selectedAsset={selectedAsset} triggerAssetRangers={triggerAssetRangers} />

              {activeSceneContent.map((x) => (
                <AssetTrimmer scenes={scenes} key={x.assetStyleID} assetToTrim={x} selectedAsset={selectedAsset} sceneStart={sceneStart} sceneDuration={sceneDuration} setInteractObject={setInteractObject} />
              ))}

              <div className={classes.timeNeedleDiv}>
                <input
                  onChange={(e) => {
                    if (playMode !== PLAYING) {
                      changeRange(e);
                    }
                  }}
                  className={classes.timeNeedle}
                  step="0.25"
                  type="range"
                  min="0"
                  max="100"
                  value={newRange || 0}
                  id="time-needle"
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid item className={classes.imgPrev}>
          <Grid className={classes.imgPrevInner}>
            <Grid
              ref={drop3}
              className={classes.itemSelc}
              onClick={() => {
                if (!!activeSceneContent.find((x) => x.isMaster === true)) {
                  selectAssetFromSceneList(activeSceneContent.find((x) => x.isMaster === true));
                }
              }}
            >
              <Grid className={classes.itemSelcInner}>
                <ActiveSceneMasterClip masterClip={activeSceneContent.find((x) => x.isMaster === true)} />
                <AiOutlineSync className={classes.resetLenBtn} size={22} onClick={() => resetSceneMasterClipLength(activeSceneID)} title="Restore lenght" />
              </Grid>
            </Grid>

            <Grid ref={drop} className={classes.itemRest} id="scene-content">
              <SceneContent
                activeSceneContent={activeSceneContent.filter((x) => !x.deleted && x.asset.typeID !== 7)}
                setSelectedAsset={setSelectedAsset}
                setSceneAssetsReorder={setSceneAssetsReorder}
                setActiveSceneID={setActiveSceneID}
                setInteractObject={setInteractObject}
                setCurrentAttribute={setCurrentAttribute}
                setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
                setLibraryUpdated={setLibraryUpdated}
                selectAssetFromSceneList={selectAssetFromSceneList}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default ProjectTime;
