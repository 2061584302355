import { Redirect, Route } from 'react-router-dom';
import { BrandingDataContext } from '../../context/branding_data_context';
import ChangePassword from '../auth/ChangePassword';
import ConfirmationScreen from '../auth/ConfirmationScreen';
import Inspiration from '../inspiration/Inspiration';
import Library from '../library/Library';
import NewMoviesPage from '../movies/NewMoviesPage';
import ProjectEditor from '../projectEditor/ProjectEditor';
import ProjectSection from '../projectSection/ProjectSection';
import Settings from '../settings/Settings';
import SettingsPlans from '../settings/settingsTabs/SettingsPlans';
import UserManagement from '../userManagement/UserManagement';

const AllRoutes = ({
  isMobile,
  classes,
  brandingColors,
  brandingFonts,
  profilePic,
  setProfilePic,
  insteadDateImg,
  getUserDetail,
  userDetail,
  setUserDetail,
  userDetailUnchanged,
  setUserDetailUnchanged,
  triggerNotifications,
  setTriggerNotifications
}) => {
  return (
    <>
      <Route exact path="/">
        <Redirect to={isMobile ? '/library' : '/promotions'} />
      </Route>

      <Route exact path="/login">
        <Redirect to={isMobile ? '/library' : '/promotions'} />
      </Route>

      {!isMobile && (
        <Route exact path="/changePassword">
          <Redirect to="/changePassword" />
          <ChangePassword />
        </Route>
      )}

      <Route exact path="/userManagement">
        <Redirect to="/userManagement" />
        <UserManagement userDetail={userDetail} />
      </Route>

      <Route path="/editor">
        <div className={classes.editorWrapper}>
          <BrandingDataContext.Provider value={{ brandingColors, brandingFonts }}>
            <ProjectEditor />
          </BrandingDataContext.Provider>
        </div>
      </Route>

      <Route exact path="/promotions">
        {isMobile ? <NewMoviesPage triggerNotifications={triggerNotifications} isMobile={isMobile} setTriggerNotifications={setTriggerNotifications} /> : <Inspiration />}
      </Route>

      <Route exact path="/library">
        <Library isMobile={isMobile} />
      </Route>

      <Route path="/newmovies">
        <NewMoviesPage triggerNotifications={triggerNotifications} isMobile={isMobile} setTriggerNotifications={setTriggerNotifications} />
      </Route>

      <Route path="/projects">
        {isMobile ? (
          <NewMoviesPage triggerNotifications={triggerNotifications} isMobile={isMobile} setTriggerNotifications={setTriggerNotifications} />
        ) : (
          <ProjectSection triggerNotifications={triggerNotifications} isMobile={isMobile} />
        )}
      </Route>

      <Route path="/settings">
        <Settings
          isMobile={isMobile}
          profilePic={profilePic}
          setProfilePic={setProfilePic}
          insteadDateImg={insteadDateImg}
          getUserDetail={getUserDetail}
          userDetail={userDetail}
          setUserDetail={setUserDetail}
          userDetailUnchanged={userDetailUnchanged}
          setUserDetailUnchanged={setUserDetailUnchanged}
        />
      </Route>

      <Route path="/confirmation">
        <ConfirmationScreen />
      </Route>

      <Route path="/plans">
        <SettingsPlans />
      </Route>
    </>
  );
};

export default AllRoutes;
AllRoutes.defaultProps = {
  isMobile: false,
  classes: {},
  brandingColors: [],
  brandingFonts: [],
  profilePic: '',
  setProfilePic: () => {},
  insteadDateImg: '',
  getUserDetail: () => {},
  userDetail: {},
  setUserDetail: () => {},
  userDetailUnchanged: {},
  setUserDetailUnchanged: () => {},
  triggerNotifications: false
};
