import React from "react";
import useCustomSidePickerHooks from "../customHooks/useCustomSidePickerHooks";
import sidepickStyle from "../css/sidepick.module.css";

const HeaderPicker = ({ setHeaderPickerTab, headerPickerTab }) => {
  const { useStyle } = useCustomSidePickerHooks();
  const { classes } = useStyle();

  return (
    <div className={`${classes.headPickWrapp} ${sidepickStyle.paraResp}`}>

      <div className={`${classes.headTwo} ${sidepickStyle.headTwoResp}`}>
        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(0)}>
          <p className={headerPickerTab === 0 ? classes.headTwoActive : classes.headTwoNonActive}>Intros</p>
          <span className={headerPickerTab === 0 ? classes.underlineSpanStyle : ""}></span>
        </div>

        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(1)}>
          <p className={headerPickerTab === 1 ? classes.headTwoActive : classes.headTwoNonActive}>Clips</p>
          <span className={headerPickerTab === 1 ? classes.underlineSpanStyle : ""}></span>
        </div>

        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(3)}>
          <p className={headerPickerTab === 3 ? classes.headTwoActive : classes.headTwoNonActive}>Images</p>
          <span className={headerPickerTab === 3 ? classes.underlineSpanStyle : ""}></span>
        </div>

        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(4)}>
          <p className={headerPickerTab === 4 ? classes.headTwoActive : classes.headTwoNonActive}>Animations</p>
          <span className={headerPickerTab === 4 ? classes.underlineSpanStyle : ""}></span>
        </div> 
        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(2)}>
          <p className={headerPickerTab === 2 ? classes.headTwoActive : classes.headTwoNonActive}>Sounds</p>
          <span className={headerPickerTab === 2 ? classes.underlineSpanStyle : ""}></span>
        </div>

        <div style={{ cursor: "pointer" }} onClick={() => setHeaderPickerTab(5)}>
          <p className={headerPickerTab === 5 ? classes.headTwoActive : classes.headTwoNonActive}>Pexels</p>
          <span className={headerPickerTab === 5 ? classes.underlineSpanStyle : ""}></span>
        </div>
      </div>
    </div>
  );
};

export default HeaderPicker;
