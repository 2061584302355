import { Grid } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import stripeService from '../../../services/apiServices/stripeService';
import { toast } from 'react-toastify';

const SettingsPlans = () => {
  let history = useHistory();
  const location = useLocation();
  let back = '< Back';
  const [currentPlan, setCurrentPlan] = useState(null);
  const [pricingTable, setPricingTable] = useState(null);

  useEffect(() => {
    if (!!location.state) {
      const cp = location.state.currentPlan;
      if (!!cp) {
        setCurrentPlan(cp);
      }
    }

    const { axiosReq, cancelTokenSource } = stripeService.getPublicKey();
    axiosReq.then(async (resp) => {
      if (resp.data.success === true) {
        setPricingTable(resp.data);
      } else {
        toast.error(resp.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setTimeout(() => {
          history.push('/settings', { organisationTab: true });
        }, 3000);
      }
    });
  }, []);

  if (currentPlan !== null) return <div></div>;

  return (
    <Grid container>
      <button style={{ backgroundColor: 'transparent', color: 'white', textDecoration: 'underline', fontSize: '15px' }} onClick={() => history.goBack()}>
        {back}
      </button>
      <Grid item xs={12} lg={12} md={12} sm={12}>
        <p style={{ fontFamily: "'Poppins', sans-serif", fontStyle: 'normal', fontWeight: '700', fontSize: '28px', lineHeight: '72px', color: '#FFFFFF' }}>Plans:</p>
      </Grid>
      <Grid style={{ width: '100%' }}>
        {!!pricingTable && (
          <stripe-pricing-table customer-email={pricingTable.email} client-reference-id={pricingTable.id} pricing-table-id={pricingTable.pricingTableID} publishable-key={pricingTable.key}></stripe-pricing-table>
        )}
      </Grid>
    </Grid>
  );
};

export default SettingsPlans;
