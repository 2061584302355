import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import AttributesBar from '../assetSettings/atributtesBar/AttributesBar';
import FilterRight from '../library/assetSection/filterAsset/FilterRight';
import sidepickStyle from './css/sidepick.module.css';
import useCustomSidePickerHooks from './customHooks/useCustomSidePickerHooks';
import BodyPicker from './elements/BodyPicker';
import HeaderPicker from './elements/HeaderPicker';
import PexelsPicker from './elements/PexelsPicker';

const SidePicker = ({ libraryUpdated, setLibraryUpdated, selectedAsset, currentAttribute, setInteractObject, openAdvancedTextOptions, setOpenAdvancedTextOptions, activeSceneContent }) => {
  const { useStyle } = useCustomSidePickerHooks();
  const { classes } = useStyle();
  const [headerPickerTab, setHeaderPickerTab] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [assetElements, setAssetElements] = useState([]);

  let activeTab = '';
  if (headerPickerTab === 1) {
    activeTab = 'video';
  } else if (headerPickerTab === 3) {
    activeTab = 'image';
  } else if (headerPickerTab === 2) {
    activeTab = 'audio';
  }

  return (
    <div id={sidepickStyle.frameResp} className={classes.frame}>
      <HeaderPicker setHeaderPickerTab={setHeaderPickerTab} headerPickerTab={headerPickerTab} />
      <div style={{ width: '100%', padding: '0px 15px', marginTop: '10px', marginBottom: '10px' }}>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch color="black" size={30} />
              </InputAdornment>
            )
          }}
          className={classes.searchInput}
          variant="outlined"
          onChange={(e) => setSearchValue(e.target.value)}
          size="small"
        />
      </div>
      {!!currentAttribute && currentAttribute !== 'audio' && (
        <AttributesBar
          key={selectedAsset.assetStyleID}
          selectedAsset={selectedAsset}
          currentAttribute={currentAttribute}
          setInteractObject={setInteractObject}
          openAdvancedTextOptions={openAdvancedTextOptions}
          setOpenAdvancedTextOptions={setOpenAdvancedTextOptions}
          activeSceneContent={activeSceneContent}
        />
      )}

      {headerPickerTab === 0 && (
        <>
          <BodyPicker
            searchValue={searchValue}
            libraryUpdated={libraryUpdated}
            headerPickerTab={headerPickerTab}
            headerAssetType="scenes"
            currentAttribute={currentAttribute}
            assetElements={assetElements}
            setAssetElements={setAssetElements}
          />
        </>
      )}
      {headerPickerTab === 1 && (
        <>
          <BodyPicker
            searchValue={searchValue}
            libraryUpdated={libraryUpdated}
            headerPickerTab={headerPickerTab}
            headerAssetType="video"
            currentAttribute={currentAttribute}
            assetElements={assetElements}
            setAssetElements={setAssetElements}
          />
        </>
      )}

      {headerPickerTab === 2 && (
        <>
          <BodyPicker
            searchValue={searchValue}
            libraryUpdated={libraryUpdated}
            headerPickerTab={headerPickerTab}
            headerAssetType="audio"
            currentAttribute={currentAttribute}
            assetElements={assetElements}
            setAssetElements={setAssetElements}
          />
        </>
      )}
      {headerPickerTab === 3 && (
        <>
          <BodyPicker
            searchValue={searchValue}
            libraryUpdated={libraryUpdated}
            headerPickerTab={headerPickerTab}
            headerAssetType="image"
            currentAttribute={currentAttribute}
            assetElements={assetElements}
            setAssetElements={setAssetElements}
          />
        </>
      )}
      {headerPickerTab === 4 && (
        <>
          <BodyPicker
            searchValue={searchValue}
            libraryUpdated={libraryUpdated}
            headerPickerTab={headerPickerTab}
            headerAssetType="html"
            currentAttribute={currentAttribute}
            assetElements={assetElements}
            setAssetElements={setAssetElements}
          />
        </>
      )}
      {headerPickerTab === 5 && (
        <>
          <PexelsPicker searchValue={searchValue} currentAttribute={currentAttribute} />
        </>
      )}
      <div
        style={{
          color: 'white',
          display: 'flex',
          justifyContent: 'end',
          marginRight: '20px',
          marginTop: '8px'
        }}
      >
        <FilterRight activeTab={activeTab} callAssets={setLibraryUpdated} assetsForDelete={[]} showUpload={headerPickerTab === 1 || headerPickerTab === 2 || headerPickerTab === 3} isOnBodyPicker={true} width="50%" />
      </div>
    </div>
  );
};

export default SidePicker;
