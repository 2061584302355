import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const saveTestimonialUrl = '/api/Testimonial/save-testimonial';
const getDraftedTestiimonial = '/api/Testimonial/getDraftedTestimonial/';

const saveTestimonial = (testimonialData) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + saveTestimonialUrl,
      data: testimonialData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getSingleDraftedTestimonial = (testimonialId) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  if (!testimonialId) {
    return;
  }

  return {
    req: axios.get(baseUrl + getDraftedTestiimonial + testimonialId, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  saveTestimonial,
  getSingleDraftedTestimonial
};
