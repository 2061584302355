import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import movieService from '../../services/apiServices/movieService';
import useStyleCardAsset from '../library/assetSection/cardasset/useStyleCardAsset';
import SingleMoviePart from './movieTranslation/SingleMoviePart';

const modalMovieTransaltionStyle = makeStyles({
  rightBox: {
    width: '99%',
    backgroundColor: '#262338',
    padding: '15px',
    borderRadius: '10px'
  },
  tabWrapp: {
    width: '100%',
    padding: '4px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  tabActive: {
    width: '48%',
    color: 'white',
    textAlign: 'center',
    fontSize: '19px',
    borderBottom: '1px solid white',
    fontWeight: 500
  },
  tab: {
    width: '48%',
    color: 'gray',
    textAlign: 'center',
    fontSize: '19px',
    borderBottom: '1px solid gray'
  },
  captionsBodyWrapp: {
    marginTop: '12px',
    width: '100%',
    padding: '3px',
    maxHeight: '300px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      display: 'none'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'grey'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
      marginRight: '10px'
    }
  },
  exportMovieBtn: {
    width: '150px',
    backgroundColor: '#CA024F',
    padding: '12px',
    borderRadius: '10px',
    fontFamily: "'Poppins'",
    fontWeight: '600',
    cursor: 'pointer',
    color: 'white',
    border: '1px solid #CA024F'
  },
  exportMovieCrt: {
    width: '150px',
    backgroundColor: '#14142B',
    padding: '12px',
    borderRadius: '10px',
    borderColor: 'white',
    color: 'white',
    fontFamily: "'Poppins'",
    fontWeight: '600',
    cursor: 'pointer',
    outline: '1px solid white',
    marginRight: '10px'
  }
});

const MovieTranslationModal = ({ openTranslation, setOpenTranslation, movieData, languageList }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const classes = modalMovieTransaltionStyle();
  const cssStyle = styleMovieThumbnail();

  const [translationLanguage, setTranslationLanguage] = useState('en-US');
  const [movieTranslations, setMovieTranslations] = useState([]);
  const [valueTab, setValueTab] = useState(1);

  const createSubtitle = (id, languageID) => {
    const { axiosReq } = movieService.makeSubtitle(id, languageID);
    axiosReq.then((x) => {
      if (x.data.success && !!x.data.data) {
        setMovieTranslations((r) => [...r, { ...x.data.data, subtitles: JSON.parse(x.data.data.subtitleJSON) }]);
      } else {
        setOpenTranslation(false);
      }
    });
  };

  useEffect(() => {
    if (movieData?.movieTranslations?.length > 0) {
      let arr = movieData.movieTranslations.map((x) => ({ ...x, subtitles: JSON.parse(x.subtitleJSON) }));
      setMovieTranslations(arr);
      setTranslationLanguage(movieData?.movieTranslations[0]?.originalLanguageID);
    }
  }, [movieData]);

  const handleExportWithSubtitles = (translationID) => {
    setOpenTranslation(false);
    const { axiosReq } = movieService.exportWithSubtitle(translationID);
    axiosReq.then((x) => {
      if (x.data.success) {
        toast.success('Export started!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  const handleExportSRT = (languageID) => {
    const subtitle = movieTranslations.find((x) => x.languageID === languageID);

    movieService.downloadSRT(movieData.name, subtitle.subtitleJSON);
  };

  const handleSubtitleEdit = (data) => {
    let translation = movieTranslations.find((x) => x.languageID === translationLanguage);
    let subtitles = translation?.subtitles;
    if (!!subtitles) {
      let subtitlesCopy = [...subtitles];
      let changedObjextIndex = subtitlesCopy.findIndex((x) => x.Index === data.Index);
      subtitlesCopy[changedObjextIndex] = data;
      const { axiosReq } = movieService.editSubtitle(translation.translationID, JSON.stringify(subtitlesCopy));
      axiosReq.then((x) => {
        if (x.data.success) {
          let translationIndex = movieTranslations.findIndex((x) => x.languageID === translationLanguage);
          let movieTranslationCopy = [...movieTranslations];
          movieTranslationCopy[translationIndex].subtitles = subtitlesCopy;
          movieTranslationCopy[translationIndex].subtitleJSON = JSON.stringify(subtitlesCopy);
          setMovieTranslations(movieTranslationCopy);
        }
      });
    }
  };

  const detectedLanguage = languageList.find((x) => x.id === movieData.movieAudio?.originalLanguageID) || {};
  const generatedLanguages = languageList.filter((x) => movieTranslations.map((y) => y.languageID).includes(x.id) && x.id !== movieData.movieAudio?.originalLanguageID);
  const otherLanguages = languageList.filter((x) => ![...generatedLanguages, detectedLanguage].map((y) => y.id).includes(x.id));

  return (
    <Dialog
      maxWidth={'lg'}
      PaperProps={{ style: { zIndex: '12', backgroundColor: '#14142B', border: '1.5 solid #4E4B66', borderRadius: '15px', color: 'white', padding: '20px', border: '2px solid #4E4B66', height: '655px' } }}
      open={openTranslation}
      onClose={(e) => {
        e.stopPropagation();
        setOpenTranslation(false);
      }}
    >
      <DialogTitle
        style={{ fontSize: '22px', fontFamily: 'Poppins', fontWeight: 600 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        Subtitles | {movieData.name}
        <IconButton
          style={{ color: 'white', position: 'absolute', right: '15px' }}
          onClick={(e) => {
            setOpenTranslation(false);
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="dialog-content" onClick={(e) => e.stopPropagation()}>
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ display: 'flex' }}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <select
                  style={{ width: '100%', marginBottom: '10px', padding: '10px 20px', borderRadius: '10px', outline: 'none', backgroundColor: '#262338', color: 'white', fontFamily: 'Poppins', borderColor: '#4E4B66' }}
                  value={translationLanguage}
                  onChange={(e) => {
                    setTranslationLanguage(e.target.value);
                  }}
                >
                  {detectedLanguage && (
                    <>
                      <option style={{ fontWeight: 700, fontColor: 'white' }} disabled>
                        Detected language
                      </option>
                      <option key={detectedLanguage.id} value={detectedLanguage.id}>
                        {detectedLanguage.name}
                      </option>
                      <option style={{ color: '#6E7191', fontSize: 12 }} value="" disabled="disabled">
                        ──────────────────────────
                      </option>
                    </>
                  )}
                  {generatedLanguages.length > 0 && (
                    <>
                      <option style={{ fontWeight: 700, fontColor: 'white' }} disabled>
                        Generated languages
                      </option>
                      {generatedLanguages.map((x) => {
                        return (
                          <option key={x.id} value={x.id}>
                            {x.name}
                          </option>
                        );
                      })}
                      <option style={{ color: '#6E7191', fontSize: 12 }} value="" disabled="disabled">
                        ──────────────────────────
                      </option>
                    </>
                  )}
                  {otherLanguages.map((x) => {
                    return (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    );
                  })}
                </select>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <div style={{ marginLeft: '10px', marginBottom: '10px' }}>
                  {!movieTranslations.find((x) => x.languageID === translationLanguage) && (
                    <div
                      style={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        padding: '10px 20px',
                        borderRadius: '10px',
                        backgroundColor: '#A996FF',
                        color: '#4700AB',
                        fontWeight: 600,
                        fontFamily: 'Poppins',
                        borderColor: '#4E4B66'
                      }}
                      onClick={() => {
                        createSubtitle(movieData.id, translationLanguage);
                      }}
                    >
                      Subtitle
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <video
                controls
                style={{ width: '100%' }}
                className={`${cssStyle.thubmnailSize}`}
                src={movieData.storageUrl + movieData.moviePath}
                onClick={(e) => {
                  //   openPreview(e);
                }}
                crossOrigin="anonymous"
              >
                <React.Fragment key={movieTranslations}>
                  {movieTranslations.map((x) => {
                    if (x.languageID === translationLanguage) {
                      return (
                        <track
                          default={x.languageID === translationLanguage}
                          key={translationLanguage}
                          label={languageList.find((y) => y.id === x.languageID)?.name}
                          kind="subtitles"
                          srcLang={x.languageID}
                          src={movieData.storageUrl + x.subtitlePath.replace('.json', '.vtt')}
                        />
                      );
                    }
                  })}
                </React.Fragment>
              </video>
            </Grid>

            <Grid style={{ height: '60px', width: '444px' }}> </Grid>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <Grid className="vertical-scroll-box" item style={{ height: '370px', marginLeft: '15px' }}>
              <article className={classes.rightBox}>
                <div className={classes.tabWrapp}>
                  <div className={valueTab === 1 ? classes.tabActive : classes.tab} onClick={() => setValueTab(1)}>
                    Captions
                  </div>
                  <div className={valueTab === 2 ? classes.tabActive : classes.tab} onClick={() => setValueTab(2)}>
                    Style
                  </div>
                </div>

                {valueTab === 1 && (
                  <div className={classes.captionsBodyWrapp}>
                    {movieTranslations
                      .find((x) => x.languageID === translationLanguage)
                      ?.subtitles?.map((x) => (
                        <SingleMoviePart key={x.Index} data={x} handleSubtitleEdit={handleSubtitleEdit} />
                      ))}
                  </div>
                )}
              </article>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: '20px', padding: '5px', width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          {movieTranslations.find((x) => x.languageID === translationLanguage) && (
            <>
              <button
                type="button"
                className={classes.exportMovieCrt}
                onClick={() => {
                  handleExportSRT(translationLanguage);
                }}
              >
                Export SRT
              </button>
              <button
                type="button"
                className={classes.exportMovieBtn}
                onClick={() => {
                  handleExportWithSubtitles(movieTranslations.find((x) => x.languageID === translationLanguage).translationID);
                }}
              >
                Export Movie
              </button>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MovieTranslationModal;
MovieTranslationModal.defaultProps = {
  openTranscription: false,
  setOpenTranscription: () => {},
  movieData: [],
  languageList: []
};
