import previewQuestionDetails from './questionPreviewDetails.module.css';

const QuestionPreviewDetails = ({ selectedQuestion, state, typeId }) => {
  // let questionNo = `question${selectedQuestion}`;
  return (
    <div className={previewQuestionDetails.mainPreviewRightInner}>
      <div className={previewQuestionDetails.detailsQuestion}>
        <div className={previewQuestionDetails.detailsHeader}>
          <h6>Question {selectedQuestion + 1}</h6>
          <p className={previewQuestionDetails.detailsDescription}>{state?.step1.questions[typeId - 1][selectedQuestion]?.value}</p>
        </div>

        <div className={previewQuestionDetails.detailsFooter}>
          <button className={previewQuestionDetails.btnChooseLib} type="button">
            Choose from library
          </button>
          <button className={previewQuestionDetails.btnRecordVideo} type="button">
            Record video
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionPreviewDetails;
QuestionPreviewDetails.defaultProps = {
 selectedQuestion:0 ,
 state: {},
 typeId: 1
}
