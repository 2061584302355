import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const url = '/api/Scene';
const librarySceneUrl = '/api/Scene/save-library';
const undoSceneUrl = '/api/Scene/undo-scenes';
const remainingUrl = '/api/Company/remaining-seconds';

const getProjectScenes = (projectId, isEditor = false) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + url, {
      params: { ProjectID: projectId, IsEditor: isEditor },
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const saveProjectScenes = (scenes, projectID, saveBeforeShotstack = false) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.post(
      baseUrl + url,
      { Scenes: scenes, ProjectID: projectID, SavedBeforeShotstack: saveBeforeShotstack },
      {
        cancelToken: source.token,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    ),
    cancelTokenSource: source
  };
};

const saveScenesForLibrary = (scenes) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.post(
      baseUrl + librarySceneUrl,
      { Scenes: scenes },
      {
        cancelToken: source.token,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    ),
    cancelTokenSource: source
  };
};

const deleteProjectScenes = (Id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.delete(`${baseUrl + url}/DeleteScene/${Id}`, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    }),
    cancelTokenSource: source
  };
};

const getUndoScenes = (LastProjectTempID, ProjectID, isRedo) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + undoSceneUrl, {
      params: {
        LastProjectTempID: LastProjectTempID,
        ProjectID: ProjectID,
        IsRedo: isRedo
      },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

// const getRemainingSeconds = () => {
//   const CancelToken = axios.CancelToken;
//   const source = CancelToken.source();

//   let token = localStorage.getItem('token');

//   return {
//     req: axios.get(baseUrl + remainingUrl, {
//       cancelToken: source.token,
//       headers: { Authorization: 'Bearer ' + token }
//     }),
//     cancelTokenSource: source
//   };
// };

export default {
  getProjectScenes,
  saveProjectScenes,
  deleteProjectScenes,
  getUndoScenes,
  saveScenesForLibrary,
  // getRemainingSeconds
};
