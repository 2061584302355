import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { toast } from 'react-toastify';
import projectApi from '../../../services/apiServices/projects/projectApi';
import InviteModal from './InviteModal';
import SingleUser from './SingleUser';

const styleAccountSettings = makeStyles({
  passWrapp: {
    backgroundColor: '#262338',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    borderRadius: '10px'
  },
  passInputsWrapp: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    paddingLeft: '15px',
    marginTop: '12px'
  },
  headWrapp: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px',
    color: 'white'
  },
  listMainWrapp: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    maxHeight: '470px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      marginLeft: '10px',
      paddingLeft: '10px',
      width: '5px',
      height: '20px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px',
      width: '10px',
      height: '20px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#44415A',
      height: '20px'
    }
  },
  searchBarWrapp: {
    width: '55%',
    position: 'relative'
  },
  searchBar: {
    width: '100%',
    padding: '12px',
    paddingLeft: '36px',
    outline: 'none',
    borderRadius: '10px',
    backgroundColor: '#262338',
    color: 'white',
    border: '1px solid white',
    fontSize: '1.05rem',
    '&::placeholder': {
      color: 'white',
      fontSize: '1.05rem'
    }
  },
  searchIcon: {
    color: 'white',
    position: 'absolute',
    fontSize: '23px',
    left: '10px',
    top: '11px'
  },
  invitePar: {
    color: '#8072C1',
    fontWeight: 'bold',
    cursor: 'pointer',
    textDecoration: 'underline'
  },
  inviteWrapp: { padding: '25px', display: 'flex', justifyContent: 'flex-end' }
});

const UserList = ({ currentPlan }) => {
  const classes = styleAccountSettings();

  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [data, setData] = useState([]);
  const [roles, setRoles] = useState([]);
  const [search, setSearch] = useState('');

  const inviteUser = () => {
    if (!currentPlan) {
      toast.warn('To invite users upgrade your plan.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }
    setOpenInviteModal(true);
  };

  const getFayeUsers = () => {
    const { axiosReq } = projectApi.getFilterUsers(search);
    axiosReq.then((res) => {
      if (res.data.success === true) {
        setData(res.data.data);
        setRoles(res.data.roles);
      }
    });
  };

  useEffect(() => {
    getFayeUsers();
  }, [search]);

  return (
    <article className={classes.passWrapp}>
      <header className={classes.headWrapp}>
        <h2>Users</h2>

        <div className={classes.searchBarWrapp}>
          <input className={classes.searchBar} type="text" placeholder="Search" onChange={(e) => setSearch(e.target.value)} />
          <FiSearch className={classes.searchIcon} />
        </div>
      </header>

      <div className={classes.listMainWrapp}> {data.length > 0 && data.map((item, ind) => <SingleUser key={ind} item={item} roles={roles} ind={ind} len={data.length} />)}</div>

      <article className={classes.inviteWrapp}>
        <p onClick={inviteUser} className={classes.invitePar}>
          + Invite new users
        </p>
      </article>

      {openInviteModal && <InviteModal data={data} setData={setData} setOpenInviteModal={setOpenInviteModal} />}
    </article>
  );
};

export default UserList;
UserList.defaultProps = {
  currentPlan: {}
};
