import Grid from '@material-ui/core/Grid';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import { toast } from 'react-toastify';
import movieService from '../../services/apiServices/movieService';

const MovieTranscribeModal = ({ openTranscription, setOpenTranscription, movieData, languageList }) => {
  const [originalLanguage, setOriginalLanguage] = useState('en-US');
  const [showPending, setShowPending] = useState(false);

  const createTranscription = (id, originalLanguage) => {
    const { axiosReq } = movieService.makeTranscription(id, originalLanguage);
    axiosReq.then((x) => {
      if (x.data.success) {
        toast.success('Movie transcription started', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        toast.info(x.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  return (
    <Dialog
      maxWidth={'md'}
      PaperProps={{ style: { zIndex: '12', backgroundColor: '#14142B', borderRadius: '15px', color: 'white', padding: '20px', border: '2px solid #4E4B66' } }}
      open={openTranscription}
      onClose={(e) => {
        e.stopPropagation();
        setOpenTranscription(false);
      }}
    >
      <DialogTitle
        style={{ fontSize: '22px', fontFamily: 'Poppins', fontWeight: 600 }}
        onClick={(e) => e.stopPropagation()}> Transcription | {movieData.name}
        <IconButton
          style={{ color: 'white', position: 'absolute', right: '15px' }}
          onClick={(e) => {
            setOpenTranscription(false);
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className="dialog-content"
        onClick={(e) => e.stopPropagation()}
      >
        <Grid container>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div style={{ marginRight: '10px', marginBottom: '10px' }}>
              <select
                disabled={showPending}
                style={{ width: '100%', padding: '10px 20px', borderRadius: '10px', outline: 'none', backgroundColor: '#262338', color: 'white', fontFamily: 'Poppins', borderColor: '#4E4B66' }}
                value={originalLanguage}
                onChange={(e) => {
                  setOriginalLanguage(e.target.value);
                }}
              >
                {languageList.map((x) => {
                  return (
                    <option key={x.id} value={x.id}> {x.name} </option>
                  );
                })}
              </select>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div style={{ marginLeft: '10px' }}>
              <div
                style={{
                  textAlign: 'center',
                  cursor: showPending ? 'default' : 'pointer',
                  padding: '10px 20px',
                  borderRadius: '10px',
                  backgroundColor: showPending ? '#262338' : '#A996FF',
                  color: '#4700AB',
                  fontWeight: 600,
                  fontFamily: 'Poppins',
                  borderColor: '#4E4B66'
                }}
                onClick={() => {
                  if (!showPending) {
                    setShowPending(true);
                    createTranscription(movieData.id, originalLanguage);
                    setOpenTranscription(false);
                  }
                }}
              >
                {showPending ? 'Pending' : 'Transcribe'}
              </div>
            </div>
          </Grid>
          <div style={{ width: '786px' }}>
            <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={movieData.storageUrl + movieData.thumbnailPath} alt="Logo" />
          </div>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default MovieTranscribeModal;
MovieTranscribeModal.defaultProps = {
  openTranscription: false, 
  setOpenTranscription: ()=>{}, 
  movieData:[], 
  languageList : []
}