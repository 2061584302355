import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
const MoviesMenu = ({
  selectedMoreId,
  setSelectedMoreId,
  movieOptionsAnchorEl,
  setOpenMovieSettingsDialog,
  closeMovieOptions,
  isMobile,
  openPreview,
  setOpenShareMovieDialog,
  data,
  handleDeleteOpen,
  setOpenTranslation,
  setOpenTranscription
}) => {
  return (
    <ClickAwayListener onClickAway={() => {}}>
      <Popper open={selectedMoreId} anchorEl={movieOptionsAnchorEl} placement={'bottom'} transition onMouseOver={() => setSelectedMoreId(true)} onMouseLeave={() => setSelectedMoreId(false)}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Box className="movie-options-cloud" sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
              <MenuList>
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenMovieSettingsDialog(true);
                    closeMovieOptions(e);
                  }}
                >
                  Settings
                </MenuItem>

                {!isMobile && (
                  <MenuItem
                    onClick={(e) => {
                      openPreview(e);
                    }}
                  >
                    Preview
                  </MenuItem>
                )}

                <MenuItem
                  onClick={(e) => {
                    setOpenShareMovieDialog(true);
                    closeMovieOptions(e);
                    e.stopPropagation();
                  }}
                >
                  Share
                </MenuItem>

                {!isMobile && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      window.location.href = data.storageUrl + data.moviePath;
                      closeMovieOptions(e);
                    }}
                  >
                    Download
                  </MenuItem>
                )}

                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteOpen();
                    closeMovieOptions(e);
                  }}
                >
                  Delete
                </MenuItem>
                {!!data.movieAudio && !!data.movieAudio?.speechPath && !isMobile && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenTranslation(true);
                    }}
                  >
                    Subtitle
                  </MenuItem>
                )}
                {!data.movieAudio && !data.movieAudio?.speechPath && !isMobile && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenTranscription(true);
                    }}
                  >
                    Subtitle
                  </MenuItem>
                )}
              </MenuList>
            </Box>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

export default MoviesMenu;
MoviesMenu.defaultProps = {
  selectedMoreId: '',
  setSelectedMoreId: ()=>{},
  movieOptionsAnchorEl: false,
  setOpenMovieSettingsDialog: ()=>{},
  closeMovieOptions: null,
  isMobile: false,
  openPreview: false,
  setOpenShareMovieDialog: ()=>{},
  data: [],
  handleDeleteOpen: ()=>{},
  setOpenTranslation: ()=>{},
  setOpenTranscription: ()=>{}
}