const ActiveSceneMasterClip = ({ masterClip }) => {
  return (
    <>
      {masterClip?.asset?.waitingForPreview && <div style={{ position: 'absolute', color: 'white', top: 0, left: '10px', fontSize: '11px' }}>Waiting for preview</div>}
      {!!masterClip > 0 && (masterClip.asset.type === 'image' || masterClip.asset.type === 'html') && <img key={masterClip.assetStyleID} src={masterClip.asset.src} style={{ height: '100%', width: '100%', padding: '5px' }} alt='Master clip' />}
      {!!masterClip > 0 && masterClip.asset.type === 'video' && (
        <video key={masterClip.assetStyleID} style={{ objectFit: 'fill', height: '100%', width: '100%', padding: '5px' }}>
          <source src={masterClip.asset.previewSrc || masterClip.asset.src} />
        </video>
      )}
    </>
  );
};

export default ActiveSceneMasterClip;
