import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { CurrentTimeContext } from '../../../../../../context/current_time_context';

const useStyles = makeStyles({
  sceneAudioAsset: {
    height: '60px',
    width: '135px',
    alignSelf: 'center',
    backgroundColor: 'lightblue',
    fontWeight: '500',
    textAlign: 'center',
    textOverflow: 'clip',
    cursor: 'pointer',
    objectFit: 'cover'
  },
  innerAudioAsset: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px'
  }
});

const AudioSceneAsset = ({ asset, setSelectedAsset, activeSceneContent, setCurrentAttribute }) => {
  const classes = useStyles();
  const { setCurrentTime } = useContext(CurrentTimeContext);

  return (
    <div
      className={classes.sceneAudioAsset}
      onClick={(e) => {
        if (e.detail === 2) {
          let sceneStart = 0;
          if (!!activeSceneContent.find((x) => x.isMaster === true)) {
            sceneStart = activeSceneContent.find((x) => x.isMaster === true).start;
          }
          let assetStart = asset.start;
          setCurrentTime(assetStart - sceneStart);
        }
        setSelectedAsset(asset);
        setCurrentAttribute("audio")
      }}
    >
      <p className={classes.innerAudioAsset}>{asset.asset.name}</p>
    </div>
  );
};
export default AudioSceneAsset;
AudioSceneAsset.defaultProps = {
  asset: {}, 
  setSelectedAsset: ()=>{}, 
  activeSceneContent: [], 
  setCurrentAttribute: ()=>{}
}