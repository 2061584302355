import { useState } from 'react';
import { toast } from 'react-toastify';
import authService from '../../../services/apiServices/authService';

const useSignup = (setLoginVisible) => {
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [openConfirmSignUp, setOpenConfirmSignUp] = useState(false);
  const [showSuccessReg, setShowSuccessReg] = useState(false);
  const [showChmark, setShowChmark] = useState(false);
  const [showChmarkError, setShowChmarkError] = useState('');

  const handleChangeInput = (value, setError, setValue) => {
    setError(null);
    setValue(value);
  };

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const handleSignUpClick = () => {
    let error = false;
    if (firstName.trim().length < 1) {
      setFirstNameError('First name cannot be empty.');
      error = true;
      return;
    }

    if (lastName.trim().length < 1) {
      setLastNameError('Last name cannot be empty.');
      error = true;
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError('Email is invalid');
      error = true;
      return;
    }

    if (password.trim().length < 6) {
      setPasswordError('Password must have at least 6 characters');
      error = true;
    } else if (password.trim() !== confirmPassword.trim()) {
      setPasswordError('Passwords must match');
      error = true;
    }

    if (!showChmark) {
      setShowChmarkError('You have to accept Terms of Use and Privacy Policy');
      error = true;
    }

    if (error) {
      return;
    }

    const { req, cancelTokenSource } = authService.doRegister(firstName, lastName, email, password);

    req.then((res) => {
      if (res.data.success === true) {
        localStorage.setItem('fayeproject', res.data.projectID);
        setShowSuccessReg(true);
        window.lintrk('track', { conversion_id: 12898913 });
        window.gr('track', 'conversion', { email: email });
      } else if (res.data.userExists) {
        toast.error('Email already exists!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setEmailError('User already exists!');
        setShowChmarkError('');
        setShowChmark(false);
      }
    });
  };

  const closeConfirmSignUp = () => {
    setLoginVisible(true);
    setOpenConfirmSignUp(false);
    setFirstName('');
    setLastName('');
    setEmail('');
    setPassword('');
    setConfirmPassword('');
    setShowChmark(false);
    setShowChmarkError('');
  };

  return {
    firstName,
    setFirstName,
    firstNameError,
    setFirstNameError,
    lastName,
    setLastName,
    lastNameError,
    setLastNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    showSuccessReg,
    showChmarkError,
    showChmark,
    setShowChmark,
    handleChangeInput,
    handleSignUpClick,
    closeConfirmSignUp
  };
};

export default useSignup;
