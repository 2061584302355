import { useEffect, useState } from 'react';
import sceneService from '../../../../services/apiServices/sceneService';
import FullPreviewModal from '../../../modals/FullPreviewModal';
import SoundtrackStage from '../../../stage/stage-objects/soundtrackStage/SoundtrackStage';
import useProjectsSection from '../custom/useProjectsSection';
import TableHead from './TableHead';
import TableItem from './TableItem';
import tableStyle from './table.module.css';

const Table = ({ allProjects, setAllProjects, isMobile, isChannel }) => {
  const [selectedId, setSelectedId] = useState('');
  const [selectedProjectID, setSelectedProjectID] = useState(null);
  const [openFullPreviewModal, setOpenFullPreviewModal] = useState(false);
  const { deleteHandler, selectProject, activateHandler } = useProjectsSection({ allProjects, setAllProjects });

  const [scenes, setScenes] = useState([]);
  const [soundtrack, setSoundtrack] = useState({});
  const [projectDuration, setProjectDuration] = useState({});

  const GetProjectScenes = (projectID) => {
    const { req, cancelTokenSource } = sceneService.getProjectScenes(projectID);
    req.then((resp) => {
      if (resp.data.success === true) {
        if (!!resp.data.project) {
          if (!!resp.data.project.soundtrack) {
            setSoundtrack(resp.data.project.soundtrack);
          } else {
            setSoundtrack({});
          }
          let scenes = resp.data.scenes;
          setScenes(scenes);
          let totalDuration = 0;
          for (let i = 0; i < scenes.length; i++) {
            let sceneAssets = scenes[i].assets.filter((x) => x.deleted === false).sort((a, b) => b.z - a.z);

            if (sceneAssets.length > 0) {
              for (let j = 0; j < sceneAssets.length; j++) {
                // scene duration is by the first clip
                let masterClipLength = sceneAssets[j].isMaster === true ? sceneAssets[j].length : 0;
                totalDuration += masterClipLength;
              }
            }
          }
          setSelectedProjectID(projectID);
          setProjectDuration(totalDuration);
          setOpenFullPreviewModal(true);
        }
      }
    });
  };

  useEffect(() => {
    if (!openFullPreviewModal) {
      setSoundtrack({});
    }
  }, [openFullPreviewModal]);

  const handleFullPreview = (projectID) => {
    GetProjectScenes(projectID);
  };

  return (
    <div className={tableStyle.tableWrapp}>
      <div className={tableStyle.tableMain}>
        <TableHead isChannel={isChannel} />

        <div style={{ width: '100%' }}>
          {allProjects.map((item) => (
            <TableItem
              key={item.id}
              allProjects={allProjects}
              setAllProjects={setAllProjects}
              isMobile={isMobile}
              item={item}
              selectProject={selectProject}
              deleteHandler={deleteHandler}
              handleFullPreview={handleFullPreview}
              isChannel={isChannel}
              activateHandler={activateHandler}
              selectedId={selectedId}
              setSelectedId={setSelectedId}
            />
          ))}
        </div>
      </div>
      <FullPreviewModal
        key={selectedProjectID}
        scenes={scenes}
        projectDuration={projectDuration}
        setOpenFullPreviewModal={setOpenFullPreviewModal}
        openFullPreviewModal={openFullPreviewModal}
        hasUseTemplate={false}
        projectId={selectedProjectID}
        isProjectPage={true}
        deleteHandler={deleteHandler}
      />
      <SoundtrackStage key={soundtrack.id} soundtrack={soundtrack} activeSceneID={null} scenes={scenes} openFullPreviewModal={openFullPreviewModal} setInteractObject={() => {}} isHidden={true} />
    </div>
  );
};

export default Table;
Table.defaultProps = {
  allProjects: [],
  setAllProjects: () => {},
  isMobile: false
};
