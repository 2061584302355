import { Grid } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import libraryService from '../../../services/apiServices/libraryService';
import CustomGrid from '../../appArchitect/CustomGrid';
import useCustomSidePickerHooks from '../customHooks/useCustomSidePickerHooks';
import { Cards } from './Cards';

const BodyPicker = ({ searchValue, headerAssetType, libraryUpdated, headerPickerTab, currentAttribute, assetElements, setAssetElements }) => {
  const { useStyle } = useCustomSidePickerHooks();
  const { bodyPickStyle } = useStyle();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const scrollDivRef = useRef();

  const GetAssets = (type, skip, take, search, onlyRefresh = false) => {
    if (typeof skip === 'undefined') {
      skip = 0;
    }

    if (typeof take === 'undefined') {
      take = 16;
    }

    const { req, cancelTokenSource } = libraryService.getAssets(type, skip, take, search);
    req.then((resp) => {
      if (!!resp.data) {
        setIsLoading(false);
        if (!onlyRefresh) {
          setAssetElements((prev) => [...prev, ...resp.data.data]);
        } else {
          setAssetElements(resp.data.data);
        }

        setTotalPages(Math.ceil(resp.data.totalCount / take));
      }
    });
  };

  useEffect(() => {
    setInnerWidth(window.innerWidth);
    window.addEventListener('resize', () => setInnerWidth(window.innerWidth));

    return () => window.removeEventListener('resize', setInnerWidth);
  }, []);

  useEffect(() => {
    setAssetElements([]);
    setCurrentPage(0);
  }, [headerAssetType]);

  useEffect(() => {
    if (!!searchValue) {
      setAssetElements([]);
      setCurrentPage(0);
    }
  }, [searchValue]);

  useEffect(() => {
    if (libraryUpdated > 0) {
      setCurrentPage(1);
      let type = '';
      if (headerPickerTab === 1) {
        type = 'video';
      } else if (headerPickerTab === 2) {
        type = 'audio';
      } else if (headerPickerTab === 3) {
        type = 'image';
      } else if (headerPickerTab === 4) {
        type = 'html';
      } else if (headerPickerTab === 0) {
        type = 'scenes';
      }

      if (!!type) {
        GetAssets(type, 0, 16, searchValue, true);
      }
    }
  }, [libraryUpdated]);

  useEffect(() => {
    if (currentPage < 1) {
      setCurrentPage(1);
      return;
    }

    let jump = (currentPage - 1) * 16;
    if (headerPickerTab === 1) {
      GetAssets('video', jump, 16, searchValue);
    } else if (headerPickerTab === 2) {
      GetAssets('audio', jump, 16, searchValue);
    } else if (headerPickerTab === 3) {
      GetAssets('image', jump, 16, searchValue);
    } else if (headerPickerTab === 4) {
      GetAssets('html', jump, 16, searchValue);
    } else if (headerPickerTab === 0) {
      GetAssets('scenes', jump, 16, searchValue);
    }
  }, [currentPage]);

  const onScroll = () => {
    if (scrollDivRef.current && !isLoading) {
      const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
      if (scrollTop + clientHeight > scrollHeight - 10 && currentPage < totalPages) {
        setIsLoading(true);
        setCurrentPage(currentPage + 1);
      }
    }
  };

  const bodyPickerTrimValue = headerPickerTab === 1 || headerPickerTab === 2 || headerPickerTab === 3 ? 30 : 0;

  return (
    <>
      <div id="body-picker" style={{ height: (!currentAttribute ? 450 - bodyPickerTrimValue : 390 - bodyPickerTrimValue) + 'px' }} className={bodyPickStyle.bodyPicker} onScroll={() => onScroll()} ref={scrollDivRef}>
        <Grid container className={bodyPickStyle.cardsBodyWrapp} spacing={2}>
          {assetElements.map((x) => {
            return (
              <CustomGrid
                key={x.id}
                customBreakPoints={['xlg']}
                item
                xl={headerPickerTab === 2 ? 12 : 3}
                xlg={headerPickerTab === 2 ? 12 : 4}
                sm={headerPickerTab === 2 ? 12 : innerWidth < 1224 ? 3 : 6}
                xs={headerPickerTab === 2 ? 12 : 6}
              >
                <Cards key={x.id} headerAssetType={headerAssetType} data={x} />
              </CustomGrid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default BodyPicker;
