import { fabric } from 'fabric';
import { useContext, useEffect, useState } from 'react';
import { CurrentTimeContext } from '../../../../context/current_time_context';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../../utilities/consts';
import { rangeColors } from './../../../timeline/projectTime/AssetRangeColors';

const TextStageCanvas = ({ clip, selectedAsset, setInteractObject, sceneDuration, sceneStart, editSuffix = '' }) => {
  const [canvas, setCanvas] = useState(null);
  const [canvasStyle, setCanvasStyle] = useState(null);
  const [base64img, setBase64Img] = useState('');
  const [visibility, setVisibility] = useState('hidden');
  const [isMounted, setIsMounted] = useState(false);
  const { currentTime } = useContext(CurrentTimeContext);

  const makeBorderAroundTextObject = (color) => {
    if (!canvas) return;

    var ctx = canvas.contextContainer,
      obj = canvas.getObjects()[0];
    if (obj) {
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(obj.aCoords.tl.x, obj.aCoords.tl.y);
      ctx.lineTo(obj.aCoords.tr.x, obj.aCoords.tr.y);
      ctx.lineTo(obj.aCoords.br.x, obj.aCoords.br.y);
      ctx.lineTo(obj.aCoords.bl.x, obj.aCoords.bl.y);
      ctx.closePath();
      ctx.stroke();
    }
  };

  const makeScaledUpBase64Img = (style, scaleX, scaleY, item, text) => {
    let styleTemp = JSON.parse(style);
    styleTemp.scaleX = scaleX * 10;
    styleTemp.scaleY = scaleY * 10;
    let textTemp = new fabric.IText(text?.replace('\\n', '\n'), {
      ...styleTemp,
      ...{
        lockScalingFlip: true,
        width: item.w * 10,
        height: item.h * 10,
        top: item.y,
        left: item.x
      }
    });

    const htmlImageBase64 = textTemp.toDataURL('image/png');
    return htmlImageBase64;
  };

  useEffect(() => {
    let c = new fabric.Canvas('screen_' + clip.assetStyleID + editSuffix, { containerClass: 'canvas-container-full canvas-container-full-id-' + clip.assetStyleID + editSuffix });
    setCanvas(c);

    return () => {
      if (!!canvas && canvas._objects.length > 0) {
        canvas.clear();
        if (canvas._objects.length > 0) {
          canvas.dispose();
        }
      }
      setCanvas(null);
    };
  }, []);

  useEffect(() => {
    if (canvas === null) return;

    let styles = {};
    if (!!clip.canvasStyle) {
      styles = JSON.parse(clip.canvasStyle.replace('.ttf', ''));
    }

    let text = new fabric.IText(clip.asset.text?.replace('\\n', '\n'), {
      ...styles,
      ...{
        evented: true,
        selectable: true,
        editable: true,
        lockRotation: true,
        lockScalingFlip: true,
        borderColor: rangeColors[clip.asset.typeID],
        width: clip.w,
        height: clip.h,
        top: clip.y,
        left: clip.x
      }
    });

    text.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false
    });

    canvas.setDimensions({ width: SCREEN_WIDTH, height: SCREEN_HEIGHT });
    canvas.add(text);

    canvas.renderAll();

    canvas.on('text:changed', function (e) {
      let item = {};
      item.type = 'textchange';
      item.text = e.target.text;
      item.id = clip.assetStyleID;
      setInteractObject(item);
    });

    // https://stackoverflow.com/questions/42833142/prevent-fabric-js-objects-from-scaling-out-of-the-canvas-boundary
    canvas.on('object:moving', function (e) {
      var obj = e.target;
      // if object is too big ignore
      if (obj.currentHeight > obj.canvas.height || obj.currentWidth > obj.canvas.width) {
        return;
      }
      obj.setCoords();
      // top-left  corner
      if (obj.getBoundingRect().top < 0 || obj.getBoundingRect().left < 0) {
        obj.top = Math.max(obj.top, obj.top - obj.getBoundingRect().top);
        obj.left = Math.max(obj.left, obj.left - obj.getBoundingRect().left);
      }
      // bot-right corner
      if (obj.getBoundingRect().top + obj.getBoundingRect().height > obj.canvas.height || obj.getBoundingRect().left + obj.getBoundingRect().width > obj.canvas.width) {
        obj.top = Math.min(obj.top, obj.canvas.height - obj.getBoundingRect().height + obj.top - obj.getBoundingRect().top);
        obj.left = Math.min(obj.left, obj.canvas.width - obj.getBoundingRect().width + obj.left - obj.getBoundingRect().left);
      }
    });

    canvas.on('object:scaling', function (e) {
      var obj = e.target;
      obj.setCoords();
      let top = obj.getBoundingRect().top;
      let left = obj.getBoundingRect().left;
      let height = obj.getBoundingRect().height;
      let width = obj.getBoundingRect().width;

      // restrict scaling below bottom of canvas
      if (top + height > SCREEN_HEIGHT) {
        text.scaleY = 1;
        text.setCoords();
        let h = text.getScaledHeight();

        text.scaleY = (SCREEN_HEIGHT - top) / h;
        text.setCoords();
        canvas.renderAll();

        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
      }

      // restrict scaling above top of canvas
      if (top < 0) {
        text.scaleY = 1;
        text.setCoords();
        let h = text.getScaledHeight();
        text.scaleY = (height + top) / h;
        text.top = 0;
        text.setCoords();
        canvas.renderAll();

        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
      }

      // restrict scaling above right of canvas
      if (left + width > SCREEN_WIDTH) {
        text.scaleX = 1;
        text.setCoords();
        let w = text.getScaledWidth();

        text.scaleX = (SCREEN_WIDTH - left) / w;
        text.setCoords();
        canvas.renderAll();

        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
      }

      // restrict scaling above left of canvas
      if (left < 0) {
        text.scaleX = 1;
        text.setCoords();
        let w = text.getScaledWidth();
        text.scaleX = (width + left) / w;
        text.left = 0;
        text.setCoords();
        canvas.renderAll();
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.lockMovementX = true;
        obj.lockMovementY = true;
      }
    });

    canvas.on('object:modified', function (event) {
      // after text object is done with modifing e.g. resizing or moving
      if (!!event.target) {
        event.target.lockScalingX = false;
        event.target.lockScalingY = false;
        event.target.lockMovementX = false;
        event.target.lockMovementY = false;

        let x = Number(event.target.left.toFixed(2));
        let y = Number(event.target.top.toFixed(2));
        let w = Number(text.getScaledWidth().toFixed(2));
        let h = Number(text.getScaledHeight().toFixed(2));

        let item = {};
        item.id = clip.assetStyleID;
        item.sceneid = clip.sceneID;
        item.type = 'resizable';
        item.h = h;
        item.w = w;
        item.canvasScaleX = text.scaleX;
        item.canvasScaleY = text.scaleY;

        item.x = Math.abs(Number(x).toFixed(3));
        item.y = Math.abs(Number(y).toFixed(3));

        let elementWidthMid = w / 2;
        let elementHeightMid = h / 2;

        let elementMidPointX = item.x + elementWidthMid;
        let elementMidPointY = item.y + elementHeightMid;

        const SCREEN_MID_X = SCREEN_WIDTH / 2;
        const SCREEN_MID_Y = SCREEN_HEIGHT / 2;

        let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / SCREEN_WIDTH);
        let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / SCREEN_HEIGHT);
        item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
        item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

        item.text = text.text;

        setInteractObject(item);
      } else {
        const clip = event.clip;

        let x = clip.x;
        let y = clip.y;
        let w = text.getScaledWidth();
        let h = text.getScaledHeight();

        let item = {};
        item.id = clip.assetStyleID;
        item.sceneid = clip.sceneID;
        item.type = 'resizable';
        item.h = h;
        item.w = w;
        item.canvasScaleX = text.scaleX;
        item.canvasScaleY = text.scaleY;

        item.x = Math.abs(Number(x).toFixed(3));
        item.y = Math.abs(Number(y).toFixed(3));

        let elementWidthMid = w / 2;
        let elementHeightMid = h / 2;

        let elementMidPointX = item.x + elementWidthMid;
        let elementMidPointY = item.y + elementHeightMid;

        const SCREEN_MID_X = SCREEN_WIDTH / 2;
        const SCREEN_MID_Y = SCREEN_HEIGHT / 2;
        let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / SCREEN_WIDTH);
        let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / SCREEN_HEIGHT);
        item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
        item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

        item.text = text.text;

        setTimeout(() => {
          setInteractObject(item);
        }, [100]);
      }
    });

    setIsMounted(true);

    return () => {
      canvas.off();
    };
  }, [canvas]);

  useEffect(() => {
    if (!!canvas && !!clip && !!isMounted) {
      let text = canvas.getObjects()[0];
      let clipCanvasStyles = JSON.parse(clip.canvasStyle) || {};

      text.set('top', clip.y);
      text.set('left', clip.x);
      text.set('text', clip.asset.text);

      let result = Object.entries(clipCanvasStyles).map(([k, v]) => ({ [k]: v }));
      for (let i = 0; i < result.length; i++) {
        text.set(result[i]);
      }
      canvas.renderAll();

      if (clip.canvasStyle !== canvasStyle) {
        setCanvasStyle(clip.canvasStyle);
        canvas.fire('object:modified', { clip });
      }

      const htmlImageBase64 = makeScaledUpBase64Img(clip.canvasStyle, clipCanvasStyles.scaleX, clipCanvasStyles.scaleY, clip, clip.asset.text);
      setBase64Img(htmlImageBase64);
    }
  }, [clip, isMounted]);

  useEffect(() => {
    if (base64img.length > 0 && clip.htmlImageBase64 !== base64img) {
      let item = {};
      item.id = clip.assetStyleID;
      item.sceneid = clip.sceneID;
      item.type = 'textbase64img';
      item.htmlImageBase64 = base64img;
      setInteractObject(item);
    }
  }, [base64img]);

  useEffect(() => {
    if (currentTime >= clip.start - sceneStart && currentTime <= clip.start - sceneStart + clip.length) {
      setVisibility('visible');
    } else {
      if (visibility == 'visible') {
        setVisibility('hidden');
      }
    }
  }, [currentTime, clip.length, clip.start, sceneStart, sceneDuration]);

  useEffect(() => {
    let container = document.querySelector('.canvas-container-full-id-' + clip.assetStyleID + editSuffix);
    if (!!container) {
      container.style.visibility = visibility;
    }
  }, [visibility]);

  useEffect(() => {
    if (!!selectedAsset && !!canvas && selectedAsset.assetStyleID !== clip.assetStyleID) {
      canvas.discardActiveObject().renderAll();
    } else if (!!selectedAsset && !!canvas && selectedAsset.assetStyleID === clip.assetStyleID) {
      makeBorderAroundTextObject(rangeColors[clip.z]);
      var obj = canvas.getObjects()[0];
      if (obj) {
        canvas.setActiveObject(obj).renderAll();
      }
    }
  }, [selectedAsset]);

  let container = document.querySelector('.canvas-container-full-id-' + clip.assetStyleID + editSuffix);
  if (!!container) {
    container.style.zIndex = clip.z;
  }

  // must be wrapped in div because of error when removing asset from scene!
  return (
    <div style={{ zIndex: clip.z, opacity: clip.opacity || 1 }}>
      <canvas
        data-select="text"
        data-z={clip.z || 0}
        data-w={clip.w || 0}
        data-h={clip.h || 0}
        data-x={clip.x}
        data-y={clip.y}
        scene-id={clip.sceneID}
        stage-id={clip.assetStyleID}
        id={'screen_' + clip.assetStyleID + editSuffix}
      />
    </div>
  );
};

export default TextStageCanvas;
