import interact from 'interactjs';
import { useEffect, useRef } from 'react';

const useInteract = (isCrop, setInteractObject, cropRight = 0, cropBottom = 0, cropLeft = 0, cropTop = 0, mainElement, asset) => {
  const ref = useRef(null);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      const interactObject = interact(node);

      if (!isCrop) {
        interactObject.draggable({
          inertia: false,
          onmove: (event) => dragStageListener(event, cropLeft, cropTop),
          onend: function (event) {
            drageStageEnd(event, setInteractObject, cropRight, cropBottom, cropLeft, cropTop);
          },
          modifiers: [
            interact.modifiers.restrictEdges({ outer: '#screen-screen' }),
            interact.modifiers.restrictRect({
              restriction: '#screen-screen'
            })
          ]
        });
      }

      interactObject.resizable({
        edges: isCrop ? { left: true, right: true, bottom: true, top: true } : { left: false, right: true, bottom: true, top: false },
        preserveAspectRatio: !isCrop,
        modifiers: isCrop
          ? [
              // interact.modifiers.aspectRatio({
              //   ratio: 'preserve',
              //   modifiers: [interact.modifiers.restrictSize({ max: '#screen-screen' }), interact.modifiers.restrictEdges({ outer: '#screen-screen' })]
              // })
              interact.modifiers.restrictEdges({ outer: '#screen-screen' })
              // interact.modifiers.restrictSize({
              //   max: { width: node.offsetWidth, height: node.offsetHeight },
              //   min: { width: 10, height: 10 },
              //   elementRect: { left: 0, top: 0, right: 1, bottom: 1 }
              // })
            ]
          : [
              interact.modifiers.aspectRatio({
                ratio: 'preserve',
                modifiers: [interact.modifiers.restrictSize({ max: '#screen-screen' }), interact.modifiers.restrictEdges({ outer: '#screen-screen' })]
              })
            ],
        listeners: {
          start: (event) => {
            event.interactable.options.startRect = JSON.parse(JSON.stringify(event.rect));
          },
          move: function (event) {
            let { x, y } = event.target.dataset;
            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`
            });

            Object.assign(event.target.dataset, { x, y });
          },
          end: function (event) {
            if (isCrop) {
              cropResize(ref, mainElement, asset, setInteractObject);
            } else {
              handleResizeEnd(event, setInteractObject, cropRight, cropBottom, cropLeft, cropTop);
            }
          }
        }
      });
    }

    return () => {
      if (node) {
        interact(node).unset();
      }
    };
  }, [isCrop, cropRight, cropBottom, cropLeft, cropTop]);

  return ref;
};

function cropResize(ref, mainElement, asset, setInteractObject) {
  const { x: screenX, y: screenY } = document.querySelector('#screen-screen').getBoundingClientRect();
  const { x, y, top, right, bottom } = mainElement.current.getBoundingClientRect();
  const { x: intX, top: intT, right: intR, bottom: intB } = ref.current.getBoundingClientRect();

  let newY = y - screenY;
  let newX = x - screenX;

  let cropLeft = 0;
  if (x < intX) {
    cropLeft = intX - x;
  }

  let cropTop = 0;
  if (top < intT) {
    cropTop = intT - top;
  }

  let cropRight = 0;
  if (intR < right) {
    cropRight = right - intR;
  }

  let cropBottom = 0;
  if (intB < bottom) {
    cropBottom = bottom - intB;
  }

  let item = {};
  item.id = asset.assetStyleID;
  item.sceneid = asset.sceneID;
  item.type = 'interact-crop';

  item.cropRight = cropRight;
  item.cropBottom = cropBottom;
  item.cropLeft = cropLeft;
  item.cropTop = cropTop;
  item.x = newX;
  item.y = newY;

  let elementWidthMid = asset.w / 2;
  let elementHeightMid = asset.h / 2;

  let elementMidPointX = item.x + elementWidthMid;
  let elementMidPointY = item.y + elementHeightMid;

  const SCREEN_MID_X = 786 / 2;
  const SCREEN_MID_Y = 442 / 2;

  let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / 786);
  let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / 442);
  item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
  item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

  setInteractObject(item);
}

function handleResizeEnd(event, setInteractObject, cropRight = 0, cropBottom = 0, cropLeft = 0, cropTop = 0) {
  let item = {};
  item.id = event.target.getAttribute('stage-id');
  item.sceneid = event.target.getAttribute('scene-id');
  item.type = 'resizable';
  const { width, height } = event.rect;

  item.cropRight = 0;
  if (cropRight > 0) {
    const scaleFactor = width / event.interactable.options.startRect.width;
    item.cropRight = cropRight * scaleFactor;
  }

  item.cropBottom = 0;
  if (cropBottom > 0) {
    const scaleFactor = height / event.interactable.options.startRect.height;
    item.cropBottom = cropBottom * scaleFactor;
  }

  item.cropLeft = 0;
  if (cropLeft > 0) {
    const scaleFactor = width / event.interactable.options.startRect.width;
    item.cropLeft = cropLeft * scaleFactor;
  }

  item.cropTop = 0;
  if (cropTop > 0) {
    const scaleFactor = height / event.interactable.options.startRect.height;
    item.cropTop = cropTop * scaleFactor;
  }

  item.w = width + item.cropLeft + item.cropRight;
  item.h = height + item.cropTop + item.cropBottom;

  item.x = parseFloat(Number(event.target.getAttribute('data-x')).toFixed(3)) - (item.cropLeft - cropLeft);
  item.y = parseFloat(Number(event.target.getAttribute('data-y')).toFixed(3)) - (item.cropTop - cropTop);

  let elementWidthMid = (event.target.offsetWidth + item.cropRight + item.cropLeft) / 2;
  let elementHeightMid = (event.target.offsetHeight + item.cropBottom + item.cropTop) / 2;

  let elementMidPointX = item.x + elementWidthMid;
  let elementMidPointY = item.y + elementHeightMid;

  const SCREEN_MID_X = 786 / 2;
  const SCREEN_MID_Y = 442 / 2;

  let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / 786);
  let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / 442);
  item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
  item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

  setInteractObject(item);
}

function drageStageEnd(event, setInteractObject, cropRight = 0, cropBottom = 0, cropLeft = 0, cropTop = 0) {
  let target = event.target,
    item = {};

  item.id = target.getAttribute('stage-id');
  item.sceneid = target.getAttribute('scene-id');

  item.x = parseFloat(Number(event.target.getAttribute('data-x')).toFixed(3));
  item.y = parseFloat(Number(event.target.getAttribute('data-y')).toFixed(3));

  // shotstack is taking element mid point x,y(0,0) for placing in viewport
  let elementWidthMid = (target.offsetWidth + cropRight + cropLeft) / 2;
  let elementHeightMid = (target.offsetHeight + cropBottom + cropTop) / 2;

  let elementMidPointX = item.x + elementWidthMid;
  let elementMidPointY = item.y + elementHeightMid;

  const SCREEN_MID_X = 786 / 2;
  const SCREEN_MID_Y = 442 / 2;

  let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / 786);
  let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / 442);
  item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
  item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

  item.type = 'draggable';

  setInteractObject(item);
}

function dragStageListener(event, cropLeft = 0, cropTop = 0) {
  var target = event.target,
    // keep the dragged position in the data-x/data-y attributes
    x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
    y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
  target.style.webkitTransform = target.style.transform = 'translate(' + (x + cropLeft) + 'px, ' + (y + cropTop) + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);

  target.setAttribute('data-y', y);
}

export { useInteract, cropResize };
