import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { CurrentTimeContext } from '../../../../../../context/current_time_context';

const useStyles = makeStyles({
  sceneImgAsset: {
    height: '60px',
    width: '135px',
    alignSelf: 'center',
    cursor: 'pointer',
    objectFit: 'cover'
  }
});

const ImageSceneAsset = ({ asset, setSelectedAsset, activeSceneContent, setCurrentAttribute }) => {
  const classes = useStyles();
  const { setCurrentTime } = useContext(CurrentTimeContext);
  return (
    <img
     alt='asset   scene'
      src={asset.asset.src}
      className={classes.sceneImgAsset}
      onClick={e => {
        if (e.detail === 2) {
          let sceneStart = 0;
          if (!!activeSceneContent.find(x => x.isMaster === true)) {
            sceneStart = activeSceneContent.find(x => x.isMaster === true).start;
          }
          let assetStart = asset.start;
          setCurrentTime(assetStart - sceneStart);
        }
        setSelectedAsset(asset);
        setCurrentAttribute('image');
      }}
    />
  );
};
export default ImageSceneAsset;
