import styles from './dropdown.module.css';
import downArrow from './downArrow.png';
import uppArrow from './upArrow.png';
import { useState } from 'react';
import QuestionsList from './droplist/QuestionsList';

const DropdownQuestion = ({ setDefinedQuestions, definedQuestions, state, dispatch, typeId }) => {
  const [openDropdownList, setOpenDropdownList] = useState(false);

  return (
    <div className={styles.dropdownQuestWrapp}>
      <div className={styles.dropdownQuest} onClick={() => setOpenDropdownList((prev) => !prev)}>
        <span>Add Question</span>
        <img src={openDropdownList ? uppArrow : downArrow} alt="down arrow" className={styles.arrowDown} />
      </div>

      {openDropdownList && (
        <div className={styles.dropListWrapp}>
          <QuestionsList styles={styles} setDefinedQuestions={setDefinedQuestions} definedQuestions={definedQuestions} state={state} dispatch={dispatch} typeId={typeId} />
        </div>
      )}
    </div>
  );
};

export default DropdownQuestion;
DropdownQuestion.defaultProps = {
  setDefinedQuestions: ()=>{}, 
  definedQuestions: [], 
  state:{}, 
  dispatch: ()=>{}, 
  typeId:1
}