import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { CurrentTimePreviewContext } from '../../../../context/current_time_preview_context';
import { CurrentTimeContext } from '../../../../context/current_time_context';
import { PlayModeContext } from '../../../../context/play_mode_context';
import { PlayModePreviewContext } from '../../../../context/play_mode_preview_context';
import { PAUSED, PLAYING } from '../../../../constants/video_states';
import AssetVolume from '../../../projectEditor/sceneList/scene/sceneContent/sceneAsset/AssetVolume';
import WaveSurfer from 'wavesurfer.js';
import { ReactComponent as DOT_MENU } from '../../../../files/icons/DOT_MENU.svg';
import VolumeUpIcon from '@mui/icons-material/VolumeUpOutlined';

const soundtrackStyles = makeStyles({
  soundtrackInfo: {
    color: 'white',
    position: 'absolute',
    marginTop: '10px',
    zIndex: '99',
    fontFamily: "'Poppins'"
  },
  dotMenu: {
    color: 'white',
    position: 'absolute',
    right: '10px',
    top: '25px',
    cursor: 'pointer'
  },
  volumeBox: {
    display: 'flex',
    width: '150px',
    position: 'absolute',
    top: '17px',
    right: '40px',
    zIndex: '10',
    background: 'rgba(10, 10, 21, 0.5)',
    padding: '3px',
    alignItems: 'center'
  }
});

function secondsToHms(d) {
  d = Number(d);

  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  if (h != 0) {
    return ('0' + h).slice(-2) + ':' + ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  } else {
    return ('0' + m).slice(-2) + ':' + ('0' + s).slice(-2);
  }
}

const SoundtrackStage = ({ soundtrack, activeSceneID, scenes, openFullPreviewModal, setInteractObject, isHidden = false }) => {
  const classes = soundtrackStyles();
  const [waveform, setWaveform] = useState(null);
  const [showVolume, setShowVolume] = useState(false);
  const { currentTime } = useContext(CurrentTimeContext);
  const { playMode } = useContext(PlayModeContext);
  const { playModePreview } = useContext(PlayModePreviewContext);
  const { currentTimePreview } = useContext(CurrentTimePreviewContext);

  useEffect(() => {
    setWaveform(
      WaveSurfer.create({
        barWidth: 2,
        barGap: 3,
        cursorWidth: 1,
        container: '#soundtrack-waveform',
        backend: 'WebAudio',
        height: 70,
        maxCanvasWidth: 40,
        progressColor: '#1de2cf',
        responsive: true,
        waveColor: 'rgba(29, 226, 207,0.5)',
        cursorColor: 'transparent',
        interact: false,
        hideScrollbar: true
      })
    );
  }, []);

  useEffect(() => {
    if (!!waveform && !!soundtrack.id) {
      waveform.load(soundtrack.url);
    } else if (!!waveform && !soundtrack.id) {
      waveform.empty();
    }
  }, [soundtrack, waveform]);

  useEffect(() => {
    if (!!waveform && !!activeSceneID && !openFullPreviewModal && !!scenes.find((x) => x.id === activeSceneID)) {
      if (!waveform.isPlaying()) {
        let activeSceneAssets = scenes.find((x) => x.id === activeSceneID).assets || [];
        let sceneStart = activeSceneAssets.length > 0 && !!activeSceneAssets.find((x) => x.isMaster === true) ? activeSceneAssets.find((x) => x.isMaster === true).start : 0;

        waveform.setCurrentTime(sceneStart + currentTime);
      } else if (currentTime === 0) {
        let activeSceneAssets = scenes.find((x) => x.id === activeSceneID).assets || [];
        let sceneStart = activeSceneAssets.length > 0 && !!activeSceneAssets.find((x) => x.isMaster === true) ? activeSceneAssets.find((x) => x.isMaster === true).start : 0;
        waveform.pause();
        waveform.setCurrentTime(sceneStart + 0);
      }
    }
  }, [currentTime, scenes, activeSceneID, openFullPreviewModal]);

  useEffect(() => {
    if (!!waveform) {
      if (playMode === PAUSED) {
        if (!!waveform.isPlaying()) {
          waveform.pause();
        }
      } else if (playMode === PLAYING) {
        if (!waveform.isPlaying()) {
          let activeSceneAssets = scenes.find((x) => x.id === activeSceneID).assets || [];
          let sceneStart = activeSceneAssets.length > 0 && !!activeSceneAssets.find((x) => x.isMaster === true) ? activeSceneAssets.find((x) => x.isMaster === true).start : 0;
          if (sceneStart + currentTime < soundtrack.originalLength) {
            waveform.play();
          }
        }
      }
    }
  }, [playMode]);

  useEffect(() => {
    if (!!waveform) {
      if (playModePreview === PAUSED) {
        if (!!waveform.isPlaying()) {
          waveform.pause();
        }
      } else if (playModePreview === PLAYING) {
        if (!waveform.isPlaying()) {
          if (currentTimePreview < soundtrack.originalLength) {
            waveform.play();
          }
        }
      }
    }
  }, [playModePreview]);

  useEffect(() => {
    if (!!waveform && !openFullPreviewModal) {
      waveform.pause();
      waveform.setCurrentTime(0);
    }
  }, [openFullPreviewModal]);

  useEffect(() => {
    if (!!waveform && !waveform.isPlaying()) {
      waveform.setCurrentTime(currentTimePreview);
    }
  }, [currentTimePreview]);

  useEffect(() => {
    if (!!waveform && !!soundtrack.volume) {
      waveform.setVolume(soundtrack.volume);
    }
  }, [soundtrack.volume]);

  return (
    <div hidden={isHidden}>
      {!!Object.keys(soundtrack).length && (
        <div className={classes.soundtrackInfo}>
          {soundtrack.name} | {secondsToHms(soundtrack.originalLength)}
        </div>
      )}

      <div id="soundtrack-waveform"></div>

      <VolumeUpIcon
        className={classes.dotMenu}
        onClick={(e) => {
          e.stopPropagation();
          setShowVolume(!showVolume);
        }}
      />

      {!!Object.keys(soundtrack).length && showVolume && <AssetVolume volumeStyle={classes.volumeBox} asset={soundtrack} color="white" setInteractObject={setInteractObject} isSoundtrack={true} />}
    </div>
  );
};

export default SoundtrackStage;
SoundtrackStage.defaultProps = {
  soundtrack: { originalLength: '', id: '', name: '', url: '', volume: '' },
  activeSceneID: '',
  scenes: [],
  openFullPreviewModal: false,
  setInteractObject: {},
  isHidden: false
};
