import { makeStyles } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import CloseIcon from '@mui/icons-material/Close';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { AiOutlineMore, AiOutlineSave } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { ReactComponent as RENDER_PROJECT } from '../../../files/icons/RENDER_PROJECT.svg';
import MoviesSettingsDialogue from '../../movies/MoviesSettingsDialogue';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import brandingApi from '../../../services/apiServices/brandingApi';

const useStyles = makeStyles({
  projectNameInput: {
    margin: '0px 20px 10px 20px'
  },
  textIncreaseIcon: {
    fill: 'white !important',
    cursor: 'pointer !important',
    fontSize: '35px !important',
    '&:hover': {
      backgroundColor: 'gray'
    },
    '&:active': {
      backgroundColor: 'lightgray'
    }
  },
  saveNameIcon: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'gray'
    },
    '&:active': {
      backgroundColor: 'lightgray'
    }
  },
  exportModal: {
    position: 'absolute',
    top: '50%',
    color: 'white',
    height: 'fit-content',
    fontFamily: 'Poppins',
    fontSize: '13px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: '#262338',
    borderRadius: '10px',
    border: '1px solid gray',
    boxShadow: 24,
    p: 4
  },
  icon: {
    fill: 'white',
    alignSelf: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'gray'
    },
    '&:active': {
      backgroundColor: 'lightgray'
    }
  }
});

const NameAndSaveHandlers = ({ movieName, setMovieName, setInteractObject, saveProjectScenes, addToRender, countUsersOnProject }) => {
  const classes = useStyles();
  const [openMovieSettingsDialog, setOpenMovieSettingsDialog] = useState(false);
  const history = useHistory();
  const [openExportModal, setOpenExportModal] = useState(false);
  const handleOpenExportModal = () => setOpenExportModal(true);
  const handleCloseExportModal = () => setOpenExportModal(false);

  const [defaultTextColor, setDefaultTextColor] = useState('rgba(255, 255, 255, 1)');
  const [defaultTextBoxColor, setDefaultTextBoxColor] = useState('rgba(0, 0, 0, 0.3)');
  const [defaultFont, setDefaultFont] = useState('Poppins');

  const [projectMenuOptionsAnchorEl, setProjectMenuOptionsAnchorEl] = useState(null);
  const [projectMenuMovieOptions, setProjectMenuSettingOptions] = useState(false);
  const [openProjSettingsDialog, setOpenProjSettingsDialog] = useState(false);

  const closeProjectMenuOptions = (event) => {
    event.stopPropagation();
    setProjectMenuOptionsAnchorEl(null);
    setProjectMenuSettingOptions(false);
  };

  const FabButton = styled(Fab)(({ theme }) => ({
    width: '144px',
    textTransform: 'none',
    marginTop: '20px',
    borderRadius: '16px',
    backgroundColor: '#A996FF',
    color: '#4700AB',
    fontWeight: 'bold',
    borderColor: 'gray',
    height: '40px',
    outline: 'none',
    fontSize: '17px',
    '&:hover': {
      backgroundColor: '#c9befa',
      color: '#4700AB'
    }
  }));

  const FabButtonCancel = styled(Fab)(({ theme }) => ({
    width: '144px',
    textTransform: 'none',
    marginTop: '20px',
    borderRadius: '16px',
    backgroundColor: '#262338',
    color: 'gray',
    border: '2px solid gray',
    height: '40px',
    fontWeight: 'bold',
    outline: 'none',
    fontSize: '17px',
    '&:hover': {
      backgroundColor: '#3d3859',
      color: 'gray'
    }
  }));

  const handleClickPlans = () => {
    history.push('/plans');
  };

  useEffect(() => {
    const { axiosReq } = brandingApi.getBranding();
    axiosReq.then((res) => {
      if (res.data.success === true) {
        let defaultTextColor = res.data.data.find((x) => x.brandingTypeID === 4);
        let defaultFont = res.data.data.find((x) => x.brandingTypeID === 6);
        let defaultTextBoxColor = res.data.data.find((x) => x.brandingTypeID === 7);

        if (defaultTextColor) {
          setDefaultTextColor(defaultTextColor.item);
        }
        if (defaultFont) {
          setDefaultFont(defaultFont.item);
        }
        if (defaultTextBoxColor) {
          setDefaultTextBoxColor(defaultTextBoxColor.item);
        }
      }
    });
  }, []);

  return (
    <div>
      <ClickAwayListener onClickAway={(event) => closeProjectMenuOptions(event)}>
        <Popper open={projectMenuMovieOptions} anchorEl={projectMenuOptionsAnchorEl} placement={'bottom'} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenProjSettingsDialog(true);
                      closeProjectMenuOptions(e);
                    }}
                  >
                    Settings
                  </MenuItem>

                  <MenuItem
                    onClick={(e) => {
                      closeProjectMenuOptions(e);
                      saveProjectScenes();
                      e.stopPropagation();
                    }}
                  >
                    Save as
                  </MenuItem>
                </MenuList>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>

      <Tooltip title="Project menu">
        <IconButton
          onClick={(event) => {
            if (projectMenuMovieOptions == false) {
              event.stopPropagation();
              setProjectMenuOptionsAnchorEl(event.currentTarget);
              setProjectMenuSettingOptions(true);
            } else {
              closeProjectMenuOptions(event);
            }
          }}
        >
          <AiOutlineMore className={classes.saveNameIcon} color="white" size={30} />
        </IconButton>
      </Tooltip>

      <Input
        placeholder={'Project name'}
        className={classes.projectNameInput}
        value={movieName}
        inputProps={{ 'aria-label': 'description' }}
        style={{ color: 'white', fontSize: '18px', maxWidth: '40%', fontFamily: "'Poppins', sans-serif", weight: '600' }}
        onChange={(e) => setMovieName(e.target.value)}
      />
      <Tooltip title="Text increase">
        <TextIncreaseIcon
          className={classes.textIncreaseIcon}
          onClick={() => {
            let item = {};
            item.type = 'assetinsert';
            item.assetId = uuid();
            item.name = 'New text field';
            item.text = 'New text';
            item.originalHeight = 38;
            item.originalLength = 5;
            item.originalWidth = 120;
            item.assetType = 'html';
            item.assetTypeID = 4;
            item.url = '';
            let styleObject = {
              scaleX: 1,
              scaleY: 1,
              fontSize: 34,
              fontWeight: 'normal',
              fill: defaultTextColor,
              backgroundColor: defaultTextBoxColor,
              underline: false,
              fontStyle: 'normal',
              fontFamily: defaultFont,
              textAlign: 'center'
            };

            item.canvasStyle = JSON.stringify(styleObject);

            setInteractObject(item);
          }}
        />
      </Tooltip>

      <Tooltip title="Save">
        <IconButton
          onClick={() => {
            saveProjectScenes();
          }}
        >
          <AiOutlineSave size={30} className={classes.icon} style={{ marginLeft: '15px' }} />
        </IconButton>
      </Tooltip>

      <Tooltip title="Export">
        {/* <Badge onClick={remainingSeconds === 0 ? handleOpenExportModal : null} invisible={remainingSeconds !== 0} badgeContent={<LockIcon style={{ backgroundColor: 'gray', borderRadius: '50%', color: 'black' }} />}> */}
        <Button style={{ opacity: '1' }}>
          <RENDER_PROJECT
            className={classes.icon}
            onClick={() => {
              setOpenMovieSettingsDialog(true);
            }}
          />
        </Button>
        {/* </Badge> */}
      </Tooltip>

      {countUsersOnProject > 1 && <div className="blob green">{countUsersOnProject}</div>}
      {openMovieSettingsDialog && (
        <MoviesSettingsDialogue
          data={{}}
          openMovieSettingsDialog={openMovieSettingsDialog}
          setOpenMovieSettingsDialog={setOpenMovieSettingsDialog}
          thumbnailUrl={''}
          movies={[]}
          setMovies={() => {}}
          setThumbnailUrl={() => {}}
          uploadFileRef={null}
          uploadThumbnail={() => {}}
          isSettings={false}
          addToRender={addToRender}
        />
      )}

      <Modal open={openExportModal} onClose={handleCloseExportModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.exportModal}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, ml: 5 }}>
            Export disabled
            <IconButton
              style={{ color: 'white', position: 'absolute', right: '15px' }}
              onClick={(e) => {
                setOpenExportModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 5, mb: 2 }}>
            You don't have enough seconds left for rendering. Please view our plans for an upgrade.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '5px', marginBottom: '2px' }}>
            <FabButtonCancel
              onClick={(e) => {
                setOpenExportModal(false);
              }}
            >
              Cancel
            </FabButtonCancel>
            <FabButton onClick={handleClickPlans}>View plans</FabButton>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default NameAndSaveHandlers;
NameAndSaveHandlers.defaultProps = {
  movieName: '', 
  setMovieName: ()=>{}, 
  setInteractObject: () => {}, 
  saveProjectScenes: () => {} , 
  addToRender: () => {}, 
  countUsersOnProject: () => {}
}