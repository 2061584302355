import { fabric } from 'fabric';

const hasMissingTextImage = (scenes) => {
  let length = [].concat.apply(
    [],
    scenes.map((x) => x.assets.filter((y) => y.asset.type === 'html' && !y.asset.src && !y.htmlImageBase64))
  ).length;

  return length > 0;
};

const updateMissingTextImage = (scenes) => {
  let scenesCopy = [...scenes];
  for (let i = 0; i < scenesCopy.length; i++) {
    for (let j = 0; j < scenesCopy[i].assets.length; j++) {
      let asset = scenesCopy[i].assets[j];
      if (asset.asset.type === 'html' && !asset.asset.src && !asset.htmlImageBase64) {
        scenesCopy[i].assets[j].htmlImageBase64 = makeScaledUpBase64Img(asset);
      }
    }
  }

  return scenesCopy;
};

const makeScaledUpBase64Img = (item) => {
  let styleTemp = JSON.parse(item.canvasStyle) || {};
  styleTemp.scaleX = styleTemp.scaleX * 10;
  styleTemp.scaleY = styleTemp.scaleY * 10;
  let textTemp = new fabric.IText(item.asset.text?.replace('\\n', '\n'), {
    ...styleTemp,
    ...{
      lockScalingFlip: true,
      width: item.w * 10,
      height: item.h * 10,
      top: item.y,
      left: item.x
    }
  });

  const htmlImageBase64 = textTemp.toDataURL('image/png');
  return htmlImageBase64;
};

export { hasMissingTextImage, updateMissingTextImage, makeScaledUpBase64Img };

