import { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { useState } from 'react';
import projectApi from '../../../services/apiServices/projects/projectApi';
import companyApi from '../../../services/apiServices/companyApi';
import { toast } from 'react-toastify';
import UserList from './UserList';
import ButtonFab from '../../../styled/buttons/ButtonFab';

const styleAccountSettings = makeStyles({
  passWrapp: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  passInputsWrapp: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    paddingLeft: '15px',
    marginTop: '12px'
  },
  names: {
    marginLeft: '15px',
    fontSize: '12px',
    color: '#7E7C88',
    fontWeight: 'bold',
    marginBottom: '4px'
  },
  inpField: {
    width: '90%',
    height: '50px',
    borderRadius: '15px',
    padding: '10px',
    backgroundColor: '#262338',
    color: 'white',
    border: '1px solid #262338',
    marginBottom: '5px',
    '&::placeholder': {
      paddingLeft: '10px',
      color: 'grey'
    }
  },
  restInps: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px'
  },
  saveBtn: {
    width: '25%',
    height: '50px',
    backgroundColor: '#A996FF',
    borderRadius: '12px',
    color: '#4B457A',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '1.05rem',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  saveBtnWrapp: { display: 'flex', justifyContent: 'flex-start'},
  hrline: {
    width: '30%',
    padding: '50px 20px'
  }
});

const SettingsOrganisation = ({ currentPlan }) => {
  const stylePass = styleAccountSettings();
  const [companyName, setCompanyName] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [companyEmail, setCompanyEmail] = useState(''); 

  const onSave = () => {
    let data = {
      Email: companyEmail,
      Location: companyLocation,
      Name: companyName
    };
    const { axiosReq } = projectApi.settingOrgSave(data);
    axiosReq.then((res) => {
      if (res.data.success) {
        toast.success('Data succesfully changed!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        toast.error('Data change failed! ' + res.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  useEffect(() => {
    const { req } = companyApi.getCurrentCompanyData();

    req.then((res) => {
      if (res.data.success) {
        setCompanyName(res.data.company.name);
        setCompanyLocation(res.data.company.location);
        setCompanyEmail(res.data.company.email);
      }
    });
  }, []);

  return (
    <Grid container xs={12} sm={8} lg={7} md={7} className={stylePass.passWrapp}>
      <article className={stylePass.passInputsWrapp}>
        <div className={stylePass.restInps}>
          <label className={stylePass.names}>Oranization name</label>
          <input type="text" className={stylePass.inpField} placeholder="Company name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
        </div>

        <div className={stylePass.restInps}>
          <label className={stylePass.names}>E-mail</label>
          <input type="text" className={stylePass.inpField} value={companyEmail} onChange={(e) => setCompanyEmail(e.target.value)} />
        </div>

        <div className={stylePass.restInps}>
          <label className={stylePass.names}>Location</label>
          <input type="text" className={stylePass.inpField} placeholder="Location" value={companyLocation} onChange={(e) => setCompanyLocation(e.target.value)} />
        </div>

        <div className={stylePass.saveBtnWrapp}>
          <ButtonFab tittle='Save' onClickHandler={onSave}  /> 
        </div>
      </article>

      <div className={stylePass.hrline}>
        <hr />
      </div>

      <UserList currentPlan={currentPlan} />
    </Grid>
  );
};

export default SettingsOrganisation;
SettingsOrganisation.defaultProps = {
  currentPlan:{}
}