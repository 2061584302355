import axios from 'axios';
import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthStyle from './css/useAuthStyle';

const baseUrl = process.env.REACT_APP_BASE_URL;
const url = '/api/Auth/Changepassword';

const ChangePassword = () => {
  const { authStyle } = useAuthStyle();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [error, setError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const changePassword = (e) => {
    e.preventDefault();
    axios
      .post(baseUrl + url, { password: newPassword, confirmPassword: confirmPassword })
      .then((res) => {
        history.push('/editor');
      })
      .catch((err) => {
        setError(true);
        setErrorMsg('Wrong credentials. Try again...');
      });
  };
  return (
    <section className={authStyle.authWrapp}>
      {error && (
        <article className={authStyle.errWrapp}>
          <p>{errorMsg}</p>
        </article>
      )}
      <form className={authStyle.InpDivsWrapp}>
        <div className={authStyle.logInpWrapp}>
          <input onChange={(e) => setOldPassword(e.target.value)} type="password" className={`${authStyle.logInp} ${authStyle.inpHeight}`} placeholder="Old Password" required />
        </div>
        <div className={authStyle.logInpWrapp}>
          <input onChange={(e) => setNewPassword(e.target.value)} type="password" className={`${authStyle.logInp} ${authStyle.inpHeight}`} placeholder="New Password" required />
        </div>

        <div className={authStyle.logInpWrapp}>
          <input onChange={(e) => setConfirmPassword(e.target.value)} type="password" className={`${authStyle.logInp} ${authStyle.inpHeight}`} placeholder="Confirm Password" required />
        </div>

        <div className={`${authStyle.logInpWrapp} ${authStyle.btnWrap}`}>
          <input onClick={changePassword} type="submit" className={`${authStyle.logInp} ${authStyle.logBtn}`} value="Change Password" />
        </div>
      </form>
    </section>
  );
};
export default ChangePassword;
