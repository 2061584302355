import QuestionButton from '../../button/next/QuestionButton';
import stepStartStyle from './stepStart.module.css';

const StepStart = ({ questionStep, setQuestionSteps, state, dispatch }) => {
  return (
    <div className={stepStartStyle.questionBox}>
      <p className={stepStartStyle.questionParagraph}>Project title</p>
      <div className={stepStartStyle.txtAreaWrapp}>
        <input className={stepStartStyle.txtArea} value={state?.stepStart?.projectName} onChange={(e) => dispatch({ type: 'UPDATE_PROJECT_NAME', payload: e.target.value })} placeholder="Testimonial name" />
      </div>

      <QuestionButton isStartStep={true} setQuestionSteps={setQuestionSteps} state={state} />
    </div>
  );
};

export default StepStart;
StepStart.defaultProps = {
  questionStep: 1,
  setQuestionSteps: () => {},
  state: {
    stepStart: {
      projectName: ''
    }
  },
  dispatch: () => {}
};
