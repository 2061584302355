import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const nameurl = '/api/Project/updatename';
const soundtrackurl = '/api/Project/updatesoundtrack';
const deletesountrackUrl = '/api/Project/deletesoundtrack';
const soundtrackvolumeurl = '/api/Project/updatesoundtrackvolume';
const makeSubtitleUrl = '/api/Project/makeSubtitle';
const editSubtitleUrl = '/api/Project/editSubtitle';
const makeTranscriptionUrl = '/api/Project/makeTranscription';
const exportWithSubtitleUrl = '/api/Project/exportWithSubtitle';
const moviesForChannelUrl = '/api/Movie/all-movies';
const saveChannelUrl = '/api/Movie/save-channel';
const updateChannelUrl = '/api/Movie/update-channel';
const downloadSrtUrl = '/api/File/downloadSRT';
const uploadMovieUrl = '/api/File/uploadMovie';

//obsolete
const postMovieName = (projectId, name) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + nameurl, {
      params: { ProjectID: projectId, Name: name },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const postMovieSoundtrack = (projectId, soundtrackID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + soundtrackurl, {
      params: { ProjectID: projectId, SoundtrackID: soundtrackID },
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const postMovieSoundtrackVolume = (projectId, volume) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + soundtrackvolumeurl, {
      params: { ProjectID: projectId, Volume: volume },
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const deleteMovieSoundtrackVolume = (projectId) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + deletesountrackUrl, {
      params: { ProjectID: projectId },
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const makeSubtitle = (movieID, languageID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + makeSubtitleUrl,
      data: {
        MovieID: movieID,
        LanguageID: languageID
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const editSubtitle = (translationID, subtitleJSON) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + editSubtitleUrl,
      data: {
        TranslationID: translationID,
        SubtitleJSON: subtitleJSON
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const makeTranscription = (movieID, originalLanguage) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + makeTranscriptionUrl,
      data: {
        MovieID: movieID,
        LanguageID: originalLanguage
      },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const uploadMovie = (movieData) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + uploadMovieUrl,
      data: movieData,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const getMoviesForChannel = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + moviesForChannelUrl, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const saveChannel = (data, isUpdate) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.post(baseUrl + (isUpdate ? updateChannelUrl : saveChannelUrl), data, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const exportWithSubtitle = (translationID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + exportWithSubtitleUrl, {
      params: { ID: translationID },
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const downloadSRT = (movieName, subtitleJSON) => {
  let token = localStorage.getItem('token');

  var xhr = new XMLHttpRequest();
  xhr.open('GET', baseUrl + downloadSrtUrl + '?subtitleJSON=' + subtitleJSON, true);
  xhr.responseType = 'arraybuffer';
  xhr.setRequestHeader('Authorization', 'Bearer ' + token);
  xhr.onload = function () {
    if (xhr.status === 200) {
      // Get the file content as an array buffer
      var fileContent = xhr.response;

      // Create a Blob object from the array buffer
      var fileBlob = new Blob([fileContent], { type: 'application/octet-stream' });

      // Create a URL for the Blob object
      var fileURL = URL.createObjectURL(fileBlob);

      // Create an anchor element
      var downloadLink = document.createElement('a');

      // Set the href and download attributes for the anchor element
      // The download attribute specifies that the target will be downloaded
      downloadLink.href = fileURL;
      downloadLink.download = movieName + '.srt';

      // Append the anchor element to the body
      document.body.appendChild(downloadLink);

      // Click the anchor element to trigger the download
      downloadLink.click();

      // Remove the anchor element
      document.body.removeChild(downloadLink);
    }
  };
  xhr.send();
};

export default {
  postMovieName,
  postMovieSoundtrack,
  postMovieSoundtrackVolume,
  deleteMovieSoundtrackVolume,
  makeSubtitle,
  editSubtitle,
  makeTranscription,
  exportWithSubtitle,
  downloadSRT,
  uploadMovie,
  getMoviesForChannel,
  saveChannel
};
