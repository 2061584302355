import { makeStyles } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useRef, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import { v4 as uuid } from 'uuid';
import brandingApi from '../../../services/apiServices/brandingApi';
import AttributesFontSelector from '../../assetSettings/atributtesBar/elements/AttributesFontSelector';
import DraggableDialog from '../../draggableDialog/DraggableDialog';
import useSettingsGraphics from '../customs/useSettingsGraphics';
import uploadPlus from './uploadPlus.png';

const useStyles = makeStyles({
  graphicsTextStyle: {
    color: 'white',
    fontFamily: "'Poppins', sans-serif",
    fontSize: '18px',
    marginLeft: '10px'
  },
  selectFontStyle: {
    backgroundColor: '#262338',
    height: '52px',
    overflowY: 'scroll',
    justifyContent: 'center',
    color: 'white',
    fontSize: '14px',
    width: '140px',
    marginRight: '1px',
    border: '3px solid white',
    borderRadius: '10px',
    marginLeft: '15px'
  },
  addIconStyle: {
    border: '3px solid #6678A4',
    borderRadius: '5px',
    cursor: 'pointer',
    color: '#6678A4'
  },
  uploadStyle: {
    border: '3px solid #6678A4',
    borderRadius: '12px',
    cursor: 'pointer',
    color: '#6678A4',
    width: '171.7px',
    backgroundColor: '#14142B',
    height: '52px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  colorButton: {
    width: '52px',
    height: '52px',
    border: '4px solid white',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  typoButton: {
    height: '108px',
    border: '2px solid white',
    borderRadius: '10px',
    marginLeft: '15px',
    backgroundColor: 'transparent',
    color: 'white',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionX: 'center',
    position: 'relative'
  },
  logoStyle: {
    width: '155px',
    height: '108px',
    opacity: '50%',
    marginLeft: '15px',
    border: '1px solid white',
    borderRadius: '10px'
  }
});

export const SettingsGraphics = () => {
  const classes = useStyles();
  const fontUploadRef = useRef(null);
  const logoUploadRef = useRef(null);
  const channelBackgroundUploadRef = useRef(null);
  const channelLogoUploadRef = useRef(null);

  const [colors, setColors] = useState([]);
  const [typography, setTypography] = useState([]);
  const [logotypes, setLogotypes] = useState([]);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const { rgba2hex } = useSettingsGraphics();

  const handlePickerOpen = () => {
    setShowColorPicker(true);
  };
  const handlePickerClose = () => {
    setShowColorPicker(false);
  };

  const logotypeStyle = {
    color: 'white',
    borderRadius: 10,
    padding: 3,
    position: 'absolute',
    bottom: 0,
    left: 0,
    fontSize: 12,
    cursor: 'pointer'
  };

  const [defaultTextColor, setDefaultTextColor] = useState('rgba(255, 255, 255, 1)');
  const [defaultTextBoxColor, setDefaultTextBoxColor] = useState('rgba(0, 0, 0, 0.3)');
  const [defaultBackgroundColor, setDefaultBackgroundColor] = useState('#000000');
  const [defaultFont, setDefaultFont] = useState('Poppins');
  const [brandingType, setBrandingType] = useState(1);
  const [defaultLogoItem, setDefaultLogoItem] = useState('');
  const [defaultChannelBranding, setDefaultChannelBranding] = useState([]);
  const [refreshItems, setRefreshItems] = useState(false);

  const updateBranding = (item, type) => {
    let brand = {
      ID: uuid(),
      Item: item,
      BrandingTypeID: type
    };

    if (type === 1) {
      setColors((current) => [...current, { id: brand.ID, item: brand.Item, brandingTypeID: brand.BrandingTypeID }]);
    } else if (type === 4) {
      setDefaultTextColor(item);
    } else if (type === 5) {
      setDefaultBackgroundColor(item);
    } else if (type === 6) {
      setDefaultFont(item);
    } else if (type === 7) {
      setDefaultTextBoxColor(item);
    } else if (type === 8) {
      setDefaultLogoItem(item);
    }

    const { axiosReq } = brandingApi.saveBranding(brand);
  };

  const updateDefaultFont = (font) => {
    setBrandingType(6);
    updateBranding(font, 6);
  };

  const uploadFont = (e) => {
    e.stopPropagation();

    let file = e.target.files[0];

    if (file.name.includes('ttf') || file.name.includes('otf')) {
      let fontBrand = {
        ID: uuid(),
        Item: file.name.replace('.ttf', '').replace('.otf', ''),
        BrandingTypeID: 2
      };

      var dataEd = new FormData();
      dataEd.append('ID', fontBrand.ID);
      dataEd.append('Type', 'typography');
      dataEd.append('File', file);

      const { axiosReq } = brandingApi.uploadBranding(dataEd);
      axiosReq.then(async (res) => {
        if (res.data.success === true) {
          setTypography((current) => [...current, { id: fontBrand.ID, item: fontBrand.Item, brandingTypeID: fontBrand.BrandingTypeID }]);
          fontBrand.Url = res.data.url;

          const myFont = new FontFace(fontBrand.Item, `url(${fontBrand.Url})`);
          await myFont.load();
          document.fonts.add(myFont);

          const { axiosReq } = brandingApi.saveBranding(fontBrand);
        }
      });
    }
  };

  const uploadLogo = (e) => {
    e.stopPropagation();

    let file = e.target.files[0];

    if (file.type.includes('image/')) {
      let logoBrand = {
        ID: uuid(),
        Item: file.name,
        BrandingTypeID: 3
      };

      var dataEd = new FormData();
      dataEd.append('ID', logoBrand.ID);
      dataEd.append('Type', 'logotype');
      dataEd.append('File', file);

      const { axiosReq } = brandingApi.uploadBranding(dataEd);

      axiosReq.then((res) => {
        if (res.data.success === true) {
          logoBrand.Url = res.data.url;

          setLogotypes((current) => [...current, { id: logoBrand.ID, item: logoBrand.Item, brandingTypeID: logoBrand.BrandingTypeID, url: logoBrand.Url }]);
          const { axiosReq: req } = brandingApi.saveBranding(logoBrand);
        }
      });
    }
  };

  const uploadChannelBranding = (e, type, typeID) => {
    e.stopPropagation();

    let file = e.target.files[0];

    if (file.type.includes('image/')) {
      let logoBrand = {
        ID: uuid(),
        Item: file.name,
        BrandingTypeID: typeID
      };

      var dataEd = new FormData();
      dataEd.append('ID', logoBrand.ID);
      dataEd.append('Type', type);
      dataEd.append('File', file);

      const { axiosReq } = brandingApi.uploadBranding(dataEd, true);

      axiosReq.then((res) => {
        if (res.data.success === true) {
          logoBrand.Url = res.data.url;

          const { axiosReq: req } = brandingApi.saveBranding(logoBrand);
          req.then((x) => {
            setRefreshItems((x) => !x);
          });
        }
      });
    }
  };

  const deleteBranding = (item) => {
    if (item.brandingTypeID === 1) {
      setColors(colors.filter((x) => x.id !== item.id));
    } else if (item.brandingTypeID === 2) {
      setTypography(typography.filter((x) => x.id !== item.id));
    } else if (item.brandingTypeID === 3) {
      setLogotypes(logotypes.filter((x) => x.id !== item.id));
    }

    const { req } = brandingApi.deleteBranding(item.id);
    req.then((x) => {
      if (item.brandingTypeID === 9 || item.brandingTypeID === 10) setRefreshItems((x) => !x);
    });
  };

  useEffect(() => {
    const { axiosReq } = brandingApi.getBranding();
    axiosReq.then((res) => {
      if (res.data.success === true) {
        let colors = res.data.data.filter((x) => x.brandingTypeID === 1);
        let typography = res.data.data.filter((x) => x.brandingTypeID === 2);
        let logotypes = res.data.data.filter((x) => x.brandingTypeID === 3);
        let defaultTextColor = res.data.data.find((x) => x.brandingTypeID === 4);
        let defaultBackgroundColor = res.data.data.find((x) => x.brandingTypeID === 5);
        let defaultFont = res.data.data.find((x) => x.brandingTypeID === 6);
        let defaultTextBoxColor = res.data.data.find((x) => x.brandingTypeID === 7);
        let defaultLogo = res.data.data.find((x) => x.brandingTypeID === 8);
        let channelBranding = res.data.data.filter((x) => x.brandingTypeID === 9 || x.brandingTypeID === 10);

        setColors(colors);
        setTypography(typography);
        setLogotypes(logotypes);
        if (defaultTextColor) {
          setDefaultTextColor(defaultTextColor.item);
        }
        if (defaultBackgroundColor) {
          setDefaultBackgroundColor(defaultBackgroundColor.item);
        }
        if (defaultFont) {
          setDefaultFont(defaultFont.item);
        }
        if (defaultTextBoxColor) {
          setDefaultTextBoxColor(defaultTextBoxColor.item);
        }
        if (defaultLogo) {
          setDefaultLogoItem(defaultLogo.item);
        }
        if (channelBranding) {
          setDefaultChannelBranding(channelBranding);
        }
      }
    });
  }, [refreshItems]);

  let pickerColor = '';
  if (brandingType === 4) pickerColor = defaultTextColor;
  else if (brandingType === 5) pickerColor = defaultBackgroundColor;
  else if (brandingType === 7) pickerColor = defaultTextBoxColor;

  return (
    <>
      <div className={classes.graphicsTextStyle}>
        <p>Colors</p>
        <div style={{ display: 'flex', paddingTop: '7px' }}>
          <AddIcon
            className={classes.addIconStyle}
            style={{ width: '52px', height: '52px' }}
            onClick={() => {
              handlePickerOpen();
              setBrandingType(1);
            }}
          />
          {colors.map((item, i) => {
            return (
              <div key={item.id} style={{ display: 'flex', flexDirection: 'column', marginLeft: '25px' }}>
                <div className={classes.colorButton} style={{ backgroundColor: item.item }} onClick={() => setDefaultTextColor(item.item)}>
                  <AiFillCloseCircle
                    style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', width: 20, height: 20, cursor: 'pointer', zIndex: 5, marginLeft: '10px', position: 'absolute', top: -10, right: -10 }}
                    onMouseOver={(e) => {
                      e.target.style.color = 'green';
                    }}
                    onMouseOut={(e) => {
                      e.target.style.color = '#CA024F';
                    }}
                    onClick={() => {
                      deleteBranding(item);
                    }}
                  />
                </div>
                <div style={{ paddingTop: '10px', fontSize: '13px', textAlign: 'center' }}>{rgba2hex(false, item.item)}</div>
              </div>
            );
          })}
        </div>
        <br />
        <hr />
        <br />
        <div style={{ display: 'flex', padding: '5px' }}>
          <div style={{ display: 'grid', marginRight: '5%' }}>
            <span>Default text</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div
                className={classes.colorButton}
                style={{ backgroundColor: defaultTextColor }}
                onClick={() => {
                  handlePickerOpen();
                  setBrandingType(4);
                }}
              />

              <div style={{ paddingTop: '10px', fontSize: '13px' }}>{rgba2hex(false, defaultTextColor)}</div>
            </div>
          </div>
          <div style={{ display: 'grid', marginRight: '5%' }}>
            <span>Default textbox</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div
                className={classes.colorButton}
                style={{ backgroundColor: defaultTextBoxColor }}
                onClick={() => {
                  handlePickerOpen();
                  setBrandingType(7);
                }}
              />
              <div style={{ paddingTop: '10px', fontSize: '13px' }}>{rgba2hex(false, defaultTextBoxColor)}</div>
            </div>
          </div>
          <div style={{ display: 'grid', marginRight: '5%' }}>
            <span>Default background</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <div
                className={classes.colorButton}
                style={{ backgroundColor: defaultBackgroundColor }}
                onClick={() => {
                  handlePickerOpen();
                  setBrandingType(5);
                }}
              />
              <div style={{ paddingTop: '10px', fontSize: '13px' }}>{rgba2hex(true, defaultBackgroundColor)}</div>
            </div>
          </div>
          <div
            style={{
              height: '120px',
              width: '214px',
              backgroundColor: defaultBackgroundColor,
              color: defaultTextColor,
              border: '2px solid #FFFFFF',
              borderRadius: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontFamily: defaultFont
            }}
          >
            <span style={{ backgroundColor: defaultTextBoxColor }}>Preview</span>
          </div>
        </div>
        <br />
        <hr />
        <br />
        <p>Typography</p>
        <div style={{ display: 'flex' }}>
          <input type="file" onChange={uploadFont} ref={fontUploadRef} style={{ display: 'none' }} accept=".ttf,.otf" />

          <button type="submit" className={classes.uploadStyle} onClick={() => fontUploadRef.current.click()}>
            <img src={uploadPlus} alt="upload font" />
            <span style={{ fontSize: '13px', marginLeft: '9px' }}>Upload new</span>
          </button>

          <AttributesFontSelector selectFontStyle={classes.selectFontStyle} canvasFontFamily={defaultFont} setCanvasFontFamily={updateDefaultFont} brandingFonts={typography.map((x) => x.item)} />

          {typography.map((item, i) => {
            return (
              <div key={item.id} style={{ fontFamily: item.item, height: 'auto', display: 'flex', alignItems: 'center', padding: '0px 5px' }} className={classes.typoButton} onClick={() => updateDefaultFont(item.item)}>
                {item.item}
                <AiFillCloseCircle
                  style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', width: 20, height: 20, cursor: 'pointer', zIndex: 5, marginLeft: '10px', position: 'absolute', top: -10, right: -10 }}
                  onMouseOver={(e) => {
                    e.target.style.color = 'green';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = '#CA024F';
                  }}
                  onClick={() => {
                    deleteBranding(item);
                  }}
                />
              </div>
            );
          })}
        </div>
        <br />
        <hr />
        <br />
        <p>Logotypes</p>
        <div style={{ display: 'flex' }}>
          <input type="file" onChange={uploadLogo} ref={logoUploadRef} style={{ display: 'none' }} accept="image/*" />
          <button type="submit" className={classes.uploadStyle} onClick={() => logoUploadRef.current.click()}>
            <img src={uploadPlus} alt="upload logotypes" />
            <span style={{ fontSize: '12px', marginLeft: '9px' }}>Upload new</span>
          </button>

          {logotypes.map((item, i) => {
            return (
              <div key={item.id} className={classes.typoButton} style={{ backgroundImage: `url(${item.url})`, width: '155px', backgroundSize: 'cover' }}>
                <AiFillCloseCircle
                  style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', width: 20, height: 20, cursor: 'pointer', zIndex: 5, marginLeft: '10px', position: 'absolute', top: -10, right: -10 }}
                  onMouseOver={(e) => {
                    e.target.style.color = 'green';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = '#CA024F';
                  }}
                  onClick={() => {
                    deleteBranding(item);
                  }}
                />
                <span style={{ ...logotypeStyle, backgroundColor: defaultLogoItem === item.id ? 'green' : 'gray', opacity: defaultLogoItem === item.id ? 1 : 0.5 }} onClick={() => updateBranding(item.id, 8)}>
                  Default
                </span>
              </div>
            );
          })}
        </div>
        <br />
        <hr />
        <br />
        <p>Channel</p>
        <div style={{ display: 'flex' }}>
          <input type="file" onChange={(e) => uploadChannelBranding(e, 'background', 10)} ref={channelBackgroundUploadRef} style={{ display: 'none' }} accept="image/*" />
          <button type="submit" className={classes.uploadStyle} onClick={() => channelBackgroundUploadRef.current.click()}>
            <img src={uploadPlus} alt="upload channel background" />
            <span style={{ fontSize: '12px', marginLeft: '9px' }}>Upload background</span>
          </button>

          <input type="file" onChange={(e) => uploadChannelBranding(e, 'logo', 9)} ref={channelLogoUploadRef} style={{ display: 'none' }} accept="image/*" />
          <button type="submit" className={classes.uploadStyle} style={{ marginLeft: 10 }} onClick={() => channelLogoUploadRef.current.click()}>
            <img src={uploadPlus} alt="upload channel logo" />
            <span style={{ fontSize: '12px', marginLeft: '9px' }}>Upload logo</span>
          </button>
          {defaultChannelBranding.map((item) => {
            return (
              <div key={item.id} className={classes.typoButton} style={{ backgroundImage: `url(${item.url + "?" + item.id})`, width: '155px', backgroundSize: 'cover' }}>
                <AiFillCloseCircle
                  style={{ color: '#CA024F', backgroundColor: 'white', borderRadius: '50%', width: 20, height: 20, cursor: 'pointer', zIndex: 5, marginLeft: '10px', position: 'absolute', top: -10, right: -10 }}
                  onMouseOver={(e) => {
                    e.target.style.color = 'green';
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = '#CA024F';
                  }}
                  onClick={() => {
                    deleteBranding(item);
                  }}
                />
                <span>{item.brandingTypeID === 10 ? 'Background' : 'Logo'}</span>
              </div>
            );
          })}
        </div>
        <br />
        <br />
      </div>
      {showColorPicker ? (
        <DraggableDialog open={showColorPicker} handleClose={handlePickerClose} updateBrandingColor={(color) => updateBranding(color, brandingType)} color={pickerColor} useHexCode={brandingType === 5} />
      ) : null}
    </>
  );
};
