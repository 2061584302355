import forgotModCSS from './ForgotModal.module.css';
import lockImg from './lock.png';

const ForgotPassMain = ({ mail, setMail, handleForgotPass }) => {
  return (
    <div className={forgotModCSS.modalPassForgotWrappInner}>
      <div className={forgotModCSS.lockWrapp}>
        <img src={lockImg} alt="lock" />
      </div>

      <h1 className={forgotModCSS.resetPassTitle}>Reset Password</h1>

      <p className={forgotModCSS.descrText}>Enter the email connected to your account and we will send instructions on how to reset your password.</p>

      <div className={forgotModCSS.inpWrapp}>
        <span>E-mail</span>
        <input type="text" value={mail} onChange={(e) => setMail(e.target.value)} />
        <button onClick={handleForgotPass}>Submit</button>
      </div>
    </div>
  );
};

export default ForgotPassMain;

ForgotPassMain.defaultProps = {
  mail: '',
  setMail: () => {},
  handleForgotPass: () => {}
};
