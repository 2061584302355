import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useRef, useState } from 'react';
import { AiFillStar, AiOutlineMore, AiOutlineStar } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { ReactComponent as PLAY_SMALL } from '../../../../files/icons/PLAY_SMALL.svg'; //'../../files/icons/PLAY_SMALL.svg';
import projectApi from '../../../../services/apiServices/projects/projectApi';
import DeleteModal from '../../../modals/deleteModal/DeleteModal';
import ProjectSettingsDialogue from '../../../movies/ProjectSettingsDialogue';
import AvatarUsers from './AvatarUsers';
import ActivateSwitch from './buttonsProject/ActivateSwitch';
import ClientBtn from './buttonsProject/ClientBtn';
import EditorBtn from './buttonsProject/EditorBtn';
import styleItem from './table.module.css';

const TableItem = ({ item, selectProject, deleteHandler, isMobile, allProjects, setAllProjects, handleFullPreview, isChannel, activateHandler, selectedId, setSelectedId }) => {
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [openDelete, setOpenDeleteModal] = useState(false);
  const [isNewProject, setIsNewProject] = useState(false);
  const [isActivated, setIsActivated] = useState(item.isActivated);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef(null);

  const checkIsCreatedInLessThan24Hours = (createdDate) => {
    const now = Date.now();
    var createdAt = new Date(createdDate).getTime();
    const oneDay = 24 * 60 * 60 * 1000;

    return now - createdAt > oneDay;
  };

  const renderImage = ({ projectStatus }) => {
    if (projectStatus === 'Pending' || projectStatus === 'Sent') return 'Pending';
    else if (projectStatus === 'Recieved Answer') return 'Recieved Answer';
    else if (projectStatus === 'Draft') return 'Draft';
    else
      return (
        <div
          style={{ position: 'relative', width: '100%', height: '100%', borderRadius: '4px', cursor: 'pointer' }}
          onClick={() => {
            //   setOpenPreview(true);
            //  setVideoUrl(!!item?.lastMovieUrl ? item.lastMovieUrl : item?.firstSceneMasterClipUrl);
          }}
        >
          <video
            ref={videoRef}
            className={styleItem.renderImg}
            onLoadedMetadata={() => {
              const video = videoRef.current;
              if (!video) return;
              setDuration(isFinite(video.duration) ? video.duration : 0);
            }}
          >
            <source src={!!item?.lastMovieUrl ? item.lastMovieUrl : item?.firstSceneMasterClipUrl} type="video/mp4" />.
          </video>
          <p style={{ position: 'absolute', bottom: '-12px', right: '5px' }}>{new Date(duration * 1000).toISOString().substr(14, 5)}</p>

          <PlayCircleOutlineIcon size={25} style={{ position: 'absolute', top: '50%', right: '50%', transform: 'translate(50%,-50%)' }} />
        </div>
      );
  };
  const duplicateHandler = (name, description, privacyID, contributorsList) => {
    setOpenSettingsModal(false);

    let obj = {
      Name: name,
      Description: description,
      ID: item.id,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };

    const { req } = projectApi.duplicateProject(obj);
    req.then((resp) => {
      setIsNewProject(false);
      if (resp?.data?.success) {
        toast.success('Project duplicated!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setAllProjects([resp.data.data, ...allProjects]);
      } else {
        toast.error('Error ' + resp?.data?.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });

    setSelectedId('');
  };

  const previewHandler = (item) => {
    if (item.projectStatus === 'Recieved Answer' || item.projectStatus === 'Done') {
      handleFullPreview(item.id, item.projectStatus === 'Done');
    }
  };

  const handleActivation = (event) => {
    setIsActivated(event.target.checked);
    activateHandler(item.id, event.target.checked);
  };

  return (
    <>
      <div className={styleItem.tableItem}>
        <div className={styleItem.firstDiv}>
          <div>
            <PLAY_SMALL className={styleItem.playBtn + (item.projectStatus !== 'Recieved Answer' && item.projectStatus !== 'Done' ? ' play-disabled' : '')} onClick={() => previewHandler(item)} />
          </div>
          <div className={styleItem.imageBox}>
            {renderImage(item)}
            {item.projectStatus !== 'Done' && !checkIsCreatedInLessThan24Hours(item?.createdDate) && <span className={styleItem.newlabel}>NEW</span>}
          </div>
        </div>

        <div>{item?.name}</div>
        <div>{item?.createdDate?.split('T')[0]}</div>
        <div>
          {!isChannel && <AvatarUsers users={item?.contributors || []} />}
          {isChannel && (
            <>
              {Array.from(Array(item.rating).keys()).map((x) => {
                return <AiFillStar fill="gold" key={'f' + x} size={15} />;
              })}
              {Array.from(Array(5 - item.rating).keys()).map((x) => {
                return <AiOutlineStar key={'e' + x} size={15} />;
              })}
            </>
          )}
        </div>
        <div style={{ width: '8%' }}>
          {!isChannel && <span>{item?.projectStatus}</span>}
          {isChannel && <ActivateSwitch sx={{ m: 1 }} isActivated={isActivated} handleActivation={handleActivation} />}
        </div>

        <div style={{ overflow: 'auto', display: 'flex', flexGrow: 1, marginRight: '6px' }}> {item?.recipient} </div>

        <div> {item?.testimonialType && <ClientBtn type={item?.testimonialType} itemId={item?.projectStatus === 'Draft' ? item?.id : null} />}</div>
        <div>
          <EditorBtn
            isDisabled={item?.projectStatus === 'Sent' || item?.projectStatus === 'Draft'}
            selectProject={() => {
              if (item?.projectStatus === 'Sent' || item?.projectStatus === 'Draft') return;
              selectProject(item?.id);
            }}
          />
        </div>
        <div style={{ position: 'relative' }}>
          <AiOutlineMore
            style={{ cursor: 'pointer', stroke: '#A996FF', zIndex: '8', width: '40px', strokeWidth: '100', filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 1))' }}
            color="#A996FF"
            size={30}
            onClick={() => setSelectedId(selectedId === item?.id ? '' : item.id)}
          />

          {selectedId === item?.id && (
            <div className={styleItem.menuDotsWrapp}>
              <ul className={styleItem.listMenu}>
                <li
                  className={styleItem.listItem}
                  onClick={() => {
                    setOpenSettingsModal(true);
                    setSelectedId('');
                  }}
                >
                  Settings
                </li>
                <li
                  className={styleItem.listItem}
                  onClick={() => {
                    setIsNewProject(true);
                    setOpenSettingsModal(true);
                  }}
                >
                  Duplicate
                </li>

                {item.projectStatus === 'Draft' && (
                  <li className={styleItem.listItem} onClick={() => (window.location.href = `/promotions?testimonialId=${item.id}`)}>
                    Open form
                  </li>
                )}

                <li className={styleItem.listItem} onClick={() => setOpenDeleteModal(true)}>
                  Delete
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>

      {selectedId === item?.id && <div className={styleItem.closeOverlay} onClick={() => setSelectedId('')} />}

      {openSettingsModal && (
        <ProjectSettingsDialogue
          isMobile={isMobile}
          key={item.id}
          data={isNewProject ? {} : item}
          isNewProject={isNewProject}
          setOpenProjSettingsDialog={setOpenSettingsModal}
          openProjSettingsDialog={openSettingsModal}
          projects={isNewProject ? [] : allProjects}
          setProjects={isNewProject ? () => {} : setAllProjects}
          handleSaveNewProject={duplicateHandler}
        />
      )}

      {openDelete && <DeleteModal setOpenDeleteModal={setOpenDeleteModal} deleteFuncion={deleteHandler} type="project" text="Are you sure you want permanently delete" projectData={item} question={'Delete project?'} />}
    </>
  );
};

export default TableItem;
TableItem.defaultProps = {
  item: {},
  selectProject: () => {},
  deleteHandler: () => {},
  isMobile: false,
  allProjects: [],
  setAllProjects: () => {},
  activateHandler: () => {}
};
