import { toast } from 'react-toastify';
import testimonialService from '../../../../../../../services/testimonialService';
import QuestionBackButton from '../../../button/back/QuestionBackButton';
import QuestionButton from '../../../button/next/QuestionButton';
import styles from './buttonStepTwo.module.css';
import SaveDraftButton from './saveDraft/SaveDraftButton';

const ButtonsStepTwo = ({ setQuestionSteps, state, typeId, questionStep, dispatch, setOpenQuestion }) => {
  const finishStep = (isDraft) => {
    let isOneOfTheInputsEmpty = false;

    const onSaveDraft = () => {
      toast.success('Draft saved!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setOpenQuestion(false)
    };

    Object.values(state.step2).forEach((item) => {
      if (item.trim() === '') {
        isOneOfTheInputsEmpty = true;
      }
    });

    if (isOneOfTheInputsEmpty) return window.alert('All fields should be filled!');

    if (!state.stepQuestion?.email?.trim() && !isDraft) return window.alert('Email cannot be empty');

    const testimonialQuestions = state?.step1.questions[typeId - 1].map((item, index) => {
      return { order: index + 1, text: item?.value?.trim() };
    });

    const testimonialData = {
      TemplateProjectID: state?.step0?.templateId,
      Name: state?.stepStart?.projectName,
      TestimonialTypeID: typeId,
      Message: state?.step2?.msg?.trim(),
      Instructions: state?.step2?.instructions?.trim(),
      Email: state?.stepQuestion?.email?.trim(),
      Questions: testimonialQuestions,
      ShowRating: state?.step1.includeRating,
      IsDraft: isDraft
    };

    const { req } = testimonialService.saveTestimonial(testimonialData);

    req
      .then((res) => {
        if (res?.data?.success) {
          if (isDraft) {
            onSaveDraft();
          } else {
            setQuestionSteps((prev) => prev + 1);
            var shareLink = res.data.shareLink;
            dispatch({ type: 'UPDATE_SHARELINK', payload: shareLink });
          }
        } else {
          const errMsg = 'Error... ' + res?.data?.message;
          window.alert(errMsg);
        }
      })
      .catch((err) => window.alert(err));
  };

  return (
    <div className={styles.bStepTwoWrapp}>
      <QuestionBackButton setQuestionSteps={setQuestionSteps} />
      {questionStep === 2 && <QuestionButton setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} />}
      {questionStep === 3 && (
        <div style={{ position: 'absolute', right: 0 }}>
          <SaveDraftButton tittle="Save Draft" onClickHandler={() => finishStep(true)} />
          <SaveDraftButton tittle="Send" onClickHandler={() => finishStep(false)} borderColor="#A996FF" color="#4700AB" backgroundColor="#A996FF" hoverBackroundColor="#c9befa" hoverColor="#4700AB" />
        </div>
      )}
    </div>
  );
};

export default ButtonsStepTwo;
ButtonsStepTwo.defaultProps = {
  setQuestionSteps: () => {},
  state: {},
  typeId: 1
};
