import { makeStyles } from '@material-ui/core';
import { useMemo, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';

import { useState } from 'react';
import { FiUpload } from 'react-icons/fi';

const uploadBoxStyle = makeStyles({});

const myStyles = {
  gridWrapp: { overflow: 'auto', borderRadius: '15px', marginTop: '6px', width: '100%' },
  imgWrapp: { display: 'flex', flexDirection: 'column', p: 2, width: '100%', borderRadius: '15px', marginTop: '20px' },
  uploadBtn: { width: '35%', cursor: 'pointer', height: '65px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '2px dotted #4E4B66', borderRadius: '15px', marginRight: '5px' },
  imagg: { opacity: 0.5, borderRadius: '15px', marginRight: '5px', cursor: 'pointer', width: '106px', height: '61px', border: '2px solid  #4E4B66' },
  uploadTxt: { color: 'white', opacity: 0.5, fontSize: '14px' },
  uploadNameFile: { opacity: 0.5, fontSize: 10 }
};

const baseStyle = {
  flex: 1,
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const UploadBox = ({ setMovieUploadFiles }) => {
  const uploadFileRef = useRef(null);
  const classes = uploadBoxStyle();

  const [uploadFileName, setMovieUploadFileName] = useState('');

  const onDrop = (acceptedFiles) => {
    setMovieUploadFileName(acceptedFiles[0]?.name);
    setMovieUploadFiles(acceptedFiles);
  };

  const onDropRejected = (rejected) => {
    if (rejected.length > 1) {
      toast.warn('Max files allowed is 1', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: onDrop,
    maxFiles: 1,
    onDropRejected: onDropRejected,
    accept: 'video/*'
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );
  return (
    <div>
      <div style={myStyles.imgWrapp}>
        <p style={myStyles.uploadTxt}>Upload video:</p>

        <div style={{ display: 'flex' }}>
          <div {...getRootProps({ style })} style={myStyles.uploadBtn}>
            <FiUpload color="#4E4B66" size={20} />
          </div>
        </div>
        {!!uploadFileName && <div style={myStyles.uploadNameFile}>{uploadFileName?.length < 30 ? uploadFileName : uploadFileName.substr(0, 25) + '...' + uploadFileName.substr(uploadFileName.length - 3, 3)}</div>}
      </div>
    </div>
  );
};

export default UploadBox;
UploadBox.defaultProps = {
  setMovieUploadFiles: () => {}
};
