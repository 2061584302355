import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import useFilterAssetStyle from './assetSection/filterAsset/useFilterAssetStyle';
import FilterLeft from './assetSection/filterAsset/FilterLeft';
import FilterRight from './assetSection/filterAsset/FilterRight';

const FilterAsset = ({ deleteAssets, clipsCount, setSkip, skip, showUpload, activeTab, callAssets, assetsForDelete, assetsData, setAssetsData }) => {
  const { classes } = useFilterAssetStyle();
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    let totPages = Math.ceil(clipsCount / 8);
    setTotalPages(totPages);
  }, [clipsCount]);

  return (
    <div className={classes.filtWrapp}>
      <Grid container>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <FilterLeft totalPages={totalPages} setSkip={setSkip} skip={skip} />
        </Grid>
        <Grid item xs={12} sm={12} lg={6} md={6}>
          <FilterRight
            assetsData={assetsData}
            setAssetsData={setAssetsData}
            deleteAssets={deleteAssets}
            showUpload={showUpload}
            activeTab={activeTab}
            callAssets={callAssets}
            assetsForDelete={assetsForDelete}
            width="150px"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FilterAsset;
FilterAsset.defaultProps = {
  deleteAssets: () => {},
  clipsCount: 1,
  setSkip: () => {},
  skip: 1,
  showUpload: false,
  activeTab: 0,
  callAssets: () => {},
  assetsForDelete: [],
  assetsData: [],
  setAssetsData: () => {}
};
