import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MuiInput from '@mui/material/Input';
import Menu from '@mui/material/Menu';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import 'bootstrap/dist/css/bootstrap.min.css';
import EmojiPicker from 'emoji-picker-react';
import { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SketchPicker } from 'react-color';
import { AiOutlineAlignCenter, AiOutlineAlignLeft, AiOutlineAlignRight, AiOutlineBold, AiOutlineDownload, AiOutlineItalic, AiOutlineUnderline } from 'react-icons/ai';
import { BrandingDataContext } from '../../../context/branding_data_context';
import assetsService from '../../../services/apiServices/assetsService';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../utilities/consts';
import TextStageCanvas from '../../stage/stage-objects/textStage/TextStageCanvas';
import useCustomHooks from './customHooks/useCustomHooks';
import AttributesFontSelector from './elements/AttributesFontSelector';

const Input = styled(MuiInput)`
  width: 42px;
`;

const AttributesBar = ({ selectedAsset, currentAttribute, setInteractObject, activeSceneContent, openAdvancedTextOptions, setOpenAdvancedTextOptions }) => {
  const { useIcons, useStyle } = useCustomHooks();
  const {
    alignCenter,
    alignLeft,
    alignRight,
    square,
    tt,
    rectangle1,
    rectangle2,
    underline,
    bold,
    opacity,
    italic,
    animation,
    trash,
    color,
    lineV,
    shade,
    rectangle3,
    rectangle4,
    line,
    star,
    polygon,
    color2,
    clock,
    sound,
    info,
    settings,
    scale,
    downarrow,
    uparrow,
    hyphen,
    leftarrow,
    rightarrow,
    vline,
    left_top,
    left_center,
    left_bottom,
    mid_top,
    mid_center,
    mid_bottom,
    right_top,
    right_center,
    right_bottom
  } = useIcons();
  const { classes } = useStyle();

  const [anchorFontMenuEl, setAnchorFontMenuEl] = useState(null);
  const openFontSizeMenu = Boolean(anchorFontMenuEl);

  const [anchorColorPickerMenuEl, setAnchorColorPickerMenuEl] = useState(null);
  const openColorPickerMenu = Boolean(anchorColorPickerMenuEl);
  const [colorPickerTab, setColorPickerTab] = useState(1);

  const [anchorOpacityMenuEl, setAnchorOpacityMenuEl] = useState(null);
  const openOpacityMenu = Boolean(anchorOpacityMenuEl);

  const [openAdvancedOptionsMenu, setOpenAdvancedOptionsMenu] = useState(false);

  const [canvasFontSize, setCanvasFontSize] = useState(34);
  const [canvasFontWeight, setCanvasFontWeight] = useState('normal');
  const [canvasFontFill, setCanvasFontFill] = useState('rgba(0, 0, 0, 1)'); // black with opacity 1
  const [canvasBackgroundColor, setCanvasBackgroundColor] = useState('rgba(255, 255, 255, 0.3)'); // white transparent (opacity 0.3)
  const [canvasFontUnderline, setCanvasFontUnderline] = useState(false);
  const [canvasFontStyle, setCanvasFontStyle] = useState('normal');
  const [canvasScaleX, setCanvasScaleX] = useState(1);
  const [canvasScaleY, setCanvasScaleY] = useState(1);
  const [canvasFontFamily, setCanvasFontFamily] = useState('arial');
  const [canvasTextAlign, setCanvasTextAlign] = useState('center');

  const [currentOpacity, setCurrentOpacity] = useState(1);
  const { brandingColors, brandingFonts } = useContext(BrandingDataContext);

  const [didMount, setDidMount] = useState(false);

  const [showEmoji, setShowEmoji] = useState(false);

  const onEmojiClick = (event) => {
    let item = {};
    item.type = 'textchange';
    item.text = selectedAsset.asset.text + event.emoji;
    item.id = selectedAsset.assetStyleID;
    setInteractObject(item);

    setShowEmoji(false);
  };

  const downloadTextImage = (assetID) => {
    const { req, cancelTokenSource } = assetsService.getAssetStorageUrl(assetID);

    req
      .then((resp) => {
        if (resp.data.success === true) {
          window.location.href = resp.data.url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeAssetPosition = (newX, newY) => {
    let item = {};
    item.id = selectedAsset.assetStyleID;
    item.sceneid = selectedAsset.sceneID;
    item.type = 'draggable';
    item.x = newX;
    item.y = newY;

    let elementWidthMid = selectedAsset.w / 2;
    let elementHeightMid = selectedAsset.h / 2;

    let elementMidPointX = item.x + elementWidthMid;
    let elementMidPointY = item.y + elementHeightMid;

    const SCREEN_MID_X = SCREEN_WIDTH / 2;
    const SCREEN_MID_Y = SCREEN_HEIGHT / 2;

    let offsetX = Math.abs((item.x + elementWidthMid - SCREEN_MID_X) / SCREEN_WIDTH);
    let offsetY = Math.abs((item.y + elementHeightMid - SCREEN_MID_Y) / SCREEN_HEIGHT);

    item.offsetx = SCREEN_MID_X < elementMidPointX ? offsetX : offsetX * -1;
    item.offsety = SCREEN_MID_Y > elementMidPointY ? offsetY : offsetY * -1;

    setInteractObject(item);
  };

  useEffect(() => {
    setDidMount(true);
    return () => {
      setDidMount(false);
    };
  }, []);

  useEffect(() => {
    if (didMount && !!selectedAsset.asset) {
      if (selectedAsset.asset.type === 'html' && !!selectedAsset.canvasStyle) {
        let assetCanvasStyle = JSON.parse(selectedAsset.canvasStyle);
        setCanvasFontSize(assetCanvasStyle.fontSize || 34);
        setCanvasFontWeight(assetCanvasStyle.fontWeight || 'normal');
        setCanvasFontFill(assetCanvasStyle.fill || 'rgba(0, 0, 0, 1)');
        setCanvasBackgroundColor(assetCanvasStyle.backgroundColor || 'rgba(255, 255, 255, 0.3)');
        setCanvasFontUnderline(assetCanvasStyle.underline || false);
        setCanvasFontStyle(assetCanvasStyle.fontStyle || 'normal');
        setCanvasScaleX(assetCanvasStyle.scaleX || 1);
        setCanvasScaleY(assetCanvasStyle.scaleY || 1);
        setCanvasFontFamily(assetCanvasStyle.fontFamily || 'arial');
        setCanvasTextAlign(assetCanvasStyle.textAlign || 'center');
        setCurrentOpacity(selectedAsset.opacity || 1);
      }
    }
  }, [selectedAsset, didMount]);

  useEffect(() => {
    if (didMount) {
      let item = {};
      item.type = 'assetstyle';
      item.id = selectedAsset.assetStyleID;
      item.sceneid = selectedAsset.sceneID;

      if (currentAttribute === 'html') {
        let styleObject = {
          fontSize: canvasFontSize,
          fontWeight: canvasFontWeight,
          fill: canvasFontFill,
          backgroundColor: canvasBackgroundColor,
          underline: canvasFontUnderline,
          fontStyle: canvasFontStyle,
          scaleX: canvasScaleX,
          scaleY: canvasScaleY,
          fontFamily: canvasFontFamily,
          textAlign: canvasTextAlign
        };

        item.canvasStyle = JSON.stringify(styleObject);
      }

      item.opacity = currentOpacity;
      setInteractObject(item);
    }
  }, [canvasFontSize, canvasFontWeight, canvasFontFill, canvasBackgroundColor, canvasFontUnderline, canvasFontStyle, canvasFontFamily, canvasTextAlign, currentOpacity]);

  useEffect(() => {
    if (openAdvancedTextOptions === true) {
      setOpenAdvancedOptionsMenu(true);
      setOpenAdvancedTextOptions(false);
    }
  }, [openAdvancedTextOptions]);

  return (
    <div id="attribute-bar" style={{ overflowX: 'scroll', position: 'relative', height: '67px' }}>
      {currentAttribute === 'html' && (
        <div className={classes.attributeBar}>
          <>
            <Tooltip title="Font">
              <AttributesFontSelector selectFontStyle={classes.selectFontStyle} canvasFontFamily={canvasFontFamily} setCanvasFontFamily={setCanvasFontFamily} brandingFonts={brandingFonts} />
            </Tooltip>
          </>

          <img src={lineV}></img>

          <>
            <Tooltip title="Font size">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={tt} alt="tt" onClick={(e) => setAnchorFontMenuEl(e.currentTarget)} />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>

          <>
            <Tooltip title="Fill/padding color">
              <button type="button" className={classes.buttonAttributeBar} onClick={(e) => setAnchorColorPickerMenuEl(e.currentTarget)}>
                <img src={color} alt="color" />
              </button>
            </Tooltip>
          </>

          {/* <img src={lineV}></img>
          <>
            <Tooltip title="Scale">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={scale} alt="scale" />
              </button>
            </Tooltip>
          </> */}
          <img src={lineV}></img>
          <div>
            <>
              <Tooltip title="Center vertically">
                <button type="button" className={classes.buttonGroupAttributeBar} onClick={() => changeAssetPosition(selectedAsset.x, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)}>
                  <img src={downarrow} alt="downarrow" />
                  <img src={hyphen} alt="hyphen" style={{ marginLeft: '-30%' }} />
                  <img src={uparrow} alt="uparrow" />
                </button>
              </Tooltip>
            </>
          </div>
          <div>
            <>
              <Tooltip title="Center horizontally">
                <button type="button" className={classes.buttonAttributeBar} onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, selectedAsset.y)}>
                  <img src={rightarrow} alt="rightarrow" style={{ marginBottom: '10%' }} />
                  <img src={vline} alt="vline" style={{ marginTop: '4%' }} />
                  <img src={leftarrow} alt="leftarrow" style={{ marginBottom: '10%' }} />
                </button>
              </Tooltip>
            </>
          </div>
          <img src={lineV}></img>
          <>
            <Tooltip title="Opacity">
              <button type="button" className={classes.buttonAttributeBar} onClick={(e) => setAnchorOpacityMenuEl(e.currentTarget)}>
                <img src={opacity} alt="opacity" />
              </button>
            </Tooltip>
          </>
          {/* <img src={lineV}></img>
          <>
            <Tooltip title="Delete">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={trash} alt="trash" />
              </button>
            </Tooltip>
          </> */}
        </div>
      )}

      {currentAttribute === 'shape' && (
        <div className={classes.attributeBar}>
          <img src={lineV}></img>

          <>
            <Tooltip title="Fill/stroke color">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={color} alt="color" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Drop shade">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={shade} alt="shade" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Scale">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={scale} alt="scale" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <div>
            <>
              <Tooltip title="Center vertically">
                <button type="button" className={classes.buttonGroupAttributeBar}>
                  <img src={downarrow} alt="downarrow" />
                  <img src={hyphen} alt="hyphen" style={{ marginLeft: '-30%' }} />
                  <img src={uparrow} alt="uparrow" />
                </button>
              </Tooltip>
            </>
          </div>
          <div>
            <>
              <Tooltip title="Center horizontally">
                <button type="button" className={classes.buttonAttributeBar}>
                  <img src={rightarrow} alt="rightarrow" style={{ marginBottom: '10%' }} />
                  <img src={vline} alt="vline" style={{ marginTop: '4%' }} />
                  <img src={leftarrow} alt="leftarrow" style={{ marginBottom: '10%' }} />
                </button>
              </Tooltip>
            </>
          </div>
          <img src={lineV}></img>
          <>
            <Tooltip title="Opacity">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={opacity} alt="opacity" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Delete">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={trash} alt="trash" />
              </button>
            </Tooltip>
          </>
        </div>
      )}

      {currentAttribute === 'video' && (
        <div className={classes.attributeBar}>
          {/* <>
            <Tooltip title="Clip info">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={info} alt="info" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Volume">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={sound} alt="sound" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Color correction">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={settings} alt="settings" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Scale">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={scale} alt="scale" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img> */}
          <div>
            <>
              <Tooltip title="Center vertically">
                <button type="button" className={classes.buttonGroupAttributeBar} onClick={() => changeAssetPosition(selectedAsset.x, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)}>
                  <img src={downarrow} alt="downarrow" />
                  <img src={hyphen} alt="hyphen" style={{ marginLeft: '-30%' }} />
                  <img src={uparrow} alt="uparrow" />
                </button>
              </Tooltip>
            </>
          </div>
          <div>
            <>
              <Tooltip title="Center horizontally">
                <button type="button" className={classes.buttonAttributeBar} onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, selectedAsset.y)}>
                  <img src={rightarrow} alt="rightarrow" style={{ marginBottom: '10%' }} />
                  <img src={vline} alt="vline" style={{ marginTop: '4%' }} />
                  <img src={leftarrow} alt="leftarrow" style={{ marginBottom: '10%' }} />
                </button>
              </Tooltip>
            </>
          </div>
          <img src={lineV}></img>
          <>
            <Tooltip title="Opacity">
              <button type="button" className={classes.buttonAttributeBar} onClick={(e) => setAnchorOpacityMenuEl(e.currentTarget)}>
                <img src={opacity} alt="opacity" />
              </button>
            </Tooltip>
          </>
          {/* <img src={lineV}></img>
          <>
            <Tooltip title="Delete">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={trash} alt="trash" />
              </button>
            </Tooltip>
          </> */}
        </div>
      )}

      {currentAttribute === 'image' && (
        <div className={classes.attributeBar}>
          {/* <>
            <Tooltip title="Color correction">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={settings} alt="setting" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Scale">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={scale} alt="scale" />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img> */}
          <div>
            <>
              <Tooltip title="Center vertically">
                <button type="button" className={classes.buttonGroupAttributeBar} onClick={() => changeAssetPosition(selectedAsset.x, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)}>
                  <img src={downarrow} alt="downarrow" />
                  <img src={hyphen} alt="hyphen" style={{ marginLeft: '-30%' }} />
                  <img src={uparrow} alt="uparrow" />
                </button>
              </Tooltip>
            </>
          </div>
          <>
            <Tooltip title="Center horizontally">
              <button type="button" className={classes.buttonAttributeBar} onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, selectedAsset.y)}>
                <img src={rightarrow} alt="rightarrow" style={{ marginBottom: '10%' }} />
                <img src={vline} alt="vline" style={{ marginTop: '4%' }} />
                <img src={leftarrow} alt="leftarrow" style={{ marginBottom: '10%' }} />
              </button>
            </Tooltip>
          </>
          <img src={lineV}></img>
          <>
            <Tooltip title="Opacity">
              <button type="button" className={classes.buttonAttributeBar} onClick={(e) => setAnchorOpacityMenuEl(e.currentTarget)}>
                <img src={opacity} alt="opacity" />
              </button>
            </Tooltip>
          </>
          {/* <img src={lineV}></img>
          <>
            <Tooltip title="Delete">
              <button type="button" className={classes.buttonAttributeBar}>
                <img src={trash} alt="trash" />
              </button>
            </Tooltip>
          </> */}
        </div>
      )}

      {/* {currentAttribute == 'audio' && (
        <div className={classes.attributeBar}>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={sound} alt="clock" />
          </button>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={info} alt="info" />
          </button>
          <img src={lineV}></img>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={settings} alt="settings" />
          </button>
          <img src={lineV}></img>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={color2} alt="color2" />
          </button>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={opacity} alt="opacity" />
          </button>
          <img src={lineV}></img>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={animation} alt="animation" />
          </button>
          <img src={lineV}></img>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={square} alt="square" />
          </button>
          <img src={lineV}></img>
          <button type="button" className={classes.buttonAttributeBar}>
            <img src={trash} alt="trash" />
          </button>
        </div>
      )} */}

      <Menu
        id="font-menu"
        anchorEl={anchorFontMenuEl}
        open={openFontSizeMenu}
        onClose={() => setAnchorFontMenuEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            width: '200px',
            padding: '12px',
            backgroundColor: '#262338'
          }
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <p style={{ color: 'white', fontFamily: "'Poppins', sans-serif", fontSize: '17px', lineHeight: '19.5px' }}>Font Size</p>
          </Grid>
          <Grid item md={2}>
            <Input
              value={canvasFontSize}
              size="small"
              style={{ backgroundColor: 'white' }}
              onChange={(event) => setCanvasFontSize(event.target.value)}
              // onBlur={handleBlur}
              inputProps={{
                step: 1,
                min: 0,
                max: 100,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Slider
              style={{ color: '#A996FF' }}
              value={canvasFontSize}
              onChange={(e, newValue) => {
                setCanvasFontSize(newValue);
              }}
              aria-labelledby="input-slider"
            />
          </Grid>
        </Grid>
      </Menu>

      <Menu
        id="colorpicker-menu"
        anchorEl={anchorColorPickerMenuEl}
        open={openColorPickerMenu}
        onClose={() => setAnchorColorPickerMenuEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            backgroundColor: '#262338'
          }
        }}
      >
        <div style={{ padding: '0 10px' }}>
          <button
            style={{
              width: '50%',
              background: 'transparent',
              border: 'none',
              color: colorPickerTab === 1 ? 'white' : '#6E7191',
              borderBottom: colorPickerTab === 1 ? '2px solid #FFFFFF' : '2px solid #6E7191',
              textAlign: 'left',
              paddingBottom: '10px',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '13px'
            }}
            onClick={() => setColorPickerTab(1)}
          >
            Line
          </button>
          <button
            style={{
              width: '50%',
              background: 'transparent',
              border: 'none',
              color: colorPickerTab === 2 ? 'white' : '#6E7191',
              borderBottom: colorPickerTab === 2 ? '2px solid #FFFFFF' : '2px solid #6E7191',
              textAlign: 'left',
              paddingBottom: '10px',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '13px'
            }}
            onClick={() => setColorPickerTab(2)}
          >
            Fill
          </button>
        </div>
        <div>
          {colorPickerTab === 1 && <SketchPicker color={canvasFontFill} presetColors={brandingColors} onChangeComplete={(e) => setCanvasFontFill(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`)} />}
          {colorPickerTab === 2 && <SketchPicker color={canvasBackgroundColor} presetColors={brandingColors} onChangeComplete={(e) => setCanvasBackgroundColor(`rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`)} />}
        </div>
      </Menu>
      <Menu
        id="opacity-menu"
        anchorEl={anchorOpacityMenuEl}
        open={openOpacityMenu}
        onClose={() => setAnchorOpacityMenuEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            width: '200px',
            padding: '12px',
            backgroundColor: '#262338'
          }
        }}
      >
        <Grid container>
          <Grid item md={9}>
            <p style={{ color: 'white', fontFamily: "'Poppins', sans-serif", fontSize: '17px', lineHeight: '19.5px' }}>Opacity</p>
          </Grid>
          <Grid item md={2}>
            <Input
              value={currentOpacity}
              size="small"
              style={{ backgroundColor: 'white' }}
              onChange={(event) => setCurrentOpacity(event.target.value === '' ? '' : Number(event.target.value))}
              // onBlur={handleBlur}
              inputProps={{
                step: 0.01,
                min: 0.01,
                max: 1.0,
                type: 'number',
                'aria-labelledby': 'input-slider'
              }}
            />
          </Grid>
          <Grid item md={12}>
            <Slider
              style={{ color: '#A996FF' }}
              value={currentOpacity}
              onChange={(e, newValue) => {
                setCurrentOpacity(newValue);
              }}
              aria-labelledby="input-slider"
              step={0.01}
              min={0.01}
              max={1.0}
            />
          </Grid>
        </Grid>
      </Menu>
      <Modal
        onEntered={() => {
          document.querySelector('.modal').removeAttribute('tabindex'); // jebeno sranje kurčevo
        }}
        show={openAdvancedOptionsMenu}
        onHide={() => setOpenAdvancedOptionsMenu(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div
          style={{
            background: '#262338',
            marginLeft: '-30%',
            width: '856px',
            height: '770px',
            border: '2px solid #4E4B66',
            borderRadius: '10px',
            padding: '35px',
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column'
          }}
        >
          <Grid container>
            <Grid item style={{ width: '100%' }}>
              <div style={{ fontFamily: "'Poppins', sans-serif", fontSize: '18px', color: 'white', fontWeight: 600, lineHeight: '27px', marginBottom: '10px' }}>Advanced Text options</div>
              <Divider sx={{ backgroundColor: '#4E4B66', marginBottom: '10px' }} />
            </Grid>
            <Grid item>
              <Tooltip title="Font">
                <AttributesFontSelector selectFontStyle={classes.selectFontStyle} canvasFontFamily={canvasFontFamily} setCanvasFontFamily={setCanvasFontFamily} brandingFonts={brandingFonts} />
              </Tooltip>
            </Grid>
            <Grid item style={{ margin: '0 10px' }}>
              <Divider orientation="vertical" sx={{ backgroundColor: '#4E4B66' }} />
            </Grid>
            <Grid item style={{ display: 'flex', alignItems: 'center' }}>
              <>
                <Tooltip title="Font size">
                  <div>
                    <img src={tt} alt="tt" style={{ paddingRight: '10px' }} />
                    <Input
                      value={canvasFontSize}
                      size="small"
                      style={{ backgroundColor: 'white' }}
                      onChange={(event) => setCanvasFontSize(event.target.value)}
                      inputProps={{
                        step: 1,
                        min: 0,
                        max: 100,
                        type: 'number',
                        'aria-labelledby': 'input-slider'
                      }}
                    />
                  </div>
                </Tooltip>
              </>
            </Grid>
            <Grid item style={{ margin: '0 10px' }}>
              <Divider orientation="vertical" sx={{ backgroundColor: '#4E4B66' }} />
            </Grid>
            <Grid item>
              <>
                <Tooltip title="Fill/padding color">
                  <button type="button" className={classes.buttonAttributeBar} onClick={(e) => setAnchorColorPickerMenuEl(e.currentTarget)}>
                    <img src={color} alt="color" />
                  </button>
                </Tooltip>
              </>
            </Grid>

            <Grid item style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {showEmoji ? (
                <div style={{ position: 'absolute', top: '0', left: '0', zIndex: 100 }}>
                  <div onClick={() => setShowEmoji(false)} style={{ position: 'fixed', top: '0', left: '0', width: '100vw', height: '100vh' }} />
                  <EmojiPicker emojiStyle="native" style={{ zIndex: 101 }} onEmojiClick={onEmojiClick} />
                </div>
              ) : (
                <EmojiEmotionsIcon style={{ color: 'white' }} onClick={() => setShowEmoji(true)} />
              )}
            </Grid>

            <Grid item style={{ margin: '0 10px' }}>
              <Divider orientation="vertical" sx={{ backgroundColor: '#4E4B66' }} />
            </Grid>
            <Grid container style={{ marginTop: '20px' }}>
              <Grid item>
                <Tooltip title="Bold">
                  <IconButton onClick={() => setCanvasFontWeight(canvasFontWeight === 'bold' ? 'normal' : 'bold')}>
                    <AiOutlineBold size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Italic">
                  <IconButton onClick={() => setCanvasFontStyle(canvasFontStyle === 'italic' ? 'normal' : 'italic')}>
                    <AiOutlineItalic size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Underline">
                  <IconButton onClick={() => setCanvasFontUnderline(!canvasFontUnderline)}>
                    <AiOutlineUnderline size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item style={{ margin: '0 10px' }}>
                <Divider orientation="vertical" sx={{ backgroundColor: '#4E4B66' }} />
              </Grid>
              <Grid item>
                <>
                  <Tooltip title="Left align">
                    <IconButton onClick={() => setCanvasTextAlign('left')}>
                      <AiOutlineAlignLeft size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                    </IconButton>
                  </Tooltip>
                </>
                <>
                  <Tooltip title="Center align">
                    <IconButton onClick={() => setCanvasTextAlign('center')}>
                      <AiOutlineAlignCenter size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                    </IconButton>
                  </Tooltip>
                </>
                <>
                  <Tooltip title="Right align">
                    <IconButton onClick={() => setCanvasTextAlign('right')}>
                      <AiOutlineAlignRight size={28} style={{ fill: 'white', marginRight: '10px', cursor: 'pointer', marginTop: '-8px' }} />
                    </IconButton>
                  </Tooltip>
                </>
              </Grid>
              <Grid item style={{ margin: '0 10px' }}>
                <Divider orientation="vertical" sx={{ backgroundColor: '#4E4B66' }} />
              </Grid>
              <Grid item>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(0, 0)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={left_top} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(0, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={left_center} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(0, SCREEN_HEIGHT - selectedAsset.h)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={left_bottom} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, 0)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={mid_top} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img
                    onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)}
                    style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }}
                    src={mid_center}
                  />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(SCREEN_WIDTH / 2 - selectedAsset.w / 2, SCREEN_HEIGHT - selectedAsset.h)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={mid_bottom} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(SCREEN_WIDTH - selectedAsset.w, 0)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={right_top} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(SCREEN_WIDTH - selectedAsset.w, SCREEN_HEIGHT / 2 - selectedAsset.h / 2)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={right_center} />
                </Tooltip>
                <Tooltip title="Change position">
                  <img onClick={() => changeAssetPosition(SCREEN_WIDTH - selectedAsset.w, SCREEN_HEIGHT - selectedAsset.h)} style={{ height: '25px', marginRight: '10px', cursor: 'pointer' }} src={right_bottom} />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ marginTop: '15px' }}>
            <div className="text-options" style={{ width: SCREEN_WIDTH + 'px', height: SCREEN_HEIGHT + 'px' }} id="screen-screen">
              <TextStageCanvas
                clip={activeSceneContent.find((x) => x.assetStyleID === selectedAsset.assetStyleID)}
                key={selectedAsset.assetStyleID}
                selectedAsset={selectedAsset}
                setInteractObject={setInteractObject}
                sceneDuration={activeSceneContent.length > 0 ? activeSceneContent.find((x) => x.isMaster === true).length : 0}
                sceneStart={activeSceneContent.length > 0 ? activeSceneContent.find((x) => x.isMaster === true).start : 0}
                editSuffix={'_edit'}
              />
            </div>
          </Grid>
          <AiOutlineDownload size={28} style={{ fill: 'white', marginRight: '10px', marginTop: '10px', cursor: 'pointer' }} onClick={() => downloadTextImage(selectedAsset.assetID)} />
        </div>
      </Modal>
    </div>
  );
};
export default AttributesBar;
