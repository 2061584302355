import React, { useContext, useEffect, useState } from 'react';
import { CurrentTimePreviewContext } from '../../../../context/current_time_preview_context';

const ImageStagePreview = ({ image }) => {
  let position = `translate(${image.x + (image.crop?.cropLeft || 0)}px, ${image.y + (image.crop?.cropTop || 0)}px)`;
  let rotation = image.rotate ? `rotate(${image.rotate}deg)` : '';

  const { currentTimePreview } = useContext(CurrentTimePreviewContext);

  const [visibility, setVisibility] = useState('hidden');

  useEffect(() => {
    if (currentTimePreview >= image.start && currentTimePreview < image.start + image.length) {
      setVisibility('visible');
    } else {
      if (visibility == 'visible') {
        setVisibility('hidden');
      }
    }
  }, [currentTimePreview, visibility, image.length, image.start]);

  let style = {
    zIndex: image.z,
    transform: `${position} ${rotation}`,
    opacity: image.opacity || 1,
    width: `${parseFloat(image.w - (image.crop?.cropRight || 0) - (image.crop?.cropLeft || 0))}px`,
    height: `${parseFloat(image.h - (image.crop?.cropBottom || 0) - (image.crop?.cropTop || 0))}px`,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  return (
    <div data-z={image.z} data-x={image.x} data-y={image.y} id={`screen_${image.assetStyleID}`} scene-id={image.sceneID} stage-id={image.assetStyleID} style={style}>
      <div
        style={{
          transform: `translate(${-Math.floor(image.crop?.cropLeft || 0)}px, ${-Math.floor(image.crop?.cropTop || 0)}px)`
        }}
      >
        <img src={image.asset.src} height={image.h + 'px'} width={image.w + 'px'} className="resizable" alt="" />
      </div>
    </div>
  );
};

export { ImageStagePreview };

