import { makeStyles } from '@material-ui/core';

const subLinkStylePage = makeStyles({
  linkText: {
    width: '108px',
    height: '22px',
    fontFamily: "'Poppins'",
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '22px',
    letterSpacing: '0.25px'
  },
  wrapp: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '0px',
    overflowX: 'auto',
  },
  innerWrapp: {
    padding: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start'
  }
});

const SubLinks = ({ subLinks, activeSubLibTab, setActiveSubLibTab }) => {
  const cssLinkStyle = subLinkStylePage();

  return (
    <div className={cssLinkStyle.wrapp}>
      <div className={cssLinkStyle.innerWrapp} >
        {subLinks.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setActiveSubLibTab(index)}
              style={{  display: 'flex', flexDirection: 'column', marginLeft: index === 0 ? '3px' : '40px', fontSize: '15px', color: activeSubLibTab === index ? '#B9A4E9' : '#545773'}} >
              <p className={cssLinkStyle.linkText}>{item}</p>
              <span style={{  marginTop: '-7px', height: '2px', width: '130%', backgroundColor: activeSubLibTab === index ? '#B9A4E9' : '#545773'  }} ></span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubLinks;
SubLinks.defaultProps = {
  subLinks: [], 
  activeSubLibTab: 0, 
  setActiveSubLibTab: () => {}
}