import celebration from './Celebration.png';
import SuccessModalStyle from './SuccessSignup.module.css';

const SuccessSignup = ({ closeConfirmSignUp, email }) => {
  return (
    <div className={SuccessModalStyle.modalPassForgotWrappInner}>
      <div className={SuccessModalStyle.lockWrapp}>
        <img src={celebration} alt="celebration" />
      </div>

      <h1 className={SuccessModalStyle.resetPassTitle}>Success!</h1>

      <p className={SuccessModalStyle.descrText}>We have sent an email to {email}. Please confirm your email by clicking on the link in the mail..</p>

      <div className={SuccessModalStyle.inpWrapp}>
        <button type="button" onClick={closeConfirmSignUp}>
          Ok
        </button>
      </div>

      <div className={SuccessModalStyle.wrappHelperClear}></div>
    </div>
  );
};

export default SuccessSignup;

SuccessSignup.defaultProps = {
  closeConfirmSignUp: () => {},
  email: ''
};
