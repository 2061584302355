import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import movieService from '../../services/apiServices/movieService';
import ThumbnailPart from '../movies/custom/movieSettingsDialogue/ThumbnailPart';
import AddMovieStep from '../movies/elements/channel/modalAdd/steps/AddMovieStep';
import EmbedCode from '../movies/elements/channel/modalAdd/steps/EmbedCode';
import FirstStep from '../movies/elements/channel/modalAdd/steps/FirstStep';

const channelSettingsStyles = makeStyles({
  shareMovieDialogText: {
    fontFamily: 'Poppins',
    fontSize: '13px',
    opacity: '50%',
    marginBottom: '0px'
  },
  shareMovieTextField: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '43px',
    border: 'none',
    paddingLeft: '20px',
    color: 'white',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  shareMovieTextarea: {
    backgroundColor: '#262338',
    borderRadius: '10px',
    width: '100%',
    height: '93px',
    border: 'none',
    color: 'white',
    padding: '7px 20px',
    fontFamily: 'Poppins',
    '&:focus-visible': {
      outline: 'none',
      border: '1px solid #4E4B66'
    }
  },
  selectWrapp: {
    width: '100%',
    padding: '10px 20px',
    borderRadius: '10px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white',
    fontFamily: 'Poppins',
    borderColor: '#4E4B66'
  },
  checkBox: {
    position: 'relative',
    width: '25px',
    height: '25px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    '&:hover': {
      opacity: '1'
    }
  },
  imgWrapp: { width: '48px', height: '48px', position: 'relative' },
  image: { width: '100%', height: '100%', borderRadius: '50%' },
  delImg: { fontSize: '10px', fontWeight: 'bold', borderRadius: '50%', padding: '5px', backgroundColor: 'red', position: 'absolute', right: '-5px', top: '-5px' },
  ulistWrapp: { width: '100%', backgroundColor: '#262338', marginTop: '10px', padding: '10px', borderRadius: '10px' },
  ulistHeader: { width: '100%', display: 'flex', justifyContent: 'space-between' },
  searchWrapp: { width: '100%' },
  searchWrappInner: { width: '100%', position: 'relative', margin: '10px 0' },
  searchInp: { color: 'white', backgroundColor: '#262338', border: '1px solid white', width: '100%', height: '40px', borderRadius: '10px', outline: 'none', paddingLeft: '30px' },
  iconSearch: { position: 'absolute', left: '5px', top: '7px', color: 'white' },
  saveBtnWrapp: { width: '100%', marginTop: '30px', padding: '5px', display: 'flex', justifyContent: 'flex-end' },
  submodalBtn: { fontSize: '11px', padding: 8, border: '1px solid white', width: '95px', borderRadius: '10px', cursor: 'pointer', fontWeight: 700, marginTop: 10, textAlign: 'center' },
  saveBtn: {
    width: '30%',
    backgroundColor: '#A996FF',
    padding: '12px 20px',
    borderRadius: '10px',
    color: '#5312B5',
    fontWeight: 700,
    fontSize: '20px',
    '&:disabled': {
      opacity: 0.5,
      '& span': {
        opacity: 0.5,
        color: '#4700AB'
      }
    },
    '&:hover:not(:disabled)': {
      backgroundColor: ' #BFBEFC',
      color: '#5312B5'
    },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  }
});

const ChannelSettingsModal = ({ isMobile, data, setOpenChannelSettingsModal, openChannelSettingsModal, thumbnailUrl, setThumbnailUrl, setTriggerNotifications }) => {
  const classes = channelSettingsStyles();
  const [channelName, setChannelName] = useState(data.name);
  const [channelDescription, setChannelDescription] = useState(data.description);
  const [thumbnails, setThumbnails] = useState([]);

  const [movies, setMovies] = useState([]);
  const [openAddMovies, setOpenAddMovies] = useState(false);
  const [selectedIDs, setSelectedIds] = useState(data.movieIDs || []);
  const [openSettings, setOpenSettings] = useState(false);
  const [widgetMode, setWidgetMode] = useState(data.channelModeID);
  const [widgetFormat, setWidgetFormat] = useState(data.channelFormatID);

  const FabButton = styled(Fab)(({ theme }) => ({
    width: isMobile ? '135px' : '144px',
    textTransform: 'none',
    borderRadius: '16px',
    height: isMobile ? '53px' : '61px',
    backgroundColor: '#A996FF',
    color: '#4700AB',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '17px',
    '&:hover': {
      backgroundColor: '#c9befa',
      color: '#4700AB'
    }
  }));

  const handleSaveMovieSettings = () => {
    const channelData = {
      ID: data.id,
      Name: channelName,
      Description: channelDescription,
      ChannelFormatID: widgetFormat,
      ChannelModeID: widgetMode,
      MovieIDs: selectedIDs || []
    };

    if (channelData.MovieIDs.length === 0) return alert('Selecte movie(s) for channel');

    const { axiosReq, cancelTokenSource } = movieService.saveChannel(channelData, true);
    axiosReq.then((res) => {
      if (res.data.success === true) {
        setTriggerNotifications((x) => !x);
        setOpenChannelSettingsModal(false);
      }
    });
  };

  useEffect(() => {
    const { req, cancelTokenSource } = movieService.getMoviesForChannel();

    req.then((resp) => {
      if (resp.data.success === true) {
        setMovies(resp.data.data);
      }
    });
  }, []);

  const handleAddMovie = ({ payload }) => {
    const { id, value } = payload;
    const updatedIds = value ? [...selectedIDs, id] : selectedIDs.filter((x) => x !== id);
    setSelectedIds(updatedIds);
  };

  const handleWidgetSettings = ({ type, payload }) => {
    if (type === 'UPDATE_FORMAT') {
      setWidgetFormat(payload);
    } else {
      setWidgetMode(payload);
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          zIndex: '12',
          backgroundColor: '#14142B',
          width: '600px',
          maxWidth: '90%',
          border: '1.5 solid #4E4B66',
          borderRadius: '15px',
          color: 'white',
          padding: isMobile ? '2px' : '20px',
          border: '2px solid #4E4B66'
        }
      }}
      open={openChannelSettingsModal}
      onClose={(e) => {
        e.stopPropagation();
        setOpenChannelSettingsModal(false);
      }}
    >
      <DialogTitle
        style={{ fontSize: isMobile ? '20px' : '22px', fontFamily: 'Poppins', fontWeight: 600 }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {'Channel Settings'}
        <IconButton
          style={{ color: 'white', position: 'absolute', right: '15px' }}
          onClick={(e) => {
            setOpenChannelSettingsModal(false);
            e.stopPropagation();
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        // style={{ overflow: 'hidden' }}
        className="dialog-content"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {openAddMovies && (
          <>
            <AddMovieStep movies={movies} dispatch={handleAddMovie} checkedIds={selectedIDs} />
            <div className={classes.saveBtnWrapp}>
              <FabButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenAddMovies(false);
                }}
              >
                <span>Ok</span>
              </FabButton>
            </div>
          </>
        )}
        {openSettings && (
          <>
            <FirstStep dispatch={handleWidgetSettings} format={widgetFormat} mode={widgetMode} />
            <div className={classes.saveBtnWrapp}>
              <FabButton
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenSettings(false);
                }}
              >
                <span>Ok</span>
              </FabButton>
            </div>
          </>
        )}
        {!openAddMovies && !openSettings && (
          <>
            <p className={classes.shareMovieDialogText}>Channel Title:</p>
            <input
              maxLength="250"
              placeholder="Channel title"
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setChannelName(e.target.value)}
              type="text"
              value={channelName}
              className={classes.shareMovieTextField}
            />
            <p style={{ marginTop: 10 }} className={classes.shareMovieDialogText}>
              Channel Description:
            </p>
            <textarea
              placeholder="Channel description"
              style={{ height: 100, paddingTop: 10 }}
              className={classes.shareMovieTextField}
              value={channelDescription}
              onChange={(e) => setChannelDescription(e.target.value)}
            ></textarea>

            <div style={{ display: 'flex' }}>
              <div className={classes.submodalBtn} onClick={() => setOpenAddMovies(true)}>
                + Add movies
              </div>
              <div className={classes.submodalBtn} style={{ width: '110px', marginLeft: '10px' }} onClick={() => setOpenSettings(true)}>
                Widget Settings
              </div>
            </div>
            <ThumbnailPart classes={classes} data={{ channelID: data.id }} thumbnails={thumbnails} thumbnailUrl={thumbnailUrl} setThumbnailUrl={setThumbnailUrl} isChannel={true} />
            <hr />
            <EmbedCode showDescription={false} widgetLink={data.shareLink} />
            <div className={classes.saveBtnWrapp}>
              <FabButton
                onClick={(e) => {
                  e.stopPropagation();

                  handleSaveMovieSettings(data.id);
                  setOpenChannelSettingsModal(false);
                }}
              >
                <span>Save</span>
              </FabButton>
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default ChannelSettingsModal;
ChannelSettingsModal.defaultProps = {
  isMobile: false,
  data: {},
  setOpenChannelSettingsModal: () => {},
  openChannelSettingsModal: false,
  thumbnailUrl: '',
  setThumbnailUrl: () => {},
  setTriggerNotifications: () => {}
};
