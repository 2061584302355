import Grid from '@material-ui/core/Grid';
import React from 'react';
import SingleChannel from './SingleChannel';

const Channels = ({ channels, setTriggerNotifications, setActiveBtnHeader, setChannelID }) => {
  return (
    <Grid container style={{ marginTop: '20px' }}>
      {channels.map((p) => (
        <Grid key={p.id} item xl={3} lg={4} md={6} sm={6} xs={12} align="center">
          <SingleChannel isMobile={false} data={p} setTriggerNotifications={setTriggerNotifications} setActiveBtnHeader={setActiveBtnHeader} setChannelID={setChannelID}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default Channels;
Channels.defaultProps = {
  channels: [],
  setTriggerNotifications: () => {}
};
