import { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import w1 from './video_default.png';

const VideoTag = ({ checkToggle, item, classStyle }) => {
  const [cardHover, setCardHover] = useState(false);
  const videoRef = useRef(null);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    hoverOverElement(cardHover);
  }, [cardHover]);

  const hoverOverElement = (isHovering) => {
    if (item?.hasPreview && !item?.previewUrl) return;

    if (videoRef.current) {
      if (videoRef.current.paused && isHovering) {
        videoRef.current.play();
      } else if (!videoRef.current.paused && !isHovering) {
        videoRef.current.pause();
      }
    }
  };

  return (
    <>
      <video
        loop
        onClick={() => setOpenPreview(true)}
        className={classStyle}
        style={{ outline: checkToggle ? '3px solid #A996FF' : '0px solid #A996FF' }}
        ref={videoRef}
        onMouseEnter={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        poster={w1}
      >
        <source src={(item.url || item.src) + '#t=1'} type="video/mp4" />
      </video>

      <Dialog open={openPreview} onClose={() => setOpenPreview(false)}>
        <IconButton onClick={() => setOpenPreview(false)}  sx={{  position: 'absolute', right: 8, top: 20,  color: 'white',  zIndex: 99  }} >
          <CloseIcon />
        </IconButton>
        <video width="100%" height="100%" controls>
          <source src={item.url || item.src} />
        </video>
      </Dialog>
    </>
  );
};

export default VideoTag;
VideoTag.defaultProps = {
  checkToggle: false, 
  item: {},
  classStyle: {}
}