import { makeStyles } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SidebarActionsContext } from '../../context/sidebar_actions_context';
import authService from '../../services/apiServices/authService';
import projectApi from '../../services/apiServices/projects/projectApi';
import stripeService from '../../services/apiServices/stripeService';
import ProjectSettingsDialogue from '../movies/ProjectSettingsDialogue';
import ProjectsListInspiration from './ProjectListInspiration';
import useInspiration from './custom/useInspiration';
import scratchBtn from './img/scratch.png';
import Testimonial from './testimonial/Testimonial';

const useStyles = makeStyles({
  libraryStartWrapp: {
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  dashboardHeader: {
    width: '99%',
    padding: '10px 0',
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
    fontFamily: "'Poppins'"
  },
  libraryWrapp: {
    width: '100%',
    color: 'white',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width: 360px)']: {
      marginRight: '0px'
    },
    ['@media (max-width: 451px)']: {
      marginLeft: '4%'
    },
    ['@media (max-width: 960px)']: {
      marginLeft: '4%'
    }
  }
});

const Inspiration = () => {
  const history = useHistory();
  const classes = useStyles();

  const { showSideMenu, setShowSideMenu } = useContext(SidebarActionsContext);
  const [recentProjects, setRecentProjects] = useState([]);
  const [inspiredProjects, setInspiredProjects] = useState([]);
  const [openNewProject, setOpenNewProject] = useState(false);
  const [openFayeVideo, setOpenFayeVideo] = useState(false);
  const [openPrices, setOpenPrices] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [pricingTable, setPricingTable] = useState(null);
  const [counterPricing, setCounterPricing] = useState(parseInt(localStorage.getItem('counterPricing') || 4)); 
 
  //draft template
  const [draftObject, setDraftObject] = useState('');
  const [isDraftedTemplate, setIsDraftedTemplate] = useState(false);
  const {checkIsDrafted} = useInspiration({ setDraftObject, setIsDraftedTemplate });

  const GetInspirationProjects = (skip, take, onlyPublic, setData) => {
    const { axiosReq } = projectApi.getAllProjects(skip, take, '', onlyPublic);
    axiosReq.then((resp) => {
      if (resp.data.success === true) {
        const projects = resp.data.data.filter(x => x?.projectStatus !== 'Sent' && x?.projectStatus !== 'Draft')
        setData(projects);
      }
    });
  };

  const CheckIsFirstLogin = () => {
    const { req } = authService.isFirstLogin();

    req.then((res) => {
      if (res.data.success && res.data.isFirstLogin) {
        setOpenFayeVideo(true);
      }
      GetCurrentPlan();
    });
  };

  const updateCouterPricing = () => {
    localStorage.setItem('counterPricing', counterPricing + 1);
    setCounterPricing(counterPricing + 1);
  };

  const GetCurrentPlan = () => {
    const { axiosReq, cancelTokenSource } = stripeService.getCurrentPlan();

    axiosReq
      .then(async (resp) => {
        if (resp.data.success === true) {
          setCurrentPlan(resp.data.currentPlan);
        } else {
          GetPricingTables();
        }
      })
      .catch((err) =>  console.log(err));
  };

  const GetPricingTables = () => {
    const { axiosReq, cancelTokenSource } = stripeService.getPublicKey();
    axiosReq.then(async (resp) => {
      if (resp.data.success === true) {
        setPricingTable(resp.data);
        setOpenPrices(true);
      }
    });
  };

  useEffect(() => {
    GetInspirationProjects(0, 500, false, setRecentProjects);
    GetInspirationProjects(0, 500, true, setInspiredProjects);
    CheckIsFirstLogin();
    updateCouterPricing();
   
    checkIsDrafted(); //check is drafted
  }, []);

  const createNewProjectFunc = (projectName, projectDescription, privacyID, contributorsList) => {
    if (!projectName) {
      toast.error('Enter project name', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    let obj = {
      Name: projectName,
      Description: projectDescription,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };

    const { axiosReq, cancelTokenSource } = projectApi.createNewProject(obj);
    axiosReq.then((resp) => {
      if (resp.data.success === true) {
        localStorage.removeItem('fayeproject');
        localStorage.setItem('fayeproject', resp.data.data);
        history.push('/editor');
      } else {
        toast.error('Error ' + resp?.data?.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
    setOpenNewProject(false);
  };

  return (
    <div className={classes.libraryStartWrapp}>
      <div className={classes.libraryWrapp}>
        {!showSideMenu && <AiOutlineMenuUnfold style={{ fontSize: '33px', marginRight: '20px' }} onClick={() => setShowSideMenu(true)} />}


        <Grid className={classes.dashboardHeader}>
            <h1> Dashboard</h1>

            <button 
              onClick={() =>  setOpenNewProject(true)}
              style={{ width: '223.5px', height: '44px', backgroundColor: '#14142A', color: 'white', borderRadius: '10px', marginTop: '25px', display: 'flex', justifyContent:'center', alignItems:'center', border: '2px solid white' }}
            >
              <img src={scratchBtn} style={{ marginRight: '6px' }} alt="add project button" />
              <p  style={{margin: 0, marginLeft:'6px', padding: 0, display: 'flex', justifyContent:'center', alignItems:'center',  height:'100%' }}>From scratch</p>
            </button>
        </Grid>

        <Testimonial draftObject={draftObject} isDraftedTemplate={isDraftedTemplate} setIsDraftedTemplate={setIsDraftedTemplate} />

        <Grid container style={{ marginTop: '15px' }}>
          <Grid item xs={8} style={{ marginTop: '10px' }}>
            <h5>Recent Projects:</h5>
          </Grid>
          {/* <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight:'15px'}}>
            <Button onClick={() =>  setOpenNewProject(true)}
              style={{ width: '223.5px', height: '44px', backgroundColor: '#CA024F', color: 'white', marginLeft: '25%', borderRadius: '20px' }}
            >
              <img src={newProjectBtn} style={{ marginRight: '5px' }} alt="add project button" />
              New project
            </Button>
          </Grid> */}
          <ProjectsListInspiration key={'recent'} type={'recent'} projects={recentProjects} />
          <Grid item xs={12} style={{ marginTop: '20px' }}>
            <h5>Explore and get inspired:</h5>
            <div style={{ display: 'flex', marginTop: '30px', width: '100%' }}>
              <ProjectsListInspiration key={'inspired'} type={'inspired'} projects={inspiredProjects} />
            </div>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '20px', marginBottom: '20px' }}>
            <h5>Faye Tutorials:</h5>
            <div style={{ display: 'flex', marginTop: '30px', width: '100%' }}>
              <div style={{ position: 'relative', cursor: 'pointer' }}>
                <video width="350px" height="200px" onClick={() => setOpenFayeVideo(true)}>
                  <source src={'https://fayeprodstorageaccount.blob.core.windows.net/misc/FayeDemo.mp4#t=3'} />
                </video>
                <div style={{ position: 'absolute', fontFamily: 'Poppins', left: '20px', bottom: '20px' }}>
                  <div style={{ fontSize: '12px' }}>Tutorial</div>
                  <div style={{ fontSize: '24px', fontWeight: 700 }}>Welcome to Faye</div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <Dialog
        maxWidth="786px"
        open={openFayeVideo}
        onClose={() => setOpenFayeVideo(false)}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: { background: 'transparent', border: 'none', boxShadow: 'none' }
        }}
        BackdropProps={{ style: { backgroundColor: 'black', opacity: 0.8 } }}
      >
        <div style={{ ontFamily: 'Poppins', fontWeight: 700, color: 'white' }}>
          <div style={{ fontSize: '36px' }}>Welcome to Faye!</div>
          <div>Watch this short intro to the platform.</div>
        </div>
        <DialogContent
          style={{
            width: 'auto',
            height: 'auto',
            maxWidth: '826px',
            padding: '20px',
            background: '#14142B',
            borderRadius: '20px',
            border: '1px solid #4E4B66',
            overflowY: 'hidden'
          }}
        >
          <IconButton
            onClick={() => setOpenFayeVideo(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 20,
              color: 'white'
            }}
          >
            <CloseIcon />
          </IconButton>
          <div>
            <video width="100%" controls>
              <source src={'https://fayeprodstorageaccount.blob.core.windows.net/misc/FayeDemo.mp4?' + new Date().getTime()} />
            </video>
          </div>
        </DialogContent>

        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            style={{
              width: '150px',
              backgroundColor: '#A996FF',
              color: '#4700AB',
              padding: '18px 20px',
              marginTop: '30px',
              borderRadius: '20px',
              fontFamily: '"Poppins"',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '17px',
              lineHeight: '26px',
              cursor: 'pointer',
              marginBottom: '0px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transitionDuration: '0.4s',
              WebkitTransitionDuration: '0.4s'
            }}
            onClick={() => setOpenFayeVideo(false)}
          > Get Started </div>
        </div>
      </Dialog>

      {openPrices && !openFayeVideo && counterPricing % 5 === 0 && (
        <Dialog
          PaperProps={{
            style: {
              width: '800px',
              maxWidth: '90%',
              background: '#14142B'
            }
          }}
          open={openPrices}
          onClose={() => setOpenPrices(false)}
        >
          <DialogContent className="dialog-content">
            <IconButton
              onClick={() => setOpenPrices(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 20,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Grid style={{ width: '100%' }}>
              {!!pricingTable && !currentPlan && (
                <stripe-pricing-table customer-email={pricingTable.email} client-reference-id={pricingTable.id} pricing-table-id={pricingTable.pricingTableID} publishable-key={pricingTable.key}></stripe-pricing-table>
              )}
            </Grid>
          </DialogContent>
        </Dialog>
      )}

      {openNewProject && (
        <ProjectSettingsDialogue
          data={{}}
          setOpenProjSettingsDialog={setOpenNewProject}
          openProjSettingsDialog={openNewProject}
          projects={[]}
          setProjects={() => {}}
          isNewProject={true}
          handleSaveNewProject={createNewProjectFunc}
        />
      )}
    </div>
  );
};
export default Inspiration;
