import { makeStyles } from '@material-ui/core';
import clientLogo from './clientLogo.png';
import employeetLogo from './employeeLogo.png';
import newplayerLogo from './newplayerLogo.png';
import productLogo from './producttLogo.png';

const useStyles = makeStyles({
  buttonClient: {
    width: '80%',
    height: '34px',
    borderRadius: '8px',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    fontSize: '13px'
  },
  imageStyle: { marginRight: '10px' }
});

const colorOfTheText = (type) => {
  if (type === 'Client') return '#CBFFAE';
  else if (type === 'Employee') return '#FFC3C0';
  else if (type === 'Product') return '#FFE6B0';
  else return '#8DE9FF';
};

const renderText = (type) => {
  if (type === 'Client') return 'Client';
  else if (type === 'Employee') return 'Employee';
  else if (type === 'Product') return 'Product';
  else return 'New Player';
};

const renderLogo = (type) => {
  if (type === 'Client') return clientLogo;
  else if (type === 'Employee') return employeetLogo;
  else if (type === 'Product') return productLogo;
  else return newplayerLogo;
};

const ClientBtn = ({ type, itemId }) => {
  const styles = useStyles();
  const textStyle = { color: colorOfTheText(type) };

  const redirectDraftedTestimonial = () => !!itemId && (window.location.href = `/promotions?testimonialId=${itemId}`);
  return (
    <button className={styles.buttonClient} style={{ border: `1px solid ${colorOfTheText(type)}` }} type="button" onClick={redirectDraftedTestimonial}>
      <img className={styles.imageStyle} src={renderLogo(type)} alt={type} />
      <span style={textStyle}> {renderText(type)} </span>
    </button>
  );
};

export default ClientBtn;
ClientBtn.defaultProps = {
  type: 'client'
};
