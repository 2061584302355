import { createContext, useState } from "react";

export const PlayModePreviewContext = createContext(null);

export const PlayModePreviewContextProvider = ({ children }) => {

    const [playModePreview, setPlayModePreview] = useState(false);

    return (
        <PlayModePreviewContext.Provider value={{ playModePreview, setPlayModePreview }}>
            {children}
        </PlayModePreviewContext.Provider>
    )
}