import { CopyToClipboard } from 'react-copy-to-clipboard';
import MobileTabsLibrary from '../../library/mobile/MobileTabsLibrary';
import TabSocialMobile from './TabSocialMobile';

const TabShareMobile = ({ shareUrl, classes, FabButton, value, setValue }) => {
  const shareTabs = ['Social', 'Link'];
  return (
    <div style={{ width: '100%', padding: '16px', height: '285px', overflow: 'hidden' }}>
      <MobileTabsLibrary isShareTab={true} mobileSubLinks={shareTabs} setActiveSubLibTab={setValue} activeSubLibTab={value} />
      {value === 0 && <TabSocialMobile shareUrl={shareUrl} classes={classes} />}

      {value === 1 && (
        <>
          <div style={{ width: '100%', padding: '12px' }}>
            <input style={{ width: '100%', backgroundColor: '#262338', border: 'none', outline: 'none', color: 'white', padding: '7px', fontSize: '12px' }} type="text" value={shareUrl} disabled />
          </div>

          <div style={{ padding: '12px' }}>
            <CopyToClipboard text={shareUrl}>
              <FabButton size="large" variant="extended">
                Copy link
              </FabButton>
            </CopyToClipboard>
          </div>
        </>
      )}
    </div>
  );
};

export default TabShareMobile;
