import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Checkbox from '@mui/material/Checkbox';
import QuestionBackButton from '../../button/back/QuestionBackButton';
import QuestionButton from '../../button/next/QuestionButton';
import DropdownQuestion from '../../dropwdown/DropdownQuestion';
import SingleQuestionBox from '../../single/SingleQuestionBox';
import styleStepOne from './stepOne.module.css';

const StepOne = ({ questionStep, setQuestionSteps, state, dispatch, typeId }) => {
  const handleChange = (event) => {
    dispatch({ type: 'INCLUDE_RATING', payload: event.target.checked });
  };

  return (
    <>
      <div className={styleStepOne.mainQuestionWrapp}>
        {state?.step1.questions[typeId - 1].map((question, index) => (
          <SingleQuestionBox key={index + '' + questionStep} typeId={typeId} questionTitle={question} index={index} state={state} dispatch={dispatch} questionStep={questionStep} />
        ))}
      </div>

      <DropdownQuestion state={state} dispatch={dispatch} typeId={typeId} />

      <div className={styleStepOne.includeStar}>
        <Checkbox
          checked={state?.step1.includeRating}
          onChange={handleChange}
          defaultChecked
          size="small"
          icon={<RadioButtonUncheckedIcon sx={{ color: '#6E7191' }} />}
          checkedIcon={<RadioButtonCheckedIcon sx={{ color: '#6E7191' }} />}
        />

        <span>Include star rating</span>
      </div>

      <div style={{ marginTop: '10px' }}>
        <QuestionBackButton setQuestionSteps={setQuestionSteps} />
        <QuestionButton setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} />
      </div>
    </>
  );
};

export default StepOne;
StepOne.defaultProps = {
  questionStep: 1,
  setQuestionSteps: () => {},
  state: () => {},
  dispatch: () => {},
  typeId: 1
};
