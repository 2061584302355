import { useEffect, useState } from 'react';
import useQuestions from './parts/custom/useQuestions';
import StepProgress from './parts/progressSteps/StepProgress';
import QuestionHeader from './parts/questionHeader/QuestionHeader';
import StepZero from './parts/step0/StepZero';
import StepOne from './parts/step1/StepOne';
import StepTwo from './parts/step2/StepTwo';
import StepQuestion from './parts/stepQuestion/StepQuestion';
import StepStart from './parts/stepStart/StepStart';
import questionStyles from './question.module.css';

const Question = ({ setOpenQuestion, btnHeaderTextColor, btnName, typeId, isDraftedTemplate, draftObject }) => {
  const [questionStep, setQuestionSteps] = useState(-1);
  const { state, dispatch } = useQuestions();

  useEffect(() => {
    if (isDraftedTemplate) {
      setQuestionSteps(2);
      dispatch({ type: 'INIT_DRAFT', payload: draftObject });
    }
  }, []);

  const renderProprietaryStep = () => {
    let StepComponent = StepZero;
    if (questionStep === 0) {
      StepComponent = StepStart;
    } else if (questionStep === 1) {
      StepComponent = StepOne;
    } else if (questionStep === 2) {
      StepComponent = StepTwo;
    } else if (questionStep === 3 || questionStep === 4) {
      StepComponent = StepQuestion;
    }

    return <StepComponent questionStep={questionStep} setQuestionSteps={setQuestionSteps} state={state} dispatch={dispatch} typeId={typeId} setOpenQuestion={setOpenQuestion} />;
  };

  return (
    <div className={questionStyles.questionWrapp}>
      <div className={questionStyles.questionWrappInner} style={{ height: questionStep > 0 && questionStep < 3 && '96vh' }}>
        <div className={questionStyles.questMain} style={{ height: questionStep > 0 && questionStep < 3 && '94vh' }}>
          <QuestionHeader questionStyles={questionStyles} setOpenQuestion={setOpenQuestion} questionStep={questionStep} btnHeaderTextColor={btnHeaderTextColor} btnName={btnName} />
          {renderProprietaryStep()}
          {questionStep >= 0 && <StepProgress questionStep={questionStep} />}
        </div>
        <div className={questionStyles.overlayClose} onClick={() => setOpenQuestion(false)} />
      </div>
    </div>
  );
};

export default Question;

Question.defaultProps = {
  setOpenQuestion: () => {},
  btnHeaderTextColor: 'white',
  btnName: 'Unknown',
  typeId: 1,
  isDraftedTemplate: false,
  draftObject: {}
};
