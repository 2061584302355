import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ButtonFab from '../../styled/buttons/ButtonFab';
import signupClass from './css/LoginComponent.module.css';
import useSignup from './customs/useSignup';
import msg from './medias/msg.png';
import people from './medias/people.png';
import FullSignupModal from './modals/signupModal/FullSignupModal';
import TermsOfUse from './termsOfUse/TermsOfUse';

const SignupComponent = ({ setLoginVisible }) => {
  const {
    firstName,
    setFirstName,
    firstNameError,
    setFirstNameError,
    lastName,
    setLastName,
    lastNameError,
    setLastNameError,
    email,
    setEmail,
    emailError,
    setEmailError,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    passwordError,
    setPasswordError,
    showSuccessReg,
    showChmarkError,
    showChmark,
    setShowChmark,
    handleChangeInput,
    handleSignUpClick,
    closeConfirmSignUp
  } = useSignup(setLoginVisible);
  const navigate = useHistory();

  return (
    <>
      <Grid container className={signupClass.cont}>
        <div className={signupClass.label}>First Name</div>

        <div className={signupClass.wrapInpImg}>
          <input value={firstName} className={signupClass.input} onChange={(e) => handleChangeInput(e.target.value, setFirstNameError, setFirstName)} />
          <img src={people} alt="people" className={signupClass.inputImg} />
        </div>
        <span className={signupClass.error}>{firstNameError}</span>

        <div className={signupClass.label}>Last Name</div>
        <div className={signupClass.wrapInpImg}>
          <input value={lastName} className={signupClass.input} onChange={(e) => handleChangeInput(e.target.value, setLastNameError, setLastName)} />
          <img src={people} alt="people" className={signupClass.inputImg} />
        </div>
        <span className={signupClass.error}>{lastNameError}</span>

        <div className={signupClass.label}>E-Mail</div>
        <div className={signupClass.wrapInpImg}>
          <input value={email} className={signupClass.input} onChange={(e) => handleChangeInput(e.target.value, setEmailError, setEmail)} />
          <img src={msg} alt="message" className={signupClass.inputImg} />
        </div>
        <span className={signupClass.error}>{emailError}</span>

        <div className={signupClass.label}>Password</div>
        <input
          type="password"
          className={signupClass.input}
          value={password}
          onChange={(e) => {
            if (e.target.value.trim().length === 0) return;
            handleChangeInput(e.target.value.trim(), setPasswordError, setPassword);
          }}
        />
        <div className={signupClass.label}>Repeat Password</div>
        <input value={confirmPassword} type="password" className={signupClass.input} onChange={(e) => handleChangeInput(e.target.value, setPasswordError, setConfirmPassword)} />
        <span className={signupClass.error}>{passwordError}</span>
      </Grid>

      <TermsOfUse showChmark={showChmark} setShowChmark={setShowChmark} />

      <span className={signupClass.error}>{showChmarkError}</span>
      <Grid container className={signupClass.buttoncont}>
        <ButtonFab tittle="Sign Up" onClickHandler={handleSignUpClick} />

        <div className={signupClass.already}>
          {'Already have an account? '}
          <span className={signupClass.loginHere} onClick={() => navigate.push('/login')}>
            <u>Login here</u>
          </span>
        </div>
      </Grid>
      {showSuccessReg && <FullSignupModal closeConfirmSignUp={closeConfirmSignUp} email={email} />}
    </>
  );
};

export default SignupComponent;
