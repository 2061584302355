import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import projectApi from '../../services/apiServices/projects/projectApi';
import HeaderAsset from '../header/HeaderAsset';
import SubHeader from './parts/subHeader/SubHeader';
import Table from './parts/table/Table';
import stylePS from './projectSection.module.css';

const ProjectSection = ({ triggerNotifications, isMobile }) => {
  const location = useLocation();
  const [allProjects, setAllProjects] = useState([]);

  const [projectTotalPages, setProjectTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [pageCurrent, setPageCurrent] = useState(0);

  const [channel, setChannel] = useState({});
  const channelId = location.state?.channelId;

  const GetProjects = (page, searchTerm = '', channelID = null) => {
    const { axiosReq, cancelTokenSource } = projectApi.getAllProjects(page, 12, searchTerm, false, channelID);
    axiosReq
      .then((resp) => {
        if (resp.data.success) {
          setAllProjects(resp.data.data);
          setProjectTotalPages(Math.ceil(resp.data.totalCount / 12));
          setChannel(resp.data.channel || {});
        }
      })
  };

  useEffect(() => {
    GetProjects(pageCurrent, searchTerm, channelId);
  }, [searchTerm, pageCurrent, triggerNotifications, location]);

  return (
    <section className={stylePS.projectWrapp}>
      <HeaderAsset title="Projects" setSearchTerm={setSearchTerm} channelName={channel.name} />
      <SubHeader projectTotalPages={projectTotalPages} setPageCurrent={setPageCurrent} />
      <Table isMobile={isMobile} allProjects={allProjects} setAllProjects={setAllProjects} isChannel={!!channelId} />
    </section>
  );
};

export default ProjectSection;
ProjectSection.defaulrProps = {
  triggerNotifications: false,
  isMobile: false
};
