import { makeStyles } from '@material-ui/core';

  const styleAuth = makeStyles({ 
    authWrapp:{
    position:'fixed',
    top:'0',
    left:'0',
      width:'100%',
      height: '100vh',
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      zIndex:'9'
    },
    InpDivsWrapp:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
        width: '50%',
    },
    logInpWrapp:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    logInp:{
        marginTop:'5px',
        width:'80%',
        paddingLeft:'10px',
    '&::placeholder':{
        paddingLeft:'10px'
    }
},
inpHeight:{
        borderColor:'white',
        height:'50px',
        borderRadius:'15px',
        outline:'none'
    },
    logBtn:{
        marginTop:'10px',
        height:'31px',
        borderRadius:'5px',
        backgroundColor:'blue',
        color:'white',
        fontSize:'1.05rem',
    },
    errWrapp:{
        marginTop:'10px',
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        backgroundColor:'red',
        padding:'6px',
        borderRadius:'5px',
        '& p':{
        color:'white'
        }
    },
    regErrMsg:{
      color:'white'
    }
  });

const useAuthStyle = () => {
    const authStyle = styleAuth();
  return {authStyle};
};

export default useAuthStyle;
