import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const getAssetUrl = '/api/Assets/asset-url';
const addAssetTagUrl = '/api/Assets/asset-tag';
const removeAssetTagUrl = '/api/Assets/remove-asset-tag';
const saveAssetForLibraryUrl = '/api/Assets/save-to-library';

const getAssetStorageUrl = (AssetID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + getAssetUrl, {
      params: { AssetID: AssetID },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const addAssetTag = (assetID, name) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + addAssetTagUrl,
      data: {
        AssetID: assetID,
        Name: name
      },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const removeAssetTag = (ID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + removeAssetTagUrl,
      data: {
        ID: ID
      },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const saveAssetToLibrary = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + saveAssetForLibraryUrl,
      data: data,
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  getAssetStorageUrl,
  addAssetTag,
  removeAssetTag,
  saveAssetToLibrary
};
