import React, { useContext, useEffect, useRef, useState } from 'react';
import { PLAYING } from '../../../../constants/video_states';
import { CurrentTimePreviewContext } from '../../../../context/current_time_preview_context';
import { PlayModePreviewContext } from '../../../../context/play_mode_preview_context';

const VideoStagePreview = ({ clip, timeRangePreviewClicked }) => {
  let position = `translate(${clip.x + (clip.crop?.cropLeft || 0)}px, ${clip.y + (clip.crop?.cropTop || 0)}px)`;
  let rotation = clip.rotate ? `rotate(${clip.rotate}deg)` : '';

  const { currentTimePreview } = useContext(CurrentTimePreviewContext);
  const { playModePreview } = useContext(PlayModePreviewContext);
  const videoRef = useRef();

  const [clipUrl, setClipUrl] = useState('');
  const [visibility, setVisibility] = useState('hidden');

  const UpdateVideoPreviewElement = () => {
    if (currentTimePreview >= clip.start && currentTimePreview < clip.start + clip.length) {
      setVisibility('visible');
    } else {
      setVisibility('hidden');
    }
  };

  useEffect(() => {
    if (!!clip) {
      setClipUrl(clip.asset.previewSrc ?? clip.asset.src);
    }
  }, []);

  useEffect(() => {
    videoRef.current.volume = clip.volume;
  }, [clip.volume]);

  useEffect(() => {
    let clipTime = currentTimePreview - (clip.start - 0) + clip.trim;
    let newTime = currentTimePreview > clip.start && currentTimePreview < clip.start - 0 + clip.length ? clipTime : clip.trim;
    videoRef.current.currentTime = newTime + '';
  }, [timeRangePreviewClicked, clip.start, clip.length, clip.trim, playModePreview]);

  useEffect(() => {
    UpdateVideoPreviewElement();
  }, [currentTimePreview, clip.length, clip.start, clip.trim]);

  useEffect(() => {
    if (playModePreview === PLAYING) {
      if (videoRef.current.paused && visibility === 'visible') {
        videoRef.current.play().then((_) => {});
      } else if (!videoRef.current.paused && visibility === 'hidden') {
        videoRef.current.pause();
      }
    } else {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    }
  }, [visibility, playModePreview]);

  let style = {
    zIndex: clip.z,
    opacity: clip.opacity || 1,
    transform: `${position} ${rotation}`,
    width: `${parseFloat(clip.w - (clip.crop?.cropRight || 0) - (clip.crop?.cropLeft || 0))}px`,
    height: `${parseFloat(clip.h - (clip.crop?.cropBottom || 0) - (clip.crop?.cropTop || 0))}px`,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  return (
    <div data-z={clip.z} data-x={clip.x} data-y={clip.y} id={`screen_${clip.assetStyleID}`} scene-id={clip.sceneID} stage-id={clip.assetStyleID} data-master={clip.isMaster} style={style}>
      <div
        style={{
          transform: `translate(${-Math.floor(clip.crop?.cropLeft || 0)}px, ${-Math.floor(clip.crop?.cropTop || 0)}px)`
        }}
      >
        <video
          onEnded={() => {
            setVisibility('hidden');
            videoRef.current.pause();
          }}
          src={clipUrl}
          height={clip.h + 'px'}
          width={clip.w + 'px'}
          ref={videoRef}
          className={`resizable`}
          autobuffer="true"
        />
      </div>
    </div>
  );
};

export { VideoStagePreview };

