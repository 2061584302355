import { Fab, makeStyles, styled } from '@material-ui/core';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const embedStyles = makeStyles({
  wrapper: { color: 'white' },
  header: { fontWeight: 700 },
  info: { fontSize: '12px' },
  txtinfo: { fontSize: '12px', opacity: 0.5, margin: '15px 0px 5px 0px' },
  txtarea: { width: '100%', height: '100%', backgroundColor: '#262338', borderRadius: '12px', outline: 'none', padding: '10px', color: 'white', fontSize: '12px', fontWeight: '500' },
});

const FabButton = styled(Fab)(({ theme }) => ({
  width: '100px',
  height: '20px',
  fontWeight: 700,
  borderRadius: 10,
  backgroundColor: '#A996FF',
  color: '#4700AB',
  borderColor: '#A996FF',
  outline: 'none',
  fontSize: '11px',
  '&:hover': {
    backgroundColor: '#c9befa',
    color: '#4700AB'
  }
}));

const EmbedCode = ({ widgetLink, showDescription = true }) => {
  const classes = embedStyles();
  const iframe = `<iframe src=https://faye-share.azurewebsites.net/w/${widgetLink} width="400" height="220" title="Faye Widget"></iframe>`;

  return (
    <div className={classes.wrapper}>
      {showDescription && (
        <>
          <div>
            <h1 className={classes.header}>Widget settings</h1>
            <p className={classes.info}>Copy this code to embed on your website. This code will be available in your Channel settings.</p>
          </div>
        </>
      )}

      <div>
        <p className={classes.txtinfo}>Widget embed code</p>
        <textarea value={iframe} rows={3} className={classes.txtarea} spellcheck="false"></textarea>
      </div>
      <CopyToClipboard text={iframe}>
        <FabButton
          onClick={() => {
            toast.success('Link copied to clipboard', {
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }}
        >
          Copy code
        </FabButton>
      </CopyToClipboard>
      <div>
        <p className={classes.txtinfo}>Channel link</p>
        <input value={'https://faye-share.azurewebsites.net/c/' + widgetLink} rows={3} className={classes.txtarea} spellcheck="false"></input>
      </div>
      <CopyToClipboard text={'https://faye-share.azurewebsites.net/c/' + widgetLink}>
        <FabButton
          style={{ marginTop: 5 }}
          onClick={() => {
            toast.success('Link copied to clipboard', {
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }}
        >
          Copy link
        </FabButton>
      </CopyToClipboard>
    </div>
  );
};

export default EmbedCode;
EmbedCode.defaultProps = {
  widgetLink: '',
  showDescription: true
};
