import React from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import useFilterAssetStyle from './useFilterAssetStyle';
import cloudImg from './media/uploadCloud.png';

const UploadFilAsset = ({ onMobile = false, setOpenUploadModal, isOnBodyPicker }) => {
  const { classes } = useFilterAssetStyle();
  return (
    <div className={onMobile ? classes.uploadMobileWrapp : classes.uploadWrapp} onClick={() => setOpenUploadModal(true)}>
      {/* <CloudUploadIcon /> */}
      <img src={cloudImg} alt="cloud" />
      {!onMobile && <span>{isOnBodyPicker ? 'Upload' : 'Upload new'}</span>}
      {onMobile && <span>Upload</span>}
    </div>
  );
};

export default UploadFilAsset;
UploadFilAsset.defaultProps = {
  onMobile: false,
  setOpenUploadModal: () => {},
  isOnBodyPicker: false
};
