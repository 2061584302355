import { useState } from 'react';
import plusAdd from '../../../inspiration/img/plus.png';
import ModalChannel from './modalAdd/ModalChannel';
const styleBtn = {
  display: 'flex',
  marginRight: '7px',
  justifyContent: 'center',
  alignItems: 'center',
  width: '168px',
  height: '40px',
  backgroundColor: '#A996FF',
  border: '1px solid #A996FF',
  borderRadius: '14px',
  color: 'white'
};
const styleText = { marginRight: '5px' };
const styleImg = { marginRight: '5px' };

const ButtonAddNewChannel = ({ setTriggerNotifications }) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <button type="button" style={styleBtn} onClick={() => setOpenModal(true)}>
        <img style={styleImg} src={plusAdd} alt="add" />
        <span style={styleText}>New Channel</span>
      </button>
      {openModal && <ModalChannel openModal={openModal} setOpenModal={setOpenModal} setTriggerNotifications={setTriggerNotifications} />}
    </>
  );
};

export default ButtonAddNewChannel;
ButtonAddNewChannel.defaultProps = {
  setTriggerNotifications: () => {}
}