import { makeStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import { useState } from 'react';
import Avatar from 'react-avatar';

const styleListAdd = makeStyles({
  listAddWrapp: { padding: '5px 0', borderBottom: '1px solid white', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  leftSideWrapp: { display: 'flex' },
  image: { width: '41px', height: '41px', backgroundColor: 'grey', borderRadius: '50%' },
  nameWrapp: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px' },
  checkWrapp: { display: 'flex', justifyContent: 'center', alignItems: 'center', width: '25px', height: '25px', borderRadius: '50%' }
});

const ListAdd = ({ item, contributorsList = [], setContributorsList }) => {
  const classes = styleListAdd();
  const [checked, setChecked] = useState(contributorsList.filter((x) => x.userId === item.id).length > 0);

  const toggleCheck = (e) => {
    e.stopPropagation();
    setChecked((prev) => !prev);

    if (!checked) {
      setContributorsList([...contributorsList, { ...item, userId: item.id }]);
    } else {
      let x = contributorsList.filter((x) => x.userId !== item.id);
      setContributorsList(x);
    }
  };

  return (
    <div className={classes.listAddWrapp}>
      <div className={classes.leftSideWrapp}>
        <Avatar size="35px" style={{ clipPath: 'circle()' }} round="true" src={item.imageUrl} name={`${item.firstName} ${item.lastName}`} />

        <div className={classes.nameWrapp}>
          <p>
            {item.firstName} {item.lastName}
          </p>
        </div>
      </div>

      <div onClick={toggleCheck} className={classes.checkWrapp} style={{ backgroundColor: checked ? '#A996FF' : 'grey' }}>
        {checked && <CheckIcon style={{ color: 'white' }} />}
      </div>
    </div>
  );
};
export default ListAdd;
ListAdd.defaultProps = {
  item: {}, 
  contributorsList: [], 
  setContributorsList: () => {}
}