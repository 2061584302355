import React from 'react';
import useStyleCardAsset from './useStyleCardAsset';
import VideoTag from './VideoTag';
import ImageTag from './ImageTag';
import AudioTag from './AudioTag';
import SceneTag from './SceneTag';
import CheckIcon from '@mui/icons-material/Check';
import { AiOutlineMore } from 'react-icons/ai';

const MainCA = ({ item, checkToggle, setCheckToggle, activeSound, setActiveSound, itemsReadyDelete, toggleMore, isMobile }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();

  return (
    <>
      {item.assetType === 'video' && <VideoTag checkToggle={checkToggle} item={item} classStyle={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`} />}

      {(item.assetType === 'image' || item.assetType === 'html') && <ImageTag checkToggle={checkToggle} item={item} classStyle={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`} />}

      {item.assetType === 'audio' && <AudioTag activeSound={activeSound} setActiveSound={setActiveSound} checkToggle={checkToggle} item={item} classStyle={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`} />}

      {item.assetType === 'scenes' && <SceneTag checkToggle={checkToggle} item={item} classStyle={`${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`} />}

      {!item.isCommon && !isMobile && (
        <>
          <div
            onClick={() => {
              setCheckToggle((prev) => !prev);
              itemsReadyDelete(item);
            }}
            className={cssStyle.checkBox}
            style={{ backgroundColor: checkToggle ? '#A996FF' : 'grey' }}
          >
            {checkToggle && <CheckIcon style={{ color: '#5E5594', fontSize: '18px' }} />}
          </div>

          <AiOutlineMore onClick={toggleMore} style={{ position: 'absolute', top: '10px', right: '10px', color: 'white', fontSize: '33px', fontWeight: 'bold' }} />
        </>
      )}
    </>
  );
};

export default MainCA;
MainCA.defaultProps = {
  item: {}, 
  checkToggle: {}, 
  setCheckToggle: () => {}, 
  activeSound: {}, 
  setActiveSound: () => {}, 
  itemsReadyDelete: () => {}, 
  toggleMore: false, 
  isMobile: false
}
