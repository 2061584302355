import { useEffect, useRef, useState } from 'react';
import assetsService from '../../services/apiServices/assetsService';
import EditAsset from './assetSection/editAsset/EditAsset.js';
import librarySceneService from '../../services/apiServices/librarySceneService';
import useStyleCardAsset from './assetSection/cardasset/useStyleCardAsset';
import MainCA from './assetSection/cardasset/MainCA';
import DetailsCA from './assetSection/cardasset/DetailsCA';
import AssetTagDialog from './assetSection/cardasset/AssetTagDialog';

const CardAsset = ({ isMobile, item, itemsReadyDelete, activeSound, setActiveSound, setRerenderSave, activeBtnHeader }) => {
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();
  const [more, setMore] = useState(false);
  const [checkToggle, setCheckToggle] = useState(false);
  const [assetTags, setAssetTags] = useState([]);
  const [openTagDialogFor, setOpenTagDialogFor] = useState(null);
  const [newTag, setNewTag] = useState('');

  const cutNameOverlay = () => {
    let name = item.name.replace(/\.[^/.]+$/, '');
    if (name.length > 19) {
      let tmpName = name.substring(0, 18);
      return tmpName + '...';
    }
    return name;
  }

  const cutDateOverlay = () => {
    let dater = item.originalLength;
    let numberedDate = parseInt(dater);
    if (numberedDate < 10) {
      return `00:0${numberedDate}`;
    } else if (numberedDate < 60) {
      return `00:${numberedDate}`;
    } else {
      let mins = parseInt(numberedDate / 60);
      if (mins < 10) {
        mins = `0${mins}`;
      }

      let secs = parseInt(numberedDate % 60);
      if (secs < 10) {
        secs = `0${secs}`;
      }

      return mins + ":" + secs;
    }

  }
  useEffect(() => {
    if (!!item) {
      setAssetTags(item.assetTags);
    }
  }, [item]);

  const addTagToAsset = () => {
    if (activeBtnHeader === 1) {
      //have to finish
      const { req, cancelTokenSource } = assetsService.addAssetTag(openTagDialogFor, newTag);

      req.then((resp) => {
        setOpenTagDialogFor(null);
        if (resp.data.success === true) {
          setAssetTags([...assetTags, resp.data.data]);
        }
      });
    } else {
      const { req } = librarySceneService.addLibrarySceneTag(item.id, newTag);

      req.then((resp) => {
        setOpenTagDialogFor(null);
        if (resp.data.success === true) {
          setAssetTags([...assetTags, resp.data.data]);
        }
      });
    }
  };

  const removeAssetTag = (id) => {
    if (activeBtnHeader === 1) {
      const { req, cancelTokenSource } = assetsService.removeAssetTag(id);

      req.then((resp) => {
        if (resp.data.success === true) {
          setAssetTags(assetTags.filter((item) => item.id !== id));
        }
      });
    } else {
      const { req, cancelTokenSource } = librarySceneService.removeLibSceneTag(id);

      req.then((resp) => {
        if (resp.data.success === true) {
          setAssetTags(assetTags.filter((item) => item.id !== id));
        }
      });
    }
  };

  const uploadFile = useRef(null);

  const uplImg = (e) => {
    e.stopPropagation();
    let el = uploadFile.current;
    el.click();
  };

  const toggleMore = () => setMore((prev) => !prev);

  return (
    <div className={cssStyle.newMovieWrapp}>
      {more && <EditAsset item={item} setRerenderSave={setRerenderSave} setMore={setMore} activeBtnHeader={activeBtnHeader} />}
      <div className={`${cssStyle.newMovie} ${isMobile ? cssStyle.thubmnailSizeMobile : cssStyle.thubmnailSize}`}>
        <MainCA isMobile={isMobile} item={item} checkToggle={checkToggle} setCheckToggle={setCheckToggle} activeSound={activeSound} setActiveSound={setActiveSound} itemsReadyDelete={itemsReadyDelete} toggleMore={toggleMore} />

        {!isMobile && <DetailsCA item={item} assetTags={assetTags} removeAssetTag={removeAssetTag} setOpenTagDialogFor={setOpenTagDialogFor} />}

        {isMobile && <span style={{ position: 'absolute', top: 10, left: 10, fontSize: '12px', backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2px' }} >{cutNameOverlay()}</span>}
        {isMobile && item.assetType !== 'image' && activeBtnHeader === 1 && <span style={{ position: 'absolute', bottom: 10, right: 10, fontSize: '12px', backgroundColor: 'rgba(0,0,0, 0.5)', padding: '2px' }} >{cutDateOverlay()}</span>}

        {/* {isMobile && <span style={{position:'absolute', top: 10, left: 10}} >{item.name.replace(/\.[^/.]+$/, '')}</span> } */}


      </div>
      <AssetTagDialog openTagDialogFor={openTagDialogFor} setOpenTagDialogFor={setOpenTagDialogFor} setNewTag={setNewTag} addTagToAsset={addTagToAsset} />
    </div>
  );
};

export default CardAsset;
CardAsset.defaultProps = {
  isMobile: false, 
  item: {}, 
  itemsReadyDelete: [], 
  activeSound: {}, 
  setActiveSound: () => {}, 
  setRerenderSave: () => {}, 
  activeBtnHeader: 0
}
