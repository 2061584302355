import { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import projectApi from '../../../services/apiServices/projects/projectApi';
import authService from '../../../services/apiServices/authService';
import { toast } from 'react-toastify'; 
import ButtonFab from '../../../styled/buttons/ButtonFab';

const styleAccountSettings = makeStyles({
  passWrapp: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  saveBtn: {
    width: '25%',
    height: '50px',
    backgroundColor: '#A996FF',
    borderRadius: '12px',
    color: '#4B457A',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '1.05rem',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  saveBtnWrapp: { display: 'flex', justifyContent: 'flex-start', width: '100%' },
  saveBtnWrappMobile: {display: 'flex', justifyContent: 'flex-end', width: '97%', paddingTop: '30px'},
  passTitleWrapp: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'white',
    paddingLeft: '15px'
  },
  passInputsWrapp: {
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    paddingLeft: '15px',
    marginTop: '12px'
  },
  names: {
    marginLeft: '15px',
    fontSize: '12px',
    color: '#7E7C88',
    fontWeight: 'bold',
    marginBottom: '4px'
  },
  inpField: {
    width: '90%',
    height: '50px',
    borderRadius: '15px',
    padding: '10px',
    backgroundColor: '#262338',
    color: 'white',
    border: '1px solid #262338',
    marginBottom: '5px'
  },
  restInps: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px'
  },
  forgPassWrapp: {
    width: '90%',
    padding: '0px',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '12px'
  },
  forgsPara: {
    textDecoration: 'underline'
  }
});

const SettingsPassword = ({isMobile}) => {
  const stylePass = styleAccountSettings();
  const [oldPass, setOldPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [repNewPass, setRepNewPass] = useState(''); 

  const savePassBtn = () => {
    if (oldPass === '' || newPass === '' || repNewPass == '') {
      alert('Input is empty!');
    } else if (oldPass === newPass) {
      alert('Current password and new password should not be the same!');
    } else if (newPass !== repNewPass) {
      alert('New password and repat new password should be the same!');
    } else {
      const { axiosReq } = projectApi.changePassword(oldPass, newPass);

      axiosReq
        .then(() => {
          setOldPass('');
          setNewPass('');
          setRepNewPass('');
          toast.success('Password changed!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        })
        .catch(() => {
          toast.error('Error occured! Password is not changed!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        });
    }
  };

  const sendForgotPass = () => {
    let email = localStorage.getItem('username');
    const { req } = authService.forgotPassword(email);

    req
      .then(() => {
        toast.success('Password changed!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      })
      .catch(() => {
        toast.error('Error occured! Password is not changed!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  return (
    <Grid container xs={12} sm={12} lg={5} md={6}  className={stylePass.passWrapp} style={{padding: isMobile && '2px'}}>
      <div className={stylePass.passTitleWrapp}>
        <h1 style={{fontSize: isMobile && '18px'}}>Change password</h1>
      </div>

      <div className={stylePass.passInputsWrapp}>
        <div className={stylePass.restInps}>
          <label className={stylePass.names}>Current password</label>
          <input type="password" className={stylePass.inpField} style={{width: isMobile && '97%'}} value={oldPass} onChange={(e) => setOldPass(e.target.value)} />

          <div className={stylePass.forgPassWrapp}>
            <p className={stylePass.forgsPara} onClick={sendForgotPass}>
              Forgot password?
            </p>
          </div>
        </div>

        <div className={stylePass.restInps} style={{ marginTop:isMobile && '-15px'}}>
          <label className={stylePass.names}>New password</label>
          <input type="password" className={stylePass.inpField} style={{width: isMobile && '97%' }} value={newPass} onChange={(e) => setNewPass(e.target.value)} />
        </div>

        <div className={stylePass.restInps}>
          <label className={stylePass.names}>Repeat new password</label>
          <input type="password" className={stylePass.inpField} style={{width: isMobile && '97%'}} value={repNewPass} onChange={(e) => setRepNewPass(e.target.value)} />
        </div>

        <div className={isMobile ? stylePass.saveBtnWrappMobile : stylePass.saveBtnWrapp}>
          <ButtonFab tittle='Save' onClickHandler={savePassBtn} />
        </div>
      </div>
    </Grid>
  );
};

export default SettingsPassword;
SettingsPassword.defaultProps = {
  isMobile: false
}