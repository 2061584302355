import { useReducer } from 'react';

const initialState = {
  stepStart: {
    channelTitle: '',
    channelDescription: '',
    channelType: 1
  },
  firstStep: {
    format: 1,
    mode: 1
  },
  addMovieStep: {
    ids: [],
    movies: []
  }
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'UPDATE_TITLE':
      return { ...state, stepStart: { ...state.stepStart, channelTitle: action.payload } };
    case 'UPDATE_DESC':
      return { ...state, stepStart: { ...state.stepStart, channelDescription: action.payload } };
    case 'UPDATE_TYPE':
      return { ...state, stepStart: { ...state.stepStart, channelType: action.payload } };
    case 'UPDATE_FORMAT':
      return { ...state, firstStep: { ...state.firstStep, format: action.payload } };
    case 'UPDATE_MODE':
      return { ...state, firstStep: { ...state.firstStep, mode: action.payload } };
    case 'ADD_MOVIES_LIST':
      return { ...state, addMovieStep: { ...state.addMovieStep, movies: action.payload } };
    case 'UPDATE_IDS':
      if (action.payload.value) {
        const updatedIds = [...state.addMovieStep.ids, action.payload.id];
        return { ...state, addMovieStep: { ...state.addMovieStep, ids: updatedIds } };
      } else {
        const updatedIds = state.addMovieStep.ids.filter((x) => x !== action.payload.id);
        return { ...state, addMovieStep: { ...state.addMovieStep, ids: updatedIds } };
      }
    default:
      return state;
  }
}

const useChannel = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default useChannel;
