import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import burgerMenu from '../media/burgerMenu.png';
import closeMenu from '../media/closeMenu.png';
import mobileLogo from '../media/mobileLogo.png';
import mobileStyle from '../mobileCss/MobileSidebar.module.css';

export default function MobileSidebar({ isLogged }) {
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const navigate = useHistory();
  const isSignup = window.location.pathname.toLowerCase() === '/signup';

  const navToPage = (item) => {
    if (!isLogged) {
      return window.alert('You are logged out. Please log in!');
    }

    if (item === 0) navigate.push('/newmovies');
    else if (item === 1) navigate.push('/library');
    else if (item === 2) navigate.push('/settings');
    else if (item === 3) {
      localStorage.removeItem('token');
      localStorage.removeItem('fayeproject');
      localStorage.removeItem('username');

      setTimeout(() => {
        window.location.href = '/login';
      }, 1000);
    }

    setOpenBurgerMenu(false);
  };

  const List = () => (
    <Box className={mobileStyle.innerWrapp} role="presentation" onClick={() => setOpenBurgerMenu(false)} onKeyDown={() => setOpenBurgerMenu(false)}>
      <Box className={mobileStyle.innerMenu}>
        <img src={mobileLogo} alt="mobile faye logo" />
        <img src={closeMenu} alt="close button" style={{ height: '24px' }} onClick={() => setOpenBurgerMenu(false)} />
      </Box>

      <Box>
        {['Movies', 'Asset Library', 'Settings'].map((text, index) => (
          <div
            style={{ padding: '18px', cursor: 'pointer', marginTop: index === 0 ? '38px' : '0px' }}
            onClick={(e) => {
              e.stopPropagation();
              navToPage(index);
            }}
          >
            <p style={{ paddingLeft: '20px', color: 'white', fontWeight: 'bold', fontSize: '18px' }}>{text}</p>
          </div>
        ))}
      </Box>

      <Box style={{ width: '100%', padding: '15px' }}>
        <hr style={{ height: '3px', width: '100%', color: 'white' }} />
      </Box>

      {isLogged && (
        <Box
          style={{ padding: '18px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            navToPage(3);
          }}
        >
          <p style={{ paddingLeft: '20px', color: 'white', fontWeight: 'bold', fontSize: '18px' }}>Log out</p>
        </Box>
      )}

      {!isLogged && isSignup && (
        <Box
          style={{ padding: '18px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenBurgerMenu(false);
            navigate.push('/login');
          }}
        >
          <p style={{ paddingLeft: '20px', color: 'white', fontWeight: 'bold', fontSize: '18px' }}>Login</p>
        </Box>
      )}

      {!isLogged && !isSignup && (
        <Box
          style={{ padding: '18px', cursor: 'pointer' }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenBurgerMenu(false);
            navigate.push('/signup');
          }}
        >
          <p style={{ paddingLeft: '20px', color: 'white', fontWeight: 'bold', fontSize: '18px' }}>Sign Up</p>
        </Box>
      )}
    </Box>
  );

  return (
    <div className={mobileStyle.mobileSidebarWrapp} style={{ height: !isLogged && '52px' }}>
      <div className={mobileStyle.headerWrapp}>
        <img src={mobileLogo} alt="mobile faye logo" />
        <img src={burgerMenu} alt="burger menu" onClick={() => setOpenBurgerMenu(true)} style={{ height: '24px' }} />
      </div>

      <>
        <Drawer open={openBurgerMenu} onClose={() => setOpenBurgerMenu(false)}>
          <List />
        </Drawer>
      </>
    </div>
  );
}
