// import { useState } from 'react';
import previewQuestion from './previewQuestion.module.css';
import QuestionPreviewDetails from './questionPreviewDetails/QuestionPreviewDetails';

const PreviewQuestion = ({ state, typeId, selectedQuestion, setSelectedQuestion, setVisibleLowerThan, setVisibleGreaterThan }) => {
    const checkVisibility = (index) => {
    if (index === 0) {
      setVisibleGreaterThan(true);
      setVisibleLowerThan(false);
    } else if (index > 0 && index < state?.step1.questions[typeId - 1].length - 1) {
      setVisibleGreaterThan(true);
      setVisibleLowerThan(true);
    } else {
      setVisibleGreaterThan(false);
      setVisibleLowerThan(true);
    }
  };

  const onChangeRadio = (index) => {
    checkVisibility(index);
    setSelectedQuestion(index);
  };
  return (
    <article className={previewQuestion.preivewBoxWrapp}>
      <div className={previewQuestion.headerWrapp}>
        <p className={previewQuestion.headerPara}>
          {selectedQuestion + 1} of {state?.step1.questions[typeId - 1].length}
        </p>
      </div>

      <div className={previewQuestion.mainPreviewQuests}>

        {/* left */}
        <div className={previewQuestion.mainPreviewLeft}>
          <div className={previewQuestion.mainPreviewLeftInner}>
            {state?.step1.questions[typeId - 1].map((item, index) => {
              return (
                <div key={item.id} className={previewQuestion.itemQuestion}>
                  <label htmlFor="quest">Question{index + 1}</label>
                  <input id="quest" name="quest" type="radio" value={index} onChange={() => onChangeRadio(index)} checked={selectedQuestion === index} />
                </div>
              );
            })}
          </div>
        </div>

        {/* right */}
        <div className={previewQuestion.mainPreviewRight}>
          <QuestionPreviewDetails selectedQuestion={selectedQuestion} state={state} typeId={typeId} />
        </div>
      </div>
    </article>
  );
};

export default PreviewQuestion;
PreviewQuestion.defaultProps = {
  state: {}
};
