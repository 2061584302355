import { makeStyles } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { toast } from "react-toastify";
import authService from '../../../services/apiServices/authService';

const styleShareDialog = makeStyles({
    shareMovieDialogText: {
      fontFamily: "poppins",
      fontSize: "13px",
      opacity: "50%",
      marginTop: "20px",
      marginBottom: "15px",
      height: "20px"
    },
    shareMovieTextField: {
      backgroundColor: "#262338",
      borderRadius: "15px",
      width: "100%",
      height: "43px",
      border: "none",
      padding:'7px',
      marginTop: "8px",
      color: "white",
      '& input':{
         marginLeft: "15px", height: "23px", paddingTop: "12px", fontFamily: "poppins" }
    },
    shareMovieTextarea: {
      backgroundColor: "#262338",
      borderRadius: "15px",
      width: "100%",
      height: "93px",
      border: "none",
      marginTop: "8px",
      color: "white",
      marginTop:'20px',
      padding: '7px',
      '& p':{ marginLeft: "15px", height: "23px", paddingTop: "12px", fontFamily: "poppins" }
    },
    imgWrapp:{width:'48px', height:'48px', position:'relative'},
    image:{width:'100%', height:'100%', borderRadius:'50%'},
    delImg:{fontSize:'10px', fontWeight:'bold', borderRadius:'50%', padding:'5px', backgroundColor:'red', position:'absolute', right:'-5px', top:'-5px'},
    ulistWrapp:{width:'100%', backgroundColor:'#262338', marginTop:'10px', padding:'10px', borderRadius:'10px'},
    ulistHeader: {width:'100%', display:'flex', justifyContent:'space-between'},
    searchWrapp:{width:'40%', zIndex:'40'},
    searchWrappInner: {width:'100%',position:'relative', margin:'10px 0' },
    searchInp:{color:'white', backgroundColor:'#262338', border:'1px solid white', width: '100%', height:'40px', borderRadius:'10px', outline:'none', paddingLeft:'30px'},
    iconSearch:{position:'absolute', left:'5px', top:'7px', color:'white'},
    saveBtnWrapp:{width:'100%', marginTop:'30px', padding:'5px', display:'flex', justifyContent:'flex-end'},
    saveBtn: {width:'26%', backgroundColor:'#A996FF', padding:'15px 25px', borderRadius:'10px', borderColor:'#A996FF', color:'#5312B5', fontWeight:'bold', fontSize:'1.15rem'},
    listWrappUsers:{
        padding:'6px',
        minHeight:'10px',
        maxHeight:'400px',
        overflow:'auto',
        '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
  });

const InviteModal = ({data, setData, setOpenInviteModal}) => {
    const [inviteEmail, setInviteEmail] = useState('');
    const classes = styleShareDialog();
 
    const inviteUser = () => {

      if (!/\S+@\S+\.\S+/.test(inviteEmail.trim())) {
        toast.error("Email is not correct", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }
      
        const {req} = authService.inviteUser(inviteEmail.trim());

        req
          .then( res => { 
            if(res.data.success){
              setData([...data, res.data.data])
              window.gr('track', 'conversion', { email: inviteEmail.trim() });
            }
            setOpenInviteModal(false);
          })
          .catch(err => console.log("erri " + err))
    }

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', top:0, left:0, right:0, bottom: 0, zIndex:'20'}}>
            <div onClick={()=>setOpenInviteModal(false)} style={{display:'flex', justifyContent:'center', alignItems:'center', position:'fixed', top:0, left:0, right:0, bottom: 0, backgroundColor:'rgba(0,0,0, 0.92)', zIndex:'30'}}></div>
            <div className={classes.searchWrapp}>
                <h2 style={{color:'white', textAlign:'center',padding:'10px 0'}}>Invite New User</h2>
                    <div className={classes.searchWrappInner}>
                        <input type='email' onChange={(e)=>setInviteEmail(e.target.value)} className={classes.searchInp} placeholder='Enter email you want to invite...' required/>
                        <SearchIcon className={classes.iconSearch} /> 
                    </div>  
                    <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', marginTop:'12px'}}>
                      <button onClick={inviteUser} style={{padding: '10px 29px', backgroundColor:'#A996FF', color:'white', borderRadius:'7px'}} type='button'>Invite</button>
                    </div>  
            </div>
   </div>
  )
}

export default InviteModal