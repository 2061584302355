import { useRef } from 'react';
import deleteIcon from '../media/delete.png';
import styleSingleQuestion from './singleQuestionBox.module.css';

const SingleQuestionBox = ({ typeId, questionTitle, index, state, dispatch, questionStep, setDefinedQuestions, definedQuestions }) => {
  let input = useRef(null);

  const breakLineAfter27Chars = (value, e) => {
    let newValue = value.length % 27 === 0 && e.nativeEvent.inputType === 'insertText' ? value + '\n' : value;
    return newValue;
  };

  const updateStateStepTwo = (e) => {
    if (index === 0) {
      dispatch({ type: 'UPDATE_MESSAGE', payload: input.current.value });
    } else {
      dispatch({ type: 'UPDATE_INSTRUCTIONS', payload: input.current.value });
    }
  };

  const onChangeValues = (e) => {
    if (questionStep === 1) {
      dispatch({ type: 'UPDATE_VALUE', payload: { ...questionTitle, value: breakLineAfter27Chars(input.current.value, e), typeId: typeId } });
    } else {
      updateStateStepTwo(e);
    }
  };

  const deleteQuestion = () => {
    dispatch({ type: 'DELETE_QUESTION', payload: questionTitle?.id, typeId: typeId });
  };

  return (
    <div className={styleSingleQuestion.questionBox}>
      <p className={styleSingleQuestion.questionParagraph}>{!questionTitle?.id ? questionTitle : `Question${index + 1}`}</p>
      <div className={styleSingleQuestion.txtAreaWrapp}>
        <textarea className={`${styleSingleQuestion.txtArea}`} ref={input} value={questionStep === 1 ? questionTitle?.value : index === 0 ? state?.step2?.msg : state.step2.instructions} onChange={onChangeValues}>
          {questionTitle?.value}
        </textarea>
        {questionStep === 1 && <img className={styleSingleQuestion.imgtxtArea} onClick={deleteQuestion} src={deleteIcon} alt="delete" />}
      </div>
    </div>
  );
};

export default SingleQuestionBox;
SingleQuestionBox.defaultProps = {
  questionTitle: { id: Math.random() + Math.random(), name: 'Question' },
  index: 0,
  questionStep: 1
};
