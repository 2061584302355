import { Grid, makeStyles } from '@material-ui/core';
import { useRef, useState } from 'react';
import Avatar from 'react-avatar';
import { toast } from 'react-toastify';
import projectApi from '../../../services/apiServices/projects/projectApi';
import ButtonFab from '../../../styled/buttons/ButtonFab';

const styleAccountSettings = makeStyles({
  settAccWrapp: { padding: '10px' },
  imageWrapp: { display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' },
  imageProf: { maxWidth: '100%', maxHeight: '100%', minHeight: '73%', marginTop: '1x', clipPath: 'circle()' },
  imageWrappInner: { height: '150px', clipPath: 'circle()', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  textChange: { textDecoration: 'underline', fontSize: '12px', color: '#7E7C88' },
  nameWrapp: { display: 'flex', flex: 2, flexDirection: 'column', alignItems: 'flex-start' },
  fnameWrapp: { display: 'flex', flexDirection: 'column', width: '100%' },
  names: { marginLeft: '15px', fontSize: '12px', color: '#7E7C88', fontWeight: 'bold', marginBottom: '4px' },
  inpField: { height: '50px', borderRadius: '15px', padding: '10px', backgroundColor: '#262338', color: 'white', border: '1px solid #262338', marginBottom: '5px' },
  restInps: { display: 'flex', flexDirection: 'column', width: '100%', marginTop: '10px' },
  saveBtn: {
    width: '50%',
    height: '50px',
    backgroundColor: '#A996FF',
    borderRadius: '12px',
    color: '#4B457A',
    fontWeight: 'bold',
    borderColor: '#A996FF',
    outline: 'none',
    fontSize: '1.05rem',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  saveBtnWrapp: { display: 'flex', justifyContent: 'flex-end', width: '90%', marginTop: '20px' },
  logOutBtnWrapp: { display: 'flex', justifyContent: 'flex-start', width: '90%', marginTop: '20px' }
});

const SettingsAccount = ({ isMobile, insteadDateImg, userDetail, setUserDetail, userDetailUnchanged, profilePic, setProfilePic }) => {
  const cssStyle = styleAccountSettings();
  const settingAccUploadImage = useRef(null);

  const [fileData, setFileData] = useState('');

  const openInput = () => {
    settingAccUploadImage.current.click();
  };

  const removeLocalSt = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('fayeproject');
    localStorage.removeItem('username');
    window.location.href = '/login';
  };

  const uploadImage = async (e) => {
    e.stopPropagation();

    let file = e.target.files[0];
    let fileType;

    if (!!file) {
      fileType = file.type.split('/');
    } else {
      alert('File error ocurred!');
    }

    if (fileType[0] === 'image') {
      setFileData(file);

      let dataEdx = new FormData();

      dataEdx.append('firstname', userDetailUnchanged.firstName);
      dataEdx.append('lastname', userDetailUnchanged.lastName);
      dataEdx.append('company', userDetailUnchanged.name);
      dataEdx.append('email', userDetailUnchanged.email);
      dataEdx.append('oldEmail', userDetailUnchanged.email);
      dataEdx.append('File', file);

      const { axiosReq } = projectApi.uploadImageSettingsAccount(dataEdx);
      await axiosReq
        .then((res) => {
          if (res.data.success === true) {
            setUserDetail(res.data.data);
            setProfilePic(res.data.data.imageUrl);
            toast.success('Photo succesfully changed!', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }
        })
        .catch(() => {
          toast.error('Photo change failed!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        });
    } else {
      alert('Please upload an image!');
    }
  };

  const checkIsChangedData = () => {
    let isChanged = false;

    if (userDetail.firstName !== userDetailUnchanged.firstName) {
      isChanged = true;
    } else if (userDetail.lastName !== userDetailUnchanged.lastName) {
      isChanged = true;
    } else if (userDetail.name !== userDetailUnchanged.name) {
      isChanged = true;
    } else if (userDetail.email !== userDetailUnchanged.email) {
      isChanged = true;
    } else if (fileData !== '') {
      isChanged = true;
    }

    return isChanged;
  };

  const saveBtn = async () => {
    const trimFirstName = userDetail.firstName.trim();
    const trimLastName = userDetail.lastName.trim();

    let chang = checkIsChangedData();

    if (!chang) {
      alert('Data is not changed!');
    } else if (trimLastName.length <= 0 || trimFirstName.length <= 0) {
      toast.error('First name or last name must not be empty!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setUserDetail(userDetailUnchanged);
    } else {
      var dataEd = new FormData();

      dataEd.append('firstname', userDetail.firstName);
      dataEd.append('lastname', userDetail.lastName);
      dataEd.append('company', userDetail.name);
      dataEd.append('email', userDetail.email);
      dataEd.append('oldEmail', userDetailUnchanged.email);
      dataEd.append('File', fileData);

      const { axiosReq } = projectApi.uploadImageSettingsAccount(dataEd);
      axiosReq.then((res) => {
        if (res.data.success === true) {
          localStorage.setItem('username', res.data.data.email);
          setUserDetail(res.data.data);
          toast.success('Data succesfully changed!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      });
    }
  };

  return (
    <Grid item xs={12} sm={8} lg={5} md={6} className={cssStyle.settAccWrapp} style={{ marginTop: isMobile && '-20px' }}>
      <Grid item xs={12} sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <div className={cssStyle.imageWrapp}>
          <div className={cssStyle.imageWrappInner}>
            <Avatar className={cssStyle.imageProf} round="true" src={profilePic} name={userDetail.firstName + ' ' + userDetail.lastName} />
          </div>

          <p onClick={openInput} className={cssStyle.textChange} style={{ marginTop: isMobile && '-18px' }}>
            Change photo
          </p>
          <input type="file" onChange={uploadImage} ref={settingAccUploadImage} style={{ display: 'none' }} />
        </div>

        <div className={cssStyle.nameWrapp}>
          <div className={cssStyle.fnameWrapp}>
            <label className={cssStyle.names}>First name</label>
            <input onChange={(e) => setUserDetail({ ...userDetail, firstName: e.target.value })} value={userDetail.firstName} type="text" className={cssStyle.inpField} />
          </div>

          <div className={cssStyle.fnameWrapp}>
            <label className={cssStyle.names}>Last name</label>
            <input value={userDetail.lastName} onChange={(e) => setUserDetail({ ...userDetail, lastName: e.target.value })} type="text" className={cssStyle.inpField} />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12} style={{ display: 'flex', flexDirection: 'column' }}>
        <div className={cssStyle.restInps}>
          <label className={cssStyle.names}>E-mail</label>
          <input disabled value={userDetail.email} onChange={(e) => setUserDetail({ ...userDetail, email: e.target.value })} type="text" className={cssStyle.inpField} />
        </div>

        <div className={cssStyle.restInps}>
          <label className={cssStyle.names}>Job title</label>
          <input disabled value={userDetail.name} onChange={(e) => setUserDetail({ ...userDetail, name: e.target.value })} type="text" className={cssStyle.inpField} />
        </div>
        <div style={{ display: 'flex', justifyContent: isMobile ? 'flex-end' : 'space-between', width: '100%', paddingTop: isMobile && '30px' }}>
          {!isMobile && <ButtonFab tittle="Log out" onClickHandler={removeLocalSt} />}
          <ButtonFab tittle="Save" onClickHandler={saveBtn} />
        </div>
      </Grid>
    </Grid>
  );
};

export default SettingsAccount;
