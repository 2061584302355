import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Modal from '@mui/material/Modal';
import { useEffect, useState } from 'react';
import projectApi from '../../services/apiServices/projects/projectApi';
import HeaderAsset from '../header/HeaderAsset';
import SubLinks from '../library/assetSection/SubLinks';
import FilterLeft from '../library/assetSection/filterAsset/FilterLeft';
import ExportsList from './ExportsList';
import UploadMovie from './elements/UploadMovie';
import ButtonAddNewChannel from './elements/channel/ButtonAddNewChannel';
import Channels from './elements/channel/Channels';
import searchIcon from './media_movie/Search.png';

const newMoviesPage = makeStyles({
  libraryWrapp: {
    marginLeft: '0px',
    marginRight: '4%',
    color: 'white',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width: 360px)']: {
      marginRight: '0px'
    },
    ['@media (max-width: 451px)']: {
      marginLeft: '4%'
    },
    ['@media (min-width: 960px)']: {
      marginLeft: '0px'
    }
  }
});

const NewMoviesPage = ({ triggerNotifications, setTriggerNotifications, isMobile }) => {
  const allTabs = ['All Movies', 'Channels'];

  const cssStyle = newMoviesPage();
  const [activeBtnHeader, setActiveBtnHeader] = useState(0);
  const [movies, setMovies] = useState([]);
  const [channels, setChannels] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState(null);
  const [moviesPage, setMoviesPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [selectedMoreId, setSelectedMoreId] = useState('');
  const [searchInputMobileModal, setSearchInputMobileModal] = useState(false);
  const [channelID, setChannelID] = useState(null);

  const GetNewMovies = (timeout, page) => {
    const searchTimeout = setTimeout(() => {
      const { axiosReq, cancelTokenSource } = projectApi.getNewMovies(page, 12, searchTerm, channelID);

      axiosReq.then((resp) => {
        if (resp.data.success === true) {
          setMovies(resp.data.data);

          setTotalPages(Math.ceil(resp.data.totalCount / 12));
        }
      });
    }, timeout);

    return searchTimeout;
  };

  const GetChannels = (timeout, page) => {
    const searchTimeout = setTimeout(() => {
      const { axiosReq, cancelTokenSource } = projectApi.getChannels(page, 12, searchTerm);

      axiosReq.then((resp) => {
        if (resp.data.success === true) {
          setChannels(resp.data.data);

          setTotalPages(Math.ceil(resp.data.totalCount / 12));
        }
      });
    }, timeout);

    return searchTimeout;
  };

  useEffect(() => {
    if (searchTerm === null) return;

    let searchTimeout = activeBtnHeader === 0 ? GetNewMovies(1100, moviesPage) : GetChannels(1100, moviesPage);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [searchTerm, activeBtnHeader]);

  useEffect(() => {
    let searchTimeout = activeBtnHeader === 0 ? GetNewMovies(200, moviesPage) : GetChannels(200, moviesPage);

    return () => {
      clearTimeout(searchTimeout);
    };
  }, [moviesPage, activeBtnHeader, triggerNotifications]);

  return (
    <div className={cssStyle.libraryWrapp}>
      {!isMobile ? (
        <HeaderAsset title="Library" setSearchTerm={setSearchTerm} isMobile={isMobile}></HeaderAsset>
      ) : (
        <Grid style={{ width: '100%', padding: '5px', marginTop: '75px' }}>
          <Box style={{ color: 'white', fontFamily: 'Poppins', fontWeight: 700 }}>
            <h4>Library</h4>
          </Box>

          <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '5px' }}>
            <div style={{ position: 'relative' }}></div>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              {searchInputMobileModal && (
                <input
                  type="text"
                  autoFocus
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{
                    marginRight: '5px',
                    fontSize: '14px',
                    height: '32px',
                    color: 'white',
                    paddingLeft: '6px',
                    backgroundColor: '#262237',
                    borderRadius: '10px',
                    border: '1px solid #262237',
                    outline: '3px solid violet'
                  }}
                />
              )}
              <img
                src={searchIcon}
                alt="search"
                onClick={() => {
                  setSearchInputMobileModal((prev) => !prev);
                }}
              />
            </div>
          </Box>
        </Grid>
      )}

      <div style={{ width: '100%', display: 'flex', justifyContent: isMobile ? 'center' : 'space-between', marginLeft: isMobile && '-5px' }}>
        {isMobile ? (
          <SubLinks activeSubLibTab={activeBtnHeader} subLinks={allTabs} setActiveSubLibTab={setActiveBtnHeader} />
        ) : (
          <>
            <SubLinks activeSubLibTab={activeBtnHeader} subLinks={allTabs} setActiveSubLibTab={setActiveBtnHeader} />
            <Grid item xs={12} sm={12} lg={6} md={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {!isMobile && activeBtnHeader === 0 && <UploadMovie setTriggerNotifications={setTriggerNotifications}/>}
              {!isMobile && activeBtnHeader !== 0 && <ButtonAddNewChannel setTriggerNotifications={setTriggerNotifications} />}
            </Grid>
          </>
        )}
      </div>

      <FilterLeft totalPages={totalPages} setSkip={setMoviesPage} skip={moviesPage} />

      {activeBtnHeader === 0 && (
        <ExportsList isMobile={isMobile} movies={movies} setOpenModal={setOpenModal} setVideoUrl={setVideoUrl} setMovies={setMovies} selectedMoreId={selectedMoreId} setSelectedMoreId={setSelectedMoreId} />
      )}
      {activeBtnHeader === 1 && <Channels channels={channels} setTriggerNotifications={setTriggerNotifications} setActiveBtnHeader={setActiveBtnHeader} setChannelID={setChannelID} />}

      {/* {isMobile && <MobilePaginate clipsCount={totalPages} setSkip={setMoviesPage} skip={moviesPage} />} */}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video controls width={'70%'}>
          <source src={videoUrl} />
        </video>
      </Modal>
    </div>
  );
};

export default NewMoviesPage;
NewMoviesPage.defaultProps = {
  triggerNotifications: false,
  isMobile: false,
  setTriggerNotifications: () => {}
};
