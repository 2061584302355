import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const AssetTagDialog = ({openTagDialogFor, setOpenTagDialogFor, setNewTag, addTagToAsset}) => {
  return (
    <Dialog open={!!openTagDialogFor} onClose={() => setOpenTagDialogFor(null)}>
        <DialogTitle>Add tag</DialogTitle>
        <DialogContent>
          <input autoFocus onChange={(e) => setNewTag(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTagDialogFor(null)}>Cancel</Button>
          <Button onClick={() => addTagToAsset()}>Add</Button>
        </DialogActions>
      </Dialog>
  )
}

export default AssetTagDialog
AssetTagDialog.defaultProps = {
  openTagDialogFor: false, 
  setOpenTagDialogFor: () => {} , 
  setNewTag: () => {}, 
  addTagToAsset: () => {}
}