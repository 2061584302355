import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import ButtonsStepTwo from '../step2/buttons/ButtonsStepTwo';
import ButtonsStepThree from '../step3/buttons/ButtonsStepThree';
import stepStartStyle from '../stepStart/stepStart.module.css';

const FabButton = styled(Fab)(({ theme }) => ({
  width: '155px',
  fontSize: '18px',
  height: '50px',
  borderRadius: '12px',
  backgroundColor: '#A996FF',
  color: '#4700AB',
  fontWeight: 'bold',
  borderColor: '#A996FF',
  outline: 'none',
  fontSize: '17px',
  '&:hover': {
    backgroundColor: '#c9befa',
    color: '#4700AB'
  }
}));

const StepQuestion = ({ questionStep, setQuestionSteps, state, dispatch, typeId, setOpenQuestion }) => {
  const shareLink = state?.stepQuestion?.shareLink;
  return (
    <div className={stepStartStyle.questionBox}>
      <p className={stepStartStyle.questionParagraph}>Email</p>
      <div className={stepStartStyle.txtAreaWrapp} style={{}}>
        <input className={stepStartStyle.txtArea + ' emailInput'} value={state?.stepQuestion?.email} onChange={(e) => dispatch({ type: 'UPDATE_EMAIL', payload: e.target.value })} placeholder="Email" />
      </div>

      <p className={stepStartStyle.questionParagraph}>Share link</p>
      <div className={stepStartStyle.txtAreaWrapp} style={{}}>
        <input
          className={stepStartStyle.txtArea}
          style={{ width: '70%', fontSize: '12px' }}
          value={!shareLink ? '' : 'https://faye-testimonials-prod.azurewebsites.net/' + shareLink}
          placeholder="Add email to copy link"
          disabled
        />

        <CopyToClipboard key={'https://faye-testimonials-prod.azurewebsites.net/' + shareLink} text={'https://faye-testimonials-prod.azurewebsites.net/' + shareLink}>
          <FabButton
            type="button"
            disabled={!shareLink}
            style={{ cursor: !shareLink ? 'not-allowed' : 'pointer' }}
            onClick={() => {
              toast.success('Link copied to clipboard', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
            }}
          >
            <span>Copy Link</span>
          </FabButton>
        </CopyToClipboard>
      </div>

      {questionStep === 3 && <ButtonsStepTwo setQuestionSteps={setQuestionSteps} state={state} typeId={typeId} questionStep={questionStep} dispatch={dispatch} setOpenQuestion={setOpenQuestion} />}
      {questionStep === 4 && <ButtonsStepThree setQuestionSteps={setQuestionSteps} dispatch={dispatch} />}
    </div>
  );
};

export default StepQuestion;
StepQuestion.defaultProps = {
  questionStep: 3,
  setQuestionSteps: () => {},
  state: [],
  dispatch: () => {},
  typeId: 1
};
