import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_BASE_URL;
const getAssetUrl = '/api/Assets/list';
const getAllScenesUrl = '/api/Scene/all-scenes';
const uploadAssetUrl = '/api/File/upload';
const updateAssetUrl = '/api/Assets/update-asset/';
const updateLibScenesUrl = '/api/Scene/update-scenes/';

const getAssets = (Type, Skip = 0, Take = 20, Search = '') => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  let apiUrl = getAssetUrl;
  if (Type === 'scenes') {
    apiUrl = getAllScenesUrl;
  }

  return {
    req: axios.get(baseUrl + apiUrl, {
      params: {
        Type: Type,
        Skip: Skip,
        Take: Take,
        Search: Search
      },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const uploadAssets = (Files, setUploadProgress) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios({
      method: 'post',
      url: baseUrl + uploadAssetUrl,
      data: Files,
      cancelToken: source.token,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token
      },
      onUploadProgress(progressEvent) {
        let percentage = Number(((progressEvent.loaded / progressEvent.total) * 50).toFixed(2));

        setUploadProgress(percentage);
      }
    }),
    cancelTokenSource: source
  };
};

let connection;
const subscribeToLibraryHub = async (user, room, setUploadProgress) => {
  connection = new HubConnectionBuilder()
    .withUrl(baseUrl + '/progressupload', {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .configureLogging(LogLevel.Error)
    .build();

  connection.on('ProgressIndicatorFromHub', (percentage) => {
    if (!!percentage) {
      let num = Number((50 + percentage / 2).toFixed(2));

      if (num === 100) {
        num = 99.99;
      }
      setUploadProgress(num);
    }
  });

  await connection.start();
  await connection.invoke('SubscribeLibraryUpload', { user, room });
};

let unsubscribeToLibraryHub = async () => {
  if (!!connection) {
    await connection.stop();
  }
};

const updateAsset = (data) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.put(baseUrl + updateAssetUrl, data, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const updateLibraryScenes = (data) => {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.put(baseUrl + updateLibScenesUrl, data, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  getAssets,
  uploadAssets,
  subscribeToLibraryHub,
  unsubscribeToLibraryHub,
  updateAsset,
  updateLibraryScenes
};
