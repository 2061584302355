import SuccessSignup from './SuccessSignup';
import signupClass from './SuccessSignup.module.css';

const FullSignupModal = ({ closeConfirmSignUp, email }) => {
  return (
    <div className={signupClass.succSignWrapp}>
      <SuccessSignup closeConfirmSignUp={closeConfirmSignUp} email={email} />
      <div className={signupClass.fadeSuccOverlay}></div>
    </div>
  );
};

export default FullSignupModal;
