import React from 'react'

const ddStyle = {
    width: '229px',
    height: '34px',
    backgroundColor: '#262338',
    color: 'white',
    borderRadius: '7px',
    padding: '0 5px'
}

const Dropdown = ({firstItem}) => {
  return (
    <select style={ddStyle}>
        <option>{firstItem}</option>
    </select>
  )
}

export default Dropdown