import noThumb from '../../../files/NoThumbnail.jpg';
import image_loader from '../media_movie/img_loader.gif';

const MoviePageThumbnailImage = ({ cssStyle, data, thumbnailUrl, openPreview }) => {
  return (
    <img
      className={`${cssStyle.channelThumb} ${cssStyle.logoMovie} ${cssStyle.thubmnailSize}`}
      key={Date.now()}
      src={data.thumbnailExist === true || thumbnailUrl ? thumbnailUrl : noThumb}
      alt="Logo"
      onClick={(e) => {
        openPreview(e);
      }}
      style={{
        wdith: '100%',
        height: '100%',
        backgroundImage: `url(${image_loader})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    />
  );
};

export default MoviePageThumbnailImage;
MoviePageThumbnailImage.defaultProps = {
  cssStyle: {},
  data: {},
  image_loader: false,
  openPreview: () => {}
};
