import { makeStyles } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import { useState } from 'react';

import checkIcon from '../../../../../../files/icons/checkmark.png';

const stylesStep = makeStyles({
  wrappModal: { width: '100%' },
  headAdd: { width: '100%', position: 'relative' },
  searchInput: {
    width: '99%',
    height: '40px',
    borderRadius: '10px',
    backgroundColor: '#262338',
    border: '1px solid #262338',
    outline: 'none',
    color: 'white',
    paddingLeft: '41px',
    '&::placeholder': { color: 'white', opacity: '0.5' }
  },

  unchecked: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: '#262338', cursor: 'pointer', position: 'absolute', top: 5, left: 5 },
  uncheckedWidget: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: 'white', cursor: 'pointer', position: 'absolute', top: 5, left: 5 },
  searchIcon: { position: 'absolute', top: '10px', color: 'white', opacity: '0.5', left: '15px' },
  moviesWrapp: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    position: 'relative',
    marginTop: '20px',
    maxHeight: '300px',
    overflow: 'auto',
    justifyContent: 'space-between',
    padding: '0px 10px 0px 0px'
  },
  movie: { width: '135px', height: '75px', position: 'relative', borderRadius: '10px', marginBottom: '22px', color: 'white' },
  movieImg: { width: '100%', height: '100%', borderRadius: '10px', maxHeight: '77px' },
  durationMovie: { position: 'absolute', bottom: '-15px', right: '6px', color: 'white' },
  movieName: { textOverflow: 'ellipsis', overflow: 'hidden' },
  header: { fontWeight: 700, color: 'white' }
});

const SingleMovie = ({ singleMovieItem, handleCheck, isChecked = false }) => {
  const [checked, setChecked] = useState(isChecked);
  const classes = stylesStep();

  return (
    <div className={classes.movie}>
      {checked ? (
        <img
          src={checkIcon}
          alt="checkmark"
          className={classes.unchecked}
          onClick={() => {
            handleCheck(false, singleMovieItem.id);
            setChecked(false);
          }}
        />
      ) : (
        <div
          className={classes.uncheckedWidget}
          onClick={() => {
            handleCheck(true, singleMovieItem.id);
            setChecked(true);
          }}
        />
      )}
      <div>
        <img src={singleMovieItem.thumbnailPath} alt="cover" className={classes.movieImg} />
      </div>
      <p className={classes.durationMovie}>{new Date(singleMovieItem.duration * 1000).toISOString().substr(14, 5)}</p>
      <div className={classes.movieName}>{singleMovieItem.name}</div>
    </div>
  );
};

const AddMovieStep = ({ movies, dispatch, checkedIds = [] }) => {
  const classes = stylesStep();
  const [search, setSearch] = useState('');

  const handleCheck = (value, id) => {
    dispatch({ type: 'UPDATE_IDS', payload: { value: value, id: id } });
  };

  return (
    <article className={classes.wrappModal}>
      <div>
        <h1 className={classes.header}>Add movies</h1>
      </div>
      <div className={classes.headAdd}>
        <input type="text" className={classes.searchInput} placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
        <SearchIcon className={classes.searchIcon} />
      </div>

      <div className={classes.moviesWrapp}>
        {movies
          .filter((x) => x.name.includes(search))
          .map((item) => (
            <SingleMovie key={item.id} handleCheck={handleCheck} singleMovieItem={item} isChecked={checkedIds.includes(item.id)} />
          ))}
      </div>
    </article>
  );
};

export default AddMovieStep;
AddMovieStep.defaultProps = {
  movies: [], 
  dispatch: () => {}, 
  checkedIds: []
}