import ButtonFab from '../../../styled/buttons/ButtonFab';
import useFeedback from '../customs/useFeedback';

const SettingsFeedback = () => {
  const { text, setText, styles, sendFeedbackMsg } = useFeedback();

  return (
    <section className={styles.feebackWrapp}>
      <h3>If you have any feeback, please let us know</h3>
      <textarea className={styles.textArea} onChange={(e) => setText(e.target.value)} value={text}></textarea>
      <ButtonFab tittle="Send" onClickHandler={sendFeedbackMsg} />
    </section>
  );
};

export default SettingsFeedback;
