import axios from 'axios';
const pexelUrl = 'https://api.pexels.com';

const pexelApiKey = '563492ad6f917000010000012fc82b92b7004ab989ef7812eb1ac893'; //process.env.REACT_APP_PEXEL_API_KEY;

const getPexelVideos = (searchKey, page) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let requestUrl = !!searchKey ? `${pexelUrl}/videos/search` : `${pexelUrl}/videos/popular`;

  return {
    axiosReq: axios.get(requestUrl, {
      params: {
        query: searchKey,
        per_page: 16,
        page: page
      },
      headers: {
        Authorization: pexelApiKey
      }
    }),
    cancelTokenSource: source
  };
};

const getPexelPhotos = (searchKey, page) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let requestUrl = !!searchKey ? `${pexelUrl}/v1/search` : `${pexelUrl}/v1/curated`;

  return {
    axiosReq: axios.get(requestUrl, {
      params: {
        query: searchKey,
        per_page: 12,
        page: page
      },
      headers: {
        Authorization: pexelApiKey
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  getPexelVideos,
  getPexelPhotos
};
