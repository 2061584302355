import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  app: {
    display: 'flex',
    width: '100%',
    backgroundColor: '#14142B',
    minHeight: '100vh'
  },
  editorWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
});
const useAppStyle = () => {
  const classes = useStyles();
  return { classes };
};

export default useAppStyle;
