import { forwardRef } from 'react';

const AttributesFontSelector = forwardRef(({ selectFontStyle, canvasFontFamily, setCanvasFontFamily, brandingFonts }, ref) => {
  return (
    <>
      <select name="fontFamily" className={selectFontStyle} style={{ fontFamily: canvasFontFamily, marginLeft: '10px' }} value={canvasFontFamily} onChange={(e) => setCanvasFontFamily(e.target.value)}>
        {brandingFonts.length > 0 && (
          <>
            <option style={{ fontWeight: 700, fontColor: 'white' }} disabled>
              Company fonts
            </option>

            {brandingFonts.map((x) => {
              return (
                <option key={x} value={x} style={{ fontFamily: x }}>
                  {x}
                </option>
              );
            })}
          </>
        )}
        <option style={{ fontWeight: 700, fontColor: 'white' }} disabled>
          Fonts
        </option>
        <option value="Arial" style={{ fontFamily: 'Arial' }}>
          Arial
        </option>
        <option value="Arial black" style={{ fontFamily: 'Arial black' }}>
          Arial Black
        </option>
        <option value="Bahnschrift" style={{ fontFamily: 'Bahnschrift' }}>
          Bahnschrift
        </option>
        <option value="Bahnschrift Condensed" style={{ fontFamily: 'Bahnschrift Condensed' }}>
          Bahnschrift Condensed
        </option>
        <option value="Bahnschrift Light" style={{ fontFamily: 'Bahnschrift Light' }}>
          Bahnschrift Light
        </option>
        <option value="Bahnschrift Light Condensed" style={{ fontFamily: 'Bahnschrift Light Condensed' }}>
          Bahnschrift Light Condensed
        </option>
        <option value="Bahnschrift Light SemiCondensed" style={{ fontFamily: 'Bahnschrift Light SemiCondensed' }}>
          Bahnschrift Light SemiCondensed
        </option>
        <option value="Bahnschrift SemiBold" style={{ fontFamily: 'Bahnschrift SemiBold' }}>
          Bahnschrift SemiBold
        </option>
        <option value="Bahnschrift SemiBold Condensed" style={{ fontFamily: 'Bahnschrift SemiBold Condensed' }}>
          Bahnschrift SemiBold Condensed
        </option>
        <option value="Calibri" style={{ fontFamily: 'Calibri' }}>
          Calibri
        </option>
        <option value="Cursive" style={{ fontFamily: 'Cursive' }}>
          Cursive
        </option>
        <option value="Helvetica" style={{ fontFamily: 'Helvetica' }}>
          Helvetica
        </option>
        <option value="Monospace" style={{ fontFamily: 'Monospace' }}>
          Monospace
        </option>
        <option value="Poppins" style={{ fontFamily: 'Poppins' }}>
          Poppins
        </option>
        <option value="Serif" style={{ fontFamily: 'Serif' }}>
          Serif
        </option>
        <option value="Tahoma" style={{ fontFamily: 'Tahoma' }}>
          Tahoma
        </option>
        <option value="Times New Roman" style={{ fontFamily: 'Times New Roman' }}>
          Times New Roman
        </option>
        <option value="Trebuchet" style={{ fontFamily: 'Trebuchet' }}>
          Trebuchet
        </option>
        <option value="Verdana" style={{ fontFamily: 'Verdana' }}>
          Verdana
        </option>
      </select>
    </>
  );
});

export default AttributesFontSelector;
