import { fabric } from 'fabric';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentTimePreviewContext } from '../../../../context/current_time_preview_context';

const TextStageCanvasPreview = ({ clip }) => {

  const [assetStyles, setAssetStyles] = useState({});
  const { currentTimePreview } = useContext(CurrentTimePreviewContext);  
    const [canvas, setCanvas] = useState(null);

  const [visibility, setVisibility] = useState('hidden');

  useEffect(() => {
    let c = new fabric.Canvas('screenpreview_' + clip.assetStyleID, { containerClass: 'canvas-container-full canvas-container-fullpreview-id-' + clip.assetStyleID });
    setCanvas(c);

    return () => {
      if (!!canvas && canvas._objects.length > 0) {
        canvas.clear();
        if (canvas._objects.length > 0) {
          canvas.dispose();
        }
        //
      }
      setCanvas(null);
    };
  }, []);

  useEffect(() => {
    if (canvas === null) return;

    let styles = {};
    if (!!clip.canvasStyle) {
      styles = JSON.parse(clip.canvasStyle);
    }

    let text = new fabric.IText(clip.asset.text?.replace("\\n", "\n"), {
      ...styles,
      ...{
        evented: false,
        selectable: false,
        editable: false,
        width: clip.w,
        height: clip.h,
        top: clip.y,
        left: clip.x
      }
    });
    canvas.setDimensions({ width: 786, height: 442 });
    canvas.add(text);

    canvas.renderAll();

  }, [canvas]);


  useEffect(() => {
    if (!!canvas && !!clip.canvasStyle) {
      let text = canvas.getObjects()[0];
      let clipCanvasStyles = JSON.parse(clip.canvasStyle);
  
      let result = Object.entries(clipCanvasStyles).map(([k, v]) => ({ [k]: v }));
      for (let i = 0; i < result.length; i++) {
        text.set(result[i]);
      }

      canvas.renderAll();
    }
  }, [clip]);

  useEffect(() => {
    if (currentTimePreview >= clip.start && currentTimePreview < clip.start + clip.length) {
      setVisibility('visible');
    } else {
      if (visibility == 'visible') {
        setVisibility('hidden');
      }
    }
  }, [currentTimePreview, clip.length, clip.start]);

 useEffect(() => {
    let container = document.querySelector('.canvas-container-fullpreview-id-' + clip.assetStyleID);
    if (!!container) {
      container.style.visibility = visibility;
    }
  }, [visibility]);

  let container = document.querySelector('.canvas-container-fullpreview-id-' + clip.assetStyleID);
  if (!!container) {
    container.style.zIndex = clip.z;
  }

   return (
    <div>
      <canvas data-z={clip.z} data-w={clip.w} data-h={clip.h} data-x={clip.x} data-y={clip.y} scene-id={clip.sceneID} stage-id={clip.assetStyleID} id={'screenpreview_' + clip.assetStyleID} />
    </div>
  );
};

export default TextStageCanvasPreview;
