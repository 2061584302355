import { makeStyles } from "@material-ui/core";

const headerAssetBtnStyles = makeStyles({
  buttonsWrapp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40%",
    borderRadius: "14px",
    padding: "6px",
    backgroundColor: "#262338",
    fontSize: "1.2rem",
    cursor: "pointer",
    height: "100%"
  },
  btnsGen: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    borderRadius: "10px",
    fontWeight: "bold"
  },

  inactiveBtn: {
    backgroundColor: "#262338",
    color: "#9093AC"
  },
  activeBtn: {
    backgroundColor: "#A996FF",
    color: "#262338"
  }
});

const HeaderAssetButtons = ({ btnNames, activeBtnHeader, setActiveBtnHeader, setTotalPages }) => {
  const cssHeaderBtnStyle = headerAssetBtnStyles();

  return (
    <div className={cssHeaderBtnStyle.buttonsWrapp}>
      {btnNames.map((item, ind) => {
        if (ind === activeBtnHeader) {
          return (
            <div key={ind} className={`${cssHeaderBtnStyle.activeBtn} ${cssHeaderBtnStyle.btnsGen}`} onClick={() => {
                setTotalPages(1)
                setActiveBtnHeader(ind)}
            }>
              {item}
            </div>
          );
        } else {
          return (
            <div key={ind} className={`${cssHeaderBtnStyle.inactiveBtn} ${cssHeaderBtnStyle.btnsGen}`} onClick={() => {
                setTotalPages(1)
                setActiveBtnHeader(ind)
            }}>
              {item}
            </div>
          );
        }
      })}
    </div>
  );
};

export default HeaderAssetButtons;
