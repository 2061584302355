import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';

const modalMovieTransaltionStyle = makeStyles({
  captionsBody: {
    width: '100%',
    margin: '3px 0',
    display: 'flex',
    borderBottom: '1px solid white',
    height: '95%',
    padding: '15px 0'
  },
  txtAreaWrapp: {
    width: '65%'
  },
  txtArea: {
    width: '100%',
    height: '105px',
    borderRadius: '12px',
    padding: '7px',
    outline: 'none',
    fontSize: '15px',
    backgroundColor: '#14142B',
    color: 'white',
    '&:focus': {
      outline: '3px solid #A996FF'
    }
  },
  innerTimerWrapp: {
    marginLeft: '10px',
    width: '20%',
    height: '95px',
    display: 'flex',
    alignItems: 'center'
  },
  innerTimerOuter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '90%',
    width: '100%'
  },
  innerTimer: {
    width: '90%',
    padding: '6px',
    backgroundColor: '#14142B',
    color: 'white',
    fontSize: '13px',
    borderRadius: '10px',
    textAlign: 'center'
  }
});

const SingleMoviePart = ({ data, handleSubtitleEdit }) => {
  const classes = modalMovieTransaltionStyle();
  const [sentence, setSentence] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');

  useEffect(() => {
    if (!!data) {
      setSentence(data.Sentence);
      setStart(data.StartSeconds);
      setEnd(data.EndSeconds);
    }
  }, [data]);

  useEffect(() => {
    if (!!sentence && !!start && !!end && (sentence !== data.Sentence || start !== data.StartSeconds || end !== data.EndSeconds)) {
      const timer = setTimeout(() => {
        let newData = Object.assign({}, data);
        newData.Sentence = sentence;
        newData.StartSeconds = start;
        newData.EndSeconds = end;
        handleSubtitleEdit(newData);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [sentence, start, end]);

  return (
    <div className={classes.captionsBody}>
      <div className={classes.txtAreaWrapp}>
        <textarea className={classes.txtArea} value={sentence} onChange={(e) => setSentence(e.target.value)}></textarea>
      </div>

      <div className={classes.innerTimerWrapp}>
        <div className={classes.innerTimerOuter}>
          <span className={classes.innerTimer}>{start}</span>
          <span className={classes.innerTimer}>{end}</span>
        </div>
      </div>
    </div>
  );
};

export default SingleMoviePart;

SingleMoviePart.defaultProps = {
  x: {
    Sentence: 'No sentence',
    StartSeconds: '00:00:31',
    EndSeconds: '00:01:00'
  },
  data: {}, 
  handleSubtitleEdit: () => {}
};
