import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { useEffect, useState } from 'react';
import movieService from '../../../../../services/apiServices/movieService';
import useChannel from '../useChannel';
import AddMovieStep from './steps/AddMovieStep';
import EmbedCode from './steps/EmbedCode';
import FirstStep from './steps/FirstStep';
import StepStart from './steps/StepStart';
import DoneBtn from './steps/buttons/DoneBtn';
import NextStepBtn from './steps/buttons/NextStepBtn';
import SaveButton from './steps/buttons/SaveButton';

export default function ModalChannel({ openModal, setOpenModal, setTriggerNotifications }) {
  const [steps, setSteps] = useState(0);
  const { state, dispatch } = useChannel();
  const [widgetLink, setWidgetLink] = useState('');

  const onNextHandler = () => {
    if (!state.stepStart?.channelTitle?.trim()) return alert('Title cannot be empty.');
    setSteps((prev) => prev + 1);
  };

  useEffect(() => {
    const { req, cancelTokenSource } = movieService.getMoviesForChannel();

    req.then((resp) => {
      if (resp.data.success === true) {
        dispatch({ type: 'ADD_MOVIES_LIST', payload: resp.data.data });
      }
    });
  }, []);

  const renderStep = () => {
    let stepRender;

    if (steps === 0) {
      stepRender = <StepStart channelTitle={state?.stepStart?.channelTitle} dispatch={dispatch} channelDescription={state?.stepStart?.channelDescription} />;
    } else if (steps === 1) {
      stepRender = <FirstStep mode={state?.firstStep?.mode} format={state?.firstStep?.format} dispatch={dispatch} />;
    } else if (steps === 2) {
      stepRender = <AddMovieStep movies={state?.addMovieStep?.movies} dispatch={dispatch} />;
    }

    return stepRender;
  };

  const save = () => {
    const channelData = {
      Name: state?.stepStart?.channelTitle,
      Description: state?.stepStart?.channelDescription,
      ChannelTypeID: state?.stepStart?.channelType,
      ChannelFormatID: state?.firstStep?.format,
      ChannelModeID: state?.firstStep?.mode,
      MovieIDs: state?.addMovieStep?.ids || []
    };

    if (channelData.MovieIDs.length === 0) return alert('Select movie(s)');

    const { axiosReq, cancelTokenSource } = movieService.saveChannel(channelData);
    axiosReq.then((res) => {
      if (res.data.success === true) {
        setTriggerNotifications((x) => !x);
        setWidgetLink(res.data.shareLink);
      }
    });
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          width: '740px',
          maxWidth: '90%',
          background: '#14142B',
          padding: '20px',
          borderRadius: '15px',
          fontFamily: 'Poppins'
        }
      }}
      open={openModal}
      onClose={() => setOpenModal(false)}
    >
      <DialogContent className="dialog-content">
        <IconButton
          onClick={() => setOpenModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 10,
            color: 'white'
          }}
        >
          <CloseIcon />
        </IconButton>
        {!widgetLink && (
          <>
            <Grid style={{ width: '100%' }}>{renderStep()}</Grid>
            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              {steps <= 1 && <NextStepBtn onClickHandler={onNextHandler} />}
              {steps === 2 && <SaveButton onClickHandler={save} />}
            </Grid>
          </>
        )}
        {widgetLink && (
          <>
            <EmbedCode widgetLink={widgetLink} />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <DoneBtn onClickHandler={() => setOpenModal(false)} />
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
