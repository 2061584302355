import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    attributeBar: {
        display: 'flex',
        padding: '10px',
        position: 'absolute'
    },
    selectFontStyle: {
        backgroundColor: '#262338',
        height: '42px',
        border: 'none',
        width: '60px',
        overflowY:'scroll',
        //cursor: 'pointer',
        justifyContent: 'center',
        color:'white',
        fontSize: '14px',
        width:'140px',
        marginRight: '1px'
    },
    buttonAttributeBar: {
        backgroundColor: '#262338',
        height: '42px',
        border: 'none',
        width: '60px',
        cursor: 'pointer',
        justifyContent: 'center',
    },
    buttonGroupAttributeBar: {
        backgroundColor: '#262338',
        display: 'grid',
        height: 'auto',
        marginTop: '13%',
        marginLeft: '8%',
        border: 'none',
        width: '60px',
        cursor: 'pointer',
        justifyContent: 'center',
    }
});
const useStyle = () => {
    const classes = useStyles();
    return {classes};
}; 
export default useStyle;