import styleQheader from './questionHeader.module.css';

const QuestionHeader = ({ setOpenQuestion, questionStep, btnHeaderTextColor, btnName }) => {
  const renderTitle = () => {
    if (questionStep === -1) return 'Template';
    else if (questionStep === 0) return 'New Testimonials';
    else if (questionStep === 1) return 'Questions';
    else return 'Preview & Send';
  };

  const renderSubTitle = () => {
    if (questionStep === 1) return 'What questions do you want to ask?';
    else if (questionStep > 1) return 'Preview and send to one or more people.';

    return '';
  };
  return (
    <>
      <div className={styleQheader.closeWrapp}>
        <span className={styleQheader.closeBtn} onClick={() => setOpenQuestion(false)}>
          &#x2715;
        </span>
      </div>

      {/* title */}
      <div className={styleQheader.titleWrapp}>
        <div className={styleQheader.titleBtnWrapp}>
          <button type="button" className={styleQheader.titleBtn} style={{ color: btnHeaderTextColor, border: `1px solid ${btnHeaderTextColor}` }}>
            {btnName}
          </button>
        </div>
        <div className={styleQheader.titleMain}>
          <h1 className={styleQheader.titleQuestion}>{renderTitle()}</h1>
        </div>
      </div>

      {/* below title */}
      <div className={styleQheader.underTitleParagraphWrapp}>
        <p className={styleQheader.subParaTitleBelow}>{renderSubTitle()}</p>
      </div>
    </>
  );
};

export default QuestionHeader;

QuestionHeader.defaultProps = {
  setOpenQuestion: () => {},
  questionStep: 1,
  btnHeaderTextColor: 'white',
  btnName: 'Unknown'
};
