import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const getLibraryScenesUrl = '/api/LibraryScenes/get-library-scenes';
const addLibSceneTagUrl = '/api/LibraryScenes/lib-scene-tag';
const removeLibSceneTagUrl = '/api/LibraryScenes/remove-library-tag';

const getLibraryScenes = () => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let token = localStorage.getItem('token');
    return {
      req: axios({
        method: 'get',
        url: baseUrl + getLibraryScenesUrl,
        cancelToken: source.token,
        headers: {
          Authorization: 'Bearer ' + token
        }
      }),
      cancelTokenSource: source
    };
  };

const addLibrarySceneTag = (sceneID, name) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let token = localStorage.getItem('token');
    return {
      req: axios({
        method: 'post',
        url: baseUrl + addLibSceneTagUrl,
        data: {
          LibrarySceneUpdId: sceneID,
          Name: name
        },
        cancelToken: source.token,
        headers: {
          Authorization: 'Bearer ' + token
        }
      }),
      cancelTokenSource: source
    };
  };


  const removeLibSceneTag = (ID) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    let token = localStorage.getItem('token');
    return {
      req: axios({
        method: 'post',
        url: baseUrl + removeLibSceneTagUrl,
        data: {
          ID: ID
        },
        cancelToken: source.token,
        headers: {
          Authorization: 'Bearer ' + token
        }
      }),
      cancelTokenSource: source
    };
  };


  export default {
    getLibraryScenes,
    addLibrarySceneTag,
    removeLibSceneTag
}