import { makeStyles } from '@material-ui/core';

const stylesBtn = makeStyles({
  btn: { width: '155px', marginTop: '20px', fontSize: '18px', height: '60px', borderRadius: '12px', backgroundColor: '#A996FF', border: '1px solid #A996FF', color: '#4700AB', textAlign: 'center', fontWeight: 'bold' }
});

const SaveButton = ({ onClickHandler }) => {
  const classes = stylesBtn();
  return (
    <button type="button" className={classes.btn} onClick={onClickHandler}>
      Save
    </button>
  );
};

export default SaveButton;
SaveButton.defaultProps = {
  onClickHandler: () => {}
};
