import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const baseUrl = process.env.REACT_APP_BASE_URL;

const getCurrentPlanUrl = '/api/Payment/current';
const getPublicKeyUrl = '/api/Payment/pkey';

const getCurrentPlan = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + getCurrentPlanUrl, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const openCustomerPortal = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.post(baseUrl + '/api/Payment/portal', null, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),

    cancelTokenSource: source
  };
};

const getPublicKey = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    axiosReq: axios.get(baseUrl + getPublicKeyUrl, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

let stripePromise;
const getStripe = (public_key) => {
  if (!stripePromise) {
    stripePromise = loadStripe(public_key);
  }

  return stripePromise;
};

export default {
  getCurrentPlan,
  openCustomerPortal,
  getPublicKey,
  getStripe
};
