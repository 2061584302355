import { toast } from 'react-toastify';
import projectApi from '../../../services/apiServices/projects/projectApi';

const useMovieThumbnailUpload = ({ data = {}, setThumbnailUrl, isChannel = false }) => {
  const onMovieThumbnailUpload = (img, id = null) => {

    if (data.isProcessing === true) {
      toast.info('Wait render to finish.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    let Thumbnail = new FormData();
    Thumbnail.append('File', img);
    Thumbnail.append('RenderID', data.renderID ?? data.id ?? id);
    Thumbnail.append('ChannelID', data.channelID);
    const { req } = projectApi.addThumbnail(Thumbnail, isChannel);
    req.then((result) => {
      if (result.data.success === true) {
        setThumbnailUrl((data.storageUrl || '') + result.data.newUrl + '?' + new Date().getTime());
        toast.success('Thumbnail changed!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        toast.error('Error ' + result.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };
  return { onMovieThumbnailUpload };
};

export default useMovieThumbnailUpload;
useMovieThumbnailUpload.defaultProps = {
  data: [], 
  setThumbnailUrl: () => {}, 
  isChannel: false 
}