import libraryService from '../../../services/apiServices/libraryService';
import projectApi from '../../../services/apiServices/projects/projectApi';

const useLibrary = ({ setAssetsForDelete, assetsForDelete, skip, setAssetsData, setClipsCount, activeSubLibTab, activeBtnHeader, searchVal }) => {
  const itemsReadyDelete = (item) => {
    if (assetsForDelete.length === 0) {
      let dataDel = {
        id: item.id,
        AssetType: item.assetType
      };
      setAssetsForDelete([...assetsForDelete, dataDel]);
    } else {
      let checkIsAleradyInArr2 = [...assetsForDelete].find((x) => x.id === item.id);

      if (!checkIsAleradyInArr2) {
        let dataDelx = {
          id: item.id,
          AssetType: item.assetType
        };
        setAssetsForDelete([...assetsForDelete, dataDelx]);
      } else {
        let filteredArr = [...assetsForDelete].filter((x) => x.id !== item.id);
        setAssetsForDelete(filteredArr);
      }
    }
  };

  const callAssets = (type, searchVal = '') => {
    const { req } = libraryService.getAssets(type, skip, 9, searchVal);

    req
      .then((res) => {
        setAssetsData(res?.data?.data);
        setClipsCount(res?.data?.totalCount);
      })
      .catch((err) => console.log('Err ' + err));
  };

  const deleteAssets = () => {
    let tabType = checkType();
    let isScene = tabType === 'scenes' ? true : false;

    const { req } = projectApi.deleteAssetsFromCompany(assetsForDelete, isScene);
    req
      .then((res) => {
        if (res?.data?.data && res?.data?.success) {
          setAssetsForDelete([]);
          let type = checkType();
          if (!!type) {
            callAssets(type, searchVal);
          }
        } else {
          //toast err message
        }
      })
      .catch((err) => console.log('err ' + err));
  };

  const checkType = () => {
    if (activeBtnHeader === 0) {
      return 'scenes';
    } else if (activeBtnHeader === 1) {
      if (activeSubLibTab === 0) {
        return 'video';
      } else if (activeSubLibTab === 1) {
        return 'audio';
      } else if (activeSubLibTab === 2) {
        return 'image';
      } else if (activeSubLibTab === 3) {
        return 'html';
      }
    }

    return '';
  };

  return { callAssets, itemsReadyDelete, deleteAssets, checkType };
};

export default useLibrary;
useLibrary.defaultProps = {
  setAssetsForDelete: () => {}, 
  assetsForDelete: [], 
  skip: 1, 
  setAssetsData: '', 
  setClipsCount: 1, 
  activeSubLibTab: '', 
  activeBtnHeader: '', 
  searchVal: ''
}