import styles from './questionButton.module.css';

const QuestionButton = ({ setQuestionSteps, state, isZeroStep, isStartStep = false, typeId }) => {
  const nextStep = () => {
    let isOneOfTheInputsEmpty = false;
    if (isZeroStep) {
      Object.values(state.step0).forEach((item) => {
        if (item.trim() === '') {
          isOneOfTheInputsEmpty = true;
        }
      });
    } else if (isStartStep) {
      Object.values(state.stepStart).forEach((item) => {
        if (item.trim() === '') {
          isOneOfTheInputsEmpty = true;
        }
      });
    } else {
      if (state?.step1.questions[typeId - 1].length === 0) {
        isOneOfTheInputsEmpty = true;
      }

      state?.step1.questions[typeId - 1].forEach((item) => {
        if (item?.value?.trim() === '') {
          isOneOfTheInputsEmpty = true;
          return;
        }
      });
    }

    if (isOneOfTheInputsEmpty) return window.alert(isZeroStep ? 'Select template' : 'All fields should be filled!');

    setQuestionSteps((prev) => prev + 1);
  };
  return (
    <div className={styles.qutton} onClick={nextStep}>
      <span className={styles.quttonTxt}>Next</span>
      <span className={styles.quttonSign}> &gt; </span>
    </div>
  );
};

export default QuestionButton;
QuestionButton.defaultProps = {
  setQuestionSteps: () => {}, 
  state: {}, 
  isZeroStep: false, 
  isStartStep: false, 
  typeId: '' 
}
