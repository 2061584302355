import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const url = '/api/Auth/DeleteUser/';
const deleteUser = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios.delete(baseUrl + url + id, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  deleteUser
}