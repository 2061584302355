import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import HeaderAsset from '../header/HeaderAsset';
import HeaderAssetButtons from '../header/HeaderAssetButtons';
import FilterAsset from './FilterAsset';
import SoundAsset from './SoundAsset';
import LibraryList from './assetSection/LibraryList';
import SubLinks from './assetSection/SubLinks';
import useLibrary from './assetSection/useLibrary';
import MobileLibraryHeader from './mobile/MobileLibraryHeader';
import MobilePaginate from './mobile/MobilePaginate';

const useStyles = makeStyles({
  libraryWrapp: {
    marginLeft: '0px',
    marginRight: '4%',
    color: 'white',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    ['@media (max-width: 360px)']: {
      marginRight: '0px'
    },
    ['@media (max-width: 451px)']: {
      marginLeft: '4%'
    },
    ['@media (min-width: 960px)']: {
      marginLeft: '0px'
    }
  },
  tabWrapp: {
    margin: '10px 0 15px 0',
    width: '97%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    maxHeight: '45px'
  },
  innerTabWrapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeTab: {
    borderColor: '#AC96FF',
    marginLeft: '25px',
    padding: '7px 19px',
    borderRadius: '6px',
    backgroundColor: '#AC96FF',
    color: '#5208B1'
  },
  inactiveTabs: {
    marginLeft: '25px',
    color: '#666379'
  },
  projectModalWrapp: {
    marginTop: '20px',
    padding: '3px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    height: '586px',
    overflow: 'auto'
  }
});

const Library = ({ isMobile }) => {
  const classes = useStyles();
  const btnHeaderNames = ['Intros', 'Assets']; //Scenes → Intros
  const subLinks = ['Clips', 'Sounds', 'Images', 'Animations']; //Texts → Animations
  const mobileSubLinks = ['Clips', 'Sounds', 'Images'];

  const [activeBtnHeader, setActiveBtnHeader] = useState(1);
  const [activeSubLibTab, setActiveSubLibTab] = useState(0);

  const [assetsForDelete, setAssetsForDelete] = useState([]);
  const [assetsData, setAssetsData] = useState([]);
  const [clipsCount, setClipsCount] = useState(1);
  const [skip, setSkip] = useState(0);
  const [assetType, setAssetType] = useState('video');

  const [searchVal, setSearchVal] = useState('');
  const [activeSound, setActiveSound] = useState({});
  const [rerenderSave, setRerenderSave] = useState(false);

  const { deleteAssets, callAssets, itemsReadyDelete, checkType } = useLibrary({ setAssetsForDelete, assetsForDelete, skip, setAssetsData, setClipsCount, setAssetType, activeSubLibTab, activeBtnHeader, searchVal });

  useEffect(() => {
    setAssetsForDelete([]);
  }, [activeSubLibTab]);

  useEffect(() => {
    let type = checkType();
    if (!!type) {
      callAssets(type, searchVal);
    }

    if (!(activeSubLibTab === 1 && activeBtnHeader === 1) && Object.keys(activeSound).length > 0) {
      setActiveSound({});
    }
  }, [skip, activeSubLibTab, activeBtnHeader, searchVal, rerenderSave]);

  const handleActiveSubTab = (index) => {
    setActiveSubLibTab(index);
    setSkip(0);
  };

  return (
    <div className={classes.libraryWrapp}>
      {!isMobile && (
        <HeaderAsset title="Asset library" setSearchTerm={setSearchVal}>
          {<HeaderAssetButtons btnNames={btnHeaderNames} activeBtnHeader={activeBtnHeader} setActiveBtnHeader={setActiveBtnHeader} setTotalPages={setClipsCount} />}
        </HeaderAsset>
      )}

      {activeBtnHeader === 1 && !isMobile && <SubLinks activeSubLibTab={activeSubLibTab} subLinks={subLinks} setActiveSubLibTab={handleActiveSubTab} />}

      {activeBtnHeader === 1 && isMobile && (
        <MobileLibraryHeader
          setSearchTerm={setSearchVal}
          mobileSubLinks={mobileSubLinks}
          activeSubLibTab={activeSubLibTab}
          setActiveSubLibTab={handleActiveSubTab}
          setActiveBtnHeader={setActiveBtnHeader}
          assetsData={assetsData}
          setAssetsData={setAssetsData}
          deleteAssets={deleteAssets}
          activeTab={checkType()}
          callAssets={callAssets}
          assetsForDelete={assetsForDelete}
          activeBtnHeader={activeBtnHeader}
          showUpload={activeBtnHeader === 1 && (activeSubLibTab === 0 || activeSubLibTab === 1 || activeSubLibTab === 2)}
        />
      )}

      {!isMobile && (
        <FilterAsset
          skip={skip}
          deleteAssets={deleteAssets}
          clipsCount={clipsCount}
          setSkip={setSkip}
          showUpload={activeBtnHeader === 1 && (activeSubLibTab === 0 || activeSubLibTab === 1 || activeSubLibTab === 2)}
          activeTab={checkType()}
          assetsForDelete={assetsForDelete}
          callAssets={callAssets}
          assetsData={assetsData}
          setAssetsData={setAssetsData}
        />
      )}

      <SoundAsset isMobile={isMobile} activeSound={activeSound} />

      <LibraryList
        setActiveBtnHeader={setActiveBtnHeader}
        isMobile={isMobile}
        setRerenderSave={setRerenderSave}
        assetsData={assetsData}
        itemsReadyDelete={itemsReadyDelete}
        setActiveSound={setActiveSound}
        activeSound={activeSound}
        activeBtnHeader={activeBtnHeader}
      />

      {isMobile && <MobilePaginate clipsCount={clipsCount} setSkip={setSkip} skip={skip} />}
    </div>
  );
};

export default Library;
Library.defaultProps = {
  isMobile: false
};
