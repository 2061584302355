import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import faye_landing from '../../files/faye_landing.png';
import faye_logo_landing from '../../files/faye_logo_landing.svg';
import loginClass from './css/LoginStyles.module.css';
import LoginComponent from './LoginComponent';
import SignupComponent from './SignupComponent';

const LoginScreen = ({ loginVisible, setLoginVisible, setIsLogged, isMobile }) => {
  const [isSignup, setIsSignup] = useState(false);

  useEffect(() => {
    if (window.location.pathname.toLowerCase() === '/signup') {
      setIsSignup(true);
      let priceID = new URLSearchParams(window.location.search).get('price_id');

      if (!!priceID) {
        localStorage.setItem('signup_package', priceID);
      }
    } else {
      setIsLogged(false);
    }
  }, []);

  return (
    <Grid className={loginClass.loginParent}>
      <Grid className={loginClass.imgWrap} style={{ backgroundImage: `url(${faye_landing})` }}>
        <img src={faye_logo_landing} className={loginClass.img} alt="landing" />
        <div className={loginClass.text}>Create a video in minutes</div>
      </Grid>

      <Grid className={loginClass.rightpart}>
        <Grid className={loginClass.formWrapp}>
          <Grid className={loginClass.getstartedgrid}>
            <div style={{ marginTop: isMobile ? '15px' : 0 }} className={loginClass.getstarted}>
              Get Started with Faye!
            </div>
          </Grid>

          <Grid className={loginClass.inputsgrid}>
            {loginVisible && <LoginComponent setIsLogged={setIsLogged} setLoginVisible={setLoginVisible} isMobile={isMobile} />}
            {!loginVisible && <SignupComponent setLoginVisible={setLoginVisible} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoginScreen;
