import { Grid } from '@material-ui/core';
import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import stripeService from '../../services/apiServices/stripeService';
import { SidebarActionsContext } from '../../context/sidebar_actions_context';
import { makeStyles } from '@material-ui/core';
import SettingsAccount from './settingsTabs/SettingsAccount';
import SettingsBilling from './settingsTabs/SettingsBilling';
import SettingsPassword from './settingsTabs/SettingsPassword';
import { SettingsGraphics } from './settingsTabs/SettingsGraphics';
import SettingsOrganisation from './settingsTabs/SettingsOrganisation';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import SettingsFeedback from './settingsTabs/SettingsFeedback';
import MobileTabsLibrary from '../library/mobile/MobileTabsLibrary';

const styleMovieList = makeStyles({
  mainGrid: {
    marginTop: '35px'
  },
  settingsTitle: {
    paddingLeft: '35px',
    fontWeight: 'bold',
    width: '100%',
    color: 'white',
    fontSize: '2.75rem'
  },
  settingsMainWrapp: {
    paddingTop: '10px',
    marginTop: '15px',
    marginLeft: '2%'
  },

  settingsMainWrappMobile: {
    paddingTop: '0px',
    marginTop: '0px',
    marginLeft: '0%'
  },
  renderLibWrapp: {
    color: 'white',
    display: 'flex',
    width: '100%',
    marginTop: '20px'
  },
  renderLibInner: {
    padding: '10px',
    width: '100%',
    display: 'flex'
  },
  mainArh: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '30px',
    fontSize: '13px',
    color: '#B9A4E9',
    fontWeight: 'bold'
  },
  mage: {
    marginTop: '5px',
    height: '2px',
    width: '130%',
    backgroundColor: '#B9A4E9'
  },
  inActiveWrapp: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '30px',
    fontSize: '13px',
    color: '#545773',
    fontWeight: 'bold'
  },
  inact: {
    marginTop: '5px',
    height: '2px',
    width: '130%',
    backgroundColor: '#545773'
  }
});

const Settings = ({isMobile, insteadDateImg, userDetail, setUserDetail, userDetailUnchanged, setUserDetailUnchanged, getUserDetail, profilePic, setProfilePic }) => {
  const cssStyle = styleMovieList();
  const location = useLocation();
  const { showSideMenu, setShowSideMenu } = useContext(SidebarActionsContext);

  let btnsLink = [];
  if (localStorage.getItem('role') === 'a1076392-0648-4341-87b1-5e9818f13d66') {
    if(isMobile){
      btnsLink = ['Account', 'Password'];
    }else{
      btnsLink = ['Account', 'Password', null, null, 'Graphics'];
    }
  } else if (localStorage.getItem('role') === '47db376f-38fb-43f5-859d-45bd145adbfd' || localStorage.getItem('role') === '322f2ef0-9283-4296-a18c-ec0d263c9599') {
    if(isMobile){
      btnsLink = ['Account', 'Password'];
    }else
       btnsLink = ['Account', 'Password', 'Organisation', 'Plan/Billing', 'Graphics', 'Feedback'];// 'Feedback'
  } 
  const [activeTab, setActiveTab] = useState(0);
  const [errorData, setErrorData] = useState('');
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    let billing = new URLSearchParams(window.location.search).get('billing');
    let purchased = new URLSearchParams(window.location.search).get('purchased');

    if (purchased) {
      localStorage.removeItem('signup_package');
    }

    if (billing) {
      setTimeout(() => {
        setActiveTab(3);
      }, [2000]);
    }

    getUserDetail();
  }, []);

  useEffect(() => {
    if (!!location.state) {
      const passwordTab = location.state.passwordTab;
      const organisationTab = location.state.organisationTab;
      if (passwordTab === true) {
        setActiveTab(1);
      } else if (organisationTab === true) {
        setActiveTab(2);
      }
    }
  }, []);

  useEffect(() => {
    const { axiosReq, cancelTokenSource } = stripeService.getCurrentPlan();

    axiosReq
      .then(async (resp) => {
        if (resp.data.success === true) {
          setCurrentPlan(resp.data.currentPlan);
        }
      })
      .catch((err) =>  console.log(err) );
  }, []);

  const renderLib = () => {
    return (
      <div className={cssStyle.renderLibWrapp}>
        <div className={cssStyle.renderLibInner}>
          {btnsLink.map((item, index) => {
            if (activeTab == index && !!item) {
              return (
                <div key={index} onClick={() => setActiveTab(index)} className={cssStyle.mainArh}>
                  <p>{item}</p>
                  <span className={cssStyle.mage}></span>
                </div>
              );
            } else if (!!item) {
              return (
                <div className={cssStyle.inActiveWrapp} key={index} onClick={() => setActiveTab(index)}>
                  <p>{item}</p>
                  <span className={cssStyle.inact}></span>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  };

//   <MobileTabsLibrary 
//   tittleSection='Asset Library'  
//   mobileSubLinks={mobileSubLinks}
//   setActiveSubLibTab={setActiveSubLibTab}
//   activeSubLibTab={activeSubLibTab}
// /> 

  return (
    <Grid className={cssStyle.mainGrid}>
      {!isMobile && <Grid className={cssStyle.settingsTitle}>
        {!showSideMenu && <AiOutlineMenuUnfold style={{ fontSize: '33px', marginRight: '20px' }} onClick={() => setShowSideMenu(true)} />}
        Settings
      </Grid> }
      {/* <Grid>{renderLib()}</Grid> */}


      {/* <MobileTabsLibrary /> */}
      {isMobile ? 
      <MobileTabsLibrary 
          isOnSettings={true}
          tittleSection='Settings'  
          mobileSubLinks={btnsLink}
          setActiveSubLibTab={setActiveTab}
          activeSubLibTab={activeTab}
    /> : <Grid>{renderLib()}</Grid>}


      <Grid className={isMobile ? cssStyle.settingsMainWrappMobile : cssStyle.settingsMainWrapp}>
        {activeTab === 0 && errorData === '' ? (
          <SettingsAccount
            isMobile={isMobile}
            profilePic={profilePic}
            setProfilePic={setProfilePic}
            getUserDetail={getUserDetail}
            insteadDateImg={insteadDateImg}
            setUserDetailUnchanged={setUserDetailUnchanged}
            userDetailUnchanged={userDetailUnchanged}
            userDetail={userDetail}
            setUserDetail={setUserDetail}
          />
        ) : (
          <h1 style={{ color: 'red' }}>{errorData}</h1>
        )}
        {activeTab === 1 && errorData === '' && <SettingsPassword isMobile={isMobile} />}
        {activeTab === 3 && errorData === '' && <SettingsBilling currentPlan={currentPlan} />}
        {activeTab === 2 && <SettingsOrganisation currentPlan={currentPlan} />}
        {activeTab === 4 && <SettingsGraphics  />}
        {activeTab === 5 && <SettingsFeedback />}

      </Grid>
    </Grid>
  );
};

export default Settings;
Settings.defaultProps = {
  isMobile: false, 
  insteadDateImg: '', 
  userDetail: {}, 
  setUserDetail: ()=>{}, 
  userDetailUnchanged: {}, 
  setUserDetailUnchanged: ()=>{}, 
  getUserDetail: {}, 
  profilePic: {}, 
  setProfilePic: ()=>{}
}
