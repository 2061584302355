import { makeStyles } from '@material-ui/core';

const stylesBtn = makeStyles({
  btn: { width: '155px', marginTop: '20px', fontSize: '18px', height: '60px', borderRadius: '12px', backgroundColor: 'transparent', border: '1px solid white', color: 'white', textAlign: 'center', fontWeight: 'bold' }
});

const DoneBtn = ({ onClickHandler }) => {
  const classes = stylesBtn();
  return (
    <button type="button" className={classes.btn} onClick={onClickHandler}>
      Done
    </button>
  );
};

export default DoneBtn;
DoneBtn.defaultProps = {
  onClickHandler: () => {}
};
