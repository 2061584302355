import DataGrid, { FilterRow, Pager, Paging, FilterPanel, Editing, Popup } from 'devextreme-react/data-grid';
import useUserMgmt from './useUserMgmt';

const columns = ['firstName', 'lastName', 'userName', 'email', 'role', 'active', 'company.name', 'created'];
const allowedPageSizes = [5, 10];

function UserManagement({ userDetail }) {
  
  const { users, deletingRow } = useUserMgmt();
  
  if (userDetail.applicationRoleId !== '47db376f-38fb-43f5-859d-45bd145adbfd') return <div></div>;

  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', padding: '50px' }}>
      <DataGrid style={{ width: '90%', backgroundColor: 'orange' }} dataSource={users} keyExpr="id" defaultColumns={columns} showBorders={true} onRowRemoving={deletingRow}>
        <Editing mode="popup" allowUpdating={true} allowDeleting={true} allowAdding={true} selectTextOnEditStart={true} useIcons={true}>
          <Popup title="Users Info" showTitle={true} width={700} height={525} />
        </Editing>
        <FilterRow visible={true} />
        <FilterPanel visible={true} />
        <Pager allowedPageSizes={allowedPageSizes} showPageSizeSelector={true} showNavigationButtons={true} />
        <Paging defaultPageSize={10} />
      </DataGrid>
    </div>
  );
}

export default UserManagement;
