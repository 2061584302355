import Dropdown from '../../../../shared/dropdown/Dropdown';
import subHeadStyle from './SubHeaderStyle.module.css';
import Paginate from '../../../library/assetSection/Paginate';

const SubHeader = ({ projectTotalPages, setPageCurrent }) => {
  return (
    <div className={subHeadStyle.subHeadWrapp}>
      <div className={subHeadStyle.subHeadMain}>
        <div className={subHeadStyle.leftSubHead}>
          <Dropdown firstItem="All folders" />
          <Paginate totalPages={projectTotalPages} setSkip={setPageCurrent} />
        </div>

        <div>
          <Dropdown firstItem="Most recent" />
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
SubHeader.defaultProps = {
  projectTotalPages: 1, 
  setPageCurrent: ()=>{}
}
