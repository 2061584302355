import { makeStyles } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

const currentPlanStyle = makeStyles({
  buttons: {
    width: '160px',
    height: '60.6px',
    backgroundColor: '#A996FF',
    borderRadius: '20px',
    fontFamily: 'Poppins',
    fontSize: '17px',
    color: '#4700AB',
    transitionDuration: '0.4s',
    webkitTransitionDuration: '0.4s',
    '&:hover': { backgroundColor: '#c9befa', transitionDuration: '0.1s' },
    '&:active': { boxShadow: '0 0 0 0 white', borderRadius: '4em', opacity: '1', transition: '0s' }
  },
  headlines: {
    fontFamily: 'Poppins',
    fontSize: '15px',
    color: 'white',
    opacity: '0.5'
  },
  currentPlanStyle: {
    backgroundColor: '#29293E',
    padding: '30px',
    width: '400px',
    fontFamily: "'Poppins'",
    borderRadius: '20px',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
});

let wrappCP = {
  display: 'flex'
};

let progressWrapp = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '80px',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px'
};

let dataUsed = {
  lineHeight: '1',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-beetwen',
  alignItems: 'center'
};

let textCenter = {
  textAlign: 'center'
};

let lineHeightSt = {
  marginTop: '17px',
  lineHeight: '0.5',
  color: '#898994',
  fontWeight: 'bold',
  fontFamily: "'Poppins'"
};

const CurrentPlan = ({ currentPlan, classes, showProgress = false }) => {
  // const classes = currentPlanStyle();

  return (
    <div style={wrappCP}>
      <div className={classes.currentPlanStyle}>
        <h4>{currentPlan.name}</h4>
        <p>{currentPlan.description}</p>
        <br></br>
        <h4>{currentPlan.price}</h4>
        <p>{currentPlan.priceName}</p>
        <p>Status: {currentPlan.status}</p>
      </div>

      {showProgress && (
        <div style={progressWrapp}>
          <div style={dataUsed}>
            <CircularProgress
              style={{ width: '120px', height: '120px', color: 'white', boxShadow: 'inset 0 0 0px 10px #262338', borderRadius: '50%' }}
              variant="determinate"
              value={(currentPlan.secondsUsed / currentPlan.totalPlanSeconds) * 100}
            />
            <div style={lineHeightSt}>
              <p style={textCenter}>Monhtly Renders</p>
              <p style={textCenter}>
                {currentPlan.secondsUsed} of {currentPlan.totalPlanSeconds}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentPlan;
