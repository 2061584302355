import { useEffect, useState } from 'react';

import authService from '../../services/apiServices/authService';

const ConfirmationScreen = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('fayeproject');

    let token = new URLSearchParams(window.location.search).get('token');
    let email = new URLSearchParams(window.location.search).get('email');

    const { req, cancelTokenSource } = authService.confirmRegistration(token, email);

    req.then((res) => {
      if (res.data.success === true) {
        setIsConfirmed(true);
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000);
      } else {
      }
    });
  }, []);

  return (
    <div style={{ display: 'flex', color: 'white', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
      {!isConfirmed && <h1>Waiting for account confirmation...</h1>}
      {isConfirmed && <h1>Account is confirmed!</h1>}
    </div>
  );
};

export default ConfirmationScreen;
