import React from 'react';
import scissorLogo from './scissors.png';

const textStyle = {
  color: '#4700AB',
  fontSize: '13px',
  fontWeight: 600
};

const imageStyle = {
  marginRight: '10px'
};

const buttonEditor = {
  backgroundColor: '#A996FF',
  width: '80%',
  height: '34px',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid #A996FF'
};
const EditorBtn = ({ selectProject, isDisabled = false }) => {
  return (
    <button style={{ ...{ opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'not-allowed' : 'pointer' }, ...buttonEditor }} disabled={isDisabled} type="button" onClick={selectProject}>
      <img style={imageStyle} src={scissorLogo} alt="scissors" />
      <span style={textStyle}> Editor </span>
    </button>
  );
};

export default EditorBtn;
