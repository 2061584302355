import { makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { CurrentTimeContext } from '../../../../../../context/current_time_context';

const useStyles = makeStyles({
  sceneTextAsset: {
    height: '60px',
    width: '135px',
    alignSelf: 'center',
    backgroundColor: 'azure',
    fontWeight: '500',
    textAlign: '-webkit-center',
    cursor: 'pointer',
    textOverflow: 'clip',
    objectFit: 'cover'
  },
  innerTextAsset: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100px'
  }
});

const TextSceneAsset = ({ asset, setSelectedAsset, activeSceneContent, setCurrentAttribute, setOpenAdvancedTextOptions }) => {
  const classes = useStyles();
  const { setCurrentTime } = useContext(CurrentTimeContext);

  return (
    <div
      className={classes.sceneTextAsset}
      onClick={(e) => {
        if (e.detail === 2) {
          let sceneStart = 0;
          if (!!activeSceneContent.find((x) => x.isMaster === true)) {
            sceneStart = activeSceneContent.find((x) => x.isMaster === true).start;
          }
          let assetStart = asset.start;
          setCurrentTime(assetStart - sceneStart);
          setOpenAdvancedTextOptions(true);
        }
        setSelectedAsset(asset);
        setCurrentAttribute('html');
      }}
    >
      <p className={classes.innerTextAsset}>{asset.asset.text}</p>
    </div>
  );
};
export default TextSceneAsset;
TextSceneAsset.defaultProps = {
  asset: {},
  setSelectedAsset: () => {},
  activeSceneContent: {},
  setCurrentAttribute: () => {},
  setOpenAdvancedTextOptions: () => {}
};
