import useMovieThumbnailUpload from './custom/useMovieThumbnailUpload';

const MovieThumbnailUpload = ({ data, setThumbnailUrl, uploadFileRef, isChannel = false }) => {
  const { onMovieThumbnailUpload } = useMovieThumbnailUpload({ data, setThumbnailUrl, isChannel });

  return (
    <input
      onChange={(e) => {
        e.stopPropagation();
        onMovieThumbnailUpload(e.target.files[0]);
      }}
      type="file"
      accept="image/*"
      ref={uploadFileRef}
      style={{ backgroundColor: 'white', display: 'none' }}
    />
  );
};

export default MovieThumbnailUpload;
MovieThumbnailUpload.defaultProps = {
  data: [],
  setThumbnailUrl: () => {},
  uploadFileRef: null,
  isChannel: false
};
