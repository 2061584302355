import { useState } from 'react';
import { makeStyles } from '@material-ui/core'; 
import { toast } from 'react-toastify';
import projectApi from '../../../services/apiServices/projects/projectApi';

const feedbackStyle = makeStyles({
    feebackWrapp: {
     color:'white',
     display: 'flex',
     flexDirection: 'column'
    },
    textArea: {
     width: '37%',
     height: '150px',
     marginTop: '20px',
     outline: 'none',
     borderRadius:'5px',
     padding: '5px' 
    }
   });


const useFeedback = () => {
    const [text, setText] = useState('');
    const styles = feedbackStyle();
  
    const errorToast = (msg='Error') => {
      toast.error(msg , {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  
    const successToast = (msg='Success') => {
      toast.success(msg , {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      setText('');
    }
  
     const sendFeedbackMsg = () => {
  
        const trimmedText = text.trim();
  
        if(trimmedText.length < 4 || trimmedText.length > 9999) return errorToast('Message should consists between 4 and 9999 characters!');
  
        const data = {
          message: trimmedText
        }
  
        const {req} = projectApi.sendUserFeedback(data);
        req
          .then(res=> res.data.success && successToast(res.data.message)) 
          .catch(err => errorToast(err.message))
     }
 
    return {text, setText, styles, sendFeedbackMsg }
}

export default useFeedback