import { makeStyles } from '@material-ui/core';
import horizontalImg from '../../../../../../files/horizontal.PNG';
import checkIcon from '../../../../../../files/icons/checkmark.png';
import verticalImg from '../../../../../../files/vertical_scroll.PNG';

const stylesStepStart = makeStyles({
  format: { fontSize: '15px', color: 'white', opacity: '0.5' },
  generalStyle: { color: 'white', width: '100%' },
  header: { fontWeight: 700 },
  inputTitle: { fontWeight: '600' },
  mainBodyStyle: { marginTop: '35px' },
  channelTypeStyle: { marginTop: '25px' },
  inputWidgetStyle: { marginLeft: '15px' },
  unchecked: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: '#888893', cursor: 'pointer' },
  typesCheckbox: { display: 'flex', alignItems: 'center' },
  labels: { marginLeft: '10px' },
  uncheckedWidget: { width: '22px', height: '22px', borderRadius: '50%', backgroundColor: '#262338', marginLeft: '20px', cursor: 'pointer' },
  leftFormat: { width: '75%' },
  rightFormat: { width: '25%' },
  formatMain: { width: '100%', display: 'flex' },
  horizontalImg: { width: '93%', marginTop: '15px' },
  labelWrapp: { display: 'flex' },
  verticalImg: { width: '92%', marginTop: '15px' },
  hr: { marginTop: '25px' },
  modeWrapper: { width: '100%', paddingTop: '10px' },
  modeInner: { width: '60%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
});

const FirstStep = ({ mode, format, dispatch }) => {
  const classes = stylesStepStart();

  return (
    <article className={classes.generalStyle}>
      <div>
        <h1 className={classes.header}>Widget settings</h1>
      </div>

      <div className={classes.mainBodyStyle}>
        <div>
          <div>
            <p className={classes.format}>Format</p>
          </div>

          <div className={classes.formatMain}>
            {/* left side format */}
            <div className={classes.leftFormat}>
              <div className={classes.labelWrapp}>
                {format === 1 ? <img src={checkIcon} alt="foramt" className={classes.unchecked} /> : <div className={classes.unchecked} onClick={() => dispatch({ type: 'UPDATE_FORMAT', payload: 1 })} />}
                <label className={classes.labels}>Carousel</label>
              </div>

              <img src={horizontalImg} className={classes.horizontalImg} alt="horizontal" />
            </div>

            {/* right side format */}
            <div className={classes.rightFormat}>
              <div className={classes.labelWrapp}>
                {format === 2 ? <img src={checkIcon} alt="format" className={classes.unchecked} /> : <div className={classes.unchecked} onClick={() => dispatch({ type: 'UPDATE_FORMAT', payload: 2 })} />}
                <label className={classes.labels}>Vertical scroll</label>
              </div>

              <img src={verticalImg} className={classes.verticalImg} alt="vertical" />
            </div>
          </div>

          <hr className={classes.hr} />

          {/* mode labels */}
          <div className={classes.modeWrapper}>
            <div className={classes.modeInner}>
              <div className={classes.labelWrapp}>
                {mode === 1 ? <img src={checkIcon} alt="checkmark" className={classes.unchecked} /> : <div className={classes.unchecked} onClick={() => dispatch({ type: 'UPDATE_MODE', payload: 1 })} />}
                <label className={classes.labels}>Light mode</label>
              </div>
              <div className={classes.labelWrapp}>
                {mode === 2 ? <img src={checkIcon} alt="checkmark" className={classes.uncheckedWidget} /> : <div className={classes.uncheckedWidget} onClick={() => dispatch({ type: 'UPDATE_MODE', payload: 2 })} />}
                <label className={classes.labels}>Dark mode</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default FirstStep;
FirstStep.defaultProps = {
  mode: '', 
  format: '', 
  dispatch: () => {}
}