import { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { CgCloseO, CgCheckO } from 'react-icons/cg';
import Avatar from 'react-avatar';
import authService from '../../../services/apiServices/authService';

const styleAccountSettings = makeStyles({
  singleItemWrapp: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '15px 0px',
    borderBottom: '1px solid white'
  },
  imgWrapp: {
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  img: {
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
    borderRadius: '50%',
    backgroundSize: 'contain'
  },
  namesWrapp: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionWrapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  userActivity: {
    width: '10px',
    height: '10px',
    borderRadius: '50%',
    marginRight: '10px'
  },
  selectWrapp: {
    padding: '10px',
    borderRadius: '5px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white'
  },
  closeWrapp: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  nameList: {
    color: '#4E4B66',
    fontWeight: 'bold'
  },
  emailList: {
    color: '#7C7C87',
    fontWeight: 'bold'
  },
  logoDelete: {
    color: 'white',
    fontSize: '34px',
    cursor: 'pointer'
  }
});

const SingleUser = ({ item, roles, ind, len }) => {
  const { firstName, lastName, id, roleID, name, email, imageUrl, active } = item;

  const [currentRoleID, setCurrentRoleID] = useState(roleID);
  const [activeUser, setActiveUser] = useState(active);
  const classes = styleAccountSettings();

  const changeUserRole = (roleID) => {
    const { req } = authService.changeUserRole(id, roleID);

    req.then((res) => {
      if (res.data.success) {
        setCurrentRoleID(roleID);
      }
    });
  };

  const deactivateUser = () => {
    const { req } = authService.deactivateUser(id);

    req.then((res) => {
      if (res.data.success) {
        setActiveUser(res.data.active);
      }
    });
  };

  return (
      <Grid container xs={12} sm={12} lg={12} md={12}  className={classes.singleItemWrapp}>
        <Grid item xs={6} sm={6} lg={3} md={3}>
          <Avatar className={classes.img} round="true" src={imageUrl} name={firstName + ' ' + lastName} />
        </Grid>
        <Grid item xs={6} sm={6} lg={5} md={5}>
          <div className={classes.namesWrapp}>
            <span className={classes.nameList}>
              {firstName} {lastName}
            </span>
            <span className={classes.emailList}>{email}</span>
          </div>
        </Grid>
        <Grid item xs={6} sm={6} lg={3} md={3}>
          <div className={classes.optionWrapp}>
            <div className={classes.userActivity} style={{ background: activeUser ? 'green' : 'red' }}></div>
            <select className={classes.selectWrapp} value={currentRoleID} onChange={(e) => changeUserRole(e.target.value)}>
              {roles.map((x) => {
                return (
                  <option value={x.id} key={x.id}>
                    {x.name.substring(7)}
                  </option>
                );
              })}
            </select>
          </div>
        </Grid>
        <Grid item xs={3} sm={3} lg={1} md={1}>
          <div className={classes.closeWrapp}>
            {activeUser && <CgCloseO className={classes.logoDelete} onClick={() => deactivateUser(id)} />}
            {!activeUser && <CgCheckO className={classes.logoDelete} onClick={() => deactivateUser(id)} />}
          </div>
        </Grid>
      </Grid>
  );
};

export default SingleUser;
