import { makeStyles } from '@material-ui/core';

const btnNextStyle = makeStyles({
  qutton: {
    width: '147px',
    height: '68px',
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    color: 'white',
    backgroundColor: '#14142b',
    border: '1px solid #14142b'
  },
  quttonTxt: {
    fontSize: '18px',
    marginRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  quttonSign: {
    marginTop: '-4px',
    fontSize: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const NextStepBtn = ({ onClickHandler }) => {
  const styles = btnNextStyle();
  return (
    <button className={styles.qutton} type="button" onClick={onClickHandler}>
      <span className={styles.quttonTxt}>Next</span>
      <span className={styles.quttonSign}> &gt; </span>
    </button>
  );
};

export default NextStepBtn;
NextStepBtn.defaultProps = {
  onClickHandler: () => {}
};
