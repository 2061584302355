import { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { CurrentTimeContext } from '../../../../context/current_time_context';
import CropLabel from '../CropLabel';
import { cropResize, useInteract } from '../useInteract';
import { rangeColors } from './../../../timeline/projectTime/AssetRangeColors';

const ImageStage = ({ image, selectedAsset, sceneDuration, sceneStart, setInteractObject }) => {
  let position = `translate(${image.x + (image.crop?.cropLeft || 0)}px, ${image.y + (image.crop?.cropTop || 0)}px)`;
  let rotation = image.rotate ? `rotate(${image.rotate}deg)` : '';
  const isAssetSelected = selectedAsset?.assetStyleID === image.assetStyleID;

  const mainElement = useRef(null);
  const draggableRef = useRef(null);
  const { currentTime } = useContext(CurrentTimeContext);

  const [isCrop, setIsCrop] = useState(false);
  const [visibility, setVisibility] = useState('hidden');
  const [currentPosition, setCurrentPosition] = useState({
    xRate: 0,
    yRate: 0
  });

  const ref = useInteract(isCrop, setInteractObject, image.crop?.cropRight, image.crop?.cropBottom, image.crop?.cropLeft, image.crop?.cropTop, mainElement, image);

  useEffect(() => {
    if (currentTime >= image.start - sceneStart && currentTime <= image.start - sceneStart + image.length) {
      setVisibility('visible');
    } else {
      if (visibility == 'visible') {
        setVisibility('hidden');
      }
    }
  }, [currentTime, visibility, image.length, image.start, sceneStart, sceneDuration]);

  useEffect(() => {
    if (image.crop?.cropTop || image.crop?.cropLeft) {
      setCurrentPosition({ xRate: -Math.floor(image.crop?.cropLeft + 3 || 0), yRate: -Math.floor(image.crop?.cropTop + 3 || 0) });
    }
  }, [image.scale]);

  let style = {
    zIndex: image.z,
    transform: `${position} ${rotation}`,
    opacity: image.opacity || 1,
    width: `${parseFloat(image.w - (image.crop?.cropRight || 0) - (image.crop?.cropLeft || 0))}px`,
    height: `${parseFloat(image.h - (image.crop?.cropBottom || 0) - (image.crop?.cropTop || 0))}px`,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  if (isAssetSelected) {
    style[!isCrop ? 'outline' : 'border'] = '3px solid ' + rangeColors[image.asset.typeID];
  }

  const stageClasses = ['asset-interact'];

  const onDrag = (e, data) => {
    const { lastX, lastY } = data;
    setCurrentPosition({ xRate: lastX > 0 ? 0 : lastX, yRate: lastY > 0 ? 0 : lastY });
  };

  const onStop = () => {
    cropResize(ref, mainElement, image, setInteractObject);
  };

  return (
    <>
      {isCrop && (
        <div
          data-z={image.z}
          data-w={image.w}
          data-h={image.h}
          data-x={image.x}
          data-y={image.y}
          data-select="image"
          id={`screen_${image.assetStyleID}`}
          scene-id={image.sceneID}
          stage-id={image.assetStyleID}
          className={stageClasses.join(' ')}
          style={{ ...style, ...{ pointerEvents: 'all' } }}
          ref={ref}
        >
          <Draggable
            nodeRef={draggableRef}
            position={{
              x: currentPosition.xRate,
              y: currentPosition.yRate
            }}
            onDrag={onDrag}
            onStop={onStop}
          >
            <div ref={draggableRef}>
              <img
                draggable={false}
                ref={mainElement}
                src={image.asset.src}
                style={{ objectFit: 'cover', cursor: 'move', transform: `translate(0px, 0px)` }}
                height={image.h + 'px'}
                width={image.w + 'px'}
                className="resizable"
                alt=""
              />
            </div>
          </Draggable>
          {isAssetSelected && <CropLabel isCrop={isCrop} handleSetIsCrop={setIsCrop} />}
        </div>
      )}
      {!isCrop && (
        <div
          data-z={image.z}
          data-w={image.w}
          data-h={image.h}
          data-x={image.x}
          data-y={image.y}
          data-select="image"
          id={`screen_${image.assetStyleID}`}
          scene-id={image.sceneID}
          stage-id={image.assetStyleID}
          className={stageClasses.join(' ')}
          style={style}
          ref={ref}
        >
          <div
            style={{
              transform: `translate(${-Math.floor(image.crop?.cropLeft || 0)}px, ${-Math.floor(image.crop?.cropTop || 0)}px)`
            }}
          >
            <img src={image.asset.src} style={{ objectFit: 'cover' }} height={image.h + 'px'} width={image.w + 'px'} className="resizable" alt="" />
          </div>

          {isAssetSelected && <CropLabel isCrop={isCrop} handleSetIsCrop={setIsCrop} />}
        </div>
      )}
    </>
  );
};

export { ImageStage };

