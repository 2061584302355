import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  frame: {
    width: '100%',
    backgroundColor: '#262338',
    borderRadius: '15px',
    position: 'relative',
    padding: '5px 0 10px 0 '
  },
  headPickWrapp: {
    width: '100%',
    overflow: 'hidden'
  },
  activeHead: {
    backgroundColor: '#A996FF',
    borderRadius: '7px',
    color: '#5F26C0'
  },
  headOne: {
    paddingTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    color: 'grey',
    '& p': {
      margin: '10px 4px',
      padding: '5px 25px',
      cursor: 'pointer'
    }
  },
  headTwo: {
    padding: '4px',
    color: 'grey',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      margin: '3px',
      width: '15%',
      lineHeight: '1'

      // '& p': {
      //     padding:'5px'
      // }
    },
    '& span': {
      border: '1px solid grey'
    }
  },
  headTwoActive: {
    color: '#FFFFFF',
    fontFamily: "'Poppins'",
  },
  headTwoNonActive: {
    fontFamily: "'Poppins'",
  },
  underlineSpanStyle: {
    border: '1px solid #FFFFFF !important'
  },
  headThree: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 0',
    paddingRight: '25px',
    '& button': {
      margin: '10px',
      padding: '3px 10px',
      borderRadius: '8px',
      border: '3px solid #9988E6',
      color: '#9988E6',
      backgroundColor: '#262338',
      cursor: 'pointer'
    }
  },
  paginationWrapper: {
    width: '100%',
    position: 'absolute',
    bottom: '10px',
    left: '0px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center'
  },
  paginationBackArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginLeft: '7px',
    padding: '10px'
  },
  paginationForwardArrow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginLeft: '7px',
    padding: '10px'
  },
  paginationActive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    color: 'white',
    marginLeft: '7px',
    backgroundColor: '#4181e8',
    padding: '10px',
    borderRadius: '50%'
  },
  paginationInactive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    marginLeft: '7px',
    padding: '10px'
  },
  searchInput: {
    backgroundColor: 'white',
    width: '100%',
    borderRadius: 20,
    ['& label.Mui-focused']: {
      color: 'white'
    },
    ['& label.MuiFormLabel-filled']: {
      color: 'white'
    },
    ['& .MuiInput-underline:after']: {
      borderBottomColor: 'yellow'
    },
    ['& .MuiOutlinedInput-root']: {
      ['& fieldset']: {
        borderColor: 'none'
      },
      ['&:hover fieldset']: {
        borderColor: 'white'
      },
      ['&.Mui-focused fieldset']: {
        borderColor: 'white'
      }
    },
    [`& fieldset`]: {
      borderRadius: 20
    }
  }
});

const useBodyPickStyle = makeStyles({
  bodyPickWrapp: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center'
  },
  innerBodyPickWrapp: {
    width: '99%',
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: '200px',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: '72px'
    }
  },
  imgBox: {
    position: 'relative',
    width: '25%',
    height: '72px',
    margin: '5px'
  },
  icWrapp: {
    backgroundColor: '#633F96',
    border: '1px solid white',
    padding: '5px',
    borderRadius: '50%',
    position: 'absolute',
    bottom: '6px',
    right: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.75',
    '&:hover': {
      opacity: '1'
    }
  },
  bodyPicker: { overflowY: 'scroll', /*height: '450px',*/ padding: '0px 3% 0 3%', marginRight: '10px' },
  cardsBodyWrapp: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '500px',
    alignContent: 'flex-start'
  }
});

const useStyle = () => {
  const classes = useStyles();
  const bodyPickStyle = useBodyPickStyle();

  return { classes, bodyPickStyle };
};

export default useStyle;
