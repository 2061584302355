import WaveSurfer from 'wavesurfer.js';
import { useEffect, useState, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { BsFillPlayFill, BsFillPauseFill } from 'react-icons/bs';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  play: {
    position: 'absolute',
    bottom: 0
  },
  name: {
    position: 'absolute',
    bottom: '10px',
    left: '40px',
    color: '#A0A3BD',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  duration: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
    color: '#A0A3BD'
  }
});

const AudioCard = ({ data = {} }) => {
  const [waveform, setWaveform] = useState(null);
  const audioCardClasses = useStyles();
  const ref = useRef(null);

  useEffect(() => {
    setWaveform(
      WaveSurfer.create({
        barWidth: 3,
        cursorWidth: 1,
        container: '.audio-card-waveform' + data.id,
        backend: 'WebAudio',
        height: 70,
        progressColor: '#1de2cf',
        interact: true,
        responsive: true,
        waveColor: 'rgba(29, 226, 207, 0.5)',
        cursorColor: 'transparent'
      })
    );

    return () => {
      setWaveform(null);
    };
  }, []);

  useEffect(() => {
    if (!!waveform && !!data && !!data.url) {
      waveform.load(data.url);

      waveform.on('finish', () => {
        waveform.setCurrentTime(0);
      });
    }

    return () => {
      pauseVideo();
    };
  }, [waveform]);

  const playAudio = () => {
    if (!!waveform) {
      waveform.playPause();
    }
  };

  const pauseVideo = () => {
    if (!!waveform && waveform.isPlaying()) {
      waveform.playPause();
    }
  };

  return (
    <Grid>
      <div ref={ref} style={{ backgroundColor: '#14142A', marginBottom: '20px' }} className={'audio-card-waveform' + data.id}>
        <div className={audioCardClasses.play} onClick={() => playAudio()}>
          {!!waveform && waveform.isPlaying() ? <BsFillPauseFill color="#A0A3BD" size="30" /> : <BsFillPlayFill color="#A0A3BD" size="30" />}
        </div>
        <div style={{ width: !!ref.current ? `${ref.current.offsetWidth - 100}px` : '150px' }} className={audioCardClasses.name}>
          {data.name}
        </div>
        {!!data.originalLength && <div className={audioCardClasses.duration}>{data.originalLength.toFixed(2)}</div>}
      </div>
    </Grid>
  );
};

export default AudioCard;
