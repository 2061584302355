const ImageTag = ({ checkToggle, item, classStyle }) => {
    return (
      <img className={classStyle} src={item.url || item.src} style={{ outline: checkToggle ? '3px solid #A996FF' : '0px solid #A996FF' }} alt='pic' />
    );
  };
  
  export default ImageTag;
  ImageTag.defaultProps = {
    checkToggle: false, 
    item: {}, 
    classStyle: {}
  }
  