import { HttpTransportType, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;
const getCurrentCompanyUrl = '/api/Company/get-current-company';
const postLogErrorUrl = '/api/Company/logger';
const getLanguagesUrl = '/api/Company/getLanguages';
const postLastNotificationUrl = '/api/Company/saveLastNotification';
const getNotificationsUrl = '/api/Company/notifications';

const getCurrentCompanyData = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + getCurrentCompanyUrl, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const getLanguages = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + getLanguagesUrl, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const getNotifications = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  let token = localStorage.getItem('token');

  return {
    req: axios.get(baseUrl + getNotificationsUrl, {
      cancelToken: source.token,
      headers: { Authorization: 'Bearer ' + token }
    }),
    cancelTokenSource: source
  };
};

const saveLastNotification = (id) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + postLastNotificationUrl + `/${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

let connection;
const subscribeToNotificationHub = async (room, setTriggerNotifications) => {
  connection = new HubConnectionBuilder()
    .withUrl(baseUrl + '/notificationhub', {
      skipNegotiation: true,
      transport: HttpTransportType.WebSockets
    })
    .configureLogging(LogLevel.Error)
    .build();

  connection.on('TriggerNotificationsFromHub', (msg) => {
    setTriggerNotifications((x) => !x);
  });

  await connection.start();
  await connection.invoke('SubscribeToNotification', { room });
};

let unsubscribeToNotificationHub = async () => {
  if (!!connection) {
    await connection.stop();
  }
};

const logError = (data) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');

  return {
    axiosReq: axios({
      method: 'post',
      url: baseUrl + postLogErrorUrl,
      data: data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  getCurrentCompanyData,
  getLanguages,
  getNotifications,
  saveLastNotification,
  subscribeToNotificationHub,
  unsubscribeToNotificationHub,
  logError
};
