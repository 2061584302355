import { createContext, useState } from "react";

export const PlayModeContext = createContext(null);

export const PlayModeContextProvider = ({ children }) => {

    const [playMode, setPlayMode] = useState(false);
    return (
        <PlayModeContext.Provider value={{ playMode, setPlayMode }}>
            {children}
        </PlayModeContext.Provider>
    )
}