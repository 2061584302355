import { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';

const AssetTrimmer = ({ scenes, assetToTrim, selectedAsset, sceneStart, sceneDuration, setInteractObject }) => {
  const [assetX, setAssetX] = useState(0);
  const [assetWidth, setAssetWidth] = useState(0);
  const [assetStartSeconds, setAssetStartSeconds] = useState(0);
  const [assetTrimSeconds, setAssetTrimSeconds] = useState(0);
  const [assetLengthSeconds, setAssetLengthSeconds] = useState(0);

  const [assetTimeSeconds, setAssetTimeSeconds] = useState('');

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (assetToTrim.assetStyleID === selectedAsset.assetStyleID) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [selectedAsset]);

  useEffect(() => {
    if (!!assetLengthSeconds) {
      setAssetTimeSeconds(new Date(assetLengthSeconds * 1000).toISOString().substr(14, 9));
    }
  }, [assetStartSeconds, assetTrimSeconds, assetLengthSeconds, sceneDuration, selectedAsset]);

  useEffect(() => {
    setAssetStartSeconds(assetToTrim.start - sceneStart);
    setAssetTrimSeconds(assetToTrim.trim);
    setAssetLengthSeconds(assetToTrim.length);

    let len = (730 * assetToTrim.length) / sceneDuration;
    setAssetWidth(len);

    if (!assetToTrim.isMaster) {
      let x = (730 * (assetToTrim.start - sceneStart)) / sceneDuration;
      setAssetX(x);
    } else {
      setAssetX(0);
    }
  }, [sceneStart, sceneDuration, scenes]);

  const style = {
    display: 'flex',
    top: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgba(141, 233, 255, 0.5)',
    borderRadius: '5px',
    borderLeft: '5px solid #8DE9FF',
    borderRight: '5px solid #8DE9FF',
    verticalAlign: 'center',
    zIndex: 999
  };

  return (
    <>
      {visible && (
        <Rnd
          key={assetToTrim.assetStyleID + sceneDuration}
          dragAxis='x'
          bounds='parent'
          style={style}
          // width is related to duration/length of the asset on timeline
          size={{ width: assetWidth, height: 60 }}
          // x is related to start (+trim) of the asset on timeline. 0 - start, 730 - end
          position={{ x: assetX, y: 4 }}
          minWidth='20px'
          onDragStop={(e, d) => {
            // calculate new seconds to add or subtract from start of the video
            let startAdd = (sceneDuration * d.x) / 730 - assetStartSeconds;
            let newSec = assetStartSeconds + startAdd;

            let item = {};
            item.type = 'videostart';
            item.id = assetToTrim.assetStyleID;
            item.sceneid = assetToTrim.sceneID;
            let newX = d.x;

            item.start = sceneStart + newSec;
            setAssetStartSeconds(newSec);
            setAssetX(newX);
            setInteractObject(item);
          }}
          onResizeStop={(e, direction, ref, delta, position) => {
            if (direction === 'left') {
              let item = {};
              item.type = 'assettrim';
              item.id = assetToTrim.assetStyleID;
              item.sceneid = assetToTrim.sceneID;

              // seconds to add/subtract from start of the asset
              let newTrimSeconds = (sceneDuration * position.x) / 730 - assetStartSeconds;

              item.trim = 0;

              // only video and audio assets have trim value
              if (assetToTrim.asset.type === 'video' || assetToTrim.asset.type === 'audio') {
                item.trim = assetTrimSeconds + newTrimSeconds;

                // if old and new trim is negative number, reset asset to 0
                if (item.trim < 0) {
                  item.trim = 0;
                  delta.width = (730 * assetLengthSeconds) / sceneDuration;
                  newTrimSeconds = -assetTrimSeconds;
                }
                item.length = assetLengthSeconds - newTrimSeconds;
              } else {
                item.length = assetLengthSeconds - newTrimSeconds;
              }

              if (!assetToTrim.isMaster) {
                let x = (730 * (assetStartSeconds + newTrimSeconds)) / sceneDuration;
                setAssetX(x);
                let lengthWidth = (730 * item.length) / sceneDuration;
                setAssetWidth(lengthWidth);
                item.start = sceneStart + assetStartSeconds + newTrimSeconds;
              } else {
                item.start = sceneStart;
                
                setAssetX(0);
              }

              // setAssetLengthSeconds(item.length)
              // setAssetStartSeconds(item.start - sceneStart);
              // setAssetTrimSeconds(item.trim);

              setInteractObject(item);
            } else if (direction === 'right') {
              let item = {};
              item.type = 'videolen';
              item.id = assetToTrim.assetStyleID;
              item.sceneid = assetToTrim.sceneID;

              // width in px from start to begin of rectangle
              let fromStartWidth = (730 * assetStartSeconds) / sceneDuration;

              // seconds to add/subtract from length of the asset
              let newTrimSeconds = (sceneDuration * delta.width) / 730;

              // width in px to add/subtract to rectangle
              let newWidth = delta.width;

              if (assetToTrim.asset.type === 'video' || assetToTrim.asset.type === 'audio') {
                // handle asset length if more seconds added than video/audio has originally

                if (assetLengthSeconds + newTrimSeconds > assetToTrim.originalLength) {
                  // add seconds so new length is matched to video's original length
                  newTrimSeconds = assetToTrim.originalLength - assetLengthSeconds;
                  newWidth = (730 * newTrimSeconds) / sceneDuration;
                }
              }

              item.length = assetLengthSeconds + newTrimSeconds;

              let assetLengthSecondsTemp = assetLengthSeconds + newTrimSeconds;

              //debugger;
              // // if asset duration is higher than scene duration
              // if (item.length > sceneDuration + assetStartSeconds) {
              //   assetLengthSecondsTemp = sceneDuration - assetStartSeconds;
              //   item.length = sceneDuration - assetStartSeconds;
              //   newWidth = (786 * assetLengthSecondsTemp) / sceneDuration;
              // }

              // old length + added length (can be both positive or negative number)
              setAssetLengthSeconds(assetLengthSecondsTemp);
              // old width + add width in px (can be both positive or negative number)
              setAssetWidth(assetWidth + newWidth);
              // update asset
              setInteractObject(item);
            }
          }}
          enableResizing={{
            top: false,
            right: true,
            bottom: false,
            left: true,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }}
        >
          <div>
            <span style={{ color: 'white', fontWeight: 700 }}>{assetTimeSeconds}</span>
          </div>
        </Rnd>
      )}
    </>
  );
};

export default AssetTrimmer;
