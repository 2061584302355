import { makeStyles } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import noThumb from '../../files/NoThumbnail.jpg';
import { ReactComponent as PLAY_BIG } from '../../files/icons/PLAY_BIG.svg';
import projectApi from '../../services/apiServices/projects/projectApi';

const styleMovieThumbnail = makeStyles({
  newMovieWrapp: { position: 'relative', marginBottom: '20px' },
  newMovie: {
    width: '350px',
    height: '200px',
    position: 'relative'
  },
  logoMovie: {
    borderRadius: '15px',
    border: '1px solid #4E4B66',
    cursor: 'pointer',
    width: '350px',
    height: '200px'
  },
  uploadImg: {
    position: 'absolute',
    width: '25px',
    height: '25px',
    bottom: '10px',
    right: '10px',
    borderRadius: '50%',
    backgroundColor: 'grey',
    '&:hover': {
      opacity: '1'
    }
  },
  infoDiv: {
    width: '350px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '20px'
  },
  movieName: {
    color: 'white',
    fontFamily: "'Poppins', sans-serif",
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '15px'
  },
  interaction: {
    position: 'absolute',
    bottom: '10px',
    left: '10px'
  },
  projectOptions: {
    position: 'absolute',
    top: '10px',
    right: '10px'
  },
  date: {
    color: 'gray'
  }
});

const ProjectThumbnailInspiration = ({ data, type, setSelectedProjectID, GetProjectScenes }) => {
  const history = useHistory();
  const cssStyle = styleMovieThumbnail();

  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [thumbnailType, setThumbnailType] = useState(2);

  const selectProject = (id) => {
    const { req } = projectApi.checkIsProjectEditable(id);
    req.then((resp) => {
      if (resp.data.success && resp.data.isEditable) {
        localStorage.removeItem('fayeproject');
        localStorage.setItem('fayeproject', id);
        history.push('/editor');
      } else {
        toast.info('You have to be Owner or Contributor to edit this project', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  useEffect(() => {
    setThumbnailUrl(data.firstSceneMasterClipUrl);
    setThumbnailType(data.firstSceneMasterClipTypeID || 2);
  }, []);

  return (
    <div className={cssStyle.newMovieWrapp}>
      <div
        className={cssStyle.newMovie}
        onClick={(e) => {
          if (type === 'inspired') {
            e.stopPropagation();
            setSelectedProjectID(data.id);
            GetProjectScenes(data.id);
          } else if (type === 'recent') {
            selectProject(data.id);
          }
        }}
      >
        {thumbnailType === 2 && <img className={cssStyle.logoMovie} src={!!thumbnailUrl ? thumbnailUrl : noThumb} alt="Logo" />}
        {thumbnailType === 1 && (
          <video className={cssStyle.logoMovie}>
            <source src={thumbnailUrl} />
          </video>
        )}
        <PLAY_BIG
          onClick={(e) => {
            e.stopPropagation();
            setSelectedProjectID(data.id);
            GetProjectScenes(data.id);
          }}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            left: '0px',
            bottom: '10px',
            height: '50px'
          }}
        />
      </div>
      <div className={cssStyle.infoDiv}>
        <div className={cssStyle.movieName}>{data.name}</div>
        <div className={cssStyle.date}>{new Date(data.createdDate).toLocaleDateString('de') + (data.isProcessing === true ? ' Rendering... ' : '')}</div>
      </div>
    </div>
  );
};

export default ProjectThumbnailInspiration;
ProjectThumbnailInspiration.defaultProps = {
  data: [], 
  type: '', 
  setSelectedProjectID: () => {}, 
  GetProjectScenes: () => {}
}
