import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useContext, useEffect, useState } from 'react';
import { AiFillPauseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PAUSED, PLAYING } from '../../constants/video_states';
import { CurrentTimePreviewContext } from '../../context/current_time_preview_context';
import { PlayModePreviewContext } from '../../context/play_mode_preview_context';
import { ReactComponent as PLAY_SMALL } from '../../files/icons/PLAY_SMALL.svg';
import projectApi from '../../services/apiServices/projects/projectApi';
import useTemplateIcon from '../inspiration/img/useTemplateIcon.png';
import ProjectSettingsDialogue from '../movies/ProjectSettingsDialogue';
import useProjectsSection from '../projectSection/parts/custom/useProjectsSection';
import FullProjectPreview from '../timeline/projectTime/FullProjectPreview';
import ProjectPreviewButton from './fullPreview/ProjectPreviewButton';

const FullPreviewModal = ({ scenes, projectDuration, setOpenFullPreviewModal, openFullPreviewModal, hasUseTemplate, projectId, deleteHandler, isProjectPage = false }) => {
  const [previewIntervalId, setPreviewIntervalId] = useState(0);
  const [newPreviewRange, setNewPreviewRange] = useState(0);
  const [timeRangePreviewClicked, setTimeRangePreviewClicked] = useState(false);
  const [openProjSettingsDialog, setOpenProjSettingsDialog] = useState(false);

  const { selectProject } = useProjectsSection({});

  const history = useHistory();
  const { playModePreview, setPlayModePreview } = useContext(PlayModePreviewContext);
  const { currentTimePreview, setCurrentTimePreview } = useContext(CurrentTimePreviewContext);

  const duplicateProject = (name, description, privacyID, contributorsList) => {
    let obj = {
      Name: name,
      Description: description,
      ID: projectId,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };
    const { req } = projectApi.duplicateProject(obj);
    req.then((resp) => {
      if (resp.data.success) {
        toast.success('Project duplicated!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setTimeout(() => history.push('/projects'), 1200);
      } else {
        toast.error('Error ' + resp.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  const handlePlayPreview = () => {
    setPlayModePreview(PLAYING);
    let t = new Date();
    t.setSeconds(t.getSeconds());
    let startUnix = t.getTime();

    let previewIntervalId = window.setInterval(() => {
      let t2 = new Date();
      t2.setSeconds(t2.getSeconds());
      let currentUnixTime = t2.getTime();

      setCurrentTimePreview(currentTimePreview + (currentUnixTime - startUnix) / 1000);
    }, 150);
    setPreviewIntervalId(previewIntervalId);
  };

  const handlePauseOrStopPreview = () => {
    window.clearInterval(previewIntervalId);
    setPlayModePreview(PAUSED);
  };

  const handleStopPreview = () => {
    handlePauseOrStopPreview();
    setPlayModePreview(PAUSED);
    setCurrentTimePreview(0);
  };

  useEffect(() => {
    if (projectDuration > 0) {
      let rangeValue = currentTimePreview * (100 / projectDuration); // calculate new range
      setNewPreviewRange(rangeValue);
    }

    if (currentTimePreview >= projectDuration) {
      handleStopPreview();
    }
  }, [currentTimePreview]);

  const dataFormat = (data) => {
    if (!!data) {
      return new Date(data * 1000).toISOString().substr(14, 5);
    } else {
      return data;
    }
  };

  return (
    <>
      <Dialog
        maxWidth="786px"
        open={openFullPreviewModal}
        onClose={() => {
          setOpenFullPreviewModal(false);
          handleStopPreview();
        }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          style: { background: 'transparent', border: 'none', boxShadow: 'none' }
        }}
      >
        <DialogTitle>
          <IconButton
            onClick={() => {
              setOpenFullPreviewModal(false);
              handleStopPreview();
            }}
            sx={{ position: 'absolute', right: 0, top: 1, color: 'white' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: '20px', background: '#14142B', borderRadius: '20px', border: '1px solid #4E4B66' }}>
          <FullProjectPreview scenes={scenes} projectDuration={projectDuration} timeRangePreviewClicked={timeRangePreviewClicked} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'start', position: 'absolute', zIndex: 9999, bottom: hasUseTemplate || isProjectPage ? '70px' : '20px', display: 'flex', alignSelf: 'center' }}>
          {playModePreview !== PLAYING ? (
            <PLAY_SMALL onClick={() => handlePlayPreview()} size={40} style={{ fill: '#A996FF', alignSelf: 'center', cursor: 'pointer' }} />
          ) : (
            <AiFillPauseCircle style={{ fill: '#A996FF', alignSelf: 'center', cursor: 'pointer' }} size={40} onClick={handlePauseOrStopPreview} />
          )}
          <input
            style={{ width: '600px', marginLeft: '20px', backgroundSize: newPreviewRange + '% 100%' }}
            onChange={(e) => {
              if (playModePreview !== PLAYING) {
                let range = Number(e.target.value);
                let realCurrentPreviewTime = range / (100 / projectDuration);

                setCurrentTimePreview(realCurrentPreviewTime);
                setTimeRangePreviewClicked(!timeRangePreviewClicked);
              }
            }}
            step="0.25"
            type="range"
            min="0"
            max="100"
            value={newPreviewRange || 0}
            id="time-needle-preview"
          />
          <div style={{ fontSize: '13px', fontStyle: 'normal', fontFamily: '"Poppins"', fontWeight: 'bold', color: 'white' }}>{currentTimePreview === 0 ? '00:00' : dataFormat(currentTimePreview)}</div>
        </DialogActions>
        {hasUseTemplate === true && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <button
              onClick={() => setOpenProjSettingsDialog(true)}
              style={{ backgroundColor: 'transparent', color: 'white', border: '2px solid white', padding: '10px', borderRadius: '15px', fontFamily: 'Poppins', height: '44px', display: 'flex', alignItems: 'center' }}
            >
              <img src={useTemplateIcon} style={{ marginRight: '15px' }} alt="preview" />
              Use as a Template
            </button>
          </div>
        )}
        {isProjectPage && (
          <ProjectPreviewButton
            useTemplateIcon={useTemplateIcon}
            deleteHandler={deleteHandler}
            projectId={projectId}
            setOpenFullPreviewModal={setOpenFullPreviewModal}
            selectProject={selectProject}
            projectDuration={projectDuration}
            scenes={scenes}
          />
        )}
      </Dialog>
      {hasUseTemplate === true && (
        <ProjectSettingsDialogue
          data={{}}
          setOpenProjSettingsDialog={setOpenProjSettingsDialog}
          openProjSettingsDialog={openProjSettingsDialog}
          projects={[]}
          setProjects={() => {}}
          isNewProject={true}
          handleSaveNewProject={duplicateProject}
        />
      )}
    </>
  );
};

export default FullPreviewModal;
FullPreviewModal.defaultProps = {
  scenes: [],
  projectDuration: '',
  setOpenFullPreviewModal: () => {},
  openFullPreviewModal: false,
  hasUseTemplate: false,
  projectId: '',
  deleteHandler: () => {},
  isProjectPage: false
};
