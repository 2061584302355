import { ReactComponent as MoviesIcon } from '../images/MoviesIcon.svg';
import { ReactComponent as EditorIcon } from '../images/EditorIcon.svg';
import { ReactComponent as NewLibrary } from '../images/NewLibrary.svg';
import { ReactComponent as Promotions } from '../images/Promotions.svg';
import fayeIcon from '../images/fayeIcon.png';
import notificationBell from '../images/notificationBell.png';

const useIcons = () => {
  return { MoviesIcon, EditorIcon, NewLibrary, Promotions, fayeIcon, notificationBell };
};

export default useIcons;
