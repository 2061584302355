import { Grid, makeStyles } from '@material-ui/core';
import { useDrop } from 'react-dnd';
import { AiFillPlusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import sceneService from '../../../services/apiServices/sceneService';
import { ItemTypes } from '../../../utilities/items';
import projedit from '../css//projeditor.module.css';
import SceneCard from './scene/SceneCard';

const useStyles = makeStyles({
  sceneList: {
    display: 'flex',
    width: '100%',
    height: '100%'
  }
});
function SceneList({ scenes = [], activeSceneID, setActiveSceneID, setScenes, projectID, setInteractObject, setLibraryUpdated, saveProjectScenes }) {
  const deleteScene = (sceneID) => {
    const checkObj = scenes.find((x) => x.id === sceneID);

    const { axiosReq } = sceneService.deleteProjectScenes(sceneID);
    axiosReq
      .then((res) => {
        if (res.data.success === true) {
          const deletedSceneLength = checkObj.assets.find((x) => x.isMaster == true)?.length || 0;
          updateSceneList(sceneID, deletedSceneLength);
        }
      })
      .catch((err) => console.log('Error ' + err));
  };

  const updateSceneList = (sceneID, deletedSceneLength) => {
    const sceneIndex = scenes.indexOf(scenes.find((x) => x.id === sceneID));
    const scenesCopy = [...scenes.filter((x) => x.id !== sceneID).sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))];

    for (let i = sceneIndex; i < scenesCopy.length; i++) {
      scenesCopy[i].assets.map(function (x) {
        x.start = i > 0 ? x.start - deletedSceneLength : 0;
        return x;
      });
    }

    if (scenesCopy.length === 0) {
      let newScene = {};
      newScene.id = uuid();
      newScene.name = 'New scene';
      newScene.orderNumber = 1;
      newScene.projectID = projectID;
      newScene.assets = [];
      scenesCopy.push(newScene);
    }
    setScenes(scenesCopy);
    setActiveSceneID(scenesCopy[scenesCopy.length - 1].id);
  };

  const handleSaveSceneToLibrary = (sceneId, newSceneName, newSceneDescription, newPrivacyID) => {
    saveProjectScenes(true, () => {
      saveSceneForLibrary(sceneId, newSceneName, newSceneDescription, newPrivacyID);
    });
  };

  const saveSceneForLibrary = (sceneId, newSceneName, newSceneDescription, newPrivacyID) => {
    let sceneToSaveInLibrary = scenes.filter((x) => x.id == sceneId);
    if (scenes.length === 0) return;

    sceneToSaveInLibrary[0].name = newSceneName;
    sceneToSaveInLibrary[0].description = newSceneDescription;
    sceneToSaveInLibrary[0].privacyID = newPrivacyID;

    const { axiosReq, cancelTokenSource } = sceneService.saveScenesForLibrary(sceneToSaveInLibrary);
    axiosReq
      .then((resp) => {
        if (resp.data.success === true) {
          setLibraryUpdated(new Date().getTime());
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          return () => {
            cancelTokenSource.cancel();
          };
        } else {
          toast.error('Error ' + resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch((err) => {
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const addNewScene = (data) => {
    let newScene = {};
    newScene.id = uuid();
    newScene.name = 'New scene';
    newScene.orderNumber =
      scenes
        .map((x) => x.orderNumber)
        .sort()
        .slice(-1)
        .pop() + 1;
    newScene.projectID = projectID;
    newScene.assets = [];
    setScenes([...scenes, newScene]);
    setActiveSceneID(newScene.id);

    if (!!data) {
      if (data.assetType === 'scenes') {
        let item = {};
        item.type = 'sceneinsert';
        item.assets = data.assets.sort((a, b) => a.z - b.z);
        setInteractObject(item);
      } else if (data.assetType === 'pexelvideos') {
        let videoFile = data.video_files
          .filter((x) => x.quality === 'sd')
          .reduce((prev, current) => {
            return prev.width > current.width ? prev : current;
          });

        if (!videoFile) {
          videoFile = data.video_files
            .filter((x) => x.quality === 'hd')
            .reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
        }
        let item = {};
        item.type = 'assetinsert';
        item.assetId = uuid();
        item.ismain = true;
        item.name = data.url
          .split('/')
          .filter((x) => x)
          .pop();
        item.originalHeight = videoFile.height;
        item.originalWidth = videoFile.width;
        item.assetType = 'video';
        item.assetTypeID = 1;
        item.url = videoFile.link;
        let videoMeta = document.createElement('video');
        videoMeta.preload = 'metadata';
        videoMeta.src = videoFile.link;
        videoMeta.addEventListener('loadedmetadata', function () {
          item.originalLength = videoMeta.duration;
          setInteractObject(item);
        });
      } else if (data.assetType === 'pexelphotos') {
        let item = {};
        item.type = 'assetinsert';
        item.assetId = uuid();
        item.ismain = true;
        item.name = data.alt;
        item.originalHeight = data.height;
        item.originalLength = 5;
        item.originalWidth = data.width;
        item.assetType = 'image';
        item.assetTypeID = 2;
        item.url = data.src.original;
        setInteractObject(item);
      } else {
        if (data.assetType === 'video' && !!data.hasPreview && !data.previewUrl) {
          toast.warning('Please wait for the video to finish processing.', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          return;
        }

        let item = {};
        item.type = 'assetinsert';
        item.assetId = data.assetType === 'html' ? uuid() : data.id;
        item.ismain = true;
        item.name = data.name;
        item.originalHeight = data.originalHeight;
        item.originalLength = data.assetType === 'image' || data.assetType === 'html' ? 5 : data.originalLength;
        item.originalWidth = data.originalWidth;
        item.assetType = data.assetType;
        item.assetTypeID = data.assetTypeID;
        item.url = data.url;
        item.canvasStyle = data.canvasStyle;
        item.text = data.text;
        setInteractObject(item);
      }
    }
  };

  const classes = useStyles();

  const [{ canDrop6, isOver6 }, drop6] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        let data = positionedAsset.data;
        addNewScene(data);

        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    []
  );

  return (
    <Grid item xs={12} id={projedit.sceneListResp} className={`${classes.sceneList}`}>
      {scenes.map((scene, i) => {
        return (
          <SceneCard
            key={scene.id}
            scene={scene}
            activeSceneID={activeSceneID}
            setActiveSceneID={setActiveSceneID}
            no={i}
            deleteScene={deleteScene}
            handleSaveSceneToLibrary={handleSaveSceneToLibrary}
            setInteractObject={setInteractObject}
          />
        );
      })}

      <Grid style={{ display: 'flex', alignItems: 'center' }} ref={drop6}>
        <AiFillPlusCircle
          size={30}
          style={{ fill: '#A996FF',  cursor: 'pointer'  }}
          onClick={() => {
            if (scenes[scenes.length - 1].assets.length === 0) {
              toast.error('Your last scene is without assets', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }
            addNewScene();
          }}
        />
      </Grid>
    </Grid>
  );
}
export default SceneList;
SceneList.defaultProps = {
  scenes: [], 
  activeSceneID: '', 
  setActiveSceneID: ()=>{}, 
  setScenes: ()=>{}, 
  projectID: '', 
  setInteractObject: ()=>{}, 
  setLibraryUpdated: ()=>{}, 
  saveProjectScenes: ()=>{} 
}
