import Avatar from '@mui/joy/Avatar';
import { default as ReactAvatar } from 'react-avatar';

export default function AvatarUsers({ users = [] }) {
  return (
    <div style={{ position: 'relative' }}>
      {users.length > 0 &&
        users.map(
          (item, index) =>
            index < 3 && (
              <div key={item.id} style={{ border: '3px solid #14142B', borderRadius: '25px', position: 'absolute', top: -15, left: 15 * index, zIndex: users.length - index }}>
                <ReactAvatar size="30px" style={{ clipPath: 'circle()' }} round="true" key={item.id} name={item.firstName + ' ' + item.lastName} src={item.imageUrl} />
              </div>
            )
        )}
      {users.length > 3 && (
        <div style={{ border: '3px solid #14142B', borderRadius: '25px', position: 'absolute', top: -15, left: 55 }}>
          <Avatar style={{ backgroundColor: '#333230', width: '30px', height: '30px' }}>+{users.length - 3}</Avatar>
        </div>
      )}
    </div>
  );
}
