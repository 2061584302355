import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

const SaveDraftButton = ({ tittle, onClickHandler, backgroundColor = '#14142B', borderColor = 'white', color = 'white', hoverBackroundColor = '#282e30', hoverColor = 'white' }) => {
  const SaveButton = styled(Fab)(({ theme }) => ({
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    marginRight: '5px',
    marginTop: '10px',
    fontSize: '10px',
    width: '100px',
    border: `2px solid ${borderColor} !important`,
    color: color,
    borderRadius: '15px !important',
    backgroundColor: backgroundColor,
    '&:hover': {
      color: hoverColor,
      backgroundColor: hoverBackroundColor,
      transition: 'background-color 0.5s !important'
    }
    // '@media (max-width: 548px)': {
    //   height: '55px',
    //   width: '142px'
    // }
  }));
  return (
    <SaveButton size="large" variant="extended" onClick={onClickHandler}>
      {tittle}
    </SaveButton>
  );
};

export default SaveDraftButton;
