import { useContext, useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { PLAYING } from '../../../../constants/video_states';
import { CurrentTimeContext } from '../../../../context/current_time_context';
import { PlayModeContext } from '../../../../context/play_mode_context';
import CropLabel from '../CropLabel';
import { cropResize, useInteract } from '../useInteract';
import { rangeColors } from './../../../timeline/projectTime/AssetRangeColors';

const VideoStage = ({ clip, selectedAsset, timeRangeClicked, sceneDuration, sceneStart, setInteractObject }) => {
  let position = `translate(${clip.x + (clip.crop?.cropLeft || 0)}px, ${clip.y + (clip.crop?.cropTop || 0)}px)`;
  let rotation = clip.rotate ? `rotate(${clip.rotate}deg)` : '';
  const isAssetSelected = selectedAsset?.assetStyleID === clip.assetStyleID;

  const { currentTime } = useContext(CurrentTimeContext);
  const { playMode } = useContext(PlayModeContext);
  const draggableRef = useRef(null);
  const videoRef = useRef();

  const [visibility, setVisibility] = useState('hidden');
  const [clipStart, setClipStart] = useState(0);
  const [clipTrim, setClipTrim] = useState(0);
  const [clipLength, setClipLength] = useState(0);
  const [clipUrl, setClipUrl] = useState('');

  const [isCrop, setIsCrop] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    xRate: 0,
    yRate: 0
  });

  const ref = useInteract(isCrop, setInteractObject, clip.crop?.cropRight, clip.crop?.cropBottom, clip.crop?.cropLeft, clip.crop?.cropTop, videoRef, clip);

  useEffect(() => {
    if (!!clip) {
      setClipUrl(clip.asset.previewSrc ?? clip.asset.src);
    }
  }, []);

  useEffect(() => {
    if (clip.crop?.cropTop || clip.crop?.cropLeft) {
      setCurrentPosition({ xRate: -Math.floor(clip.crop?.cropLeft + 3 || 0), yRate: -Math.floor(clip.crop?.cropTop + 3 || 0) });
    }
  }, [clip.scale]);

  useEffect(() => {
    videoRef.current.volume = clip.volume;
  }, [clip.volume]);

  useEffect(() => {
    setClipStart(clip.start);
    setClipTrim(clip.trim);
    setClipLength(clip.length);
  }, [clip.trim, clip.start, clip.length]);

  const UpdateVideoElement = () => {
    if (currentTime >= clipStart - sceneStart && currentTime <= clipStart - sceneStart + clipLength) {
      setVisibility('visible');
    } else {
      setVisibility('hidden');
    }
  };

  useEffect(() => {
    if (playMode === PLAYING) {
      if (videoRef.current.paused && visibility === 'visible') {
        videoRef.current.play().then((_) => {});
      } else if (!videoRef.current.paused && visibility === 'hidden') {
        videoRef.current.pause();
      }
    } else {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
      }
    }
  }, [visibility, playMode]);

  useEffect(() => {
    let clipTime = currentTime - (clipStart - sceneStart) + clipTrim;
    let newTime = sceneStart + currentTime > clipStart && currentTime < clipStart - sceneStart + clipLength ? clipTime : clipTrim;
    videoRef.current.currentTime = newTime + '';
  }, [sceneDuration, clipStart, clipTrim, clipLength, sceneStart, timeRangeClicked, playMode]);

  useEffect(() => {
    UpdateVideoElement();
  }, [currentTime, clipStart, clipTrim, clipLength, sceneStart, sceneDuration]);

  let style = {
    zIndex: clip.z,
    opacity: clip.opacity || 1,
    transform: `${position} ${rotation}`,
    width: `${parseFloat(clip.w - (clip.crop?.cropRight || 0) - (clip.crop?.cropLeft || 0))}px`,
    height: `${parseFloat(clip.h - (clip.crop?.cropBottom || 0) - (clip.crop?.cropTop || 0))}px`,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  if (isAssetSelected) {
    style[!isCrop ? 'outline' : 'border'] = '3px solid ' + rangeColors[clip.asset.typeID];
  }

  const onDrag = (e, data) => {
    const { lastX, lastY } = data;
    setCurrentPosition({ xRate: lastX > 0 ? 0 : lastX, yRate: lastY > 0 ? 0 : lastY });
  };

  const onStop = () => {
    cropResize(ref, videoRef, clip, setInteractObject);
  };

  const stageClasses = ['asset-interact'];

  return (
    <>
      {isCrop && (
        <div
          data-z={clip.z}
          data-w={clip.w}
          data-h={clip.h}
          data-x={clip.x}
          data-y={clip.y}
          data-select="video"
          id={`screen_${clip.assetStyleID}`}
          scene-id={clip.sceneID}
          stage-id={clip.assetStyleID}
          data-master={clip.isMaster}
          style={{ ...style, ...{ pointerEvents: 'all' } }}
          className={`${stageClasses.join(' ')}`}
          ref={ref}
        >
          <Draggable
            nodeRef={draggableRef}
            position={{
              x: currentPosition.xRate,
              y: currentPosition.yRate
            }}
            onDrag={onDrag}
            onStop={onStop}
          >
            <div ref={draggableRef}>
              <video
                onEnded={() => {
                  setVisibility('hidden');
                  videoRef.current.pause();
                }}
                style={{ objectFit: 'cover' }}
                src={clipUrl}
                height={clip.h + 'px'}
                width={clip.w + 'px'}
                ref={videoRef}
                className={`resizable`}
                autobuffer="true"
              />
            </div>
          </Draggable>
          {isAssetSelected && <CropLabel isCrop={isCrop} handleSetIsCrop={setIsCrop} />}
        </div>
      )}
      {!isCrop && (
        <div
          data-z={clip.z}
          data-w={clip.w}
          data-h={clip.h}
          data-x={clip.x}
          data-y={clip.y}
          data-select="video"
          id={`screen_${clip.assetStyleID}`}
          scene-id={clip.sceneID}
          stage-id={clip.assetStyleID}
          data-master={clip.isMaster}
          style={style}
          className={`${stageClasses.join(' ')}`}
          ref={ref}
        >
          <div
            style={{
              transform: `translate(${-Math.floor(clip.crop?.cropLeft || 0)}px, ${-Math.floor(clip.crop?.cropTop || 0)}px)`
            }}
          >
            <video
              onEnded={() => {
                setVisibility('hidden');
                videoRef.current.pause();
              }}
              style={{ objectFit: 'cover' }}
              src={clipUrl}
              height={clip.h + 'px'}
              width={clip.w + 'px'}
              ref={videoRef}
              className={`resizable`}
              autobuffer="true"
            />
          </div>

          {isAssetSelected && <CropLabel isCrop={isCrop} handleSetIsCrop={setIsCrop} />}
        </div>
      )}
    </>
  );
};

export { VideoStage };

