import { useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ConfirmationScreen from '../auth/ConfirmationScreen';
import LoginScreen from '../auth/LoginScreen';
import MobileSidebar from './mobile/sideMenu/MobileSidebar';

const LoggedOut = ({ setIsLogged, isMobile }) => {
  const [loginVisible, setLoginVisible] = useState(true);

  return (
    <Router>
      {isMobile && <MobileSidebar isLogged={false} />}

      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <LoginScreen setIsLogged={setIsLogged} loginVisible={loginVisible} setLoginVisible={setLoginVisible} isMobile={isMobile} />
        </Route>
        <Route path="/signup">
          <LoginScreen setIsLogged={setIsLogged} loginVisible={false} setLoginVisible={() => (window.location.href = '/login')} isMobile={isMobile} />
        </Route>
        <Route path="/confirmation">
          <ConfirmationScreen />
        </Route>
        <Route path="*">
          <LoginScreen setIsLogged={setIsLogged} loginVisible={loginVisible} setLoginVisible={setLoginVisible} isMobile={isMobile} />
        </Route>
      </Switch>
    </Router>
  );
};
export default LoggedOut;
