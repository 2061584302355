import { makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import { useEffect, useRef, useState } from 'react';
import { useDrag } from 'react-dnd';
import Emitter from '../../../services/emitter';
import { ItemTypes } from '../../../utilities/items';
import AudioCard from './AudioCard';

const styleCards = makeStyles({
  videoStyle: {
    margin: '5px',
    width: '32.2%',
    maxHeight: '650px',
    marginLeft: '5px',
    border: '1px solid black',
    padding: '0'
  },
  cardWrapp: {
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '5px'
  },
  cardMedia: { width: '100%', height: '112px', objectFit: 'scale-down' },
  mediaWrapper: {
    position: 'relative',
    paddingBottom: '56.25%'
  },
  videoElement: { objectFit: 'cover', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }
});

export const Cards = ({ headerAssetType, data, pexelurl }) => {
  const cardsStyle = styleCards();

  const [imageSrc, setImageSrc] = useState(data.url);
  const [rowArray, setRowArray] = useState([]);

  const videoRef = useRef(null);
  const [cardHover, setCardHover] = useState(false);
  const [openModalPreview, setOpenModalPreview] = useState(false);
  const [pexelsVideoUrl, setPexelsVideoUrl] = useState('');

  const [sceneThumbnail, setSceneThumbnail] = useState('');
  const [sceneThumbnailType, setSceneThumbnailType] = useState('');

  const handleOnClick = (e, img, row) => {
    setImageSrc(img);
    setRowArray((arr) => [...arr, row]);
    Emitter.emit(Emitter.CHANGE, {
      imageSrc: img,
      rows: row,
      rowArray: rowArray
    });
  };

  useEffect(() => {
    if (headerAssetType === 'scenes') {
      if (!!data && !!data.assets && !!data.assets.find((x) => x.isMaster === true)) {
        setSceneThumbnail(data.assets.find((x) => x.isMaster === true).asset.src);
        setSceneThumbnailType(data.assets.find((x) => x.isMaster === true).asset.type);
      }
    } else if (headerAssetType === 'pexelvideos' || headerAssetType === 'pexelphotos') {
      if (headerAssetType === 'pexelvideos') {
        let videoFile = data.video_files
          .filter((x) => x.quality === 'sd')
          .reduce((prev, current) => {
            return prev.width > current.width ? prev : current;
          });

        if (!videoFile) {
          videoFile = data.video_files
            .filter((x) => x.quality === 'hd')
            .reduce((prev, current) => {
              return prev.width < current.width ? prev : current;
            });
        }

        setPexelsVideoUrl(videoFile.link);
      }
      data.assetType = headerAssetType;
    }
  }, [data]);

  useEffect(() => {
    hoverOverElement(cardHover);
  }, [cardHover]);

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.CARD,
      item: { data },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (data && dropResult) {
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging()
      })
    }),
    [data]
  );

  const hoverOverElement = (isHovering) => {
    if (data.hasPreview && !data.previewUrl) return;

    if (videoRef.current) {
      if (videoRef.current.paused && isHovering) {
        videoRef.current.play();
      } else if (!videoRef.current.paused && !isHovering) {
        videoRef.current.pause();
      }
    }
  };

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div style={{ opacity }}>
      <Card style={{ backgroundColor: '#14142A', borderRadius: '15px' }} onMouseEnter={() => setCardHover(true)} onMouseLeave={() => setCardHover(false)}>
        <CardActionArea
          className={cardsStyle.cardActionArea}
          onMouseOver={(e) => {
            e.target.style.opacity = '0.5';
          }}
          onMouseOut={(e) => {
            e.target.style.opacity = '1';
          }}
          onClick={(e) => handleOnClick(e, imageSrc, 1)}
        >
          {(headerAssetType === 'image' || headerAssetType === 'html') && <CardMedia ref={drag} className={cardsStyle.cardMedia} component="img" image={imageSrc} />}
          {headerAssetType === 'video' && (
            <div className={cardsStyle.mediaWrapper} ref={drag} onClick={() => setOpenModalPreview(true)}>
              <video loop className={cardsStyle.videoElement} ref={videoRef} style={{ width: '100%', objectFit: 'cover' }} onMouseEnter={() => hoverOverElement(true)} onMouseLeave={() => hoverOverElement(false)}>
                <source src={(data.previewUrl || data.url) + '#t=1'} />
              </video>
              {data.hasPreview && data.previewUrl && <span style={{ position: 'absolute', opacity: '1', color: 'white', right: '10px' }}>Preview</span>}
              {data.hasPreview && !data.previewUrl && (
                <>
                  <span style={{ position: 'absolute', opacity: '1', color: 'white', right: '10px' }}>In Progress...</span>
                  <CircularProgress sx={{ color: 'rgba(255,255,255,0.6)', left: '40%', bottom: '40%', position: 'absolute' }} color="inherit" />
                </>
              )}
            </div>
          )}
          {headerAssetType === 'pexelphotos' && (
            <>
              <CardMedia ref={drag} className={cardsStyle.cardMedia} component="img" image={pexelurl} />
            </>
          )}
          {headerAssetType === 'pexelvideos' && (
            <>
              <div className={cardsStyle.mediaWrapper} ref={drag} onClick={() => setOpenModalPreview(true)}>
                <video loop ref={videoRef} className={cardsStyle.videoElement} onMouseEnter={() => hoverOverElement(true)} onMouseLeave={() => hoverOverElement(false)}>
                  <source src={pexelsVideoUrl} />
                </video>
              </div>
            </>
          )}
          {headerAssetType === 'scenes' && (
            <>
              <CardMedia className={cardsStyle.cardMedia} ref={drag} component={sceneThumbnailType === 'video' ? 'video' : 'img'} image={sceneThumbnail} />
            </>
          )}

          {headerAssetType === 'audio' && data.assetType === 'audio' && (
            <>
              <CardMedia ref={drag}>
                <AudioCard data={data} />
              </CardMedia>
            </>
          )}
        </CardActionArea>
      </Card>
      <Modal
        open={openModalPreview}
        onClose={() => setOpenModalPreview(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <video loop className={cardsStyle.videoStyle} controls autoPlay>
          <source src={headerAssetType === 'video' ? data.url : pexelsVideoUrl} />
        </video>
      </Modal>
    </div>
  );
};
