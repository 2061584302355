import React from 'react';
import { useHistory } from 'react-router-dom';
import faye_landing from '../../../files/faye_landing.png';
import faye_logo_landing from '../../../files/faye_logo_landing.svg';
import ButtonFab from '../../../styled/buttons/ButtonFab';
import msg from '../../auth/medias/msg.png';
import mobStyle from './mobileCss/MobileLoggedInCss.module.css';

const MobileLoggedIn = ({ handleLoginClick, handleChangeEmail, handleChangePassword, setOpenForgotPassModal, emailError, passwordError }) => {
  const navigate = useHistory();

  return (
    <section className={mobStyle.pageWrapp}>
      <article className={mobStyle.upperPart} style={{ backgroundImage: `url(${faye_landing})`, backgroundSize: 'cover' }}>
        <div className={mobStyle.logoWrapp}>
          <img className={mobStyle.logoImg} src={faye_logo_landing} alt="logo pic" />
          <h3 className={mobStyle.firstTitle}>Create a video </h3>
          <h3>in minutes</h3>
        </div>
      </article>

      <article className={mobStyle.lowerPart}>
        <div className={mobStyle.lowerPartInner}>
          <div className={mobStyle.lowerPartTitleWrapp}>
            <h4 className={mobStyle.signTitle}>Sign In</h4>
          </div>

          <div className={mobStyle.formWrapp}>
            <div className={mobStyle.inputBoxWrapp}>
              <label className={mobStyle.label}>E-mail</label>
              <div className={mobStyle.inputWrapp}>
                <input className={mobStyle.input} type="text" placeholder="Enter you email" onChange={handleChangeEmail} />
                <img src={msg} alt="message" className={mobStyle.logoPlaceholderImg} />
                <span className={mobStyle.errorMsg}>{emailError}</span>
              </div>
            </div>

            <div className={mobStyle.inputBoxWrapp}>
              <label className={mobStyle.label}>Password</label>

              <div className={mobStyle.inputWrapp}>
                <input className={mobStyle.input} type="password" placeholder="Enter you password" onChange={handleChangePassword} />
                <span className={mobStyle.errorMsg}>{passwordError}</span>
              </div>
            </div>
          </div>

          <div className={mobStyle.btnWrapp}>
            <ButtonFab tittle="Sign in" onClickHandler={handleLoginClick} isLoggedIn={false} />
            <p className={mobStyle.signupTxt}>
              {'Don’t have an account? '}
              <span
                style={{ color: 'white', cursor: 'pointer' }}
                onClick={() => {
                  navigate.push('/signup');
                }}
              >
                <u>Create one for free</u>
              </span>
            </p>
          </div>
        </div>
      </article>
    </section>
  );
};

export default MobileLoggedIn;
