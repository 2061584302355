import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const loginUrl = '/api/Auth/Login';
const registerUrl = '/api/Auth/Register';
const confirmUrl = '/api/Auth/Confirm';
const inviteUserUrl = '/api/Auth/inviteUser/';
const changeRoleUrl = '/api/Auth/changeRole/';
const deactivateUserUrl = '/api/Auth/deactivatUser/';
const forgotPasswordUrl = '/api/Auth/forgotpassword/';
const isFirstLoginUrl = '/api/Auth/first-login/';

const doLogin = (email, password) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return {
    req: axios.post(baseUrl + loginUrl, { email: email, password: password }),
    cancelTokenSource: source
  };
};

const doRegister = (firstName, lastName, email, password) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return {
    req: axios.post(baseUrl + registerUrl, { firstName: firstName, lastName: lastName, email: email, password: password }, { headers: { 'Content-Type': 'application/json' } }),
    cancelTokenSource: source
  };
};

const confirmRegistration = (token, email) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  return {
    req: axios.get(baseUrl + confirmUrl, {
      params: { Token: token, Email: email },
      cancelToken: source.token,
      headers: {}
    }),
    cancelTokenSource: source
  };
};

const inviteUser = (email) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + inviteUserUrl,
      data: { email: email },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const changeUserRole = (UserID, RoleID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + changeRoleUrl,
      data: { ID: UserID, RoleID, RoleID },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const deactivateUser = (UserID) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios({
      method: 'post',
      url: baseUrl + deactivateUserUrl,
      data: { ID: UserID },
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

const forgotPassword = (email) => {
  return {
    req: axios({
      method: 'post',
      url: baseUrl + forgotPasswordUrl,
      data: { email: email }
    })
  };
};

const isFirstLogin = () => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  let token = localStorage.getItem('token');
  return {
    req: axios.get(baseUrl + isFirstLoginUrl, {
      cancelToken: source.token,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }),
    cancelTokenSource: source
  };
};

export default {
  doLogin,
  doRegister,
  confirmRegistration,
  inviteUser,
  changeUserRole,
  deactivateUser,
  forgotPassword,
  isFirstLogin
};
