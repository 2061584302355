import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { PAUSED, PLAYING } from '../../../../constants/video_states';
import { CurrentTimeContext } from '../../../../context/current_time_context';
import { PlayModeContext } from '../../../../context/play_mode_context';
import { rangeColors } from "./../../../timeline/projectTime/AssetRangeColors";

const AudioStage = ({ audio, selectedAsset, timeRangeClicked, sceneDuration, sceneStart }) => {
  let position = `translate(${audio.x}px, ${audio.y}px)`;

  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef();
  const { currentTime } = useContext(CurrentTimeContext);

  const [visibility, setVisibility] = useState('hidden');

  const [audioStart, setAudioStart] = useState(0);
  const [audioTrim, setAudioTrim] = useState(0);
  const [audioLength, setAudioLength] = useState(0);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audio.volume;
    }
  }, [audio.volume])

  useEffect(() => {
    setAudioStart(audio.start);
    setAudioTrim(audio.trim);
    setAudioLength(audio.length);

  }, [audio.trim, audio.start, audio.length])

  const UpdateAudioElement = () => {
    if (currentTime >= audioStart - sceneStart && currentTime < audioStart - sceneStart + audioLength) {
      setVisibility('visible');
      if (playMode === PLAYING) {
        if (audioRef.current.paused && !isPlaying) {
          audioRef.current.play().then(_ => {
            setIsPlaying(true);
          });
        }
      } else {
        if (!audioRef.current.paused && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      }
    } else {
      setVisibility('hidden');
      if (!audioRef.current.paused && isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
        audioRef.current.currentTime = audioTrim;
      }
    }
  };

  useEffect(() => {
    audioRef.current.currentTime = currentTime < audioStart - sceneStart ? audioTrim : currentTime - audioStart + sceneStart + audioTrim;
  }, [sceneDuration, audioTrim, audioStart, audioLength, sceneStart, timeRangeClicked]);

  useEffect(() => {
    UpdateAudioElement();
  }, [currentTime, audioLength, audioStart, audioTrim, sceneDuration]);

  let style = {
    zIndex: audio.z,
    transform: `${position}`,
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  if (!!selectedAsset && selectedAsset.assetStyleID === audio.assetStyleID) {
    style.outline = '3px solid ' + rangeColors[audio.asset.typeID];
  }

  const { playMode } = useContext(PlayModeContext);

  useEffect(() => {
    if (playMode === PAUSED) {
      if (!audioRef.current.paused && isPlaying) {
        audioRef.current.pause();
      }
    } else if (playMode === PLAYING) {
      if (currentTime >= audioStart - sceneStart && currentTime < audioStart - sceneStart + audioLength) {
        if (audioRef.current.paused && !isPlaying) {
          audioRef.current.play().then(_ => {
            setIsPlaying(true);
          });
        }
      } else {
        if (!audioRef.current.paused && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      }
    }
  }, [playMode]);

  return (
    <div data-select="audio" data-z={audio.z} data-w={audio.w} data-h={audio.h} data-x={audio.x} data-y={audio.y}  id={`screen_${audio.assetStyleID}`} scene-id={audio.sceneID} stage-id={audio.assetStyleID} style={style}>
      <audio
        ref={audioRef}
        style={{
          display: 'flex',
          borderRadius: '25px',
          border: '1px solid rebeccapurple',
          cursor: 'pointer',
          backgroundColor: '#262338',
          width: '242px'
        }}
        onEnded={() => {
          setVisibility('hidden');
          audioRef.current.pause();
          setIsPlaying(false);
          audioRef.current.currentTime = audioTrim;
        }}
        onPause={() => setIsPlaying(false)}
        controls={false}
      >
        <source src={audio.asset.src + '#t=' + audioTrim + ',' + audioLength} />
      </audio>
    </div>
  );
};

export { AudioStage };
