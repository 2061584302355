import LinearProgress from '@mui/material/LinearProgress';
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator';
import { useEffect, useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import libraryService from '../../../services/apiServices/libraryService';
import MoviesSettingsDialogue from '../MoviesSettingsDialogue';
import useMovieThumbnailUpload from '../custom/useMovieThumbnailUpload';

const styles = {
  buttonStyle: {
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CA0250',
    border: '1px solid #CA0250',
    borderRadius: '12px',
    color: 'white',
    fontFamily: "'Poppins'",
    fontSize: '15px'
  },
  btnName: {
    marginLeft: '3px'
  }
};

const UploadMovie = ({ setTriggerNotifications }) => {
  const history = useHistory();
  const [openMovieSettingsDialog, setOpenMovieSettingsDialog] = useState(false);
  const [uploadToken, setUploadToken] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { onMovieThumbnailUpload } = useMovieThumbnailUpload({ data: {}, setThumbnailUrl: () => {}, isChannel: false });
  console.log('uploadProgress', uploadProgress);
  useEffect(() => {
    libraryService.subscribeToLibraryHub(localStorage.username, localStorage.username, setUploadProgress);

    return async () => {
      libraryService.unsubscribeToLibraryHub();
    };
  }, []);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (uploadProgress > 0) {
        let result = window.confirm('Upload is not finished. Leave?');
        if (uploadToken && result) {
          uploadToken.cancel(' - Cancelled upload');
        }
        return result;
      }
    });

    return () => {
      unblock();
    };
  }, [uploadProgress]);

  const UploadAssets = (formData, callBack = (id, refresh) => {}, movieUploadFiles) => {
    setOpenMovieSettingsDialog(false);
    const { req, cancelTokenSource } = libraryService.uploadAssets(formData, setUploadProgress);
    setUploadToken(cancelTokenSource);
    req
      .then(async (resp) => {
        setUploadToken(null);
        if (resp.data.success === true) {
          setUploadProgress(100);
          toast.success('Movie uploaded!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
          try {
            let t = await generateVideoThumbnails(movieUploadFiles[0], 1);
            const response = await fetch(t[0]);
            const blob = await response.blob();
            const thumbnailBlob = new File([blob], 'thumbnail', { type: 'image/jpeg' });
            onMovieThumbnailUpload(thumbnailBlob, resp.data.data[0]);
          } catch (error) {}
          callBack(resp.data.data[0], () => setTriggerNotifications((x) => !x));

          setTimeout(() => {
            setUploadProgress(0);
          }, 1500);
        } else {
          toast.error('Error ' + resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          setTimeout(() => setUploadProgress(0), 3500);
        }
      })
      .catch((err) => {
        setUploadToken(null);
        setTimeout(() => setUploadProgress(0), 3500);
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const UploadSingleMovie = async (movieUploadFiles, callback = () => {}) => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    let promiseArr = [];
    let thumbnailBlob = '';

    for (let i = 0; i < movieUploadFiles.length; i++) {
      const url = URL.createObjectURL(movieUploadFiles[i]);
      const videoElement = document.createElement('video');
      videoElement.src = url;
      videoElement.preload = 'metadata';

      let newPromise = new Promise((resolve, reject) => {
        videoElement.addEventListener('loadedmetadata', function () {
          let result = {};
          result.originalWidth = this.videoWidth;
          result.originalHeight = this.videoHeight;
          result.originalLength = this.duration;
          result.file = movieUploadFiles[i];
          result.type = 'video';
          result.isError = false;
          resolve(result);
        });

        videoElement.onerror = async (error) => {
          let result = {};
          result.originalWidth = 0;
          result.originalHeight = 0;
          result.originalLength = 0;
          result.file = movieUploadFiles[i];
          result.type = 'video';
          result.isError = true;
          resolve(result);
        };
      });
      promiseArr.push(newPromise);
    }

    Promise.all([...promiseArr]).then((values) => {
      var formData = new FormData();
      for (let i = 0; i < values.length; i++) {
        let pos = '[' + i + ']';
        formData.append('Form' + pos + '.File', values[i].file);
        formData.append('Form' + pos + '.Type', values[i].type);
        formData.append('Form' + pos + '.OriginalLength', values[i].originalLength);
        formData.append('Form' + pos + '.OriginalWidth', values[i].originalWidth);
        formData.append('Form' + pos + '.OriginalHeight', values[i].originalHeight);
        formData.append('Form' + pos + '.IsMovie', true);
        formData.append('Form' + pos + '.IsError', values[i].originalHeight < 720 || values[i].isError || isAndroid);
        formData.append('Form' + pos + '.IncreaseSize', values[i].originalHeight !== 0 && values[i].originalHeight < 720);
      }
      UploadAssets(formData, callback, movieUploadFiles);
    });
  };

  return (
    <>
      {uploadProgress === 0 && (
        <button style={styles.buttonStyle} type="button" onClick={() => setOpenMovieSettingsDialog(true)}>
          <FiUpload color="white" size={19} style={{ marginRight: '3px' }} />
          <span style={styles.btnName}>Upload</span>
        </button>
      )}
      {uploadProgress > 0 && (
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginLeft: '20px' }}>
          <LinearProgress
            variant="determinate"
            value={uploadProgress}
            sx={{
              position: 'absolute',
              width: '150px',
              height: '100%',
              right: '0px',
              borderRadius: '6px',
              backgroundColor: '#E580A7',
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#CA0250'
              }
            }}
          />
          <span style={{ color: 'white', zIndex: 99, padding: '8px', width: '150px', textAlign: 'center' }}> {uploadProgress}%</span>
        </div>
      )}

      {openMovieSettingsDialog && (
        <MoviesSettingsDialogue
          data={{}}
          openMovieSettingsDialog={openMovieSettingsDialog}
          setOpenMovieSettingsDialog={setOpenMovieSettingsDialog}
          thumbnailUrl={''}
          movies={[]}
          setMovies={() => {}}
          setThumbnailUrl={() => {}}
          uploadFileRef={null}
          uploadThumbnail={() => {}}
          isSettings={true}
          addToRender={UploadSingleMovie}
          isUploadMovie={true}
        />
      )}
    </>
  );
};

export default UploadMovie;
UploadMovie.defaultProps = {
  movies: [],
  setMovies: () => {}
};
