import { useState } from 'react';
import { toast } from 'react-toastify';
import projectApi from '../../../services/apiServices/projects/projectApi';
import sceneService from '../../../services/apiServices/sceneService';
import { hasMissingTextImage, updateMissingTextImage } from '../../../utilities/helpers';
import plus from '../../inspiration/img/plus.png';
import trashBin from '../../inspiration/img/trashbin.png';
import MoviesSettingsDialogue from '../../movies/MoviesSettingsDialogue';

const buttonStyle = {
  width: '180px',
  marginRight: '15px',
  backgroundColor: 'transparent',
  color: 'white',
  border: '2px solid white',
  padding: '10px',
  borderRadius: '15px',
  fontFamily: 'Poppins',
  height: '44px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};
const previewStyle = { display: 'flex', justifyContent: 'center', marginTop: '10px' };

const ProjectPreviewButton = ({ scenes, useTemplateIcon, deleteHandler, selectProject, projectId, setOpenFullPreviewModal, projectDuration = 0 }) => {
  const [openExport, setOpenExport] = useState(false);

  const deleteModalItem = () => {
    deleteHandler(projectId);
    setOpenFullPreviewModal(false);
  };

  const saveProjectScenes = (saveMissingTextImgs = false, callback = () => {}) => {
    let scenesToSave = scenes;
    if (saveMissingTextImgs) {
      const hasMissingTxtImg = hasMissingTextImage(scenes);
      if (hasMissingTxtImg) {
        scenesToSave = updateMissingTextImage(scenes);
      }
    }

    const { axiosReq, cancelTokenSource } = sceneService.saveProjectScenes(scenesToSave, projectId);

    axiosReq
      .then((resp) => {
        if (resp.data.success === true) {
          callback();
          toast.success(resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        } else {
          toast.error('Error ' + resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
      })
      .catch((err) => {
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const handleRenderingScenes = (name, description, privacyID) => {
    const { axiosReq, cancelTokenSource } = projectApi.addProjectToRender(projectId, Math.floor(projectDuration), name, description, privacyID);
    axiosReq.then((r) => {
      if (r.data.success === true) {
        toast.success(r.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        toast.error('Error ' + r.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });
  };

  const addToRender = (name, description, privacyID) => {
    if (!name) {
      toast.warn('Name cannot be empty.', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    saveProjectScenes(true, () => {
      handleRenderingScenes(name, description, privacyID);
    });
  };

  return (
    <>
      <div style={previewStyle}>
        <button onClick={() => setOpenExport(true)} style={buttonStyle}>
          <img src={plus} style={{ marginRight: '10px' }} alt="activate" /> Export
        </button>

        <button
          onClick={() => {
            selectProject(projectId);
          }}
          style={buttonStyle}
        >
          <img src={useTemplateIcon} style={{ marginRight: '10px' }} alt="preview" /> Edit
        </button>

        <button onClick={deleteModalItem} style={buttonStyle}>
          <img src={trashBin} style={{ marginRight: '10px' }} alt="delete trashbin" /> Delete
        </button>
      </div>
      {openExport && (
        <MoviesSettingsDialogue
          data={{}}
          openMovieSettingsDialog={openExport}
          setOpenMovieSettingsDialog={setOpenExport}
          thumbnailUrl={''}
          movies={[]}
          setMovies={() => {}}
          setThumbnailUrl={() => {}}
          uploadFileRef={null}
          uploadThumbnail={() => {}}
          isSettings={false}
          addToRender={addToRender}
        />
      )}
    </>
  );
};

export default ProjectPreviewButton;
ProjectPreviewButton.defaultProps = {
  useTemplateIcon: () => {},
  deleteHandler: () => {},
  selectProject: () => {},
  projectId: '',
  setOpenFullPreviewModal: () => {},
  projectDuration: 0
};
