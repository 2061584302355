import { Link } from 'react-router-dom';
import cssTerms from './Terms.module.css';

const TermsOfUse = ({ showChmark, setShowChmark }) => {
  return (
    <div className={cssTerms.termsPolicyWrapp}>
      <span onClick={() => setShowChmark((prev) => !prev)} className={cssTerms.circleWrapp}>
        {showChmark ? <span className={cssTerms.chmark}>&#10003;</span> : ''}
      </span>
      <span className={cssTerms.textWrapp}>
        {"I agree to the "}
        <Link style={{ color: 'white' }} to={{ pathname: 'https://faye-io-web.azurewebsites.net/Home/TermsOfUse' }} target="_blank">
          {"Terms of Use"}
        </Link>
        {" and "}
        <Link style={{ color: 'white' }} to={{ pathname: 'https://faye-io-web.azurewebsites.net/Home/PrivacyPolicy' }} target="_blank">
          Privacy Policy
        </Link>
      </span>
    </div>
  );
};
export default TermsOfUse;
