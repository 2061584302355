import succStyle from './SuccessfullyReset.module.css';
import letter from './letter.png';

const SuccessfullyReset = ({ mail }) => {
  return (
    <article className={succStyle.succWrapp}>
      <div className={succStyle.MsgLogoWrapp}>
        <img src={letter} alt="Message" />

        <h1 className={succStyle.titleSucc}>Check your mail</h1>

        <p className={succStyle.descSuc}>
          We have sent a mail with instructions to <b>{mail}</b>
        </p>
      </div>
    </article>
  );
};

export default SuccessfullyReset;
