import { Grid } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { CurrentTimeContext } from '../../context/current_time_context';
import { CurrentTimePreviewContext } from '../../context/current_time_preview_context';
import { PlayModeContextProvider } from '../../context/play_mode_context';
import { PlayModePreviewContextProvider } from '../../context/play_mode_preview_context';
import { SidebarActionsContext } from '../../context/sidebar_actions_context';
import brandingApi from '../../services/apiServices/brandingApi';
import companyApi from '../../services/apiServices/companyApi';
import projectApi from '../../services/apiServices/projects/projectApi';
import AllRoutes from '../routes/AllRoutes';
import Sidebar from '../sidebar/Sidebar';
import MobileSidebar from './mobile/sideMenu/MobileSidebar';

const LoggedIn = ({ classes, isMobile }) => {
  const hist = createBrowserHistory();
  const [newProjectName, setNewProjectName] = useState('');
  const [didMount, setDidMount] = useState(false);
  const [brandingColors, setBrandingColors] = useState([]);
  const [brandingFonts, setBrandingFonts] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  // const [remainingSeconds, setRemainingSeconds] = useState(0);
  const [currentTimePreview, setCurrentTimePreview] = useState(0);
  const [userDetail, setUserDetail] = useState({});
  const [userDetailUnchanged, setUserDetailUnchanged] = useState({});
  const [insteadDateImg, setInsteadDateImg] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showSideMenu, setShowSideMenu] = useState(true);
  const [triggerNotifications, setTriggerNotifications] = useState(false);

  const handleGlobalError = useCallback((event) => {
    if (event.error.message !== 'The error you provided does not contain a stack trace.') {
      const { req } = companyApi.logError({ Message: localStorage.getItem('fayeproject') + ' ' + event.error.message, StackTrace: event.error.stack });
    }
  }, []);

  const getUserDetail = async () => {
    const { req } = projectApi.getSettingsUser();

    req.then((res) => {
      if (res.data.success === true) {
        setUserDetail(res.data.data);
        setProfilePic(res.data.data.imageUrl);
        setFirstName(res.data.data.firstName);
        setLastName(res.data.data.lastName);
        setUserDetailUnchanged(res.data.data);
      }
    });
  };

  const getBranding = () => {
    const { axiosReq } = brandingApi.getBranding();
    axiosReq.then((res) => {
      if (res.data.success === true) {
        setBrandingColors(res.data.data.filter((x) => x.brandingTypeID === 1).map((x) => x.item));
        setBrandingFonts(res.data.data.filter((x) => x.brandingTypeID === 2).map((x) => x.item));

        res.data.data
          .filter((x) => x.brandingTypeID === 2)
          .forEach(async (x) => {
            const myFont = new FontFace(x.item, `url(${x.url})`);
            await myFont.load();

            document.fonts.add(myFont);
          });

        setDidMount(true);
      } else {
        setDidMount(true);
      }
    });
  };

  useEffect(() => {
    getUserDetail();
    getBranding();
    setInsteadDateImg(new Date().getTime());
    setShowSideMenu(window.outerWidth > 450);
  }, []);

  useEffect(() => {
    window.addEventListener('error', handleGlobalError);

    return () => window.removeEventListener('error', handleGlobalError);
  }, []);

  useEffect(() => {
    if (!!userDetail.companyID) {
      companyApi.subscribeToNotificationHub(userDetail.companyID, setTriggerNotifications);

      return async () => {
        companyApi.unsubscribeToNotificationHub();
      };
    }
  }, [userDetail.companyID]);

  if (!didMount) {
    return <div></div>;
  }

  const mainScreenValue = showSideMenu ? 11 : 12;

  return (
    <BrowserRouter history={hist}>
      <Grid container style={{ flexWrap: 'nowrap', width: !isMobile ? '100%' : '100vw' }}>
        <Switch>
          <Route exact path="/signup">
            <Redirect to={isMobile ? '/library' : '/promotions'} />
          </Route>
        </Switch>
        <SidebarActionsContext.Provider value={{ newProjectName, setNewProjectName,/* remainingSeconds, setRemainingSeconds, */showSideMenu, setShowSideMenu }}>
          {!isMobile ? (
            <Grid style={{ display: showSideMenu ? 'block' : 'none' }} item xs={3} lg={1} md={1} sm={2}>
              <Sidebar
                profilePic={profilePic}
                userDetail={userDetail}
                firstName={firstName}
                lastName={lastName}
                showSideMenu={showSideMenu && window.outerWidth < 450}
                setShowSideMenu={setShowSideMenu}
                triggerNotifications={triggerNotifications}
                setTriggerNotifications={setTriggerNotifications}
              />
            </Grid>
          ) : (
            <MobileSidebar isLogged={true} />
          )}

          <CurrentTimeContext.Provider value={{ currentTime, setCurrentTime }}>
            <CurrentTimePreviewContext.Provider value={{ currentTimePreview, setCurrentTimePreview }}>
              <PlayModeContextProvider>
                <PlayModePreviewContextProvider>
                  <Grid item xs={showSideMenu && window.outerWidth > 450 ? 9 : 12} lg={mainScreenValue} md={mainScreenValue} sm={showSideMenu ? 10 : 12}>
                    <Switch>
                      <AllRoutes
                        isMobile={isMobile}
                        classes={classes}
                        brandingColors={brandingColors}
                        brandingFonts={brandingFonts}
                        profilePic={profilePic}
                        setProfilePic={setProfilePic}
                        insteadDateImg={insteadDateImg}
                        getUserDetail={getUserDetail}
                        setUserDetail={setUserDetail}
                        userDetailUnchanged={userDetailUnchanged}
                        setUserDetailUnchanged={setUserDetailUnchanged}
                        userDetail={userDetail}
                        triggerNotifications={triggerNotifications}
                        setTriggerNotifications={setTriggerNotifications}
                      />
                    </Switch>
                  </Grid>
                </PlayModePreviewContextProvider>
              </PlayModeContextProvider>
            </CurrentTimePreviewContext.Provider>
          </CurrentTimeContext.Provider>
        </SidebarActionsContext.Provider>
      </Grid>
    </BrowserRouter>
  );
};

export default LoggedIn;
