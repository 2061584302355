const headWrapp = { width: '100%', display: 'flex', paddingBottom: '10px', borderBottom: '0.2px solid rgba(255,255,255, 0.25)', marginTop: '10px' };
const headItem = { display: 'flex', overflow: 'auto', alignItems: 'center', width: '11%', color: 'white', fontWeight: '600', fontSize: '13px', textAlign: 'left', fontFamily: "'Poppins'" };
const headItem0 = { display: 'flex', overflow: 'auto', alignItems: 'center', width: '15%', color: 'white', fontWeight: '600', fontSize: '13px', textAlign: 'left', fontFamily: "'Poppins'" };

const smallCellItem = { display: 'flex', alignItems: 'center', width: '8%' };

const TableHead = ({ isChannel }) => {
  let headColumns = ['Preview', 'Project Name', 'Date', 'Contributors', 'Status', 'Recipient', 'Template', ''];
  if (isChannel) {
    headColumns = ['Preview', 'Project Name', 'Date', 'Rating', 'Activate video', 'Reviewer', 'Template', ''];
  }

  return (
    <div style={headWrapp}>
      {headColumns.map((item, index) => (
        <div key={item} style={index === 0 ? headItem0 : headItem}>
          {item}
        </div>
      ))}
      <div style={smallCellItem}></div>
    </div>
  );
};

export default TableHead;
