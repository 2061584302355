import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { PAUSED, PLAYING } from '../../../../constants/video_states';
import { CurrentTimePreviewContext } from '../../../../context/current_time_preview_context';
import { PlayModePreviewContext } from '../../../../context/play_mode_preview_context';

const AudioStagePreview = ({ audio, timeRangePreviewClicked }) => {
  let position = `translate(0px, 0px)`;
  const [isPlaying, setIsPlaying] = useState(false);

  const { currentTimePreview } = useContext(CurrentTimePreviewContext);
  const { playModePreview } = useContext(PlayModePreviewContext);
  const audioRef = useRef();
  const [visibility, setVisibility] = useState('hidden');

  const UpdateVideoPreviewElement = () => {
    if (currentTimePreview >= audio.start && currentTimePreview < audio.start + audio.length) {
      setVisibility('visible');
      if (playModePreview === PLAYING) {
        if (audioRef.current.paused && !isPlaying) {
          audioRef.current.play().then(_ => {
            setIsPlaying(true);
          });
        }
      } else {
        if (!audioRef.current.paused && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      }
    } else {
      setVisibility('hidden');
      if (!audioRef.current.paused && isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = audio.volume;
    }
  }, [audio.volume])

  useEffect(() => {
    let ct = currentTimePreview - audio.start + audio.trim;
    audioRef.current.currentTime = audio.start > currentTimePreview ? audio.trim : ct;
  }, [timeRangePreviewClicked, audio.start, audio.length]);

  useEffect(() => {
    UpdateVideoPreviewElement();
  }, [currentTimePreview, audio.length, audio.start, audio.trim]);

  let style = {
    zIndex: audio.z,
    transform: `${position}`,
    width: 0,
    height: 0,
    overflow: 'hidden',
    position: 'absolute',
    visibility
  };

  useEffect(() => {
    if (playModePreview === PAUSED) {
      if (!audioRef.current.paused && isPlaying) {
        audioRef.current.pause();
      }
    } else if (playModePreview === PLAYING) {
      if (currentTimePreview >= audio.start && currentTimePreview < audio.start + audio.length) {
        if (audioRef.current.paused && !isPlaying) {
          audioRef.current.play().then(_ => {
            setIsPlaying(true);
          });
        }
      } else {
        if (!audioRef.current.paused && isPlaying) {
          audioRef.current.pause();
          setIsPlaying(false);
        }
      }
    }
  }, [playModePreview]);

  return (
    <div data-z={audio.z} data-x={audio.x} data-y={audio.y} id={`screen_${audio.assetStyleID}`} scene-id={audio.sceneID} stage-id={audio.assetStyleID} style={style}>
      <audio
        ref={audioRef}
        style={{
          display: 'flex',
          borderRadius: '25px',
          border: '1px solid rebeccapurple',
          cursor: 'pointer',
          backgroundColor: '#262338',
          width: '242px'
        }}
        onPause={() => setIsPlaying(false)}
        onEnded={() => {
          setVisibility('hidden');
          audioRef.current.pause();
          setIsPlaying(false);
          audioRef.current.currentTime = audio.trim;
        }}
        controls={false}
      >
        <source src={audio.asset.src + '#t=' + audio.trim + ',' + audio.length} />
      </audio>
    </div>
  );
};

export { AudioStagePreview };
