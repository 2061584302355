import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Input } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDrop } from 'react-dnd';
import { AiOutlineBgColors, AiOutlineMore } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import noThumb from '../../../../files/NoThumbnail.jpg';
import Emitter from '../../../../services/emitter';
import { ItemTypes } from '../../../../utilities/items';
import DeleteModal from '../../../modals/deleteModal/DeleteModal';
import projedit from '../../css/projeditor.module.css';

const useStyles = makeStyles({
  scenecardWrapper: {
    display: 'flex'
  },
  rootCard: {
    display: 'flex',
    minWidth: '275px',
    margin: '10px',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    position: 'relative'
  },
  saveSceneDialogText: {
    fontFamily: 'poppins',
    fontSize: '13px',
    opacity: '50%',
    marginTop: '5px'
  },
  saveSceneTextField: {
    backgroundColor: '#262338',
    borderRadius: '15px',
    width: '100%',
    border: 'none',
    marginTop: '8px',
    color: 'white'
  },
  saveButton: {
    height: '49px',
    width: '130px',
    borderRadius: '0px',
    backgroundColor: '#A996FF',
    color: '#4700AB'
  },
  dividLineWrapp: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  dividLine: {
    width: '36%',
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px'
  },
  dividLineHolder: {
    width: '100%',
    display: 'flex'
  },
  activeImg: {
    position: 'relative'
  },
  downloadWrapper: {
    position: 'absolute',
    bottom: '21px',
    left: '8px'
  },
  downlicon: {
    backgroundColor: '#A996FF',
    padding: '5px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  sceneWrapper: {
    position: 'absolute',
    bottom: '70px',
    right: '0px'
  },
  deleteIcon: {
    backgroundColor: '#A996FF',
    padding: '5px',
    borderRadius: '7px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scenesDividerWrapp: {
    width: '50px',
    height: '100%',
    backgroundColor: '',
    display: 'flex',
    alignItems: 'center'
  },
  transparentWrapper: {
    width: '12%',
    backgroundColor: 'transparent'
  },
  selectWrapp: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    outline: 'none',
    backgroundColor: '#262338',
    color: 'white',
    marginTop: '8px'
  }
});

function SceneCard({ scene, activeSceneID, setActiveSceneID, no, deleteScene, handleSaveSceneToLibrary, setInteractObject }) {
  const classes = useStyles();
  const [imageCurrentSrc, setImageCurrentSrc] = useState();
  const [currentRowsArray, setCurrentRowsArray] = useState([]);
  const [currentRowsNumber, setCurrentRowsNumber] = useState();

  const [anchorColorBackgroundMenuEl, setAnchorColorBackgroundMenuEl] = useState(null);
  const openColorBackgroundMenu = Boolean(anchorColorBackgroundMenuEl);
  const [sceneBackgroundColor, setSceneBackgroundColor] = useState(scene.assets?.find((x) => x.asset.type === 'background')?.htmlBackground || '#000000');

  const [sceneOptionsAnchorEl, setSceneOptionsAnchorEl] = useState(null);
  const [openSceneOptions, setOpenSceneOptions] = useState(false);
  const [openSaveSceneDialog, setOpenSaveSceneDialog] = useState(false);

  const [newSceneName, setNewSceneName] = useState('');
  const [newSceneDescription, setNewSceneDescription] = useState('');
  const [newPrivacyID, setNewPrivacyID] = useState(1);

  const [openDelete, setOpenDelete] = useState(false);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: '#4700AB',
    width: '131px',
    height: '50px',
    borderRadius: '20px',
    backgroundColor: '#A996FF',
    marginRight: '15px',
    marginBottom: '15px',
    '&:hover': {
      backgroundColor: '#4700AB',
      color: '#A996FF'
    }
  }));

  const insertAsset = ({ data }, ismain = false) => {
    if (data.assetType === 'scenes') {
      let item = {};
      item.type = 'sceneinsert';
      item.assets = data.assets.sort((a, b) => a.z - b.z);
      setInteractObject(item);
    } else if (data.assetType === 'pexelvideos') {
      let videoFile = data.video_files
        .filter((x) => x.quality === 'sd')
        .reduce((prev, current) => {
          return prev.width > current.width ? prev : current;
        });

      if (!videoFile) {
        videoFile = data.video_files
          .filter((x) => x.quality === 'hd')
          .reduce((prev, current) => {
            return prev.width < current.width ? prev : current;
          });
      }
      let item = {};
      item.type = 'assetinsert';
      item.assetId = uuid();
      item.ismain = ismain;
      item.name = data.url
        .split('/')
        .filter((x) => x)
        .pop();
      item.originalHeight = videoFile.height;
      item.originalWidth = videoFile.width;
      item.assetType = 'video';
      item.assetTypeID = 1;
      item.url = videoFile.link;
      let videoMeta = document.createElement('video');
      videoMeta.preload = 'metadata';
      videoMeta.src = videoFile.link;
      videoMeta.addEventListener('loadedmetadata', function () {
        item.originalLength = videoMeta.duration;
        setInteractObject(item);
      });
    } else if (data.assetType === 'pexelphotos') {
      let item = {};
      item.type = 'assetinsert';
      item.assetId = uuid();
      item.ismain = ismain;
      item.name = data.alt;
      item.originalHeight = data.height;
      item.originalLength = 5;
      item.originalWidth = data.width;
      item.assetType = 'image';
      item.assetTypeID = 2;
      item.url = data.src.original;
      setInteractObject(item);
    } else {
      if (data.assetType === 'video' && !!data.hasPreview && !data.previewUrl) {
        toast.warning('Please wait for the video to finish processing.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      let item = {};
      item.type = 'assetinsert';
      item.assetId = data.assetType === 'html' ? uuid() : data.id;
      item.ismain = ismain;
      item.name = data.name;
      item.originalHeight = data.originalHeight;
      item.originalLength = data.assetType === 'image' || data.assetType === 'html' ? 5 : data.originalLength;
      item.originalWidth = data.originalWidth;
      item.assetType = data.assetType;
      item.assetTypeID = data.assetTypeID;
      item.url = data.url;
      item.previewUrl = data.previewUrl;
      item.canvasStyle = data.canvasStyle;
      item.text = data.text;
      setInteractObject(item);
    }
  };

  useEffect(() => {
    Emitter.on(Emitter.CHANGE, (newValue) => {
      setImageCurrentSrc(newValue.imageSrc);
      setCurrentRowsNumber(newValue.rows);
      setCurrentRowsArray(newValue.rowArray);
    });
    // Specify how to clean up after this effect:
    return function cleanup() {
      Emitter.off(Emitter.CHANGE);
    };
  });

  const closeSceneOptions = (event) => {
    event.stopPropagation();
    setSceneOptionsAnchorEl(null);
    setOpenSceneOptions(false);
  };

  const handleSceneBackgroundColor = (colorHex) => {
    let item = {};
    item.type = 'htmlBackground';
    item.sceneid = scene.id;
    item.htmlBackground = colorHex;
    setInteractObject(item);

    setSceneBackgroundColor(colorHex);
  };

  const [{ canDrop4, isOver4 }, drop4] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: (item, monitor) => {
        const positionedAsset = { ...monitor.getClientOffset(), ...item };
        insertAsset(JSON.parse(JSON.stringify(positionedAsset)), true);
        return positionedAsset;
      },
      collect: (monitor) => {
        return { mockedKey: 'mockedVal', monitor };
      }
    }),
    []
  );

  const masterClip = scene.assets.find((x) => x.isMaster === true);

  return (
    <div className={classes.scenecardWrapper}>
      <Card key={no} elevation={0} className={`${classes.rootCard}`}>
        {scene?.assets?.length > 0 && (
          <AiOutlineBgColors
            color={sceneBackgroundColor}
            style={{ cursor: 'pointer', position: 'absolute', bottom: '10px', left: '10px', zIndex: 9 }}
            size={30}
            onClick={(e) => setAnchorColorBackgroundMenuEl(e.currentTarget)}
          />
        )}

        <CardActionArea ref={drop4} className={`${projedit.activeImgWrappResp}`} style={{ width: '70%', borderRadius: '10px', maxWidth: '190px' }} onClick={() => setActiveSceneID(scene.id)}>
          <CardMedia
            key={!masterClip ? noThumb : masterClip.asset.src}
            className={`${projedit.imgResp} ${projedit.activeImgResp} ${classes.activeImg}`}
            style={{ borderRadius: '10px', objectFit: masterClip?.asset?.type === 'html' ? 'contain' : 'cover', border: scene.id === activeSceneID ? '8px solid  #A996FF' : '1px solid #4E4B66' }}
            component={!masterClip ? 'img' : masterClip.asset.type === 'image' || masterClip.asset.type === 'html' ? 'img' : 'video'}
            height="100"
            image={!masterClip ? noThumb : masterClip.asset.previewSrc || masterClip.asset.src}
          />
          <Tooltip title="Scene menu">
            <div className={classes.sceneWrapper}>
              <AiOutlineMore
                style={{ cursor: 'pointer', stroke: '#A996FF', width: '40px', strokeWidth: '100', filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 1))' }}
                color="#A996FF"
                size={30}
                onClick={(event) => {
                  event.stopPropagation();
                  setSceneOptionsAnchorEl(event.currentTarget);
                  setOpenSceneOptions(true);
                }}
              />
            </div>
          </Tooltip>
        </CardActionArea>

        <Grid className={classes.transparentWrapper}></Grid>

        <Grid className={classes.scenesDividerWrapp}>
          <Grid id={projedit.dividLineWrappResp} className={`${classes.dividLineWrapp} ${projedit.dividLineWrappResp}`}>
            <span id={projedit.dividLineResp} className={`${classes.dividLine}`} style={{ backgroundColor: '#A996FF' }}>
              <NavigateBeforeIcon style={{ marginRight: '-8px' }} />
              <NavigateNextIcon style={{ marginLeft: '-8px' }} />
            </span>
          </Grid>
        </Grid>
      </Card>
      <ClickAwayListener onClickAway={(event) => closeSceneOptions(event)}>
        <Popper open={openSceneOptions} anchorEl={sceneOptionsAnchorEl} placement={'top'} transition style={{ zIndex: 10 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box className="options-cloud" sx={{ bgcolor: '#4E4B66', color: 'white', borderRadius: '20px' }}>
                <MenuList>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenSaveSceneDialog(true);
                    }}
                  >
                    Save
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      // deleteScene(scene.id);
                      closeSceneOptions(e);
                      setOpenDelete(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>
      <Dialog
        PaperProps={{
          style: { backgroundColor: '#14142B', width: '35%', height: '65%', border: '1.5px solid #4E4B66', borderRadius: '30px', color: 'white', padding: '10px' }
        }}
        open={openSaveSceneDialog}
        onClose={() => setOpenSaveSceneDialog(false)}
        aria-labelledby="savescene-dialog-title"
        aria-describedby="savescene-dialog-description"
      >
        <DialogTitle id="savescene-dialog-title">Save Scene</DialogTitle>
        <DialogContent>
          <span className={classes.saveSceneDialogText}>Title:</span>
          <Input
            disableUnderline={true}
            sx={{ input: { color: 'white', height: '43px', marginLeft: '10px' } }}
            className={classes.saveSceneTextField}
            variant="outlined"
            onChange={(e) => setNewSceneName(e.target.value)}
          />
          <span className={classes.saveSceneDialogText}>Description:</span>
          <textarea
            style={{ color: 'white', height: '71px', paddingLeft: '10px' }}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => setNewSceneDescription(e.target.value)}
            type="text"
            className={classes.saveSceneTextField}
          ></textarea>
          <span className={classes.saveSceneDialogText}>Privacy:</span>
          <select
            className={classes.selectWrapp}
            value={newPrivacyID}
            onChange={(e) => {
              setNewPrivacyID(e.target.value);
            }}
          >
            <option value={1}>Private</option>
            <option value={2}>My Company</option>
            <option value={3}>Public</option>
          </select>
        </DialogContent>
        <DialogActions>
          <ColorButton
            variant="contained"
            onClick={(e) => {
              e.stopPropagation();

              if (!newSceneName || !newSceneDescription) {
                toast.error('Enter Name and Description.', {
                  position: 'top-right',
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true
                });
                return;
              }
              handleSaveSceneToLibrary(scene.id, newSceneName, newSceneDescription, newPrivacyID);
              setSceneOptionsAnchorEl(null);
              setOpenSceneOptions(false);
              setOpenSaveSceneDialog(false);
            }}
          >
            Save
          </ColorButton>
        </DialogActions>
      </Dialog>
      <Menu
        id="colorbackground-menu"
        anchorEl={anchorColorBackgroundMenuEl}
        open={openColorBackgroundMenu}
        onClose={() => setAnchorColorBackgroundMenuEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            backgroundColor: '#262338'
          }
        }}
      >
        <div>
          <SketchPicker color={sceneBackgroundColor} onChangeComplete={(e) => handleSceneBackgroundColor(e.hex)} />
        </div>
      </Menu>

      {openDelete && <DeleteModal setOpenDeleteModal={setOpenDelete} deleteFuncion={() => deleteScene(scene.id)} type="scene-asset" question={'Delete scene?'} projectData={scene} />}
    </div>
  );
}

export default SceneCard;
SceneCard.defaultProps = {
  scene: {},
  activeSceneID: '',
  setActiveSceneID: () => {},
  no: '',
  deleteScene: () => {},
  handleSaveSceneToLibrary: () => {},
  setInteractObject: () => {}
};
