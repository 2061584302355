import { useState } from 'react'
import folderIcon from '../../movies/media_movie/Folders.png';

const MobileFolder = ({ setActiveBtnHeader }) => {
    const [folderMobileModal, setFolderMobileModal] = useState(false);
    const [searchInputMobileModal, setSearchInputMobileModal] = useState(false);

    return (
        <div style={{ position: 'relative', width: '100%' }}>
            <img src={folderIcon} alt='folder' onClick={() => {
                setSearchInputMobileModal(false);
                setFolderMobileModal(prev => !prev);
            }} />

            {folderMobileModal && <div style={{ position: 'absolute', top: -8, left: '55px', zIndex: '15', backgroundColor: '#262237', borderRadius: '10px', width: '120px' }}>
                <div style={{ padding: '5px', marginLeft: '12px' }}
                    onClick={() => {
                        setActiveBtnHeader(0)
                        setFolderMobileModal(false);
                    }}
                >
                    <span>Scenes</span>
                </div>

                <div style={{ padding: '5px', marginLeft: '12px' }}
                    onClick={() => {
                        setActiveBtnHeader(1)
                        setFolderMobileModal(false);
                    }
                    }
                >
                    <span>Assets</span>
                </div>
            </div>
            }

            {/* {searchInputMobileModal && <input type='text' 
      onChange={(e) =>setSearchTerm(e.target.value)}
      style={{position:'absolute', top: -38, left: '50px',marginRight: '5px',  fontSize:'14px', height:'32px', color:'white', paddingLeft:'6px', backgroundColor: '#262237', borderRadius:'10px', border: '1px solid #262237', outline: 'none'}} /> }
    
    <img src={searchIcon} alt='search' 
        style={{marginLeft:'15px'}}
        onClick={()=>{
          setFolderMobileModal(false);
          setSearchInputMobileModal(prev => !prev);
        }}
  /> */}
        </div>
    )
}

export default MobileFolder
MobileFolder.defaultProps = {
    setActiveBtnHeader: () => {}
}