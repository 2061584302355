import { Grid } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import libraryService from '../../../../services/apiServices/libraryService';
import DeleteModal from '../../../modals/deleteModal/DeleteModal';
import uploadImg from '../pics/upload.png';
import UploadFilAsset from './UploadFilAsset';
import useFilterAssetStyle from './useFilterAssetStyle';

const baseStyle = {
  flex: 1,
  height: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FilterRight = ({ onMobile = false, deleteAssets, showUpload, activeTab, callAssets, assetsForDelete, width, isOnBodyPicker = false, assetsData, setAssetsData }) => {
  const history = useHistory();
  const { classes } = useFilterAssetStyle();
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [uploadToken, setUploadToken] = useState(null);
  const isAndroid = /Android/i.test(navigator.userAgent);

  let accept = '';
  if (activeTab === 'video') {
    accept = 'video/*';
  } else if (activeTab === 'image') {
    accept = 'image/*';
  } else if (activeTab === 'audio') {
    accept = 'audio/*';
  }

  useEffect(() => {
    libraryService.subscribeToLibraryHub(localStorage.username, localStorage.username, setUploadProgress);

    return async () => {
      libraryService.unsubscribeToLibraryHub();
    };
  }, []);

  const onDropRejected = (rejected) => {
    if (rejected.length > 3) {
      toast.warn('Max files allowed is 5', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  };

  const onDrop = (acceptedFiles) => {
    if (activeTab === 'image') {
      let promiseArr = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        let img = new Image();
        img.src = URL.createObjectURL(acceptedFiles[i]);

        let newPromise = new Promise((resolve, reject) => {
          img.onload = () => {
            let result = {};
            result.originalWidth = img.width;
            result.originalHeight = img.height;
            result.file = acceptedFiles[i];
            result.type = activeTab;
            resolve(result);
          };
        });
        promiseArr.push(newPromise);
      }

      Promise.all([...promiseArr]).then((values) => {
        var formData = new FormData();
        for (let i = 0; i < values.length; i++) {
          let pos = '[' + i + ']';
          formData.append('Form' + pos + '.File', values[i].file);
          formData.append('Form' + pos + '.Type', values[i].type);
          formData.append('Form' + pos + '.OriginalLength', 0);
          formData.append('Form' + pos + '.OriginalWidth', values[i].originalWidth);
          formData.append('Form' + pos + '.OriginalHeight', values[i].originalHeight);
        }
        UploadAssets(formData, 'image');
      });
    } else if (activeTab === 'video') {
      let promiseArr = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        const url = URL.createObjectURL(acceptedFiles[i]);
        const videoElement = document.createElement('video');
        videoElement.src = url;
        videoElement.preload = 'metadata';

        let newPromise = new Promise((resolve, reject) => {
          videoElement.addEventListener('loadedmetadata', function () {
            let result = {};
            result.originalWidth = this.videoWidth;
            result.originalHeight = this.videoHeight;
            result.originalLength = this.duration;
            result.file = acceptedFiles[i];
            result.type = activeTab;
            result.isError = false;
            resolve(result);
          });

          videoElement.onerror = async (error) => {
            let result = {};
            result.originalWidth = 0;
            result.originalHeight = 0;
            result.originalLength = 0;
            result.file = acceptedFiles[i];
            result.type = activeTab;
            result.isError = true;
            resolve(result);
          };
        });
        promiseArr.push(newPromise);
      }

      Promise.all([...promiseArr]).then((values) => {
        var formData = new FormData();
        for (let i = 0; i < values.length; i++) {
          let pos = '[' + i + ']';
          formData.append('Form' + pos + '.File', values[i].file);
          formData.append('Form' + pos + '.Type', values[i].type);
          formData.append('Form' + pos + '.OriginalLength', values[i].originalLength);
          formData.append('Form' + pos + '.OriginalWidth', values[i].originalWidth);
          formData.append('Form' + pos + '.OriginalHeight', values[i].originalHeight);
          formData.append('Form' + pos + '.IsError', values[i].originalHeight < 720 || values[i].isError || isAndroid);
          formData.append('Form' + pos + '.IncreaseSize', values[i].originalHeight !== 0 && values[i].originalHeight < 720);
        }

        UploadAssets(formData, 'video');
      });
    } else if (activeTab === 'audio') {
      let promiseArr = [];

      for (let i = 0; i < acceptedFiles.length; i++) {
        const url = URL.createObjectURL(acceptedFiles[i]);
        const audioElement = document.createElement('audio');
        audioElement.src = url;

        let newPromise = new Promise((resolve, reject) => {
          audioElement.addEventListener('loadedmetadata', function () {
            let result = {};
            result.originalWidth = 0;
            result.originalHeight = 0;
            result.originalLength = this.duration;
            result.file = acceptedFiles[i];
            result.type = activeTab;
            resolve(result);
          });
        });
        promiseArr.push(newPromise);
      }

      Promise.all([...promiseArr]).then((values) => {
        var formData = new FormData();
        for (let i = 0; i < values.length; i++) {
          let pos = '[' + i + ']';
          formData.append('Form' + pos + '.File', values[i].file);
          formData.append('Form' + pos + '.Type', values[i].type);
          formData.append('Form' + pos + '.OriginalLength', values[i].originalLength);
          formData.append('Form' + pos + '.OriginalWidth', values[i].originalWidth);
          formData.append('Form' + pos + '.OriginalHeight', values[i].originalHeight);
        }

        UploadAssets(formData, 'audio');
      });
    }
  };

  const UploadAssets = (formData, type) => {
    setOpenUploadModal(false);
    const { req, cancelTokenSource } = libraryService.uploadAssets(formData, setUploadProgress);
    setUploadToken(cancelTokenSource);
    req
      .then((resp) => {
        setUploadToken(null);
        if (resp.data.success === true) {
          setUploadProgress(100);
          toast.success('Files uploaded!', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          if (!isOnBodyPicker) {
            callAssets(type);
          } else {
            callAssets(new Date().getTime());
          }

          setTimeout(() => {
            setUploadProgress(0);
          }, 1500);
        } else {
          toast.error('Error ' + resp.data.message, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });

          setTimeout(() => setUploadProgress(0), 3500);
        }
      })
      .catch((err) => {
        setUploadToken(null);
        setTimeout(() => setUploadProgress(0), 3500);
        toast.error('Error ' + err.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      });
  };

  const styleInnerUpload = {
    height: '100%',
    width: '100%',
    backgroundColor: '#14142B',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '3px dashed #6B6B7A',
    borderRadius: '12px'
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    onDrop: onDrop,
    maxFiles: 5,
    onDropRejected: onDropRejected,
    accept: accept
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (uploadProgress > 0) {
        let result = window.confirm('Upload is not finished. Leave?');
        if (uploadToken && result) {
          uploadToken.cancel(' - Cancelled upload');
        }
        return result;
      }
    });

    return () => {
      unblock();
    };
  }, [uploadProgress]);

  return (
    <Grid container className={classes.filterRight}>
      {!isOnBodyPicker && !onMobile && (
        <Grid item xs={6} sm={6} lg={4} md={4}>
          <div
            type="button"
            className={classes.deleteBtnFilt}
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            Delete
          </div>
        </Grid>
      )}

      <Grid item xs={onMobile ? 12 : 6} sm={onMobile ? 12 : 6} lg={4} md={5} style={{ width: width, position: 'relative' }}>
        {showUpload && uploadProgress > 0 && (
          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginLeft: '20px' }}>
            <LinearProgress
              variant="determinate"
              value={uploadProgress}
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                right: '0px',
                borderRadius: '6px',
                backgroundColor: '#E580A7',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#CA0250'
                }
              }}
            />
            <span style={{ color: 'white', zIndex: 99, padding: '8px' }}> {uploadProgress}%</span>
          </div>
        )}
        {showUpload && uploadProgress === 0 && <UploadFilAsset onMobile={onMobile} setOpenUploadModal={setOpenUploadModal} isOnBodyPicker={isOnBodyPicker} />}
      </Grid>

      <Modal
        open={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div className={classes.uploadModal}>
          <div className={classes.uploadTitleWrapp}>
            <h2 style={{ color: 'white' }}>Upload</h2>
            <IconButton
              style={{ marginLeft: 'auto', color: 'white' }}
              onClick={(e) => {
                setOpenUploadModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.uploadBody}>
            <div {...getRootProps({ style })} style={styleInnerUpload}>
              <input {...getInputProps()} />
              <img src={uploadImg} alt="upload" />
              <p style={{ color: '#6B6B7A', padding: '20px' }}>Drag 'n' drop some files here, or click to select files</p>
            </div>
          </div>
        </div>
      </Modal>

      {openDeleteModal && (
        <DeleteModal
          setOpenDeleteModal={setOpenDeleteModal}
          activeTab={activeTab}
          assetsForDelete={assetsForDelete}
          deleteFuncion={deleteAssets}
          type="asset"
          question={assetsForDelete.length > 1 ? 'Delete assets?' : 'Delete asset?'}
          text="Are you sure you want to delete this asset from the Asset library?"
        />
      )}
    </Grid>
  );
};

export default FilterRight;
FilterRight.defaultProps = {
  onMobile: false,
  deleteAssets: [],
  showUpload: false,
  activeTab: 0,
  callAssets: () => {},
  assetsForDelete: [],
  width: '',
  isOnBodyPicker: false,
  assetsData: [],
  setAssetsData: () => {}
};
