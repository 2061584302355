import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { AiOutlineMore } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import videoIcon from '../../../../files/icons/Video_icon.png';
import projectApi from '../../../../services/apiServices/projects/projectApi';
import useStyleCardAsset from '../../../library/assetSection/cardasset/useStyleCardAsset';
import ChannelSettingsModal from '../../../modals/ChannelSettingsModal';
import DeleteModal from '../../../modals/deleteModal/DeleteModal';
import MoviePageThumbnailImage from '../../custom/MoviePageThumbnailImage';
import ChannelMenu from './ChannelMenu';

const channelStyles = makeStyles({
  descriptionWrapp: {
    width: '100%',
    height: '105px',
    backgroundColor: ' #262338',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px',
    ['@media (max-width:600px)']: {
      width: '98%'
    }
  },
  channelTitle: {
    width: '100%',
    fontWeight: 'bold',
    height: '36px',
    paddingLeft: '15px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  bottomData: {
    height: '70px',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottomLeftRadius: '12px',
    borderBottomRightRadius: '12px'
  },
  restBoxData: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '17px'
  },
  rest: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '15px'
  },
  numberOfMovies: {
    margin: 0,
    padding: 0,
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  iconRest: {
    margin: 0,
    padding: 0
  },
  paraDate: {
    margin: 0,
    padding: 0,
    opacity: '0.5',
    fontSize: '12px'
  },
  dateWrapp: {
    width: '50%',
    paddingRight: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  channelThumb: {
    borderRadius: '15px 15px 0px 0px !important'
  }
});

const SingleChannel = ({ data, setActiveBtnHeader, setTriggerNotifications, setChannelID }) => {
  const history = useHistory();
  const { styleMovieThumbnail } = useStyleCardAsset();
  const cssStyle = styleMovieThumbnail();

  const [selectedMoreId, setSelectedMoreId] = useState(false);
  const [channelSettingsAnchor, setChannelSettingsAnchor] = useState(null);
  const [openChannelSettingsModal, setOpenChannelSettingsModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState(data.channelThumbnailUrl);
  const styles = channelStyles();

  const closeOptionsMenu = () => {
    setChannelSettingsAnchor(null);
  };

  const deleteChannelHandler = () => {
    const { req } = projectApi.deleteChannel(data.id);
    req.then((resp) => {
      if (resp.data.success === true) {
        setTriggerNotifications((x) => !x);
      }
    });
    setOpenDeleteModal(false);
  };

  return (
    <div className={cssStyle.newMovieWrapp}>
      <div className={`${cssStyle.newMovie} ${cssStyle.thubmnailSize}`}>
        <div
          className={cssStyle.newMovie}
          onClick={() => {
            setTimeout(() => history.push('/projects', { channelId: data.id }), 300);
          }}
        >
          <MoviePageThumbnailImage cssStyle={{ ...cssStyle, ...styles }} data={data} thumbnailUrl={thumbnailUrl} />
        </div>

        <div className={cssStyle.movieOptions}>
          <AiOutlineMore
            onMouseOver={(event) => {
              event.stopPropagation();

              setChannelSettingsAnchor(event.currentTarget);
              setSelectedMoreId(true);
            }}
            onMouseLeave={() => setSelectedMoreId(false)}
            style={{ cursor: 'pointer', stroke: '#A996FF', width: '40px', strokeWidth: '100', filter: 'drop-shadow(3px 3px 3px rgb(0 0 0 / 1))' }}
            color="#A996FF"
            size={30}
          />
        </div>

        <div className={styles.descriptionWrapp}>
          <div className={styles.channelTitle}>{data.name}</div>

          <div className={styles.bottomData}>
            <div className={styles.restBoxData}>
              <div className={styles.rest}>
                <img className={styles.iconRest} src={videoIcon} alt="Movie count" />
                <span className={styles.numberOfMovies}>{data.movieCount}</span>
              </div>

              <div className={styles.dateWrapp}>
                <p className={styles.paraDate}>{new Date(data.created).toLocaleDateString('de')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChannelMenu
        selectedMoreId={selectedMoreId}
        setSelectedMoreId={setSelectedMoreId}
        channelSettingsAnchor={channelSettingsAnchor}
        closeOptionsMenu={closeOptionsMenu}
        setOpenChannelSettingsModal={setOpenChannelSettingsModal}
        handleOpenDeleteModal={setOpenDeleteModal}
      />

      {openChannelSettingsModal && (
        <ChannelSettingsModal
          isMobile={false}
          setOpenChannelSettingsModal={setOpenChannelSettingsModal}
          openChannelSettingsModal={openChannelSettingsModal}
          data={data}
          thumbnailUrl={thumbnailUrl}
          setThumbnailUrl={setThumbnailUrl}
          setTriggerNotifications={setTriggerNotifications}
        />
      )}

      {openDeleteModal && (
        <DeleteModal setOpenDeleteModal={setOpenDeleteModal} deleteFuncion={deleteChannelHandler} type="project" text="Are you sure you want delete channel" projectData={{}} question={'Delete channel?'} />
      )}
    </div>
  );
};

export default SingleChannel;
SingleChannel.defaultProps = {
  data: { name: '', movieCount: '', created: '' },
  isMobile: false,
  setTriggerNotifications: () => {}
};
