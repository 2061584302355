import { toast } from 'react-toastify';

 const PrivacySelect = ({classes, isMobile, setAcceptToggle, isSettings, data, privacyID, setPrivacyID}) => {
  return (
    <>
        <p className={classes.shareMovieDialogText}>Privacy:</p>
        <select
          style={{height: isMobile && '40px'}}
          className={classes.selectWrapp}
          value={privacyID}
          onChange={(e) => {
            setAcceptToggle(false);
            if (!data.isPrivacyEditable && isSettings) {
              toast.warn('Privacy can be edited only by user who created project.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
              });
              return;
            }
            setPrivacyID(e.target.value);
          }}
        >
          <option value={1}>Private (only visible for you)</option>
          <option value={2}>My Company (visible for company)</option>
          <option value={3}>Public (open for public outside of Faye)</option>
        </select>
    </>
  )
}

export default PrivacySelect
PrivacySelect.defaultProps = {
  classes: {}, 
  isMobile: false, 
  setAcceptToggle: () => {}, 
  isSettings: false, 
  data: [], 
  privacyID: '', 
  setPrivacyID: () => {}
}