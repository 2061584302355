import {useEffect, useState} from 'react';
import projectApi from '../../services/apiServices/projects/projectApi';
import deleteUserApi from '../../services/apiServices/deleteUserApi';

const useUserMgmt = () => {
    const [users, setUsers] = useState([]); 

    useEffect(() => {
      const { axiosReq } = projectApi.getFayeUsers()
      axiosReq
        .then(resp => setUsers(resp.data))
        .catch(err => console.log(err))
    }, []);
  
    function deletingRow(e) {
        const { req, cancelTokenSource } = deleteUserApi.deleteUser(e.key);
        req.then()
        .catch(err => console.log(err))
        return () => {
          cancelTokenSource.cancel()
        }
      }
  return {users, deletingRow}
}

export default useUserMgmt