import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { AiOutlineMenuFold } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import companyApi from '../../services/apiServices/companyApi';
import projectApi from '../../services/apiServices/projects/projectApi';
import ProjectSettingsDialogue from '../movies/ProjectSettingsDialogue';
import useCustomHooks from './customHooks/useCustomHooks';

const Sidebar = ({ profilePic, userDetail, firstName, lastName, showSideMenu, setShowSideMenu, triggerNotifications, setTriggerNotifications }) => {
  const history = useHistory();
  const { useIcons, useStyle, useSidebarFunc } = useCustomHooks();
  const [appVersion, setAppVersion] = useState('');
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [lastNotificationID, setLastNotificationID] = useState(null);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const openNotificationMenu = Boolean(anchorNotification);
  const [notificationTab, setNotificationTab] = useState(1);
  const { fayeIcon, notificationBell } = useIcons();
  const { classes } = useStyle();
  const { sideBtns } = useSidebarFunc();
  const [openNewProject, setOpenNewProject] = useState(false);
  const open = false;

  const [settingOptionsAnchorEl, setSettingOptionsAnchorEl] = useState(null);
  const [settingMovieOptions, setOpenSettingOptions] = useState(false);

  const closeSettingOptions = (event) => {
    event.stopPropagation();
    setSettingOptionsAnchorEl(null);
    setOpenSettingOptions(false);
  };

  const handleClickLogoutManagement = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('fayeproject');
    localStorage.removeItem('username');
    window.location.href = '/login';
  };

  const handleClickManagement = (event) => {
    window.location.href = '/userManagement';
  };

  const handleClickSettingManagement = (event) => {
    history.push('/settings');
  };

  const handleClickNotification = (e, lastNotificationID) => {
    setAnchorNotification(e.currentTarget);
    if (hasNewNotifications) {
      setHasNewNotifications(false);
      if (lastNotificationID) {
        const { req } = companyApi.saveLastNotification(lastNotificationID);
      }
    }
  };

  const getNotifications = () => {
    const { req } = companyApi.getNotifications();
    req.then(({ data }) => {
      setNotifications(data.notifications.objects);
      if (data.notifications.objects.length > 0) {
        setLastNotificationID(data.notifications.objects[0].id);
        if (data.notifications.objects[0].id !== data.notifications.key) {
          setHasNewNotifications(true);
        }
      }
    });
  };

  useEffect(() => {
    getNotifications();
  }, [triggerNotifications]);

  useEffect(() => {
    const { axiosReq, cancelTokenSource } = projectApi.getAppVersion();
    axiosReq.then((resp) => {
      setAppVersion(resp.data.data);
    });

    return () => {
      cancelTokenSource.cancel();
    };
  }, []);

  const createNewProjectFunc = (projectName, projectDescription, privacyID, contributorsList) => {
    if (!projectName) {
      toast.error('Enter project name', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      return;
    }

    let obj = {
      Name: projectName,
      Description: projectDescription,
      PrivacyID: privacyID,
      AddedContributors: contributorsList
    };

    const { axiosReq, cancelTokenSource } = projectApi.createNewProject(obj);
    axiosReq.then((resp) => {
      if (resp.data.success === true) {
        localStorage.removeItem('fayeproject');
        localStorage.setItem('fayeproject', resp.data.data);
        history.push('/editor');
      } else {
        toast.error('Error ' + resp.data.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    });

    setOpenNewProject(false);
  };

  if (window.location.pathname.toLowerCase() === '/confirmation' || window.location.pathname.toLowerCase() === '/signup') {
    return <div></div>;
  }

  const StyleMenuItem = styled(MenuItem)(() => ({
    '&:hover': {
      backgroundColor: '#675D9B',
      borderRadius: '12px'
    }
  }));

  return (
    <div className={classes.sidebarParentWrapp}>
      <div className={classes.sidebarWrapp}>
        <div className={classes.sidebar}>
          <span style={{ color: 'white', marginBottom: '5px' }}>{appVersion}</span>
          <div className={classes.logoWrapp}>
            <img src={fayeIcon} alt="fayeIcon" />
          </div>
          <div className={classes.innerSidebar}>
            {sideBtns()}
          
            <div className={classes.floatBtnWrapp}>
              {showSideMenu && <AiOutlineMenuFold style={{ fontSize: '33px', bottom: '20px', color: 'white', position: 'absolute' }} onClick={() => setShowSideMenu(false)} />}
              <div type="button" className={classes.floatBtnWrappInner}>
                <Tooltip title="New project">
                  <AddCircleIcon
                    style={{ fontSize: '80px', color: '#A996FF' }}
                    className={classes.addIcon}
                    onClick={() => {
                      setOpenNewProject(true);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>

          <div style={{ position: 'absolute', bottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton onClick={(e) => handleClickNotification(e, lastNotificationID)} size="small" aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
              <img src={notificationBell} alt="notificationBell" />
              {hasNewNotifications && <span style={{ width: '12px', height: '12px', backgroundColor: '#A996FF', borderRadius: '20px', position: 'absolute', top: '0', right: '5px', outline: '2px solid #262338' }}></span>}
            </IconButton>

            <IconButton
              onClick={(event) => {
                if (settingMovieOptions == false) {
                  event.stopPropagation();
                  setSettingOptionsAnchorEl(event.currentTarget);
                  setOpenSettingOptions(true);
                } else {
                  closeSettingOptions(event);
                }
              }}
            >
              <Avatar className={classes.unameWrapp} round="true" src={profilePic} name={firstName + ' ' + lastName} />
            </IconButton>
          </div>
        </div>
      </div>

      <Menu
        id="notifications-menu"
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        anchorEl={anchorNotification}
        open={openNotificationMenu}
        onClose={() => setAnchorNotification(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
        PaperProps={{
          style: {
            width: '250px',
            backgroundColor: '#262338',
            overflow: 'hidden'
          }
        }}
      >
        <div style={{ fontFamily: "'Poppins'", paddingLeft: '10px', fontSize: '15px', color: '#FFFFFF' }}>Notifications</div>
        <div style={{ padding: '0 10px' }}>
          <button
            style={{
              width: '50%',
              background: 'transparent',
              border: 'none',
              color: notificationTab === 1 ? 'white' : '#6E7191',
              borderBottom: notificationTab === 1 ? '2px solid #FFFFFF' : '2px solid #6E7191',
              textAlign: 'left',
              paddingBottom: '10px',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '13px'
            }}
            onClick={() => setNotificationTab(1)}
          >
            All
          </button>
          <button
            style={{
              width: '50%',
              background: 'transparent',
              border: 'none',
              color: notificationTab === 2 ? 'white' : '#6E7191',
              borderBottom: notificationTab === 2 ? '2px solid #FFFFFF' : '2px solid #6E7191',
              textAlign: 'left',
              paddingBottom: '10px',
              fontFamily: "'Poppins', sans-serif",
              fontSize: '13px'
            }}
            onClick={() => setNotificationTab(2)}
          >
            Unread
          </button>
        </div>
        <div className="vertical-scroll-box" style={{ maxHeight: '220px', overflow: 'auto' }}>
          {notificationTab === 1 &&
            notifications.map((x) => (
              <div key={x.id} style={{ position: 'sticky' }}>
                <div style={{ width: '12px', height: '12px', backgroundColor: '#A996FF', borderRadius: '20px', outline: '2px solid #262338', position: 'absolute', margin: '10px' }}></div>
                <div style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'rgb(255, 255, 255)', marginLeft: '30px', marginRight: '10px', paddingTop: '6px', lineBreak: 'anywhere' }}>{x.text}</div>
              </div>
            ))}
          {notificationTab === 2 &&
            notifications
              .filter((item) => item.id > lastNotificationID)
              .map((x) => (
                <div key={x.id} style={{ position: 'sticky' }}>
                  <div style={{ width: '12px', height: '12px', backgroundColor: '#A996FF', borderRadius: '20px', outline: '2px solid #262338', position: 'absolute', margin: '10px' }}></div>
                  <div style={{ fontFamily: 'Poppins', fontSize: '13px', color: 'rgb(255, 255, 255)', marginLeft: '30px', marginRight: '10px', paddingTop: '6px', lineBreak: 'anywhere' }}>{x.text}</div>
                </div>
              ))}
        </div>
      </Menu>

      <ClickAwayListener onClickAway={(event) => closeSettingOptions(event)}>
        <Popper open={settingMovieOptions} anchorEl={settingOptionsAnchorEl} placement={'right'} transition style={{ zIndex: 10 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={{ bgcolor: '#262338', color: 'white', borderRadius: '20px', marginLeft: '3px' }}>
                <MenuList>
                  <StyleMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      handleClickSettingManagement(e);
                      closeSettingOptions(e);
                    }}
                  >
                    Settings
                  </StyleMenuItem>
                  {localStorage.getItem('role') === '47db376f-38fb-43f5-859d-45bd145adbfd' && <MenuItem onClick={handleClickManagement}>User Management</MenuItem>}
                  <StyleMenuItem
                    onClick={(e) => {
                      closeSettingOptions(e);
                      handleClickLogoutManagement(e);
                      e.stopPropagation();
                    }}
                  >
                    Logout
                  </StyleMenuItem>
                </MenuList>
              </Box>
            </Fade>
          )}
        </Popper>
      </ClickAwayListener>

      {openNewProject && (
        <ProjectSettingsDialogue
          data={{}}
          setOpenProjSettingsDialog={setOpenNewProject}
          openProjSettingsDialog={openNewProject}
          projects={[]}
          setProjects={() => {}}
          isNewProject={true}
          handleSaveNewProject={createNewProjectFunc}
        />
      )}
    </div>
  );
};

export default Sidebar;
